"use strict";
(function (module) {
    'use strict';
    module.factory('prWidgetPadding', [
        'prFlowingPages',
        'rsEnvironment',
        'pr.globalOptionsService',
        '$filter',
        function (flowingPages, env, globalOptions, $filter) {
            var translate = $filter('translate');
            var DEFAULT_TYPE = 'default', PADDING_PROPS = ['top', 'right', 'bottom', 'left'], PADDING_VALUES = [0, 0.11, 0.22, 0.33], WIDGET_PADDING_TYPES = {
                NONE: 'nomargins',
                COMPACT: 'compact',
                NORMAL: 'normal',
                SPACIOUS: 'spacious'
            }, NONE_PADDING_STYLE = {
                padding: '0 1px 1px 0'
            }, WIDGET_PADDING_OPTIONS = {
                nomargins: {
                    label: translate('NONE'),
                    value: WIDGET_PADDING_TYPES.NONE,
                },
                compact: {
                    label: translate('COMPACT'),
                    value: WIDGET_PADDING_TYPES.COMPACT,
                },
                normal: {
                    label: translate('NORMAL'),
                    value: WIDGET_PADDING_TYPES.NORMAL,
                },
                spacious: {
                    label: translate('SPACIOUS'),
                    value: WIDGET_PADDING_TYPES.SPACIOUS,
                }
            };
            /**
             * check if padding layout has been edited
             * by checking if all values are equal
             * and comparing each layout value with 0.11, 0.22, and 0.33
             */
            function editedPaddingLayout(paddingLayout) {
                var paddingValues = _.values(paddingLayout);
                if (_.uniq(paddingValues).length !== 1) {
                    return true;
                }
                var res = false;
                paddingValues.forEach(function (val) {
                    if (!_.includes(PADDING_VALUES, val)) {
                        res = true;
                        return;
                    }
                });
                return res;
            }
            // Padding menu for global options
            function getWidgetPaddingMenu() {
                return _.values(WIDGET_PADDING_OPTIONS);
            }
            function getDefaultPaddingLabel(type) {
                var def = translate('DEFAULT');
                return def + ' (' + getLabel(type) + ')';
            }
            // Padding menu for widget in options pane
            function getWidgetPaddingMenuWithDefault() {
                var globalPaddingType = globalOptions.getWidgetPadding();
                var menu = [{
                        label: getDefaultPaddingLabel(globalPaddingType),
                        value: DEFAULT_TYPE
                    }, {
                        divider: true
                    }];
                return menu.concat(getWidgetPaddingMenu());
            }
            function getLabel(type) {
                if (type === DEFAULT_TYPE) {
                    type = globalOptions.getWidgetPadding();
                    return getDefaultPaddingLabel(type);
                }
                return WIDGET_PADDING_OPTIONS[type].label;
            }
            // returned value should be in inches
            function getPaddingValue(type) {
                switch (type) {
                    case WIDGET_PADDING_TYPES.NONE:
                        return 0;
                    case WIDGET_PADDING_TYPES.COMPACT:
                        return 0.11;
                    case WIDGET_PADDING_TYPES.NORMAL:
                        return 0.22;
                    case WIDGET_PADDING_TYPES.SPACIOUS:
                        return 0.33;
                    default:
                        break;
                }
            }
            function getPaddingOfWidget(widgetId) {
                var widget = getWidget(widgetId);
                if (widget && widget.layout && widget.layout.padding
                    && (widget.layout.padding.type !== DEFAULT_TYPE
                        || editedPaddingLayout(widget.layout.padding.layout))) {
                    return _.clone(widget.layout.padding);
                }
                var globalPaddingType = globalOptions.getWidgetPadding();
                return {
                    type: DEFAULT_TYPE,
                    layout: getLayoutOfPaddingType(globalPaddingType)
                };
            }
            /**
             * Get the padding layout object of a padding type
             *
             * @param {string} type - padding type. e.g. compact
             * @return {object} layout - padding layout. e.g. {top: 0.11, right: 0.11, bottom: 0.11, left: 0.11}
             */
            function getLayoutOfPaddingType(type) {
                if (type === DEFAULT_TYPE) {
                    type = globalOptions.getWidgetPadding();
                }
                var num = getPaddingValue(type);
                var res = {};
                _.forEach(PADDING_PROPS, function (prop) {
                    res[prop] = num;
                });
                return res;
            }
            function getWidget(widgetId) {
                if (!widgetId) {
                    widgetId = env.get('selectedWidgetId');
                }
                return flowingPages.getWidget(widgetId);
            }
            function getPaddingStyleOfWidget(widgetId) {
                var padding = getPaddingOfWidget(widgetId);
                // Make an exception for no margins option
                if (padding && padding.layout && padding.layout.top === 0) {
                    return NONE_PADDING_STYLE;
                }
                var res = {};
                _.forEach(padding.layout, function (value, key) {
                    var newKey = 'padding-' + key;
                    res[newKey] = value + 'in';
                });
                return res;
            }
            return {
                editedPaddingLayout: editedPaddingLayout,
                getPaddingOfWidget: getPaddingOfWidget,
                getPaddingStyleOfWidget: getPaddingStyleOfWidget,
                getWidgetPaddingMenu: getWidgetPaddingMenu,
                getWidgetPaddingMenuWithDefault: getWidgetPaddingMenuWithDefault,
                getLayoutOfPaddingType: getLayoutOfPaddingType,
                getLabel: getLabel,
                getPaddingValue: getPaddingValue
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
