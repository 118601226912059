"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var filter_utils_1 = require("@shared/utils/filter-utils");
var angular_1 = require("angular");
(function (angularModule) {
    angularModule.factory('pr.sharedFilterUtils', function () {
        return {
            updateFiltersForDataConfig: filter_utils_1.updateFiltersForDataConfig,
            fixOldPRFilters: filter_utils_1.fixOldPRFilters
        };
    });
})(angular_1.module('qualtrics.pr'));
