"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@services/report-state/constants");
var angular_1 = require("angular");
var TRANSLATION_KEY_PREFIX = 'PR.SECTION_WIDGET_LIMIT';
// @ts-ignore
// @ts-ignore
// @ts-ignore
function SectionWidgetLimitModalFactory($rootScope, $filter, modal) {
    var translate = $filter('translate');
    var $scope = {};
    return {
        onAddWidget: function () {
            showModal('ADD_WIDGET');
        },
        onMoveWidget: function () {
            showModal('MOVE_WIDGET');
        },
        onDeletePageBreak: function () {
            showModal('DELETE_PAGE_BREAK');
        }
    };
    // @ts-ignore
    function showModal(contentTranslationKey) {
        if ($scope.$$childHead) {
            // only show one modal at a time
            return;
        }
        $scope = $rootScope.$new();
        modal({
            show: true,
            scope: $scope,
            title: translate(TRANSLATION_KEY_PREFIX + ".TITLE"),
            content: translate(TRANSLATION_KEY_PREFIX + "." + contentTranslationKey, {
                widgetLimit: constants_1.WIDGET_LIMIT_PER_SECTION
            }),
            destroyScopeOnClose: true
        });
    }
}
(function (module) {
    module.factory('pr.sectionWidgetLimitModal', [
        '$rootScope',
        '$filter',
        'qstrap.modal',
        SectionWidgetLimitModalFactory
    ]);
})(angular_1.module('qualtrics.pr'));
