"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
(function (module) {
    var DisplayLogicConstants = function ($filter) {
        var _a, _b;
        var translate = $filter('translate');
        var displayLogicTypes = {
            or: translate('ANY'),
            and: translate('ALL')
        };
        var metrics = {
            avg: translate('MEAN'),
            min: translate('MIN'),
            max: translate('MAX'),
            count: translate('RESPONSE_COUNT'),
            sum: translate('SUM')
        };
        var rankableTypes = {
            scoringCategory: translate('AWA.DATA_SOURCE_TYPE_SINGLE_CATEGORY'),
            question: translate('AWA.DATA_SOURCE_TYPE_QUESTION_SINGULAR')
        };
        var rankableOperators = {
            itemHas: translate('AWA.RANKABLE_ITEMS_ITEM_HAS'),
            itemIsIn: translate('AWA.RANKABLE_ITEMS_ITEM_IS_IN_TOP')
        };
        var rankableComparators = {
            highestScore: translate('AWA.RANKABLE_ITEMS_HIGHEST_SCORE'),
            lowestScore: translate('AWA.RANKABLE_ITEMS_LOWEST_SCORE')
        };
        var metricOperators = {
            equals: translate('FORMAT_RULES.EQUALS'),
            notEquals: translate('FORMAT_RULES.DOES_NOT_EQUAL'),
            lessThan: translate('FORMAT_RULES.IS_LESS_THAN'),
            lessThanOrEqualTo: translate('FORMAT_RULES.IS_LESS_THAN_OR_EQUAL_TO'),
            greaterThan: translate('FORMAT_RULES.IS_GREATER_THAN'),
            greaterThanOrEqualTo: translate('FORMAT_RULES.IS_GREATER_THAN_OR_EQUAL_TO')
        };
        var raterOperators = (_a = {},
            _a["moreThan" /* MORE_THAN */] = translate('FORMAT_RULES.MORE_THAN'),
            _a["atLeast" /* AT_LEAST */] = translate('FORMAT_RULES.AT_LEAST'),
            _a["exactly" /* EXACTLY */] = translate('FORMAT_RULES.EXACTLY'),
            _a["fewerThan" /* FEWER_THAN */] = translate('FORMAT_RULES.FEWER_THAN'),
            _a["atMost" /* AT_MOST */] = translate('FORMAT_RULES.AT_MOST'),
            _a);
        var threeSixtyOperators = {
            is: translate('FORMAT_RULES.IS'),
            isNot: translate('FORMAT_RULES.IS_NOT')
        };
        var BASIC_DISPLAY_LOGIC_OBJECT = {
            type: 'displayLogic',
            displayLogic: {}
        };
        var CATEGORIES = {
            METRIC: 'metric',
            RELATIONSHIP: 'relationship',
            PERSON_METADATA: 'personMetadata',
            RANKABLE_ITEMS: 'rankableItems'
        };
        var categoryOptions = (_b = {},
            _b[CATEGORIES.METRIC] = translate('METRIC'),
            _b[CATEGORIES.RELATIONSHIP] = translate('PR.RELATIONSHIP'),
            _b[CATEGORIES.PERSON_METADATA] = translate('PR.SUBJECT_METADATA'),
            _b[CATEGORIES.RANKABLE_ITEMS] = 'RANKABLE_ITEMS',
            _b);
        this.getDisplayLogicTypeOptions = function () {
            return displayLogicTypes;
        };
        this.getMetricOptions = function () {
            return metrics;
        };
        this.getOperatorOptions = function (category) {
            switch (category) {
                case CATEGORIES.RELATIONSHIP:
                case CATEGORIES.PERSON_METADATA:
                    return threeSixtyOperators;
                case CATEGORIES.RANKABLE_ITEMS:
                    return rankableOperators;
                default:
                    return metricOperators;
            }
        };
        this.getCategoryOptions = function () {
            return categoryOptions;
        };
        this.getBasicDisplayLogicObj = function () {
            return _.cloneDeep(BASIC_DISPLAY_LOGIC_OBJECT);
        };
        this.CATEGORIES = CATEGORIES;
        this.getRaterOperatorOptions = function () {
            return raterOperators;
        };
        this.getRankableTypes = function () {
            return rankableTypes;
        };
        this.getRankableComparators = function () {
            return rankableComparators;
        };
    };
    DisplayLogicConstants.$inject = [
        '$filter',
        'rsEnvironment'
    ];
    module.service('prDisplayLogicConstants', DisplayLogicConstants);
})(angular.module('qualtrics.pr'));
