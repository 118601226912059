"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@services/font-loading-manager");
var MAX_WAIT_FOR_LAYOUT_STYLES_MS = 20000;
var WIDGET_STATUSES = {
    COMPLETED: 'COMPLETED',
    DATA_COMPONENT_LOADED: 'DATA_COMPONENT_LOADED',
    DATA_ERROR: 'DATA_ERROR',
    DATA_LOADED: 'DATA_LOADED',
    EXPECTED_DATA_ERROR: 'EXPECTED_DATA_ERROR',
    INITIALIZED: 'INITIALIZED',
    NOT_REGISTERED: 'NOT_REGISTERED',
    RESIZED: 'RESIZED',
    VIEW_ERROR: 'VIEW_ERROR',
    UNEXPECTED_DATA_ERROR: 'UNEXPECTED_DATA_ERROR'
};
(function (module) {
    /*
     * WidgetManager is incharge of all actions that affect a widget, nothing should talk
     * dirctly to a widget but rather to widgetmanger, which will notify widgets of events.
     */
    module.value('pr.WIDGET_READY_STATUSES', [
        WIDGET_STATUSES.COMPLETED,
        WIDGET_STATUSES.DATA_ERROR,
        WIDGET_STATUSES.VIEW_ERROR,
        WIDGET_STATUSES.EXPECTED_DATA_ERROR,
        WIDGET_STATUSES.UNEXPECTED_DATA_ERROR
    ]);
    var WIDGET_MANAGER_EVENTS = {
        FILTERS_CHANGE: 'filtersChanged',
        RESIZED: 'resized',
        GLOBAL_OPTIONS_CHANGED: 'globalOptionsChanged',
        PADDING_CHANGED: 'widgetPaddingChanged',
        FONT_STYLE_CHANGED: 'widgetStyleChanged',
        WIDGET_TITLE_REQUEST: 'widgetTitleRequest',
        GLOBAL_DISPLAY_LOGIC_CHANGED: 'globalDisplayLogicChanged',
        TABLE_DENSITY_CHANGED: 'widgetTableBodyDensityChanged',
        LANGUAGE_CHANGED: 'languageChanged',
        WIDGET_SECTION_UPDATED: 'widgetSectionUpdated',
        OPTIONS_PANEL_LOADED: 'optionsPanelLoaded',
        RATER_DISPLAY_LOGIC_CHANGED: 'raterDisplayLogicChanged',
        SAVE_AGGREGATE_REQUESTS: 'saveAggregateRequests',
        SET_CATEGORIES_QUESTIONS_ORDER: 'setCategoriesQuestionsOrder',
        SAVE_MESSAGE_OPS: 'saveMessageOps',
        CUSTOM_METRICS_CHANGED: 'customMetricsChanged'
    };
    var RSDK_ONLY_EVENTS = [
        WIDGET_MANAGER_EVENTS.OPTIONS_PANEL_LOADED
    ];
    module.value('pr.WIDGET_MANAGER_EVENTS', WIDGET_MANAGER_EVENTS);
    module.factory('pr.widgetManager', [
        '$injector',
        '$routeParams',
        'rsEnvironment',
        'prEventService',
        'prOverrideTrigger',
        'pr.WIDGET_READY_STATUSES',
        'pr.OVERRIDE_EVENTS',
        'pr.widgetConstants',
        '$q',
        'pr.fontLoadingManager',
        'pr.EXPORT_FILE_TYPES',
        'pr.navigationService',
        function ($injector, $routeParams, env, eventService, triggerOverrides, WIDGET_READY_STATUSES, OVERRIDE_EVENTS, widgetConstants, $q, fontLoadingManager, EXPORT_FILE_TYPES, navigationService) {
            var FILE_TYPES_THAT_NEED_LAYOUT_STYLES = [EXPORT_FILE_TYPES.PPTX, EXPORT_FILE_TYPES.DOCX];
            // Map of widgetId to status (current widget status) and event (service to trigger widget events)
            var widgetData = {};
            // Log of events associated with widgetManager, what service triggered events and what widgets did in response
            var eventLog = [];
            var isReady = false;
            var checkReady = _.debounce(function () {
                if (isReady) {
                    return;
                }
                if (isReportReady()) {
                    isReady = true;
                    logEvent('Report Ready');
                    eventService.trigger('reportReady');
                }
            }, 300);
            var _partialDataService;
            function getPartialDataService() {
                if (!_partialDataService) {
                    // must be injected here to prevent circular dependency
                    _partialDataService = $injector.get('prPartialDataService');
                }
                return _partialDataService;
            }
            /*
             * Logs what widgetManager is doing
             *
             * ex: logEvent('Status Changed: LOADING->DONE', 'WIDGET_123');
             *
             * @param event {string} Short description of what took place
             * @param widgetId {string} optional ID of widget if only one widget involved
             */
            function logEvent(event, widgetId, widgetType) {
                eventLog.push({
                    date: +new Date(),
                    event: event,
                    widgetId: widgetId,
                    widgetType: widgetType
                });
            }
            logEvent('start pr.WidgetManager');
            /*
             * Starts tracking a widget
             * @param widgetId {string}
             */
            function registerWidget(widgetId, widgetType, widget) {
                var eventStream = eventService.stream(widgetId);
                widgetData[widgetId] = {
                    status: undefined,
                    type: widgetType,
                    events: eventStream,
                    widget: widget
                };
                updateWidgetStatus(widgetId, WIDGET_STATUSES.INITIALIZED);
            }
            /*
             * Enforce that the event is valid, mostly to keep them documented in pr.WIDGET_MANAGER_EVENTS
             */
            function isValidEvent(event) {
                return _.includes(_.values(WIDGET_MANAGER_EVENTS), event);
            }
            /**
             * Currently, we have some events that are only supported by rsdk widgets
             */
            function doesWidgetSupportEvent(widgetId, event) {
                var widgetType = _.get(widgetData, [widgetId, 'type']);
                return !_.includes(RSDK_ONLY_EVENTS, event) || _.includes(widgetConstants.getRsdkWidgets(), widgetType);
            }
            /*
             * Notifies single widget of an event
             *
             * Will trigger an event in the widget: trigger('wm.' + EVENT, DATA);
             *
             * @param widgetId {string}
             * @param event {string} short ID of what happend (filterChanged, inView, ...)
             * @param trigger {string} short ID of who is calling for tracking purposes (filterService, widgetRenderingService, ...)
             * @param data {mixed} any data to pass to widget with event
             */
            function notifyWidget(widgetId, event, triggerer, data) {
                if (!isValidEvent(event) || !widgetData[widgetId] || !doesWidgetSupportEvent(widgetId, event)) {
                    return false;
                }
                logEvent(triggerer + ' notifying widget of ' + event, widgetId, widgetData[widgetId].type);
                widgetData[widgetId].events.trigger(event, data);
                return true;
            }
            /*
             * Notifies all widget of an event
             *
             * Will trigger an event in all widgets: trigger('wm.' + EVENT, DATA);
             *
             * @param event {string} short ID of what happend (filterChanged, inView, ...)
             * @param trigger {string} short ID of who is calling for tracking purposes (filterService, widgetRenderingService, ...)
             * @param data {mixed} any data to pass to widget with event
             */
            function notifyAllWidgets(event, triggerer, data) {
                if (!isValidEvent(event)) {
                    return false;
                }
                logEvent(triggerer + ' notifying all widgets of ' + event);
                _.forEach(widgetData, function (on, widgetId) {
                    if (doesWidgetSupportEvent(widgetId, event)) {
                        on.events.trigger(event, data);
                    }
                });
                return true;
            }
            /*
             * returns status of requested widget
             */
            function getWidgetStatus(widgetId) {
                return (widgetData[widgetId] || {}).status || WIDGET_STATUSES.NOT_REGISTERED;
            }
            function getWidgetComponent(widgetId) {
                return (widgetData[widgetId] || {}).widget || {};
            }
            function isWidgetReady(widgetId) {
                return _.includes(WIDGET_READY_STATUSES, getWidgetStatus(widgetId));
            }
            /**
             * Returns a promise that resolves with the data config for the given widget id
             * If the data config is already loaded, then resolves with it directly.
             * Otherwise, wait for the data component to be fully loaded and then return the data config.
             */
            function getWidgetDataConfig(widgetId) {
                return $q.resolve().then(function () {
                    if (!widgetId) {
                        return;
                    }
                    var component = getWidgetComponent(widgetId);
                    var dataComponent = _.get(component, 'lifecycle.dataComponent');
                    if (_.isEmpty(dataComponent)) {
                        return;
                    }
                    var getCurrDataConfig = function () { return _.get(component, 'lifecycle.dataConfig'); };
                    var dataConfig = getCurrDataConfig();
                    if (!_.isEmpty(dataConfig)) {
                        return dataConfig;
                    }
                    return waitForDataComponentToLoad(component).then(getCurrDataConfig);
                });
            }
            /**
             * Returns a promise that resolves after the data component loads.
             * Waits for a maximum of X seconds to prevent possibly waiting forever.
             */
            function waitForDataComponentToLoad(widgetComponent) {
                return $q(function (resolve) {
                    var timeoutId;
                    widgetComponent.lifecycle.status.onStatus(WIDGET_STATUSES.DATA_COMPONENT_LOADED, function () {
                        clearTimeout(timeoutId);
                        resolve();
                    });
                    /**
                     * Add this so that we don't get stuck forever
                     * if something breaks with the onStatus() call
                     */
                    var MAX_TIME_TO_WAIT_MS = 5000;
                    timeoutId = setTimeout(function () {
                        console.error("DATA_COMPONENT_LOADED event took over " + MAX_TIME_TO_WAIT_MS + "ms to trigger");
                        resolve();
                    }, MAX_TIME_TO_WAIT_MS);
                });
            }
            /*
             * returns map of widgetId to current status for that widget
             */
            function getAllWidgetsStatus() {
                var map = {};
                _.forEach(widgetData, function (on, id) {
                    map[id] = on.status;
                });
                return map;
            }
            /*
             * updates status of specific widget
             */
            function updateWidgetStatus(widgetId, status) {
                var oldStatus = widgetData[widgetId].status;
                logEvent('Status change: ' + oldStatus + '->' + status, widgetId, widgetData[widgetId].type);
                widgetData[widgetId].status = status;
                // statuses that trigger when a widget tries to load data
                var DATA_STATUSES = [
                    WIDGET_STATUSES.DATA_LOADED,
                    WIDGET_STATUSES.DATA_ERROR,
                    WIDGET_STATUSES.UNEXPECTED_DATA_ERROR,
                    WIDGET_STATUSES.EXPECTED_DATA_ERROR
                ];
                if (_.includes(DATA_STATUSES, status)) {
                    widgetData[widgetId].usingPartialData = getPartialDataService().usingPartialData();
                }
                checkReady();
                widgetData[widgetId].events.trigger(getWidgetStatusEventName(status));
            }
            function onWidgetStatusUpdate(widgetId, status, callback) {
                // can't use widgetData[].events.trigger because the widget may not be registered yet
                var eventStream = eventService.stream(widgetId);
                eventStream.on(getWidgetStatusEventName(status), callback);
            }
            function someWidgetsUsingPartialData() {
                return _.some(widgetData, 'usingPartialData');
            }
            /**
             * We must wait for chunked widgets to finish chunking.
             * This is needed because widget chunking happens after a widget is
             * in completed status.
             *
             * We also must wait for the layout styles to be set.
             * This is needed because the layout styles are set sometime after chunking.
             * The layout styles must be set for pptx/docx exports (see: ppt-export-regions-service.js)
            */
            function isChunkedWidgetReady(widget, status) {
                if (isInErrorStatus(status)) {
                    return true;
                }
                if (!isFinishedChunking(widget)) {
                    return false;
                }
                if (_.includes(FILE_TYPES_THAT_NEED_LAYOUT_STYLES, $routeParams.fileType)) {
                    return !isWaitingForLayoutStyles(widget);
                }
                return true;
            }
            function isInErrorStatus(status) {
                var ERROR_STATUSES = [
                    WIDGET_STATUSES.DATA_ERROR,
                    WIDGET_STATUSES.EXPECTED_DATA_ERROR,
                    WIDGET_STATUSES.UNEXPECTED_DATA_ERROR,
                    WIDGET_STATUSES.VIEW_ERROR
                ];
                return _.includes(ERROR_STATUSES, status);
            }
            function isFinishedChunking(widget) {
                return widget.finishedChunking;
            }
            function isWaitingForLayoutStyles(widget) {
                if (!widget.beginWaitingForLayoutStylesTime) {
                    return false;
                }
                // just in case layout styles don't get set due to some glitch, do not wait forever
                return new Date() - new Date(widget.beginWaitingForLayoutStylesTime) < MAX_WAIT_FOR_LAYOUT_STYLES_MS;
            }
            function onChunkingInProgress(widget) {
                widget.finishedChunking = false;
            }
            function onChunkingFinished(widget, options) {
                if (options === void 0) { options = {}; }
                widget.finishedChunking = true;
                if (options.wasRowSpanUpdated) {
                    widget.beginWaitingForLayoutStylesTime = new Date().toISOString();
                }
            }
            function onSetLayoutStyles(widget) {
                delete widget.beginWaitingForLayoutStylesTime;
            }
            /*
             * returns true if report is ready, i.e.
             * 	- partial data has been checked,
             * 	- no widgets are using partial data
             * 	- all widgets are in ready, error, no data, or misconfigured state
             * 	- no fonts are currently being loaded
             */
            function isReportReady(genericExportArgs) {
                var activeWidgets = getActiveWidgets();
                if (_.isEmpty(widgetData)) {
                    /**
                     * No widgets have been registered with widgetManager yet.
                     * There are two options:
                     * 	(1) There are no widgets in the report, in which case the report is ready.
                     * 	(2) There ARE widgets in the report, in which case the report is not ready.
                     */
                    return !activeWidgets.length;
                }
                if (!getPartialDataService().isPartialDataCheckComplete() ||
                    someWidgetsUsingPartialData()) {
                    return false;
                }
                if (fontLoadingManager.areFontsLoading()) {
                    /**
                     * We need to wait because widgets can enter the ready status
                     * before their required fonts have loaded, which will result in
                     * invisible/incorrect text until the fonts load.
                     */
                    return false;
                }
                var widgetStatuses = getAllWidgetsStatus();
                var statuses = _.values(widgetStatuses);
                var widgetTypes = _.mapValues(widgetData, 'type');
                var widgetStatusAndTypes = {
                    status: widgetStatuses,
                    types: widgetTypes
                };
                if (genericExportArgs) {
                    triggerOverrides({
                        event: OVERRIDE_EVENTS.THROW_FAILED_EXPORTS,
                        data: widgetStatusAndTypes,
                        sync: true,
                        throwErrors: true
                    });
                    // GE Export
                    return _.every(activeWidgets, function (widget) {
                        if (widget._type === 'pr.pagebreak') {
                            return true;
                        }
                        var widgetStatus = widgetStatuses[widget._id];
                        if (widget.isChunked && !isChunkedWidgetReady(widget, widgetStatus)) {
                            return false;
                        }
                        return widgetStatus && _.includes(WIDGET_READY_STATUSES, widgetStatus);
                    });
                }
                return !_.difference(statuses, WIDGET_READY_STATUSES).length;
            }
            function getActiveWidgets() {
                var sectionManager = $injector.get('prSectionManager');
                var activeWidgets = sectionManager.getWidgets();
                if (!navigationService.isSingleWidget) {
                    return activeWidgets;
                }
                var activeWidget = _.find(activeWidgets, { _id: $routeParams.widgetId });
                return activeWidget ? [activeWidget] : [];
            }
            /*
             * returns true if report is ready (all widgets in ready, error, no data, or misconfigured state)
             */
            function onReportReady(cb) {
                if (isReady) {
                    cb();
                }
                else {
                    eventService.on('reportReady', cb);
                }
            }
            /**
             * Only use this on single widget pages since it just takes the bounding rect of the first widget
             */
            function getSingleWidgetRect() {
                var el = $('.pr-single-widget');
                var offset = el.offset();
                var top = offset.top;
                var left = offset.left;
                var styles = el[0].style;
                var bottom = top + el[0].clientHeight;
                var right = left + parseInt(styles.width);
                return {
                    x: top,
                    y: left,
                    height: top + bottom,
                    width: left + right
                };
            }
            function getWidgetStatusesLog() {
                var sectionManager = $injector.get('prSectionManager');
                var activeWidgets = sectionManager.getWidgets();
                var widgetStatuses = getAllWidgetsStatus();
                var logs = [];
                _.forEach(activeWidgets, function (widget) {
                    if (widget._type === 'pr.pagebreak') {
                        return;
                    }
                    logs.push({
                        widgetId: widget._id,
                        widgetType: widget._type,
                        widgetStatus: widgetStatuses[widget._id]
                    });
                });
                return logs;
            }
            /**
             * Return all widgets that are in UNEXPECTED_DATA_ERROR status
             */
            function getErroredWidgets() {
                return _.pickBy(getAllWidgetsStatus(), function (status) {
                    return status === WIDGET_STATUSES.UNEXPECTED_DATA_ERROR;
                }) || {};
            }
            function getWidgetStatusEventName(status) {
                return "WIDGET_STATUS:" + status;
            }
            return {
                // Add a widget
                registerWidget: registerWidget,
                // Notify widgets that they might need to take an action (reload data)
                notifyAllWidgets: notifyAllWidgets,
                notifyWidget: notifyWidget,
                // Get status of widgets
                getAllWidgetsStatus: getAllWidgetsStatus,
                getErroredWidgets: getErroredWidgets,
                getWidgetStatus: getWidgetStatus,
                getWidgetStatusesLog: getWidgetStatusesLog,
                isWidgetReady: isWidgetReady,
                getActiveWidgets: getActiveWidgets,
                // Get the bounding rectangle of a single widget on the page (mostly for exports)
                getSingleWidgetRect: getSingleWidgetRect,
                // Get the widget component (componentBase) for a widget
                getWidgetComponent: getWidgetComponent,
                getWidgetDataConfig: getWidgetDataConfig,
                // Set status for a widget
                updateWidgetStatus: updateWidgetStatus,
                onWidgetStatusUpdate: onWidgetStatusUpdate,
                // Report
                isReportReady: isReportReady,
                onReportReady: onReportReady,
                eventLog: eventLog,
                // handle ready statuses for chunked widgets
                onChunkingInProgress: onChunkingInProgress,
                onChunkingFinished: onChunkingFinished,
                onSetLayoutStyles: onSetLayoutStyles
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
