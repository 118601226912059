"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
(function (module) {
    module.factory('pr.sourceManagement', [
        'prActiveReport',
        'rsEnvironment',
        'prEventService',
        'prSourceDataService',
        'prFieldsets',
        function (activeReport, env, eventService, sourceData, fieldsets) {
            var META_DATA_KEY = 'connectedSources';
            // SOURCE_LIST must be 'surveyId', not 'sourceId', because
            // ripsaw containers requier the 'surveyId' parameter to be used for sources
            var SOURCE_LIST = 'surveyId';
            function getSourceMeta() {
                return activeReport.getMetadata(META_DATA_KEY) || {};
            }
            function setReportMetadata(data) {
                var _a;
                return activeReport.setMetadata((_a = {},
                    _a[META_DATA_KEY] = data,
                    _a[SOURCE_LIST] = getSources(),
                    _a));
            }
            /**
             * Links the source(s) with the widgetId.
             *
             * @param {string} sourceId
             * @param {string} widgetId
             * @param {object} [options]
             * 	@param {boolean} [options.overwrite] Indicates whether sourceId should overwrite existing sources.
             * 		If not, it will concatenate the existing sources.
             */
            function linkSource(sourceId, widgetId, options) {
                options = options || {};
                var meta = getSourceMeta();
                if (!widgetId) {
                    widgetId = env.get('selectedWidgetId');
                }
                if (_.isArray(sourceId)) {
                    // Text widget with multiple piped text sources
                    meta[widgetId] = _.chain(options.overwrite ? [] : meta[widgetId] || [])
                        .concat(sourceId)
                        .uniq()
                        .compact()
                        .value();
                }
                else {
                    meta[widgetId] = sourceId;
                }
                setReportMetadata(meta);
                eventService.trigger('responseCountChanged');
                eventService.trigger('sourceLinked', {
                    sourceId: sourceId,
                    widgetId: widgetId
                });
            }
            function removeWidget(widgetId) {
                var meta = getSourceMeta();
                var sourceId = _.cloneDeep(meta[widgetId]);
                delete meta[widgetId];
                setReportMetadata(meta);
                eventService.trigger('responseCountChanged');
                eventService.trigger('sourceUnlinked', {
                    sourceId: sourceId,
                    widgetId: widgetId
                });
            }
            function getSources() {
                var connectedSourceIds = _.chain(getSourceMeta())
                    .values()
                    .flatten()
                    .value();
                var sourceIds = _.chain(__spreadArray([env.get('rsActiveSurvey')], connectedSourceIds))
                    .uniq()
                    .filter()
                    .value();
                var mainSourceId = fieldsets.getMainSourceId();
                if (mainSourceId && !_.includes(sourceIds, mainSourceId)) {
                    // always include the main source Id of the report
                    // except for public reports
                    sourceIds.push(mainSourceId);
                }
                return sourceIds;
            }
            return {
                linkSource: linkSource,
                removeWidget: removeWidget,
                getSources: getSources
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
