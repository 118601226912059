"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("@shared/types/environment");
var report_1 = require("@shared/types/report");
var angular_1 = require("angular");
(function (module) {
    'use strict';
    module.constant('pr.REPORT_TYPES', report_1.REPORT_TYPE);
    module.factory('pr.reportTypeService', [
        'rsEnvironment',
        function (env) {
            return {
                getReportType: function () {
                    return env.get(environment_1.ENV_VARS.REPORT_TYPE) || report_1.REPORT_TYPE.RC;
                }
            };
        }
    ]);
})(angular_1.module('qualtrics.pr'));
