"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var angular = __importStar(require("angular"));
var RenameReportController = /** @class */ (function () {
    function RenameReportController($scope, 
    // @ts-ignore
    $filter, 
    // @ts-ignore
    activeReport, 
    // @ts-ignore
    errorModal, 
    // @ts-ignore
    eventService, 
    // @ts-ignore
    sanitize) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.activeReport = activeReport;
        this.errorModal = errorModal;
        this.eventService = eventService;
        this.sanitize = sanitize;
        $scope.$ctrl = this;
        this.spin = false;
        this.translate = this.$filter('translate');
        this.oldReportName = this.activeReport.getDescription();
        this.reportName = this.oldReportName;
    }
    RenameReportController.prototype.renameReport = function () {
        var _this = this;
        var newName = this.sanitize.stripHTML(this.reportName);
        // Update UI right away with new name
        this.eventService.trigger('reportRenamed', newName);
        this.spin = true;
        this.activeReport.setDescription(newName)
            .catch(function () {
            _this.eventService.trigger('reportRenamed', _this.oldReportName);
            _this.errorModal.show(_this.translate('ERROR_RENAME_REPORT'));
        });
    };
    RenameReportController.$inject = [
        '$scope',
        '$filter',
        'prActiveReport',
        'prErrorModal',
        'prEventService',
        'prSanitize'
    ];
    return RenameReportController;
}());
(function (angularModule) {
    angularModule.controller('prRenameReportController', RenameReportController);
})(angular.module('qualtrics.pr'));
