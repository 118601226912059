"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
require("./cursor.less");
require("./widget-transformer.less");
(function (module) {
    'use strict';
    var REPORT_SELECTOR = 'pr-report', WIDGET_SELECTOR = 'pr-widget', ZOOM_PARENT_SELECTOR = '.pr-zoom-content', THROTTLE_DELAY = 100, 
    // 76px for the header + 64px buffer
    TOP_SCROLL_OFFSET = 76, SELECTION_ID_ATTR = 'selectionId', MULTI_SELECTED_CLASS = 'multiple-selected', WIDGET_MOVING_CLASS = 'widget-moving', BODY_SELECTOR = 'body';
    module.directive('prWidgetTransformer', [
        'prActiveReport',
        'prEventService',
        'prFloatingContainer',
        'prFlowingPages',
        'prPageLayoutService',
        'prViewOptions',
        'pr.widgetManager',
        '$window',
        'pr.widgetOperationsService',
        'pr.widgetUtils',
        'pr.sectionWidgetLimitModal',
        function (activeReport, eventService, floatingContainer, flowingPages, pageLayout, viewOptions, widgetManager, $window, widgetService, widgetUtils, sectionWidgetLimitModal) {
            return {
                scope: false,
                require: '^^prBody',
                link: function ($scope, $element, $attrs, $bodyCtrl) {
                    var cellSize = pageLayout.CELL_SIZE;
                    var selectedIds = [];
                    var transformStarted; // prevents a throttled transform event from triggering after finishTransform
                    init();
                    function init() {
                        $($element).transformer({
                            selectables: WIDGET_SELECTOR,
                            zoomParent: ZOOM_PARENT_SELECTOR,
                            snapX: cellSize,
                            snapY: cellSize,
                            maxWidth: getPageWidth(),
                            maxHeight: getPageHeight(),
                            select: select,
                            transformStart: startTransform,
                            transformFinished: finishTransform,
                            transform: transform,
                            topScrollOffset: TOP_SCROLL_OFFSET,
                            disabled: !!$bodyCtrl.isPublic
                        });
                        $($element).on('$destroy', destroy);
                        eventService.on('updatePageMargins', updateMaxes);
                        eventService.on('widgetsSelected', checkSelected);
                        eventService.on('zoomChange', positionResizer);
                        $($window).on('resize', positionResizer);
                    }
                    function destroy() {
                        $($element).transformer('destroy');
                    }
                    function positionResizer() {
                        $($element).transformer('positionResizer');
                    }
                    /** Page width minus margins */
                    function getPageWidth() {
                        return pageLayout.getCols() * cellSize;
                    }
                    /** Page height minus margins */
                    function getPageHeight() {
                        return pageLayout.getRows() * cellSize;
                    }
                    /*
                     * Making sure we continue to respect the width / height constraints after a page layout update, or new widget(s) is selected
                     */
                    function updateMaxes(widthRight, widthLeft, heightTop) {
                        var maxWidth = getPageWidth();
                        var maxHeight = getPageHeight();
                        var maxWidthRight = widthRight || maxWidth;
                        var maxWidthLeft = widthLeft || maxWidth;
                        var maxHeightTop = heightTop || maxHeight;
                        $($element).transformer('option', {
                            maxWidth: maxWidth,
                            maxHeight: maxHeight,
                            maxWidthRight: maxWidthRight,
                            maxWidthLeft: maxWidthLeft,
                            maxHeightTop: maxHeightTop
                        });
                    }
                    function checkSelected() {
                        var selected = $bodyCtrl.selectionIds || [];
                        // if it's different then a widget is geting selected from the system eg add widget
                        if (!_.isEqual(selected, selectedIds)) {
                            selectWidgets(selected);
                        }
                        // Apply multiple-selected class to body when doing multi-select
                        $(BODY_SELECTOR).toggleClass(MULTI_SELECTED_CLASS, (selected.length > 1));
                    }
                    function selectWidgets(ids) {
                        // looks up from the widget ID divs to the nearest pr-widget under the pr-report context
                        var selStart = REPORT_SELECTOR + ' ' + WIDGET_SELECTOR + '[' + SELECTION_ID_ATTR + '="', selEnd = '"]', selector = selStart + ids.join(selEnd + ',' + selStart) + selEnd, selected = $(selector);
                        selectedIds = ids;
                        $($element).transformer('select', selected);
                    }
                    // called when the selector selects
                    // deferred to make sure we catch the next digest cycle if we are already in one
                    function select(event, ui) {
                        selectedIds = getSelectionIds((ui || {}).items || []);
                        if (!selectedIds.length) {
                            // this deselects all widgets
                            $bodyCtrl.selectWidgets();
                        }
                        else {
                            $bodyCtrl.selectWidgets(selectedIds.slice());
                        }
                        if (ui.items && ui.items.length !== 0) {
                            updateMaxesForSelectedWidgets(ui.items);
                        }
                        // run a digest
                        digest();
                    }
                    function updateMaxesForSelectedWidgets(items) {
                        // Positions of a widget group, or a single widget
                        var leftMostPosition = Number.MAX_VALUE;
                        var rightMostPosition = 0;
                        var bottomMostPosition = 0;
                        _.each(items, function (el) {
                            var widgetId = getWidgetId(el);
                            var widget = flowingPages.getWidget(widgetId);
                            var css = pageLayout.convertLayoutToStyle(widget.layout);
                            if (css.left < leftMostPosition) {
                                leftMostPosition = css.left;
                            }
                            var currRightPosition = css.left + css.width;
                            if (currRightPosition > rightMostPosition) {
                                rightMostPosition = currRightPosition;
                            }
                            var currBottomPosition = css.top + css.height;
                            if (currBottomPosition > bottomMostPosition) {
                                bottomMostPosition = currBottomPosition;
                            }
                        });
                        var maxWidthRight = pageLayout.getWidth() - leftMostPosition - pageLayout.getMargins().right;
                        var maxWidthLeft = rightMostPosition - pageLayout.getMargins().left;
                        var maxHeightTop = bottomMostPosition - pageLayout.getMargins().top;
                        updateMaxes(maxWidthRight, maxWidthLeft, maxHeightTop);
                    }
                    /*
                     * When we begin a resize or move
                     */
                    var insertMarkerDom = angular.element('<widget-insert>'), insertMarker = {
                        moving: true,
                        layout: {}
                    };
                    function startTransform(evt, ui) {
                        transformStarted = true;
                        showOutlines();
                        // hide the floating container if it is visible
                        floatingContainer.hide();
                        // reset our yChange
                        lastYChange = 0;
                        // tell each widget it is moving
                        var pages = [], widgets = [];
                        _.each(ui.items, function (el) {
                            var selectionId = getSelectionId(el), widgetId = getWidgetId(el), widget = flowingPages.getWidget(widgetId);
                            widgets.push(widget);
                            eventService.stream(selectionId).trigger('startTransform');
                            flowingPages.startMoving(widget);
                            pages.push(widget.layout.page);
                        });
                        $bodyCtrl.setInteractingPages(pages);
                        // moving is special
                        if (ui.type === 'move') {
                            $(BODY_SELECTOR).addClass(WIDGET_MOVING_CLASS);
                            // initialize our insertMarkerLayout
                            getElementLayouts(ui.items, [], insertMarker.layout);
                            insertMarker.layout.rowSpan = 1;
                            flowingPages.showWidgetShadows(widgets);
                            digest();
                        }
                    }
                    /*
                     * Integration to the layout engine
                     * we throttle with `leading:true` because nudges call start, transform, finished right in a row
                     */
                    var throttledTransform = _.throttle(realTransform, THROTTLE_DELAY, { leading: true });
                    // this looks super stupid because for some reason jquery UI isn't liking throttled functions
                    function transform(evt, ui) {
                        throttledTransform(evt, ui);
                    }
                    function realTransform(evt, ui) {
                        if (!transformStarted) {
                            return;
                        }
                        var isResize = ui.type === 'resize', selected = ui.items, groupLayout = {}, widgets = adjustWidgetLayouts(selected, groupLayout, !isResize);
                        // we are doing a resize, so don't reinsert, just reflow
                        if (isResize) {
                            resize(selected, widgets);
                        }
                        else {
                            move(evt, ui, widgets, groupLayout);
                        }
                    }
                    function resize(selected, widgets) {
                        flowingPages.reflow();
                        // this will set the height on x-only height widgets
                        // to fix a bug with the resizer not positioning correctly
                        updateXOnlyHeight(selected);
                        flowingPages.showWidgetShadows(widgets);
                        digest();
                    }
                    var moveIdx;
                    function move(evt, ui, widgets, groupLayout) {
                        // 1. get accurate insert marker layout that is 1 rowpan high (done)
                        insertMarker.layout = angular.merge({}, groupLayout, { rowSpan: 1 });
                        // 2. insert the marker correctly into the "all widgets" list
                        var allWidgets = flowingPages.getWidgets().slice(0), subsetWidgets = _.differenceBy(allWidgets, widgets, '_id'), after = isMovingDown(ui);
                        // 3. Find the widget before the marker, and store that widget's index as where we plan to insert the full group
                        moveIdx = flowingPages.getWidgetInsertIndex(insertMarker, subsetWidgets, after);
                        // 4. When move finishes, actually adjust the layout of the widget, and insert all widgets into the insert position
                        subsetWidgets.splice(moveIdx, 0, insertMarker);
                        subsetWidgets = subsetWidgets.slice(0, moveIdx + 1);
                        flowingPages.positionWidget(subsetWidgets, insertMarker);
                        var insertMarkerPageIdx = flowingPages.getWidgetPage(insertMarker);
                        insertMarker.layout.page = insertMarkerPageIdx;
                        // if the page dom exists, show the insertMarker widget on that page
                        var pageDoms = $('pr-page');
                        if (pageDoms[insertMarkerPageIdx]) {
                            insertMarkerDom.css(angular.extend(pageLayout.convertLayoutToStyle(insertMarker.layout), { height: '0' }));
                            var pageDom = $(pageDoms[insertMarkerPageIdx]).children('.pr-page-container');
                            // only add it to the page if it's not already there
                            if (pageDom[0] !== insertMarkerDom[0].parent) {
                                pageDom.append(insertMarkerDom);
                            }
                        }
                    }
                    var lastYChange; // state to know if we are moving up or down
                    function isMovingDown(ui) {
                        var down = ui.change[1] > lastYChange;
                        lastYChange = ui.change[1];
                        return down;
                    }
                    /*
                     * When we finish a resize or move
                     */
                    // function finishTransform (evt, ui) {
                    // 	_.delay(realFinishTransform, THROTTLE_DELAY * 2, evt, ui);
                    // }
                    function finishTransform(evt, ui) {
                        insertMarkerDom.remove();
                        transformStarted = false;
                        hideOutlines();
                        // tell each widget it done moving
                        var widgets = [], isMove = (ui.type === 'move');
                        if (isMove) {
                            $(BODY_SELECTOR).removeClass(WIDGET_MOVING_CLASS);
                        }
                        _.forEach(ui.items, function (el) {
                            var selectionId = getSelectionId(el), widgetId = getWidgetId(el), widget = flowingPages.getWidget(widgetId);
                            eventService.stream(selectionId).trigger('stopTransform');
                            flowingPages.stopMoving(widget);
                            if (!isMove) {
                                flowingPages.reflowWidget(widget);
                            }
                            widgets.push(widget);
                        });
                        flowingPages.hideWidgetShadows(widgets);
                        var allWidgets = flowingPages.getWidgets();
                        if (isMove && moveIdx > -1) {
                            allWidgets = finishMoving(allWidgets, widgets, moveIdx);
                        }
                        // force a digest and make the widgets "bounce" to their correct locations
                        $element.addClass('animate');
                        digest();
                        setTimeout(function () {
                            $element.removeClass('animate');
                            // re-select widgets in case they moved to other pages
                            var ids = selectedIds;
                            selectWidgets([]);
                            selectWidgets(ids);
                            positionResizer();
                            $bodyCtrl.setInteractingPages([]);
                        }, 200);
                        widgetService.finishTransform(widgets, allWidgets);
                    }
                    function finishMoving(allWidgets, widgetsToMove, destinationIndex) {
                        var sourceIndex = _.findIndex(allWidgets, widgetsToMove[0]);
                        _.pullAll(allWidgets, widgetsToMove);
                        if (!canWidgetsBeAddedToSection(widgetsToMove, allWidgets, destinationIndex)) {
                            sectionWidgetLimitModal.onMoveWidget();
                            destinationIndex = sourceIndex;
                        }
                        allWidgets.splice.apply(allWidgets, __spreadArray([destinationIndex, 0], widgetsToMove));
                        flowingPages.reflow(allWidgets);
                        return allWidgets;
                    }
                    function canWidgetsBeAddedToSection(widgetsToMove, allWidgets, destinationIndex) {
                        // users can only move widgets originating from a single section
                        var sourceSectionId = activeReport.getPageIdOfWidget(widgetsToMove[0]);
                        var destinationSectionId = widgetUtils.getPageIdAtInsertIndex(destinationIndex, allWidgets);
                        if (sourceSectionId === destinationSectionId) {
                            // regardless of whether a section has too many widgets,
                            // we should allow moving existing widgets within the same section
                            return true;
                        }
                        var widgetTypesToMove = _.map(widgetsToMove, '_type');
                        return widgetUtils.canWidgetsBeAddedToSection(destinationSectionId, widgetTypesToMove);
                    }
                    function getWidgetId(el) {
                        return getSelectionId(el).split('.')[0];
                    }
                    function getChunkId(el) {
                        return getSelectionId(el).split('.')[1];
                    }
                    function getSelectionId(el) {
                        return $(el).attr(SELECTION_ID_ATTR);
                    }
                    function getSelectionIds(selected) {
                        // make sure any bad elements are not there
                        return _.without(_.map(selected, getSelectionId), null, undefined);
                    }
                    function digest() {
                        $scope.$applyAsync();
                    }
                    function updateXOnlyHeight(els) {
                        var filtered = $(els).filter('[resize="x"]');
                        filtered.each(function () {
                            var el = $(this), widgetId = getWidgetId(el), widget = flowingPages.getWidget(widgetId), styles = pageLayout.convertLayoutToStyle(widget.layout);
                            el.css('height', styles.height);
                        });
                    }
                    function adjustWidgetLayouts(els, optGroupLayout, isMoving) {
                        // first gather all widget layouts and collect a main layout
                        var widgets = [], groupLayout = optGroupLayout || {}, layouts = getElementLayouts(els, widgets, groupLayout, isMoving);
                        // then adjust the main layout to push the widget back on the page if it is moving off
                        var colDif = Math.min(Math.max(groupLayout.col, 0), pageLayout.getCols() - groupLayout.colSpan) - groupLayout.col;
                        // then apply the adjustments to the widgets
                        _.forEach(layouts, function (layout, idx) {
                            layout.col += colDif;
                            var widget = widgets[idx], el = els[idx], widgetLayout = widget.layout, 
                            // wLayout could be the widget or the chunk's layout
                            wLayout = getChunkLayout(el, widget), resized = wLayout.rowSpan !== layout.rowSpan || wLayout.colSpan !== layout.colSpan;
                            // don't modify the row unless it is significant
                            if (widget.isChunked) {
                                var ct = layout.row, wt = widgetLayout.row, cb = ct + layout.rowSpan, wb = wt + widgetLayout.rowSpan;
                                // it's movement is restricted to the widget, so don't modify the row
                                if (ct > wt && cb < wb) {
                                    delete layout.row;
                                }
                            }
                            if (isMoving) {
                                delete layout.rowSpan;
                            }
                            // This will need to change if we want to allow the cancel of the move
                            angular.extend(widgetLayout, layout);
                            if (resized && !isMoving) {
                                widgetManager.notifyWidget(widget._id, 'resized', 'prWidget.onResize');
                            }
                        });
                        groupLayout.col += colDif;
                        return widgets;
                    }
                    // returns a list of element layouts as a giant group layout
                    function getElementLayouts(els, optWidgets, optGroupLayout, optIsMoving) {
                        var widgets = optWidgets || [], groupLayout = optGroupLayout || {};
                        return _.map(els, function (el) {
                            var widget = flowingPages.getWidget(getWidgetId(el));
                            widgets.push(widget);
                            var layout = getWidgetLayoutFromElement(el, widget, optIsMoving);
                            groupLayout = mergeLayout(groupLayout, layout);
                            return layout;
                        });
                    }
                    /*
                     * Merges one layout with another collecting it into a giant group layout
                     */
                    function mergeLayout(to, from) {
                        var props = ['row', 'col'];
                        _.forEach(props, function (prop) {
                            var span = prop + 'Span';
                            if (!to[prop]) {
                                to[prop] = from[prop];
                                to[span] = from[span];
                            }
                            else {
                                var min = Math.min(to[prop], from[prop]), maxEnd = Math.max(to[prop] + to[span], from[prop] + from[span]);
                                to[prop] = min;
                                to[span] = maxEnd - min;
                            }
                        });
                        return to;
                    }
                    function getWidgetLayoutFromElement(el, widget, optIsMoving) {
                        var prevLayout = getChunkLayout(el, widget), css = getElementLayoutStyles(el, widget, optIsMoving);
                        // this makes the widget css appear on the same place as the page above it
                        return pageLayout.convertStyleToLayout(css, prevLayout, true);
                    }
                    function getElementLayoutStyles(el, widget, isMoving) {
                        var css = _.reduce(['top', 'left', 'width', 'height'], function (css, value) {
                            css[value] = parseInt(el.style[value] || 0);
                            return css;
                        }, {});
                        // adjust our top to the transform origin when we are moving the widget
                        if (isMoving && el.style.transformOrigin) {
                            var topAdjust = parseInt(el.style.transformOrigin.split(' ')[1], 10);
                            css.top += topAdjust;
                            css.height = cellSize;
                        }
                        else if (widget.isChunked) {
                            // overwrite the height with what our last known chunk size was
                            css.height = widget.layout.rowSpan * cellSize;
                        }
                        return css;
                    }
                    function getChunkLayout(el, widget) {
                        return ((widget.isChunked ? widget.chunks[getChunkId(el)] : widget) || {}).layout || {};
                    }
                    var outlines = null;
                    function showOutlines() {
                        if (outlines === null) {
                            outlines = viewOptions.showOutlines();
                            viewOptions.showOutlines(true);
                        }
                    }
                    function hideOutlines() {
                        viewOptions.showOutlines(outlines);
                        outlines = null;
                    }
                }
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
