"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.editUIManager = void 0;
var angular_1 = require("angular");
var REPORT_SELECTOR = 'pr-report';
var DISABLE_CLASS_SELECTOR = '.disable-on-section-update';
var EDITING_DISABLED_CLASS = 'editing-disabled';
var DISABLED_CLASS = 'disabled';
var getReportElement = (function () {
    // @ts-ignore
    var _reportElement;
    return function () {
        // @ts-ignore
        if (!_reportElement) {
            _reportElement = $(REPORT_SELECTOR);
        }
        // @ts-ignore
        return _reportElement;
    };
})();
var getElementsToDisable = (function () {
    // @ts-ignore
    var _elementsToDisable;
    return function () {
        // @ts-ignore
        if (!_elementsToDisable) {
            _elementsToDisable = $(DISABLE_CLASS_SELECTOR);
        }
        // @ts-ignore
        return _elementsToDisable;
    };
})();
var isSectionUpdatePending = false;
/**
 * Service to keep track of when a section update is pending.
 *
 * Updating (creating or deleting) a section creates a sequence of interdependent
 * network requests to update the container definition (see section-manager.ts).
 *
 * If there are any other requests to update the container during this sequence,
 * this can cause undefined behavior (e.g. widgets can lose state or disappear).
 *
 * Therefore, we need to disable all UI elements that modifies the container.
 * This includes adding/copying/deleting/resizing/moving a widget,
 * modifying the report global options, modifying the filters, etc.
 *
 * This file only disables clicks.
 * It doesn't disable widget operations using hotkeys (e.g. copying a widget with ctrl+v).
 * Widget operations using hotkeys should be blocked by widget-operations-service.ts
 */
exports.editUIManager = {
    isSectionUpdatePending: function () {
        return isSectionUpdatePending;
    },
    startSectionUpdate: function () {
        isSectionUpdatePending = true;
        exports.editUIManager.disableEditUI();
    },
    completeSectionUpdate: function () {
        isSectionUpdatePending = false;
        exports.editUIManager.enableEditUI();
    },
    enableEditUI: function () {
        getReportElement().removeClass(EDITING_DISABLED_CLASS);
        getElementsToDisable().removeClass(DISABLED_CLASS);
    },
    disableEditUI: function () {
        getReportElement().addClass(EDITING_DISABLED_CLASS);
        getElementsToDisable().addClass(DISABLED_CLASS);
    }
};
(function (module) {
    // this is just needed for unit tests
    module.factory('pr.editUIManager', function () { return exports.editUIManager; });
})(angular_1.module('qualtrics.pr'));
