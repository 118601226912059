"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@components/hyperlinks/hyperlinks-modal");
var angular_1 = require("angular");
// @ts-ignore
// @ts-ignore
function HyperlinksHelper($filter, hyperlinksModal) {
    var extendScope = {};
    var HyperlinksMenuItem = function (quillEditor, widgetId) {
        // @ts-ignore
        this.translate = $filter('translate');
        extendScope.editor = quillEditor;
        extendScope.widgetId = widgetId;
        // @ts-ignore
        return this;
    };
    HyperlinksMenuItem.prototype.getHyperlinksMenuItem = function () {
        return {
            name: 'hyperlink',
            type: 'button',
            icon: 'icon icon-link',
            tooltip: this.translate('INSERT_LINK'),
            notSelectable: true,
            action: function () {
                hyperlinksModal.open(extendScope);
            }
        };
    };
    return HyperlinksMenuItem;
}
(function (module) {
    module.factory('prHyperlinksHelper', [
        '$filter',
        'prHyperlinksModal',
        HyperlinksHelper
    ]);
})(angular_1.module('qualtrics.pr'));
