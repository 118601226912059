"use strict";
(function (module) {
    // Taken from http://widget-portal-app.eng.qops.net/portal/#/documentation/ripsaw/fieldsets/filters
    var FilterConstants = function ($filter) {
        var translate = $filter('translate');
        /**
         * Helper function that returns a blank dateRange object used for 'relative'/'rolling' dates:
         * 		type: {'relative', 'rolling'}
         *		period: {'day', 'week', 'month', 'quarter', year'}
         *		amount: # of periods
         *		adjustPeriod: {'day', 'week', 'month', 'quarter', 'year'}
         *		adjustAmount: # of periods to adjust
         *		timeZoneOffset: representing timezone shift, e.g. "-06:00" or "+06:00"
         *
         * @returns {object} that represents a dateRange object according to filters API
         */
        var getBlankDateRange = function () {
            return {
                type: '',
                period: '',
                amount: 0,
                adjustPeriod: null,
                adjustAmount: null,
                timeZoneOffset: null
            };
        };
        /**
         * Function which calculates dateRange object according to filters API (as seen above)
         *
         * @param {string} operand - the type of relative date
         *
         * @returns {object} respective dateRange object
         */
        var getDateRangeObject = function (operand) {
            var dateRange = getBlankDateRange();
            var splitOperand = operand.split('_');
            switch (splitOperand[0]) {
                case 'YESTERDAY':
                    dateRange = _.merge(dateRange, {
                        adjustPeriod: 'day',
                        adjustAmount: 1,
                        type: 'rolling',
                        amount: 0,
                        period: 'day'
                    });
                    if (splitOperand[1] === 'OLD') {
                        dateRange.type = 'relative';
                        dateRange.amount = 1;
                    }
                    break;
                case 'TODAY':
                    dateRange = _.merge(dateRange, {
                        type: 'relative',
                        amount: 0,
                        period: 'day'
                    });
                    if (splitOperand[1] === 'OLD') {
                        dateRange.amount = 1;
                    }
                    break;
                case 'THIS':
                    dateRange = _.merge(dateRange, {
                        type: 'relative',
                        amount: 0,
                        period: splitOperand[1].toLowerCase()
                    });
                    break;
                case 'LAST':
                    dateRange = _.merge(dateRange, {
                        type: 'rolling',
                        amount: (parseInt(splitOperand[1]) || 1) - 1,
                        period: splitOperand[2].replace(/s/gi, '').toLowerCase() // replace any potential "s" characters
                    });
                    break;
            }
            return dateRange;
        };
        var relativeDateFilters = {
            TODAY: {
                label: translate('TODAY'),
                name: 'TODAY',
                dateRange: getDateRangeObject('TODAY')
            },
            YESTERDAY: {
                label: translate('YESTERDAY'),
                name: 'YESTERDAY',
                dateRange: getDateRangeObject('YESTERDAY')
            },
            THIS_WEEK: {
                label: translate('THIS_WEEK'),
                name: 'THIS_WEEK',
                dateRange: getDateRangeObject('THIS_WEEK')
            },
            THIS_MONTH: {
                label: translate('THIS_MONTH'),
                name: 'THIS_MONTH',
                dateRange: getDateRangeObject('THIS_MONTH')
            },
            THIS_QUARTER: {
                label: translate('THIS_QUARTER'),
                name: 'THIS_QUARTER',
                dateRange: getDateRangeObject('THIS_QUARTER')
            },
            THIS_YEAR: {
                label: translate('THIS_YEAR'),
                name: 'THIS_YEAR',
                dateRange: getDateRangeObject('THIS_YEAR')
            },
            LAST_7_DAYS: {
                label: translate('LAST_7_DAYS'),
                name: 'LAST_7_DAYS',
                dateRange: getDateRangeObject('LAST_7_DAYS')
            },
            LAST_14_DAYS: {
                label: translate('LAST_14_DAYS'),
                name: 'LAST_14_DAYS',
                dateRange: getDateRangeObject('LAST_14_DAYS')
            },
            LAST_30_DAYS: {
                label: translate('LAST_30_DAYS'),
                name: 'LAST_30_DAYS',
                dateRange: getDateRangeObject('LAST_30_DAYS')
            },
            LAST_60_DAYS: {
                label: translate('LAST_60_DAYS'),
                name: 'LAST_60_DAYS',
                dateRange: getDateRangeObject('LAST_60_DAYS')
            },
            LAST_90_DAYS: {
                label: translate('LAST_90_DAYS'),
                name: 'LAST_90_DAYS',
                dateRange: getDateRangeObject('LAST_90_DAYS')
            },
            LAST_180_DAYS: {
                label: translate('LAST_180_DAYS'),
                name: 'LAST_180_DAYS',
                dateRange: getDateRangeObject('LAST_180_DAYS')
            }
        };
        this.getRelativeDateFilters = function () {
            return relativeDateFilters;
        };
    };
    FilterConstants.$inject = ['$filter'];
    module.service('prFilterConstants', FilterConstants);
})(angular.module('qualtrics.pr'));
