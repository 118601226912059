"use strict";
(function (module) {
    'use strict';
    var TestDirectiveController = function (undoRedo) {
        this.textModel = 'abc';
        var self = this;
        this.textChange = function (after, before) {
            undoRedo.watch([after, before], function (metadata, data) {
                self.textModel = metadata.isRedo ? data[0] : data[1];
            });
        };
    };
    TestDirectiveController.$inject = ['undoRedoStack'];
    module.controller('prTestDirectiveController', TestDirectiveController);
})(angular.module('qualtrics.pr'));
