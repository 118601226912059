"use strict";
(function (module) {
    var UploadImageController = function ($scope, $filter, prGraphicLibraryService) {
        // Graphic Service upload image file max size
        var FILE_MAX_SIZE = 16777216;
        // Valid image file types refencing monolith
        // https://gitlab-app.eng.qops.net/monolith/core/blob/master/Qualtrics/Product/Libraries/ProductAjaxProcessor.php#L3742
        var SUPPORTED_IMAGE_TYPE_LIST = [
            'image/jpg',
            'image/jpeg',
            'image/pjpeg',
            'image/gif',
            'image/png',
            'image/x-png',
            'image/tiff'
        ];
        var translate = $filter('translate');
        var ctrl = this;
        $scope.ctrl = ctrl;
        ctrl.folderOptions = $scope.folderOptions;
        ctrl.requestSent = false;
        ctrl.invalidImage = false;
        angular.extend(ctrl, {
            invalid: function () {
                /* scenarios that save button will be disabled
                 * no image is selected
                 * image size is bigger than limit or type is not supported
                 * image request is sent
                */
                return !ctrl.imageToUpload || ctrl.invalidImage || ctrl.requestSent;
            },
            uploadImage: function (event) {
                var input = event.target.files;
                ctrl.imageToUpload = input && input[0];
                if (validImage(ctrl.imageToUpload)) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        // default category is Unassigned
                        ctrl.selectedFolder = translate('UNASSIGNED');
                        $('#folderSelector').val(ctrl.selectedFolder);
                        $('#graphicDescription').val(ctrl.imageToUpload.name);
                        $('#previewImage').attr('src', e.target.result);
                    };
                    reader.readAsDataURL(ctrl.imageToUpload);
                }
                else {
                    // clear previous preview image
                    $('#previewImage').attr('src', '');
                }
                $scope.$digest();
            },
            selectFolderToUpload: function (folder) {
                ctrl.selectedFolder = folder;
                $('#folderSelector').val(folder);
            },
            uploadImageToLibrary: function () {
                if (ctrl.invalidImage) {
                    return;
                }
                ctrl.requestSent = true;
                ctrl.waitingForResponse = true;
                var formData = new FormData();
                formData.set('GraphicFile', ctrl.imageToUpload);
                formData.set('Description', $('#graphicDescription').val());
                formData.set('Category', $('#folderSelector').val());
                formData.set('Size', ctrl.imageToUpload.size);
                formData.set('GraphicType', ctrl.imageToUpload.type);
                prGraphicLibraryService.uploadImageToLibrary($scope.libraryId, formData)
                    .then(function (res) {
                    ctrl.waitingForResponse = false;
                    $scope.setImage(res.uploadGraphicToLibrary);
                    $scope.close();
                    $scope.$digest();
                })
                    .catch(function () {
                    ctrl.waitingForResponse = false;
                    ctrl.uploadResponse = 'UPLOAD_IMAGE_FAILED';
                });
            }
        });
        function validImage(imageFile) {
            var type = imageFile && imageFile.type || '';
            if (!SUPPORTED_IMAGE_TYPE_LIST.includes(type)) {
                ctrl.invalidImage = true;
                ctrl.invalidMsg = 'UNSUPPORTED_FILE_TYPE';
                return false;
            }
            if (imageFile && imageFile.size >= FILE_MAX_SIZE) {
                ctrl.invalidImage = true;
                ctrl.invalidMsg = 'FILE_TOO_LARGE';
                return false;
            }
            ctrl.invalidImage = false;
            return true;
        }
    };
    module.controller('UploadImageController', ['$scope', '$filter', 'prGraphicLibraryService', UploadImageController]);
    module.directive('imageOnChange', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var onChangeHandler = scope.$eval(attrs.imageOnChange);
                element.on('change', onChangeHandler);
                element.on('$destroy', function () {
                    element.off();
                });
            }
        };
    });
})(angular.module('qualtrics.pr'));
