"use strict";
(function (module) {
    'use strict';
    module.factory('prStyleNode', [
        function () {
            function getCSSNode(nodeId) {
                var node = document.getElementById(nodeId);
                if (!node) {
                    node = document.createElement('style');
                    node.type = 'text/css';
                    node.id = nodeId;
                    document.body.appendChild(node);
                }
                return node;
            }
            function updateCSSNode(nodeId, css) {
                var node = getCSSNode(nodeId);
                if ('textContent' in node) {
                    node.textContent = css;
                }
                else {
                    node.innerText = css;
                }
            }
            function removeCSSNode(nodeId) {
                var node = getCSSNode(nodeId);
                document.body.removeChild(node);
            }
            return {
                updateCSSNode: updateCSSNode,
                removeCSSNode: removeCSSNode
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
