"use strict";
(function (module) {
    'use strict';
    module.factory('prWidgetCountLimitWarningModal', [
        'qstrap.modal',
        function (modal) {
            function show(title, info) {
                return modal({
                    title: title,
                    content: info,
                    buttons: [
                        '<button class="btn btn-hover" ng-click="close()" translate>CLOSE</button>'
                    ],
                    className: 'widget-count-limit-warning-modal',
                    show: true
                });
            }
            return {
                show: show
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
