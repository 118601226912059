"use strict";
(function (module) {
    module.directive('prEmailListValidator', [
        'pr.emailService',
        function (emailService) {
            return {
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    function isValidEmailList(emails) {
                        if (_.isEmpty(emails)) {
                            return false;
                        }
                        var isValid = true;
                        _.forEach(emails, function (email) {
                            if (!emailService.isValidEmail(email)) {
                                isValid = false;
                            }
                        });
                        return isValid;
                    }
                    ctrl.$validators.emailList = function (modelValue, viewValue) {
                        if (ctrl.$isEmpty(modelValue)) {
                            // consider empty models to be valid.
                            // required takes care of this case.
                            return true;
                        }
                        var emails = emailService.extractEmailsFromString(viewValue);
                        return isValidEmailList(emails);
                    };
                }
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
