"use strict";
(function (module) {
    'use strict';
    module.factory('prInsertMenuService', [
        '$filter',
        '$timeout',
        'prPageHeaderFooterModal',
        'prWidgetMenuService',
        'prSourceMenuService',
        'prWidgetInit',
        'pr.fieldsets',
        'pr.hotkeyUtil',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        function ($filter, $timeout, headerFooterModal, widgetMenu, sourceMenuService, widgetInit, fieldsets, hotkey, triggerOverrides, OVERRIDE_EVENTS) {
            var translate = $filter('translate');
            var configFlags = triggerOverrides({
                event: OVERRIDE_EVENTS.CONFIG_FLAGS,
                sync: true,
                data: {}
            });
            var widgetCountExceedLimitTooltip = translate('PR.WIDGET_COUNT_EXCEED_LIMIT_INFO', {
                widgetCountLimit: configFlags.widgetCountLimit
            });
            function getMenuItems(optHeaderFooter, disablePageBreak) {
                return sourceMenuService.getSourceDataMenu().then(function (surveyDataMenu) {
                    var menuItems = [{ header: translate('SURVEY_DATA') }].concat(surveyDataMenu, { divider: true }, widgetMenu.getBasicItems(), {
                        icon: 'icon-bar-chart',
                        replaceCheck: true,
                        label: translate('VISUALIZATION'),
                        submenu: {
                            items: [
                                {
                                    label: translate('CHART'),
                                    icon: 'icon-bar-chart',
                                    replaceCheck: true,
                                    submenu: {
                                        items: widgetMenu.getChartItems(),
                                    }
                                },
                                {
                                    label: translate('TABLE'),
                                    icon: 'icon-simple-table',
                                    replaceCheck: true,
                                    submenu: {
                                        items: widgetMenu.getTableItems(),
                                    }
                                },
                                {
                                    label: translate('OTHER'),
                                    submenu: {
                                        items: widgetMenu.getOtherItems(),
                                    }
                                }
                            ]
                        }
                    }, { divider: true }, {
                        icon: 'icon-pagebreak',
                        replaceCheck: true,
                        label: hotkey.buildHotkeyLabel('PAGE_BREAK', ['icon-commandkey', 'Enter']),
                        value: 'pr.pagebreak',
                        disabled: disablePageBreak
                    });
                    if (optHeaderFooter) {
                        menuItems = menuItems.concat({ divider: true }, {
                            label: translate('PAGE_HEADER'),
                            action: function () {
                                headerFooterModal.show('header');
                            }
                        }, {
                            label: translate('PAGE_FOOTER'),
                            action: function () {
                                headerFooterModal.show('footer');
                            }
                        });
                    }
                    return triggerOverrides({
                        event: OVERRIDE_EVENTS.INSERT_MENU,
                        data: menuItems
                    });
                });
            }
            function getWidgetCountExceedLimitTooltip() {
                return widgetCountExceedLimitTooltip;
            }
            return {
                getMenuItems: getMenuItems,
                getWidgetCountExceedLimitTooltip: getWidgetCountExceedLimitTooltip
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
