"use strict";
/* global Quill */
(function (module) {
    'use strict';
    module.factory('prQuillFormatter', [
        function () {
            var not = function (x) {
                return !x;
            };
            var PIPED_TEXT_REGEX = /piped-text-[\da-z]{9}/g;
            var QuillFormatter = function (editor) {
                this.editor = editor;
            };
            QuillFormatter.prototype.setStyle = function (val) {
                this.cleanFormat();
                if (_.contains(['H1', 'H2', 'H3', 'H4', 'H5'], val)) {
                    this.updateFormat('header', _.constant(val));
                }
                else if (val === 'footnote') {
                    this.updateFormat('class', _.constant(val));
                }
            };
            QuillFormatter.prototype.setFont = function (font) {
                this.updateFormat('font', _.constant(font));
            };
            QuillFormatter.prototype.setSize = function (size) {
                var sizeToPt = size + 'pt';
                this.updateFormat('size', _.constant(sizeToPt));
            };
            QuillFormatter.prototype.toggleBold = function () {
                this.updateFormat('bold', not);
            };
            QuillFormatter.prototype.toggleItalic = function () {
                this.updateFormat('italic', not);
            };
            QuillFormatter.prototype.toggleUnderline = function () {
                this.updateFormat('underline', not);
            };
            QuillFormatter.prototype.toggleStrikethrough = function () {
                this.updateFormat('strike', not);
            };
            QuillFormatter.prototype.setForegroundColor = function (color) {
                this.updateFormat('color', _.constant(color));
            };
            QuillFormatter.prototype.setBackgroundColor = function (color) {
                this.updateFormat('background', _.constant(color));
            };
            QuillFormatter.prototype.toggleList = function (enabled, listType) {
                if (!enabled) {
                    this.updateFormat('list', _.constant(false));
                }
                else {
                    this.updateFormat('list', _.constant(listType));
                }
            };
            QuillFormatter.prototype.setAlign = function (enabled, align) {
                if (align === 'left') {
                    align = false;
                }
                this.updateFormat('align', _.constant(align));
            };
            QuillFormatter.prototype.cleanFormat = function () {
                this.updateFormat('clean', _.constant(true));
            };
            // Source: http://quilljs.com/docs/formats/
            var FORMAT_TYPES = {
                header: 'block',
                font: 'inline',
                size: 'inline',
                bold: 'inline',
                italic: 'inline',
                underline: 'inline',
                strike: 'inline',
                color: 'inline',
                background: 'inline',
                list: 'block',
                bullet: 'block',
                align: 'block',
                class: 'block'
            };
            // Mirror the Quill v1 API but still take range as input
            // http://beta.quilljs.com/docs/api/#format
            Quill.prototype.formatContent = function (range, formatName, newFormat) {
                if (!range) {
                    return;
                }
                if (!range.length) {
                    this.format(formatName, newFormat);
                    return;
                }
                if (FORMAT_TYPES[formatName] === 'block') {
                    this.formatLine(range, formatName, newFormat);
                }
                else if (FORMAT_TYPES[formatName] === 'inline') {
                    this.formatText(range, formatName, newFormat);
                }
            };
            QuillFormatter.prototype.updateFormat = function (formatName, buildNewFormat) {
                var self = this;
                var activeElement = $(document.activeElement);
                self.editor.focus();
                var range = self.editor.getSelection();
                if (!range) {
                    return;
                }
                function getContents(start, end) {
                    var delta = self.editor.getContents(start, end);
                    var ops = delta && delta.ops || [];
                    return ops;
                }
                if (formatName === 'clean') {
                    // Get contents and verify for embeds
                    // If one exists, replace it with its text equivalent
                    // It will be converted to a piped text embed
                    // after the format is done
                    var ops_1 = getContents.call(self, range.index, range.length);
                    var opsBefore = getContents.call(self, 0, range.index);
                    var opsAfter = getContents.call(self, range.index + range.length, self.editor.getLength());
                    var pipedTextEmbeds_1 = {};
                    _.forEach(ops_1, function (op) {
                        var ptAttrs = _.get(op, 'insert.piped-text.ptAttrs', {});
                        var id = _.get(op, 'insert.piped-text.ptAttrs.ptId');
                        if (!_.isEmpty(ptAttrs)) {
                            pipedTextEmbeds_1[id] = ptAttrs;
                            // Replace the embed with its string equivalent
                            op.insert = id;
                            // Increment range.length by the length of id
                            range.length += id.length - 1;
                        }
                    });
                    // This update operation is "silent"
                    // This will help ignore these intermediate changes
                    // when user wants to undo the clean
                    self.editor.setContents({
                        ops: _.concat(opsBefore, ops_1, opsAfter)
                    }, Quill.sources.SILENT);
                    self.editor.removeFormat(range.index, range.length);
                    if (!_.isEmpty(pipedTextEmbeds_1)) {
                        // We need to reset the text to embeds
                        ops_1 = getContents.call(self, range.index, range.length);
                        opsBefore = getContents.call(self, 0, range.index);
                        opsAfter = getContents.call(self, range.index + range.length, self.editor.getLength());
                        _.forEach(_.cloneDeep(ops_1), function (op, opsIndex) {
                            var newOps = op.insert.split(PIPED_TEXT_REGEX);
                            if (newOps.length > 1) {
                                // Convert the insert to a new op
                                // or split it into multiple ops
                                var matches_1 = op.insert.match(PIPED_TEXT_REGEX);
                                newOps = _.map(newOps, function (newOp, newOpIndex) {
                                    if ((newOp.length || newOpIndex < newOps.length) && matches_1[newOpIndex]) {
                                        return [{
                                                insert: newOp
                                            }, {
                                                insert: {
                                                    'piped-text': {
                                                        ptAttrs: pipedTextEmbeds_1[matches_1[newOpIndex]]
                                                    }
                                                }
                                            }];
                                    }
                                    else if (newOp.length) {
                                        return {
                                            insert: newOp
                                        };
                                    }
                                });
                                newOps = _.compact(_.flatten(newOps));
                                ops_1 = _.concat(ops_1.slice(0, opsIndex), [newOps], ops_1.slice(opsIndex + 1));
                            }
                        });
                        ops_1 = _.flatten(ops_1);
                        self.editor.setContents({
                            ops: _.concat(opsBefore, ops_1, opsAfter)
                        });
                    }
                }
                else {
                    var formats = self.editor.getFormat(range);
                    var currentFormat = formats[formatName];
                    var newFormat = buildNewFormat(currentFormat);
                    // click list button second time to remove format
                    if (formatName === 'list' && newFormat === currentFormat) {
                        newFormat = false;
                    }
                    self.editor.formatContent(range, formatName, newFormat);
                }
                if (activeElement.is('input')) {
                    activeElement.focus();
                }
                self.editor.setSelection(range);
            };
            return QuillFormatter;
        }
    ]);
}(angular.module('qualtrics.pr')));
