"use strict";
(function (module) {
    'use strict';
    module.factory('prMetricImperial', [
        '$filter',
        function ($filter) {
            var translate = $filter('translate');
            var INCH_TO_CM = 2.54, LENGTH_UNIT_MENU = {
                cm: {
                    label: getUnitLabel('cm', true),
                    value: 'cm'
                },
                inch: {
                    label: getUnitLabel('inch', true),
                    value: 'inch'
                }
            };
            function getCMOfInch(inchValue) {
                return parseFloat((inchValue * INCH_TO_CM).toFixed(1));
            }
            function getInchOfCM(cmValue) {
                return parseFloat((cmValue / INCH_TO_CM).toFixed(2));
            }
            function getUnitMenu() {
                return _.merge({}, LENGTH_UNIT_MENU);
            }
            function getUnitLabel(val, capitalize) {
                var ret = '';
                if (val === 'cm') {
                    ret = translate('CENTIMETERS');
                }
                if (val === 'inch') {
                    ret = translate('INCHES');
                }
                return capitalize ? _.capitalize(ret) : ret;
            }
            function useInch(val) {
                return val === LENGTH_UNIT_MENU.inch.value;
            }
            function getCustomLabel(title, unit) {
                var unitLabel = getUnitLabel(unit);
                return title + ' (' + unitLabel + ')';
            }
            return {
                getCMOfInch: getCMOfInch,
                getCustomLabel: getCustomLabel,
                getInchOfCM: getInchOfCM,
                getUnitMenu: getUnitMenu,
                getUnitLabel: getUnitLabel,
                useInch: useInch
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
