"use strict";
/* eslint no-redeclare: "off" */
/* globals $ */
(function (module) {
    'use strict';
    var WIDGET_SEL = 'pr-widget';
    module.directive('prTableBody', [
        'prFlowingPages',
        'prWidgetChunkerFactory',
        'prEventService',
        'pr.EVENTS',
        function (flowingPages, chunker, eventService, EVENTS) {
            return {
                scope: false,
                link: function ($scope, $element) {
                    var chunk = $scope.widget, widget = flowingPages.getWidget(chunk._id);
                    $element.on('$destroy', function destroy() {
                        $element.off('$destroy');
                        $scope.$destroy();
                        eventService.stream($scope.widget._id).off('widgetSectionUpdated', render);
                        eventService.stream(chunk.layout.id).off(EVENTS.RENDER_HIDDEN_CHUNK, render);
                        if (!flowingPages.getWidget($scope.widget._id)) {
                            // $destroy is triggered when widgets move across pages. 
                            // Only remove hidden element when widget is deleted. 
                            var hiddenElement = $('#HIDDEN_' + $scope.widget._id);
                            if ((hiddenElement || {}).length) {
                                hiddenElement.remove();
                            }
                        }
                        $scope = null;
                        $element = null;
                    });
                    $scope.$watch('isSelected()', function (selected) {
                        // we need to toggle on each chunk
                        var func = selected ? 'addClass' : 'removeClass';
                        // we use a different class than 'selected' since that is used in the selector
                        $element.closest(WIDGET_SEL)[func]('active', selected);
                    });
                    render();
                    eventService.stream($scope.widget._id).on('widgetSectionUpdated', render);
                    eventService.stream(chunk.layout.id).on(EVENTS.RENDER_HIDDEN_CHUNK, function (event, widgetChunk) {
                        if ($element) {
                            render(widgetChunk);
                        }
                    });
                    function render(widgetChunk) {
                        $element.closest('pr-widget').attr('resize', 'x');
                        $element.empty();
                        widgetChunk = _.isElement(widgetChunk) ? widgetChunk : getChunk();
                        $element.append(widgetChunk);
                    }
                    function getChunk() {
                        return chunker.getChunk(widget, chunk.layout.chunkIndex);
                    }
                }
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
