"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SequentialTaskQueue = void 0;
var angular_1 = require("angular");
var SequentialTaskQueue = /** @class */ (function () {
    // @ts-ignore
    function SequentialTaskQueue($q) {
        this.prevTaskPromise = $q.resolve();
        this.numPendingTasks = 0;
    }
    /**
     * Runs the given function after all previous tasks have completed.
     * Returns a promise that resolves with the result of the given function.
     */
    SequentialTaskQueue.prototype.add = function (newTask) {
        var _this = this;
        this.numPendingTasks++;
        this.prevTaskPromise = this.prevTaskPromise.then(newTask, newTask).finally(function () {
            _this.numPendingTasks--;
        });
        return this.prevTaskPromise;
    };
    SequentialTaskQueue.prototype.anyPendingTasks = function () {
        return this.numPendingTasks > 0;
    };
    SequentialTaskQueue.$inject = ['$q'];
    return SequentialTaskQueue;
}());
exports.SequentialTaskQueue = SequentialTaskQueue;
(function (angularModule) {
    angularModule.service('pr.sequentialTaskQueue', SequentialTaskQueue);
})(angular_1.module('qualtrics.pr'));
