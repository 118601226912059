"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var feature_flipper_1 = require("@shared/constants/feature-flipper");
var environment_1 = require("@shared/types/environment");
var angular = __importStar(require("angular"));
var Snapshot = /** @class */ (function () {
    function Snapshot(env, prFieldsets) {
        this.env = env;
        this.prFieldsets = prFieldsets;
    }
    Snapshot.prototype.getSourceIdToSnapshotIdMap = function () {
        var _a, _b, _c, _d;
        var snapshotMap = {};
        if (this.isEnabled()) {
            var fieldsets = this.prFieldsets.getCachedFieldsets();
            for (var _i = 0, _e = Object.values(fieldsets); _i < _e.length; _i++) {
                var fieldset = _e[_i];
                var snapshotId = (_a = fieldset === null || fieldset === void 0 ? void 0 : fieldset.definition) === null || _a === void 0 ? void 0 : _a.snapshotId;
                var fieldsetId = (_b = fieldset === null || fieldset === void 0 ? void 0 : fieldset.definition) === null || _b === void 0 ? void 0 : _b.fieldSetId;
                if (snapshotId && fieldsetId) {
                    snapshotMap[fieldsetId] = snapshotId;
                    var sourceSurveyId = (_d = (_c = fieldset === null || fieldset === void 0 ? void 0 : fieldset.meta) === null || _c === void 0 ? void 0 : _c.contextMeta) === null || _d === void 0 ? void 0 : _d.sourceSurveyId;
                    if (sourceSurveyId) {
                        snapshotMap[sourceSurveyId] = snapshotId;
                    }
                }
            }
        }
        return snapshotMap;
    };
    Snapshot.prototype.isEnabled = function () {
        return this.env.get(environment_1.ENV_VARS.FEATURES)[feature_flipper_1.FEATURES.SEND_SNAPSHOT_ID];
    };
    Snapshot.$inject = [
        'rsEnvironment',
        'prFieldsets'
    ];
    return Snapshot;
}());
(function (angularModule) {
    angularModule.service('prSnapshot', Snapshot);
})(angular.module('qualtrics.pr'));
