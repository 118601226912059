"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NoReportsController = /** @class */ (function () {
    function NoReportsController(
    // @ts-ignore
    $timeout, 
    // @ts-ignore
    env, 
    // @ts-ignore
    createModal, 
    // @ts-ignore
    rum, 
    // @ts-ignore
    $window, 
    // @ts-ignore
    triggerOverrides, 
    // @ts-ignore
    OVERRIDE_EVENTS, 
    // @ts-ignore
    classicURLService, fieldsetsService, 
    // @ts-ignore
    reportMenuService) {
        this.$timeout = $timeout;
        this.env = env;
        this.createModal = createModal;
        this.rum = rum;
        this.$window = $window;
        this.triggerOverrides = triggerOverrides;
        this.OVERRIDE_EVENTS = OVERRIDE_EVENTS;
        this.classicURLService = classicURLService;
        this.fieldsetsService = fieldsetsService;
        this.reportMenuService = reportMenuService;
    }
    NoReportsController.prototype.$onInit = function () {
        var _this = this;
        this.rum.appLoading();
        // Error Handling
        var error = this.env.get('error');
        if (error) {
            this.error = error;
            this.$timeout(function () {
                _this.rum.appComplete();
            });
            return;
        }
        /**
         * This page can be erroneously reached when a user has existing reports, e.g. by using the browser cache.
         * This can be fixed by reloading the page, which will load an existing report
         */
        // @ts-ignore
        this.getExistingReports().then(function (reports) {
            var hasExistingReports = reports.length > 0;
            if (hasExistingReports) {
                _this.$window.location.reload();
            }
            else {
                _this.init();
            }
        });
    };
    NoReportsController.prototype.create = function () {
        this.createModal.show();
    };
    NoReportsController.prototype.getExistingReports = function () {
        var sourceId = this.fieldsetsService.getMainSourceId();
        return this.reportMenuService.getUserReports(sourceId);
    };
    NoReportsController.prototype.init = function () {
        var _this = this;
        var configFlags = this.triggerOverrides({
            event: this.OVERRIDE_EVENTS.CONFIG_FLAGS,
            sync: true,
            data: {}
        });
        this.$timeout(function () {
            _this.rum.appComplete();
        });
        this.canAccessLegacyReports = !!this.env.get('permissions').canAccessLegacyReports;
        this.classicURL = this.classicURLService.getClassicURL();
        this.ready = true;
        this.noReportsMessage = this.triggerOverrides({
            event: this.OVERRIDE_EVENTS.NO_REPORTS_MESSAGE,
            sync: true,
            data: 'NO_REPORTS_MESSAGE' // default message
        });
        if (configFlags.autoOpenCreateReportModal) {
            this.create();
        }
    };
    NoReportsController.$inject = [
        '$timeout',
        'rsEnvironment',
        'prCreateReportModal',
        'rum',
        '$window',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        'prClassicURLService',
        'prFieldsets',
        'prReportMenuService'
    ];
    return NoReportsController;
}());
exports.default = NoReportsController;
