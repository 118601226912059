"use strict";
(function (module) {
    'use strict';
    module.factory('prPipedEmbedEventsMapper', [
        'pr.WIDGET_MANAGER_EVENTS',
        // @ts-ignore
        function (WIDGET_MANAGER_EVENTS) {
            function PipedEmbedEventsMapper() {
                // @ts-ignore
                return this;
            }
            PipedEmbedEventsMapper.eventsMap = {
                activeReportMeta: {
                    activeReportName: ['reportRenamed']
                },
                date: {
                    lastResponseDate: [WIDGET_MANAGER_EVENTS.FILTERS_CHANGE]
                },
                sourceMeta: {
                    sourceTotalResponses: [WIDGET_MANAGER_EVENTS.FILTERS_CHANGE]
                },
                sourceAggregateData: [WIDGET_MANAGER_EVENTS.FILTERS_CHANGE],
                subjectInfo: [WIDGET_MANAGER_EVENTS.FILTERS_CHANGE],
                subjectMetadata: [WIDGET_MANAGER_EVENTS.FILTERS_CHANGE],
                subjectsEvaluators: [WIDGET_MANAGER_EVENTS.FILTERS_CHANGE],
                subjectSmartText: [WIDGET_MANAGER_EVENTS.FILTERS_CHANGE],
                customMetric: [
                    WIDGET_MANAGER_EVENTS.FILTERS_CHANGE,
                    WIDGET_MANAGER_EVENTS.CUSTOM_METRICS_CHANGED
                ]
            };
            var proto = PipedEmbedEventsMapper.prototype;
            // @ts-ignore
            // @ts-ignore
            proto.getEvents = function (type, attrName) {
                var events = [];
                var ATTR_NAME_NOT_NECESSARY = [
                    'sourceAggregateData',
                    'subjectInfo',
                    'subjectMetadata',
                    'subjectSmartText',
                    'subjectsEvaluators',
                    'customMetric'
                ];
                if (!attrName || _.includes(ATTR_NAME_NOT_NECESSARY, type)) {
                    // @ts-ignore
                    events = PipedEmbedEventsMapper.eventsMap[type];
                }
                else {
                    // @ts-ignore
                    events = (PipedEmbedEventsMapper.eventsMap[type] || {})[attrName];
                }
                return events;
            };
            return PipedEmbedEventsMapper;
        }
    ]);
})(angular.module('qualtrics.pr'));
