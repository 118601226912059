"use strict";
(function (module) {
    'use strict';
    module.constant('pr.METRIC_PROPERTIES', {
        ACCESS_CODE: 'accessCodeProtect',
        APPLIED_TO: 'appliedTo',
        AXIS_LABEL: 'axisLabel',
        BREAKOUT: 'breakout',
        CONTENT: 'content',
        COPIED: 'copied',
        COLOR_PALETTE: 'colorPalette',
        CUSTOM_METRIC_IDS: 'customMetricIds',
        DELETED_CUSTOM_METRIC_ID: 'deletedCustomMetricId',
        DATA_TYPE: 'dataType',
        DELETED_RCDL_ID: 'deletedRaterCategoryDisplayLogicId',
        DIRECT_WIDGET_ADD: 'directWidgetAdd',
        FILTER_ACTIVE: 'filterActive',
        FILTER_ID: 'filterId',
        FORMAT: 'format',
        FROM_BREAKOUT_BY: 'fromBreakoutBy',
        FROM_DISPLAY_LOGIC: 'fromDisplayLogic',
        FROM_METRIC: 'fromMetric',
        FROM_VIS: 'fromVisualization',
        METRIC: 'metric',
        NUM_CUSTOM_FILTERS: 'numCustomFilters',
        NUM_MAX_METRICS: 'numMaxMetrics',
        NUM_PAGES: 'numPages',
        NUM_TO: 'numTo',
        NUM_VIS: 'numVisualizations',
        OPTION: 'option',
        PUBLIC_REPORT_ACTIVE: 'publicReportActive',
        RCDL_IDS: 'raterCategoryDisplayLogicIds',
        SOURCE: 'source',
        SURVEY_SOURCE: 'surveySource',
        TO_BREAKOUT_BY: 'toBreakoutBy',
        TO_DISPLAY_LOGIC: 'toDisplayLogic',
        TO_METRIC: 'toMetric',
        TO_VIS: 'toVisualization',
        TYPE: 'type',
        VIS_TYPE: 'visualizationType',
        WHEN: 'when',
    });
    module.factory('prMetricsService', [
        'prActiveReport',
        'tractive',
        'rsEnvironment',
        'prFields',
        'pr.METRIC_PROPERTIES',
        'rsState',
        '$interval',
        'pr.navigationService',
        function (activeReport, tractive, env, prFields, metricProperties, rsState, $interval, navigationService) {
            var container = env.get('container');
            var AXIS_LABEL_EVENT = 'AxisLabel.Change', COPY_LINK_EVENT = 'PublicReportLink.Copy', COLOR_PALETTE_EVENT = 'ColorPalette.Change', CUSTOM_METRIC_EVENT = {
                SAVE: 'CustomMetric.Save',
                REMOVE: 'CustomMetric.Remove'
            }, DELETE_REPORT_EVENT = 'Report.Delete', EXPORT_COMPLETE_EVENT = 'Export.Complete', FILTER_EVENT = {
                ADD: 'Filter.Add',
                CREATE: 'Filter.Create',
                REMOVE: 'Filter.Remove'
            }, MANAGE_PUBLIC_REPORT_EVENT = 'ManagePublicReport.Change', NEW_REPORT_EVENT = 'NewReport.Complete', PAGE_BREAK_EVENT = {
                ADD: 'PageBreak.Add',
                REMOVE: 'PageBreak.Remove'
            }, PAGE_LAND_EVENT = 'PageLand', PREVIEW_EVENT = 'Preview.Click', PUBLIC_DOWNLOAD_EVENT = 'PublicReport.Download', PUBLIC_VIEW_EVENT = 'PublicReport.View', RCDL_EVENT = {
                SAVE: 'RaterCategoryDisplayLogic.Save',
                REMOVE: 'RaterCategoryDisplayLogic.Remove'
            }, SCHEDULED_EMAIL_EVENT = 'ScheduledReportEmail.Complete', SETTINGS_EVENT = {
                CHANGE: 'ReportSettings.Change',
                DEFAULT: 'ReportSettings.Default'
            }, VISUALIZATION_EVENT = {
                ADD: 'Visualization.Add',
                BREAKOUT: 'Visualization.Breakout.Change',
                COPY: 'Visualization.Copy',
                DISPLAY: 'Visualization.DisplayLogic.Change',
                METRIC: 'Visualization.Metric.Change',
                REMOVE: 'Visualization.Remove',
                TYPE: 'Visualization.Type.Change'
            };
            var PAGE_BREAK_WIDGET_TYPE = 'pr.pagebreak', CALL_SOURCE = {
                DROPDOWN: 'DropDown',
                BUTTON: 'Button'
            }, CREATE_METHODS = {
                BLANK: 'blank',
                IMPORT: 'import',
                VIEW_RESULTS: 'view-results'
            }, DEFAULT_TYPES = {
                QUALTRICS: 'qualtrics',
                SAVE: 'save as',
                USE: 'use'
            }, EXPORT_CALL_SOURCE = {
                BUTTON: 'button',
                SHARE: 'share',
                EE: 'EE modal'
            }, FILE_FORMATS = {
                docx: 'Word',
                jpg: 'JPEG',
                pdf: 'PDF',
                pptx: 'PPTX',
                qrf: 'QRF',
                EDITABLE_PPTX: 'EDITABLE_PPTX'
            }, EMAIL_TYPE = {
                CREATE: 'create',
                UPDATE: 'update'
            };
            var standardProperties = {
                'reportId': _.get(container, 'definition._id'),
                'activeSurveyId': env.get('activeSurveyId'),
                'reportSurveyIds': _.get(container, 'definition.contextMeta.surveyId')
            };
            var trackingEnabled = true;
            pageLand();
            function enableEventTracking() {
                trackingEnabled = true;
            }
            function disableEventTracking() {
                trackingEnabled = false;
            }
            function trackEvent(eventName, properties) {
                _.assignIn(properties, standardProperties);
                if (trackingEnabled) {
                    tractive.track('REP', eventName, properties);
                    // console.log('~~ Logging ' + eventName, properties);
                }
            }
            function pageLand() {
                var event, properties = {};
                properties[metricProperties.NUM_PAGES] = countPages();
                properties[metricProperties.NUM_VIS] = countVisualizations();
                if (navigationService.isPublic) {
                    event = PUBLIC_VIEW_EVENT;
                }
                else if (!navigationService.isEditor) {
                    return;
                }
                else {
                    event = PAGE_LAND_EVENT;
                    properties[metricProperties.FILTER_ACTIVE] = !_.isEmpty(_.get(container, 'definition.contextMeta.filters'));
                    properties[metricProperties.PUBLIC_REPORT_ACTIVE] = _.get(container, 'definition.contextMeta.isPublic') || false;
                    properties[metricProperties.NUM_CUSTOM_FILTERS] = countCustomFilters();
                    properties[metricProperties.NUM_MAX_METRICS] = calcMaxNumMetrics();
                }
                if (!_.get(window, 'tractive.isRunning')) {
                    var tractiveReady_1 = $interval(function () {
                        try {
                            if (_.get(window, 'tractive.isRunning')) {
                                trackEvent(event, properties);
                                $interval.cancel(tractiveReady_1);
                            }
                        }
                        catch (e) {
                            $interval.cancel(tractiveReady_1);
                        }
                    }, 100, 50);
                }
                else {
                    trackEvent(event, properties);
                }
            }
            function countVisualizations() {
                var pages = activeReport.getPages();
                var numWidgets = _.sumBy(pages, 'widgets.length');
                if (numWidgets) {
                    // Don't want count the page break widgets
                    return numWidgets - (pages.length - 1);
                }
                return 0;
            }
            function countPages() {
                return activeReport.getPages().length || 0;
            }
            function countCustomFilters() {
                var customFiltersObject = _.get(container, 'definition.contextMeta.customFilters');
                var totalCustomFilters = 0;
                if (customFiltersObject) {
                    var customFilters = Object.values(customFiltersObject);
                    customFilters.forEach(function (filter) {
                        totalCustomFilters += Object.keys(filter).length;
                    });
                }
                return totalCustomFilters;
            }
            function calcMaxNumMetrics() {
                var maxMetrics = 0;
                var pages = activeReport.getPages();
                pages.forEach(function (page) {
                    var _a;
                    (_a = page.widgets) === null || _a === void 0 ? void 0 : _a.forEach(function (widget) {
                        var _a, _b;
                        var dataConfig = (_b = (_a = widget.state) === null || _a === void 0 ? void 0 : _a.sharedData) === null || _b === void 0 ? void 0 : _b.dataConfig;
                        if (dataConfig) {
                            var metricsOrFields = dataConfig.metrics || dataConfig.fields || [];
                            maxMetrics = Math.max(maxMetrics, metricsOrFields.length);
                        }
                    });
                });
                return maxMetrics;
            }
            function getDataType(field, embeddedDataFields) {
                if (!field) {
                    return 'none';
                }
                if (prFields.isSurveyMetaField(field)) {
                    return 'Metadata';
                }
                var id = field.fieldId;
                if (prFields.isEmbeddedDataField(field) || _.includes(embeddedDataFields, id)) {
                    return 'EmbeddedData';
                }
                if (id) {
                    return 'Question';
                }
                return 'none';
            }
            function visualizationAdd(config, value, source) {
                var surveySource = 'none';
                var widgetType = value.fieldId ? (config && config.type) : value;
                var properties = {};
                if (widgetType === PAGE_BREAK_WIDGET_TYPE) {
                    properties[metricProperties.NUM_VIS] = countVisualizations();
                    trackEvent(PAGE_BREAK_EVENT.ADD, properties);
                }
                else {
                    if (value.fieldId) {
                        surveySource = (env.get('activeSurveyId') == value.sourceId) ? 'ActiveSurvey' : 'OtherProject';
                    }
                    properties[metricProperties.DIRECT_WIDGET_ADD] = config ? true : false;
                    properties[metricProperties.SURVEY_SOURCE] = surveySource;
                    properties[metricProperties.DATA_TYPE] = getDataType(value);
                    properties[metricProperties.VIS_TYPE] = widgetType;
                    properties[metricProperties.SOURCE] = source;
                    trackEvent(VISUALIZATION_EVENT.ADD, properties);
                }
            }
            function _getWidgetData(widget, propertyNames) {
                var properties = {};
                if (_.includes(propertyNames, metricProperties.METRIC)) {
                    properties[metricProperties.VIS_TYPE] = widget._type;
                }
                // Get updated data and extract needed properties
                return rsState.getConfig(widget._id, widget.sectionId, _.get(container, 'definition._id'))
                    .then(function (sharedData) {
                    _.forEach(propertyNames, function (name) {
                        switch (name) {
                            case metricProperties.BREAKOUT:
                                properties[name] = getDataType(_.get(sharedData, 'dataConfig.breakouts[0]'));
                                break;
                            case metricProperties.METRIC:
                                properties[name] = _.get(sharedData, 'dataConfig.metrics[0].type') || 'none';
                        }
                    });
                    return properties;
                });
            }
            function visualizationRemove(widget) {
                if (widget._type == PAGE_BREAK_WIDGET_TYPE) {
                    trackEvent(PAGE_BREAK_EVENT.REMOVE, {});
                    return;
                }
                return visualizationEvent(widget, VISUALIZATION_EVENT.REMOVE);
            }
            function visualizationCopy(widget) {
                return visualizationEvent(widget, VISUALIZATION_EVENT.COPY);
            }
            function visualizationEvent(widget, eventName) {
                var propertyNames = [
                    metricProperties.VIS_TYPE,
                    metricProperties.METRIC,
                    metricProperties.BREAKOUT
                ];
                return _getWidgetData(widget, propertyNames).then(function (properties) {
                    trackEvent(eventName, properties);
                });
            }
            function changeVisualizationType(prevWidgetType, newWidgetType) {
                var properties = {};
                properties[metricProperties.FROM_VIS] = prevWidgetType;
                properties[metricProperties.TO_VIS] = newWidgetType;
                trackEvent(VISUALIZATION_EVENT.TYPE, properties);
            }
            function newReport(method) {
                var properties = {};
                properties[metricProperties.COPIED] = false;
                if (method === CREATE_METHODS.BLANK) {
                    properties[metricProperties.OPTION] = 'New Report';
                }
                else if (method === CREATE_METHODS.VIEW_RESULTS) {
                    properties[metricProperties.OPTION] = 'From Results Report';
                }
                else if (method === CREATE_METHODS.IMPORT) {
                    properties[metricProperties.OPTION] = 'Import from QRF';
                }
                else {
                    properties[metricProperties.OPTION] = 'none';
                    properties[metricProperties.COPIED] = true;
                }
                trackEvent(NEW_REPORT_EVENT, properties);
            }
            function deleteReport() {
                var properties = {};
                properties[metricProperties.NUM_VIS] = countVisualizations();
                properties[metricProperties.NUM_PAGES] = countPages();
                trackEvent(DELETE_REPORT_EVENT, properties);
            }
            function changeSettings() {
                trackEvent(SETTINGS_EVENT.CHANGE, {});
            }
            function defaultSettings(option) {
                var properties = {};
                if (option === DEFAULT_TYPES.USE) {
                    properties[metricProperties.OPTION] = 'Apply My Default';
                }
                else if (option === DEFAULT_TYPES.SAVE) {
                    properties[metricProperties.OPTION] = 'Save As My Default';
                }
                else if (option === DEFAULT_TYPES.QUALTRICS) {
                    properties[metricProperties.OPTION] = 'Apply Qualtrics Default';
                }
                trackEvent(SETTINGS_EVENT.DEFAULT, properties);
            }
            function setFilter(appliedFilter, updatedFilter, type) {
                var _a;
                var _b;
                var properties = (_a = {},
                    _a[metricProperties.APPLIED_TO] = type,
                    _a[metricProperties.FILTER_ID] = (_b = updatedFilter === null || updatedFilter === void 0 ? void 0 : updatedFilter.filterId) !== null && _b !== void 0 ? _b : 'None',
                    _a);
                if (_.isEqual(appliedFilter, updatedFilter)) {
                    return;
                }
                else if (updatedFilter) {
                    // Filter was added (or changed)
                    trackEvent(FILTER_EVENT.ADD, properties);
                }
                else {
                    // Filter was removed
                    trackEvent(FILTER_EVENT.REMOVE, properties);
                }
            }
            function managePublicReport(isPublic, isPasscodeProtected, source) {
                var properties = {};
                var oldContextMeta = activeReport.getMetadata();
                // If isPublic and isPasscodeProtected are the same, and at least one is false, then no
                // changes were made and no event should be fired
                // If both are true, then the passcode was changed.
                if (oldContextMeta.isPublic === isPublic &&
                    oldContextMeta.passcodeProtected === isPasscodeProtected &&
                    (!isPublic || !isPasscodeProtected)) {
                    return;
                }
                properties[metricProperties.PUBLIC_REPORT_ACTIVE] = isPublic;
                properties[metricProperties.ACCESS_CODE] = isPublic ? isPasscodeProtected : 'none';
                properties[metricProperties.SOURCE] = source;
                trackEvent(MANAGE_PUBLIC_REPORT_EVENT, properties);
            }
            function copyPublicLink(accessCode, source) {
                var properties = {};
                properties[metricProperties.ACCESS_CODE] = accessCode;
                properties[metricProperties.SOURCE] = source;
                trackEvent(COPY_LINK_EVENT, properties);
            }
            function exportComplete(type, callSource) {
                var properties = {};
                var CONTENT = {
                    REPORT: 'Report',
                    VIS: 'Visualization'
                };
                var SOURCE = {
                    QRF: 'Export to QRF',
                    PDFBUTTON: 'Download PDF Button',
                    PDFSHARE: 'Share Download PDF',
                    DOCXSHARE: 'Share Download Word',
                    EE: 'EE Export Manager',
                    OPTIONS: 'Options'
                };
                if (navigationService.isPublic) {
                    // Public Report Download
                    properties[metricProperties.NUM_PAGES] = countPages();
                    properties[metricProperties.NUM_VIS] = countVisualizations();
                    trackEvent(PUBLIC_DOWNLOAD_EVENT, properties);
                }
                else {
                    properties[metricProperties.FORMAT] = FILE_FORMATS[type];
                    switch (FILE_FORMATS[type]) {
                        case FILE_FORMATS.qrf:
                            properties[metricProperties.CONTENT] = CONTENT.REPORT;
                            properties[metricProperties.SOURCE] = SOURCE.QRF;
                            break;
                        case FILE_FORMATS.pdf:
                            properties[metricProperties.CONTENT] = CONTENT.REPORT;
                            if (callSource == EXPORT_CALL_SOURCE.BUTTON) {
                                properties[metricProperties.SOURCE] = SOURCE.PDFBUTTON;
                            }
                            else if (callSource == EXPORT_CALL_SOURCE.SHARE) {
                                properties[metricProperties.SOURCE] = SOURCE.PDFSHARE;
                            }
                            else if (callSource == EXPORT_CALL_SOURCE.EE) {
                                properties[metricProperties.SOURCE] = SOURCE.EE;
                            }
                            break;
                        case FILE_FORMATS.pptx:
                        case FILE_FORMATS.EDITABLE_PPTX:
                        case FILE_FORMATS.jpg:
                            if (callSource == EXPORT_CALL_SOURCE.EE) {
                                properties[metricProperties.CONTENT] = CONTENT.REPORT;
                                properties[metricProperties.SOURCE] = SOURCE.EE;
                            }
                            else {
                                properties[metricProperties.CONTENT] = CONTENT.VIS;
                                properties[metricProperties.SOURCE] = SOURCE.OPTIONS;
                            }
                            break;
                        case FILE_FORMATS.docx:
                            if (callSource == EXPORT_CALL_SOURCE.SHARE) {
                                properties[metricProperties.CONTENT] = CONTENT.REPORT;
                                properties[metricProperties.SOURCE] = SOURCE.DOCXSHARE;
                            }
                            else {
                                properties[metricProperties.CONTENT] = CONTENT.VIS;
                                properties[metricProperties.SOURCE] = SOURCE.OPTIONS;
                            }
                            break;
                        default:
                            break;
                    }
                    trackEvent(EXPORT_COMPLETE_EVENT, properties);
                }
            }
            function scheduledEmail(frequency, numRecipients, type) {
                var properties = {};
                properties[metricProperties.WHEN] = frequency;
                properties[metricProperties.NUM_TO] = numRecipients;
                properties[metricProperties.TYPE] = type === EMAIL_TYPE.CREATE ? 'Create New' : 'Update Existing';
                trackEvent(SCHEDULED_EMAIL_EVENT, properties);
            }
            function preview(callSource) {
                var properties = {};
                properties[metricProperties.SOURCE] = callSource;
                properties[metricProperties.NUM_PAGES] = countPages();
                properties[metricProperties.NUM_VIS] = countVisualizations();
                trackEvent(PREVIEW_EVENT, properties);
            }
            function changeVisualizationMetric(prevMetric, newMetric) {
                var properties = {};
                properties[metricProperties.FROM_METRIC] = prevMetric || 'none';
                properties[metricProperties.TO_METRIC] = newMetric || 'none';
                trackEvent(VISUALIZATION_EVENT.METRIC, properties);
            }
            function changeVisualizationBreakout(prevBreakout, newBreakout, embeddedDataFields) {
                var properties = {};
                properties[metricProperties.FROM_BREAKOUT_BY] = getDataType({ fieldId: prevBreakout }, embeddedDataFields);
                properties[metricProperties.TO_BREAKOUT_BY] = getDataType({ fieldId: newBreakout }, embeddedDataFields);
                trackEvent(VISUALIZATION_EVENT.BREAKOUT, properties);
            }
            function getDisplayType(option) {
                var enabled = _.isBoolean(option) ? option : _.get(option, 'enabled');
                if (!_.isUndefined(enabled)) {
                    return enabled ? 'Custom Rule' : 'Always Display';
                }
                return 'Default';
            }
            function changeVisualizationDisplayLogic(prevOption, newOption) {
                var properties = {};
                properties[metricProperties.FROM_DISPLAY_LOGIC] = getDisplayType(prevOption);
                properties[metricProperties.TO_DISPLAY_LOGIC] = getDisplayType(newOption);
                trackEvent(VISUALIZATION_EVENT.DISPLAY, properties);
            }
            function saveThreeSixtyRaterDisplayLogic(updatedRCDL) {
                var _a;
                if (updatedRCDL === void 0) { updatedRCDL = []; }
                var updatedRCDLIds = updatedRCDL.map(function (displayLogic) {
                    return displayLogic._id;
                });
                var properties = (_a = {},
                    _a[metricProperties.RCDL_IDS] = updatedRCDLIds,
                    _a);
                trackEvent(RCDL_EVENT.SAVE, properties);
            }
            function removeThreeSixtyRaterDisplayLogic(removedRCDLId) {
                var _a;
                var properties = (_a = {},
                    _a[metricProperties.DELETED_RCDL_ID] = removedRCDLId,
                    _a);
                trackEvent(RCDL_EVENT.REMOVE, properties);
            }
            function changeColorPalette(updatedPalette) {
                var properties = {};
                properties[metricProperties.COLOR_PALETTE] = updatedPalette.name;
                trackEvent(COLOR_PALETTE_EVENT, properties);
            }
            function changeAxisLabel(updatedLabel) {
                var properties = {};
                properties[metricProperties.AXIS_LABEL] = updatedLabel;
                trackEvent(AXIS_LABEL_EVENT, properties);
            }
            function saveCustomMetrics(updatedMetrics) {
                var _a;
                if (updatedMetrics === void 0) { updatedMetrics = []; }
                var updatedMetricIds = updatedMetrics.map(function (customMetric) {
                    return customMetric.id;
                });
                var properties = (_a = {},
                    _a[metricProperties.CUSTOM_METRIC_IDS] = updatedMetricIds,
                    _a);
                trackEvent(CUSTOM_METRIC_EVENT.SAVE, properties);
            }
            function removeCustomMetric(removedMetricId) {
                var _a;
                var properties = (_a = {},
                    _a[metricProperties.DELETED_CUSTOM_METRIC_ID] = removedMetricId,
                    _a);
                trackEvent(CUSTOM_METRIC_EVENT.REMOVE, properties);
            }
            return {
                CALL_SOURCE: CALL_SOURCE,
                DEFAULT_TYPES: DEFAULT_TYPES,
                EMAIL_TYPE: EMAIL_TYPE,
                EXPORT_CALL_SOURCE: EXPORT_CALL_SOURCE,
                changeAxisLabel: changeAxisLabel,
                changeColorPalette: changeColorPalette,
                changeSettings: changeSettings,
                changeVisualizationBreakout: changeVisualizationBreakout,
                changeVisualizationDisplayLogic: changeVisualizationDisplayLogic,
                changeVisualizationMetric: changeVisualizationMetric,
                changeVisualizationType: changeVisualizationType,
                copyPublicLink: copyPublicLink,
                defaultSettings: defaultSettings,
                deleteReport: deleteReport,
                disableEventTracking: disableEventTracking,
                enableEventTracking: enableEventTracking,
                exportComplete: exportComplete,
                managePublicReport: managePublicReport,
                newReport: newReport,
                pageLand: pageLand,
                preview: preview,
                removeCustomMetric: removeCustomMetric,
                removeThreeSixtyRaterDisplayLogic: removeThreeSixtyRaterDisplayLogic,
                saveCustomMetrics: saveCustomMetrics,
                scheduledEmail: scheduledEmail,
                saveThreeSixtyRaterDisplayLogic: saveThreeSixtyRaterDisplayLogic,
                setFilter: setFilter,
                trackEvent: trackEvent,
                visualizationAdd: visualizationAdd,
                visualizationCopy: visualizationCopy,
                visualizationRemove: visualizationRemove
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
