"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("angular");
(function (angularModule) {
    angularModule.factory('prStaticTranslationLoader', [
        '$q',
        'prDynamicTranslationsHelperUtils',
        // @ts-ignore
        // @ts-ignore
        function ($q, dynamicTranslationHelperUtils) {
            // @ts-ignore
            return function (options) {
                // @ts-ignore
                return dynamicTranslationHelperUtils.getStaticTranslations(options.key).catch(function (error) {
                    return $q.reject(options.key);
                });
            };
        }
    ]);
})(angular_1.module('qualtrics.pr'));
