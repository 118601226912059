"use strict";
(function (module) {
    module.directive('prEmailDomainBrandValidator', [
        '$q',
        'pr.scheduleEmailService',
        function ($q, scheduleEmailService) {
            return {
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    ctrl.$asyncValidators.domainBrand = function (modelValue, viewValue) {
                        if (ctrl.$isEmpty(modelValue)) {
                            // Consider empty model valid because the required directive
                            // handles this case.
                            return $q.resolve();
                        }
                        var def = $q.defer();
                        var domain = viewValue.substring(viewValue.indexOf('@') + 1);
                        scheduleEmailService.getValidDomains().then(function (result) {
                            var validDomains = result.data;
                            var isDomainValid = _.contains(validDomains, '*') // any domain is valid
                                || _.contains(validDomains, domain);
                            if (isDomainValid) {
                                def.resolve();
                            }
                            else {
                                def.reject();
                            }
                        });
                        return def.promise;
                    };
                }
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
