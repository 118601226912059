"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./hidden-renderer.less");
(function (module) {
    'use strict';
    var PARENT_CONTAINER = 'body';
    module.factory('prHiddenRenderer', [
        '$compile',
        '$rootScope',
        'prPageLayoutService',
        function ($compile, $rootScope, pageLayout) {
            var container, 
            /*
             * Each cache entry is an map with 2 keys:
             * 		promise: resolves with the $ctrl when the widget is done loading everything
             *		$ctrl: A controller for the hidden widget to get /set dimensions and such
             */
            widgetCache = {};
            // draws a widget and returns a promise of the widget controller
            // when the widget is finished loading everything, including data
            function renderWidget(widget) {
                var widgetId = widget._id, wDom = $('<div class="pr-hidden-widget" id='
                    + 'HIDDEN_' + widgetId
                    + '><pr-widget-body></pr-widget-body></div>'), scope = $rootScope.$new();
                scope.widget = widget;
                $compile(wDom)(scope);
                return (widgetCache[widgetId] = {
                    $element: wDom,
                    $scope: scope
                });
            }
            function getContainer() {
                if (!container) {
                    container = $('<div class="hidden-renderer">');
                    $(PARENT_CONTAINER).append(container);
                }
                return container;
            }
            function drawWidget(widget) {
                var id = widget._id;
                if (!widgetCache[id]) {
                    widgetCache[id] = renderWidget(widget);
                }
                return widgetCache[id];
            }
            /*
             * We show / hide widgets for performance reason. There is really no need to keep the widget on the DOM
             * We can also put the widget on a container that is passed in
             */
            function showWidget(widget, optContainer) {
                var el = drawWidget(widget).$element;
                var width = pageLayout.getWidgetWidth(widget);
                el.css('width', width);
                if (!el.parent().length || optContainer) {
                    (optContainer || getContainer()).append(el);
                }
                return el;
            }
            function hideWidget(widget) {
                var info = drawWidget(widget);
                // keeps all of the events and watchers and jazz, just don't have it on the DOM
                return info.$element.detach();
            }
            function removeWidget(widget) {
                if (widgetCache[widget._id]) {
                    delete widgetCache[widget._id];
                }
            }
            function appendElement(el) {
                return getContainer().append(el);
            }
            function containsElement(element) {
                return getContainer()[0].contains(element);
            }
            return {
                /*
                 * Returns a map that contains:
                 *	{
                 *		$element: <DomNode> The widget-body dom node
                 *		$scope: <AngularScope> The widget-body scope
                 *	}
                 */
                containsElement: containsElement,
                drawWidget: drawWidget,
                showWidget: showWidget,
                hideWidget: hideWidget,
                removeWidget: removeWidget,
                appendElement: appendElement
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
