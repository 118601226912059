"use strict";
(function (module) {
    'use strict';
    function PageLayoutController($scope, $filter, $timeout, pageLayout, metricImperial) {
        var $ctrl = this;
        $scope.$ctrl = $ctrl;
        var translate = $filter('translate');
        angular.extend($ctrl, {
            $onInit: function () {
                $ctrl.pageLayout = _.merge(pageLayout.getProperties(), $ctrl.pageLayout || {});
                populatePageLayout();
            },
            $doCheck: function () {
                if (!_.isEqual($ctrl.currentMargins, $ctrl.pageLayout.margins) || ($ctrl.currentUnit !== $ctrl.unit)) {
                    populatePageLayout();
                }
            },
            updateSize: function (size) {
                var sizeOptions = pageLayout.getSizeOptions($ctrl.unit);
                var dimensions = sizeOptions[size] || {};
                if (dimensions.width >= dimensions.height) {
                    // slide presentation layout 
                    $ctrl.pageLayout.orientation = 'horizontal';
                }
                else {
                    // printed report layout
                    $ctrl.pageLayout.orientation = 'vertical';
                }
            },
            getSizeMenu: function () {
                var order = [
                    { header: translate('PRINTED_REPORT') },
                    'letter', 'legal', 'a4', 'b4',
                    { header: translate('SLIDE_PRESENTATION') },
                    'widescreen1', 'widescreen2', 'fullscreen'
                ];
                var sizes = pageLayout.getSizeOptions($ctrl.unit);
                return _.map(order, function (item) {
                    if (_.isObject(item)) {
                        return item;
                    }
                    return _.extend({
                        value: item
                    }, sizes[item]);
                });
            },
            useSimpleMargin: function () {
                return !$ctrl.overrideSimpleMargin && _.uniq(_.values($ctrl.currentMargins)).length === 1;
            },
            updateAllMargins: function (value) {
                _.forEach($ctrl.currentMargins, function (val, key) {
                    $ctrl.currentMargins[key] = value;
                    $ctrl.updateMargin(key, value);
                });
            },
            updateMargin: function (side, value) {
                if (side === 'top') {
                    $ctrl.simpleVal = value;
                }
                var inchVal = $ctrl.useInch() ? $ctrl.convertToInchStep(value) : metricImperial.getInchOfCM(value);
                $ctrl.pageLayout.margins[side] = inchVal;
            },
            // Forces value to always be a .25 inch step.
            // Values that are not steps of .25 are automatically defaulted
            // by AngularJS to undefined. This breaks the report.
            convertToInchStep: function (value) {
                var NUM_STEPS_PER_INCH = 4;
                return Math.round(value * NUM_STEPS_PER_INCH) / NUM_STEPS_PER_INCH;
            },
            getSizeLabel: function () {
                var sizes = pageLayout.getSizeOptions($ctrl.unit);
                return sizes[$ctrl.pageLayout.size].label;
            },
            getSideLabel: function (side) {
                return translate(side.toUpperCase());
            },
            useInch: function () {
                return metricImperial.useInch($ctrl.unit);
            }
        });
        function getLabel(unitValue) {
            return metricImperial.getCustomLabel(translate('MARGINS'), unitValue);
        }
        function getConvertedMargins(margins) {
            var res = {};
            _.forEach(margins, function (val, side) {
                if ($ctrl.useInch()) {
                    val = metricImperial.getInchOfCM(val);
                }
                else {
                    val = metricImperial.getCMOfInch(val);
                }
                res[side] = val;
            });
            return res;
        }
        function populatePageLayout() {
            $ctrl.currentMargins = $ctrl.pageLayout.margins;
            $ctrl.currentUnit = $ctrl.unit;
            $ctrl.label = getLabel($ctrl.unit);
            if (!$ctrl.useInch()) {
                $ctrl.currentMargins = getConvertedMargins($ctrl.currentMargins);
            }
            $ctrl.simpleVal = $ctrl.currentMargins.top;
        }
    }
    PageLayoutController.$inject = [
        '$scope',
        '$filter',
        '$timeout',
        'prPageLayoutService',
        'prMetricImperial'
    ];
    module.controller('prPageLayoutController', PageLayoutController);
}(angular.module('qualtrics.pr')));
