"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var filter_utils_1 = require("@shared/utils/filter-utils");
(function (module) {
    'use strict';
    var defaultDates = ['recordedDate', 'endDate', 'startDate'];
    var FilterUtils = function (filterConstants, globalOptions, timezoneUtil) {
        this.globalOptions = globalOptions;
        this.timezoneUtil = timezoneUtil;
        this.relativeDateFilters = filterConstants.getRelativeDateFilters();
    };
    FilterUtils.$inject = [
        'prFilterConstants',
        'pr.globalOptionsService',
        'pr.timezoneUtil'
    ];
    // In sync with the amount values from filters-client
    // https://gitlab-app.eng.qops.net/dat/filters-client/-/blob/master/src/js/directives/date-value-directive.js#L166
    var VALID_AMMOUNTS = [6, 13, 29, 59, 89, 119, 179, 364];
    /**
     * Function that takes an outdated buggy dateRange object and updates it
     * to have the correct values (if necessary).
     *
     * @param {object} dateRange - dateRange object
     */
    FilterUtils.prototype.updateDateRange = function (dateRange) {
        if (!_.isObject(dateRange)) {
            return;
        }
        var alreadyUpdated = _.includes(VALID_AMMOUNTS, dateRange.amount);
        if (alreadyUpdated) {
            return;
        }
        if (dateRange.adjustPeriod === 'day') {
            dateRange.type = 'rolling';
        }
        // old dateRange objects had amounts one larger thant they should
        if (dateRange.amount > 0) {
            dateRange.amount -= 1;
        }
    };
    /* Saved Filters stores start of day as time in the fromTimezone
     * need to convert to start of day for toTimezone
     *
     * Note this is not converting to a matching time in a different timezone
     * As in 5 am in NY is 2 am in Seattle
     * But rather the same time of day
     * As in 5 am Zulu time (midnight NY) would be 8 am Zulu for midnight in Seattle
     */
    var convertFilterClientTimezone = function (time, fromTimezone, toTimezone) {
        var fromOffset = moment.tz(time, fromTimezone).utcOffset();
        var toOffset = moment.tz(time, toTimezone).utcOffset();
        var offset = fromOffset - toOffset;
        return moment(time).add(offset, 'minutes').toJSON();
    };
    /**
     * A helper function that runs anytime a date operand is updated
     *
     * If this is a multi-select date operator (i.e. "between"), then
     * the operands must be inclusive. Otherwise, they are exclusive.
     *
     * @param {object} filter - the filter containing the date operand to update
     * @param {string} filterTimezone - timezone filter was configured with
     */
    FilterUtils.prototype.updateDateOperand = function (filter, filterTimezone) {
        // New filters created with filters-client store the timezone used when creating the filter on the filter
        // we can use this to adjust the filter from the created timezone to the report timezone
        if (filterTimezone) {
            var reportTimezone = this.globalOptions.getTimezone();
            switch (filter.operator) {
                case 'after':
                /* falls through */
                case 'before':
                    filter.operand = convertFilterClientTimezone(filter.operand, filterTimezone, reportTimezone);
                    break;
                case 'between':
                    if (filter.operand && filter.operand[0]) {
                        filter.operand[0] = convertFilterClientTimezone(filter.operand[0], filterTimezone, reportTimezone);
                    }
                    if (filter.operand && filter.operand[1]) {
                        filter.operand[1] = convertFilterClientTimezone(filter.operand[1], filterTimezone, reportTimezone);
                    }
                    break;
            }
        }
        else {
            // Old filters we don't know anythign about the timezone used when creating the filter, there is not much we
            // can do to fix them, solution is to update the filter which will change it to use the code above
            // TODO: eventually remove this else statement
            // David Thought: doing nothing might be better than what the code below does, for now leaving it to maintain the status-quo
            var getEndOfDay = function (dateString) {
                var momentDate = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);
                if (!momentDate.isValid()) {
                    return dateString;
                }
                var timezone = this.globalOptions.getTimezone();
                var endOfDay = momentDate.tz(timezone).hour(23).minute(59).second(59).millisecond(999).toISOString();
                return endOfDay;
            }.bind(this);
            if (filter.operator === 'after') {
                filter.operand = getEndOfDay(filter.operand);
            }
            else if (filter.operator === 'between' && filter.operand.end) {
                filter.operand.end = getEndOfDay(filter.operand.end);
            }
        }
    };
    /**
     * Helper function to fix a given array of filters
     * @param  {Array.<Object>} filterArray
     */
    FilterUtils.prototype.fixFilters = function fixFilters(filterArray, timezone) {
        filterArray = _.isArray(filterArray) ? filterArray : [filterArray];
        var self = this;
        _.forEach(filterArray, function fixFilter(filter) {
            self.fixDateFilters(filter, timezone);
            filter_utils_1.fixOldPRFilters(filter);
        });
    };
    /**
     * this function is to fix some existing date filters to make them load the correct data
     *
     * @param {object} filters - the filters to fix
     * @param {string} filterTimezone - timezone of date filter when filter was configured in filter-client
     */
    FilterUtils.prototype.fixDateFilters = function (filters, filterTimezone) {
        if (_.isEmpty(filters)) {
            return {};
        }
        if (!_.isEmpty(filters.children)) {
            var self_1 = this;
            _.forEach(filters.children, function (currFilter) {
                self_1.fixDateFilters(currFilter, filters.timezone || filterTimezone);
            });
            return filters;
        }
        // If for some reason there isn't a filter set but there are no children, just return
        if (!filters.filter) {
            return filters;
        }
        // Should not apply timezone to non default date fields
        if (filters.filter.field && !_.includes(defaultDates, filters.filter.field)) {
            return filters;
        }
        // Fix any date operands that were created by old filter code
        this.updateDateOperand(filters.filter, filters.timezone || filterTimezone);
        // relativeDate (today or yesterday)
        if (filters.filter.dateRange) {
            this.updateDateRange(filters.filter.dateRange);
            var timezone = this.globalOptions.getTimezone();
            var timeZoneOffset = this.timezoneUtil.getTimezoneOffset(timezone);
            filters.filter.dateRange.timeZoneOffset = timeZoneOffset;
        }
        return filters;
    };
    module.service('prFilterUtils', FilterUtils);
})(angular.module('qualtrics.pr'));
