"use strict";
(function (module) {
    'use strict';
    module.factory('prCopyReportModal', [
        'qstrap.modal',
        function (modal) {
            function show() {
                return modal({
                    show: true,
                    controller: 'prCopyReportController',
                    title: '<i class="icon icon-squarestack"></i><span translate>COPY_REPORT</span>',
                    content: '<div qstrap.spin spin-if="$ctrl.spin" class="form-group">' +
                        '<label translate>REPORT_NAME</label>' +
                        '<input type="text" class="form-control form-control-full" ng-model="$ctrl.reportCopyName">' +
                        '</div>',
                    buttons: [
                        '<button class="btn btn-success" ng-disabled="$ctrl.spin" ng-click="$ctrl.copyReport($ctrl.reportCopyName)">' +
                            '<i class="icon btn-icon-check"></i>' +
                            '<span translate>COPY</span>' +
                            '</button>',
                        '<button class="btn btn-hover" ng-click="close()" translate>CANCEL</button>'
                    ]
                });
            }
            return {
                show: show
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
