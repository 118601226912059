"use strict";
/* eslint no-prototype-builtins: "off" */
(function (module) {
    /*
     * Reference: https://github.com/wix/angular-viewport-watch/blob/master/angular-viewport-watch.js
     *
     * Disable watchers that are currently out of the viewport and makes sure
     * they get enabled and updated with their correct value the moment they come back into the viewport.
     * This means that at any moment, the amount of items being watched is not greater than
     * the amount of items that fit into the user's screen.
     * This obviously cuts down the digest loop length by orders of magnitude.
     */
    module.directive('toggleWatchers', function toggleWatchers() {
        return {
            restrict: 'AE',
            scope: false,
            link: function ($scope, $el, $attr) {
                // watch the watcher for change
                $scope.$watch($attr.toggleWatchers, toggleWatchers);
                // Registers and deregisters watchers while we are disabled
                function watchDuringDisable() {
                    this.$$watchersBackup = this.$$watchersBackup || [];
                    this.$$watchers = this.$$watchersBackup;
                    var unwatch = this.constructor.prototype.$watch.apply(this, arguments);
                    this.$$watchers = null;
                    return unwatch;
                }
                function toggleWatchers(enable) {
                    var current, next = $scope.$$childHead;
                    while (next) {
                        current = next;
                        if (enable) {
                            if (current.hasOwnProperty('$$watchersBackup')) {
                                current.$$watchers = current.$$watchersBackup;
                                delete current.$$watchersBackup;
                                delete current.$watch;
                            }
                        }
                        else {
                            if (!current.hasOwnProperty('$$watchersBackup')) {
                                current.$$watchersBackup = current.$$watchers;
                                current.$$watchers = null;
                                current.$watch = watchDuringDisable;
                            }
                        }
                        next = current.$$childHead;
                        while (!next && current !== $scope) {
                            if (current.$$nextSibling) {
                                next = current.$$nextSibling;
                            }
                            else {
                                current = current.$parent;
                            }
                        }
                    }
                }
            }
        };
    });
})(angular.module('qualtrics.pr'));
