"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var angular = __importStar(require("angular"));
// Fallback value. Observed default row height for all printed reports table rows.
var DEFAULT_ROW_HEIGHT = 26;
var TableConverter = /** @class */ (function () {
    function TableConverter(editablePptxsUtils) {
        this.editablePptxsUtils = editablePptxsUtils;
    }
    TableConverter.prototype.createEditableTable = function (tableElement, coordinates, meta) {
        return {
            type: "table" /* Table */,
            widgetOptions: {
                coordinates: this.getTableCoordinates(coordinates),
                rows: this.getTableRows(tableElement),
                tableDimensions: this.getTableOptions(tableElement)
            },
            meta: meta
        };
    };
    TableConverter.prototype.getTableRows = function (tableElement) {
        var tableRows = [];
        var headerRow = this.convertTableHeader(tableElement);
        if (headerRow.length > 0) {
            tableRows.push(headerRow);
        }
        var tableBodyRows = this.convertTableBody(tableElement);
        tableRows.push.apply(tableRows, tableBodyRows);
        // Officegen does not support generating empty tables.
        if (tableRows.length === 0) {
            throw Error('table-converter did not convert any table rows.');
        }
        return tableRows;
    };
    TableConverter.prototype.getTableOptions = function (tableElement) {
        return {
            rowHeight: this.getRowHeight(tableElement),
            columnWidths: this.getColumnWidths(tableElement)
        };
    };
    /**
     * Officegen only supports specifying 1 row height for the entire table.
     * Rows with more content will automatically stretch vertically to fit the
     * content. Using the smallest row value makes the table most likely to not
     * overflow.
     */
    TableConverter.prototype.getRowHeight = function (tableElement) {
        var _this = this;
        var tableRows = Array.from(tableElement.querySelectorAll('tr'));
        var minimumRowHeight = DEFAULT_ROW_HEIGHT;
        tableRows.forEach(function (tableRow) {
            var computedStyle = window.getComputedStyle(tableRow);
            var rowHeight = _this.editablePptxsUtils.getHeight(computedStyle);
            if (rowHeight < minimumRowHeight) {
                minimumRowHeight = rowHeight;
            }
        });
        return minimumRowHeight + "pt";
    };
    TableConverter.prototype.getColumnWidths = function (tableElement) {
        var _this = this;
        var _a;
        var tableCells = Array.from(((_a = tableElement.querySelector('tr')) === null || _a === void 0 ? void 0 : _a.children) || []);
        return tableCells.map(function (tableCell) {
            var computedStyle = window.getComputedStyle(tableCell);
            return _this.editablePptxsUtils.getWidth(computedStyle);
        });
    };
    /**
     * Table coordinates are based only on x, y. Officegen uses rowHeight and
     * columnWidths to determine table width and height.
     */
    TableConverter.prototype.getTableCoordinates = function (coordinates) {
        return {
            x: coordinates.x,
            y: coordinates.y
        };
    };
    TableConverter.prototype.convertTableHeader = function (tableElement) {
        var _this = this;
        var tableHeaderCellElements = Array.from(tableElement.querySelectorAll('th') || []);
        return tableHeaderCellElements.map(function (tableHeaderCellElement) {
            return _this.createTableCell(tableHeaderCellElement);
        });
    };
    TableConverter.prototype.convertTableBody = function (tableElement) {
        var _this = this;
        var convertedTableBody = [];
        var tableBodyRows = Array.from(tableElement.querySelectorAll('tbody tr') || []);
        tableBodyRows.forEach(function (tableBodyRow) {
            var tableBodyCells = Array.from(tableBodyRow.querySelectorAll('td') || []);
            if (tableBodyCells && tableBodyCells.length) {
                var convertedTableRow = tableBodyCells.map(function (tableBodyCell) {
                    return _this.createTableCell(tableBodyCell);
                });
                convertedTableBody.push(convertedTableRow);
            }
        });
        return convertedTableBody;
    };
    TableConverter.prototype.createTableCell = function (tableCell) {
        var computedStyle = window.getComputedStyle(tableCell);
        return {
            val: tableCell.innerText || '',
            opts: {
                align: this.editablePptxsUtils.getTableAlign(computedStyle),
                bold: this.editablePptxsUtils.isBold(tableCell, computedStyle),
                // @ts-ignore
                font_face: this.editablePptxsUtils.convertFontFace(computedStyle),
                font_size: this.editablePptxsUtils.convertFontSize(computedStyle),
                font_color: this.editablePptxsUtils.convertFontColor(computedStyle),
                fill_color: this.editablePptxsUtils.convertBackgroundColor(computedStyle)
            }
        };
    };
    TableConverter.$inject = ['prEditablePptxsUtils'];
    return TableConverter;
}());
(function (angularModule) {
    angularModule.service('prPptxTableConverter', TableConverter);
})(angular.module('qualtrics.pr'));
