"use strict";
(function (module) {
    'use strict';
    var NestedDisplayLogicController = function (displayLogicConstants) {
        this.displayLogicConstants = displayLogicConstants;
        this.typeOptions = this.displayLogicConstants.getDisplayLogicTypeOptions();
        this.basicDisplayLogicObj = this.displayLogicConstants.getBasicDisplayLogicObj();
    };
    NestedDisplayLogicController.$inject = [
        'prDisplayLogicConstants',
    ];
    NestedDisplayLogicController.prototype.insertCondition = function (index) {
        this.displayLogic.children.splice(index + 1, 0, _.cloneDeep(this.basicDisplayLogicObj));
    };
    NestedDisplayLogicController.prototype.insertConditionSet = function () {
        this.displayLogic.children.push({
            type: this.displayLogic.type === 'and' ? 'or' : 'and',
            children: [_.cloneDeep(this.basicDisplayLogicObj)]
        });
    };
    NestedDisplayLogicController.prototype.removeCondition = function (index) {
        this.displayLogic.children.splice(index, 1);
        // if the condition set is empty, remove it
        if (!this.displayLogic.children.length && this.parentDisplayLogic) {
            this.parentDisplayLogic.children.splice(this.listIndex, 1);
        }
    };
    NestedDisplayLogicController.prototype.removeConditionSet = function (index) {
        var parentChildren = this.parentDisplayLogic.children;
        // Move the children outside of the removed condition set
        parentChildren.splice.apply(parentChildren, [index, 1].concat(this.displayLogic.children));
    };
    module.controller('prNestedDisplayLogicController', NestedDisplayLogicController);
})(angular.module('qualtrics.pr'));
