"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var override_service_1 = require("@services/overrides/override-service");
var feature_flipper_1 = require("@shared/constants/feature-flipper");
var environment_1 = require("@shared/types/environment");
var angular = __importStar(require("angular"));
var DEFAULT_REPORT_WIDGET_LIMIT = 1500;
var WidgetLimitService = /** @class */ (function () {
    function WidgetLimitService(triggerOverrides, env) {
        this.triggerOverrides = triggerOverrides;
        this.env = env;
    }
    WidgetLimitService.prototype.getWidgetLimit = function () {
        var configFlags = this.triggerOverrides({
            event: override_service_1.OVERRIDE_EVENTS.CONFIG_FLAGS,
            sync: true,
            data: {}
        });
        var widgetCountLimit = configFlags.widgetCountLimit;
        if (widgetCountLimit) {
            return widgetCountLimit;
        }
        if (this.isCoreXMDefaultEnabled()) {
            return DEFAULT_REPORT_WIDGET_LIMIT;
        }
        // Returns undefined and default to no limit.
    };
    // TODO: Remove this feature-flipper if the feature can be rolled out without
    // affecting customers.
    WidgetLimitService.prototype.isCoreXMDefaultEnabled = function () {
        var _a;
        return ((_a = this.env.get(environment_1.ENV_VARS.FEATURES)) === null || _a === void 0 ? void 0 : _a[feature_flipper_1.FEATURES.WIDGET_LIMIT_REPORTS_DEFAULT]) || false;
    };
    WidgetLimitService.$inject = [
        'prOverrideTrigger',
        'rsEnvironment'
    ];
    return WidgetLimitService;
}());
(function (angularModule) {
    angularModule.service('prWidgetLimitService', WidgetLimitService);
})(angular.module('qualtrics.pr'));
