"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@services/category-utils");
(function (module) {
    'use strict';
    module.factory('prFieldMenu', [
        '$filter',
        'prFieldsets',
        'prFields',
        'prPipedTextMetricsService',
        'prSourceDataService',
        'pr.categoryUtils',
        function ($filter, fieldsets, prFields, PipedTextMetrics, prSourceDataService, categoryUtils) {
            var translate = $filter('translate');
            var PGR_REGEX = /PGR/;
            var MATRIX_NUMERIC_ENTRY_REGEX = /MatrixNumericEntry/;
            var MATRIX_RO_REGEX = /MatrixRO/;
            function FieldMenu(sourceId) {
                var self = this;
                this.sourceId = sourceId;
                this.fsd = fieldsets.getFieldsetBySourceId(sourceId).then(function (fs) {
                    self.fieldSetId = fs.definition.fieldSetId;
                    return fs.definition;
                });
                this.metrics = new PipedTextMetrics();
                return this;
            }
            var proto = FieldMenu.prototype;
            /**
             * Returns a list of fields that match a given type
             * @param type {string} - type of fields interested in (i.e. ScalarVaue)
             * @returns {array} of fields that match type
             */
            proto.getFieldsByType = function (type) {
                return this.get({
                    filter: type,
                    rawFields: true
                });
            };
            proto.getPipedTextEdMenu = function () {
                var self = this;
                return this.fsd.then(function (fieldSetDefinition) {
                    var potentialFields = fieldSetDefinition.fieldSetView;
                    var eMenu = [];
                    _.forEach(potentialFields, function (field) {
                        if (prFields.isEmbeddedDataField(field)) {
                            eMenu.push({
                                label: prFields.getFieldLabel(field),
                                submenu: {
                                    items: [{
                                            label: translate('PIPED_TEXT.NAME'),
                                            value: {
                                                ptAttrs: {
                                                    sourceId: self.sourceId,
                                                    fieldSetId: self.fieldSetId,
                                                    fieldId: field.fieldId,
                                                    type: 'sourceEmbeddedData',
                                                    attrName: 'edName'
                                                }
                                            }
                                        }]
                                }
                            });
                        }
                    });
                    return eMenu;
                });
            };
            proto.getPipedTextQuestionMenu = function (options) {
                var self = this;
                options = options || {};
                return self.fsd.then(function (fieldSetDefinition) {
                    var potentialFields = fieldSetDefinition.fieldSetView;
                    return prSourceDataService.getUserSources()
                        .then(function (sources) {
                        var source = _.find(sources, { 'id': self.sourceId });
                        var sourceName = source && source.name
                            || translate('PIPED_TEXT.UNKNOWN');
                        var qMenu = [];
                        _.forEach(potentialFields, function (field) {
                            if (prFields.isSourceQuestionField(field)) {
                                var fieldMenu_1 = [];
                                var nameItem = {
                                    label: translate('PIPED_TEXT.NAME'),
                                    value: {
                                        ptAttrs: {
                                            sourceId: self.sourceId,
                                            fieldSetId: self.fieldSetId,
                                            fieldId: prFields.getFieldIdForPipedText(field),
                                            type: 'sourceQuestionMeta',
                                            attrName: 'questionName'
                                        }
                                    }
                                };
                                if (!options.shouldFilterNonNumericItems) {
                                    fieldMenu_1.push(nameItem);
                                }
                                if (!_.isArray(field.fields)) {
                                    if (options.validAggTypes && _.contains(options.validAggTypes, field.type)) {
                                        fieldMenu_1 =
                                            fieldMenu_1.concat(self.metrics.getSupportedMetricMenu(field, self.sourceId, sourceName, self.fieldSetId));
                                    }
                                }
                                else {
                                    _.forEach(field.fields, function (_field) {
                                        if (options.validAggTypes && _.contains(options.validAggTypes, _field.type)) {
                                            fieldMenu_1.push({
                                                label: prFields.getFieldLabel(_field),
                                                submenu: {
                                                    items: self.metrics.getSupportedMetricMenu(_field, self.sourceId, sourceName, self.fieldSetId)
                                                }
                                            });
                                        }
                                    });
                                }
                                qMenu.push({
                                    label: prFields.getFieldLabel(field),
                                    submenu: {
                                        items: fieldMenu_1
                                    }
                                });
                            }
                        });
                        return qMenu;
                    });
                });
            };
            /*
             * options:
             * 	- selected (array|string) array of fieldIds that are selected or single fieldId
             * 	- filter (array|string) array of field types to include in menu
             * 	- rawFields (boolean) return raw fieldItems not in menu form
             *	- includeAllChoices (boolean) Add the all choices option
             *  - questionsAsSubmenu (boolean) Add questions to submenu, opposed to top level
             *  - disableDivider (boolean) Disable divider between menus
             *  - filedAsValue (boolean) whole field as value opposed to just fieldId
             *  - includeIcon (boolean) include icon in menu
             *  - replaceCheck (boolean) replaceCheck with icon
             *  - onlyCategories (boolean) add only the categories onto the menu
             *  - removeSurveyMetadata (boolean) does not add the SurveyMetadata options in the menu
             */
            proto.get = function (options) {
                options = options || {};
                if (_.isString(options.selected)) {
                    options.selected = [options.selected];
                }
                var self = this;
                this.rawFields = [];
                return this.fsd.then(function (fieldSetDefinition) {
                    var potentialFields = fieldSetDefinition.fieldSetView;
                    var surveyFields = options.questionsAsSubmenu ? [{
                            label: translate('QUESTIONS'),
                            submenu: {
                                items: []
                            }
                        }] : [];
                    var embeddedFields = [{
                            label: translate('FIELDS_MENU_CONFIG.SURVEY_EMBEDDEDDATA'),
                            submenu: {
                                items: []
                            }
                        }];
                    var surveyMetaFields = [{
                            label: translate('FIELDS_MENU_CONFIG.SURVEY_METADATA'),
                            submenu: {
                                items: []
                            }
                        }];
                    var categoryFields = [{
                            label: translate('FIELDS_MENU_CONFIG.CATEGORIES'),
                            submenu: {
                                items: []
                            }
                        }];
                    var fieldset = { definition: fieldSetDefinition };
                    _.forEach(potentialFields, function (field) {
                        if (!field || categoryUtils.isCategoryField(field.fieldId, fieldset) || prFields.isBlacklisted(field)) {
                            return;
                        }
                        var arrayToPush = surveyFields;
                        if (prFields.isSurveyMetaField(field)) {
                            arrayToPush = surveyMetaFields[0].submenu.items;
                        }
                        else if (prFields.isEmbeddedDataField(field)) {
                            arrayToPush = embeddedFields[0].submenu.items;
                        }
                        else if (options.questionsAsSubmenu) {
                            arrayToPush = surveyFields[0].submenu.items;
                        }
                        var fieldToPush = self._getMenuObject(field, options);
                        if (fieldToPush) {
                            arrayToPush.push(fieldToPush);
                        }
                    });
                    var categories = categoryUtils.getCategories(fieldset);
                    _.forEach(categories, function (categoryInfo) {
                        var categoryFieldToPush = self._getMenuObject({
                            fieldId: categoryInfo.tagId,
                            name: categoryInfo.description || categoryInfo.name,
                            type: "Category" /* Category */
                        }, options);
                        // push all category fields in menu for data source selection
                        // push only same data source category field for breakout selection
                        var shouldPushToMenu = !options.allowedCategoryFieldId || options.allowedCategoryFieldId === categoryInfo.tagId;
                        if (categoryFieldToPush && shouldPushToMenu) {
                            categoryFields[0].submenu.items.push(categoryFieldToPush);
                        }
                    });
                    if (options.rawFields) {
                        return self.rawFields;
                    }
                    var nonSurveyFields = embeddedFields[0].submenu.items.length > 0 ?
                        surveyMetaFields.concat(embeddedFields) : surveyMetaFields;
                    if (categoryFields[0].submenu.items.length > 0) {
                        nonSurveyFields = nonSurveyFields.concat(categoryFields);
                    }
                    if (!options.disableDivider) {
                        nonSurveyFields = [{ divider: true }].concat(nonSurveyFields);
                    }
                    if (options.onlyCategories) {
                        surveyFields = [].concat(categoryFields);
                    }
                    return options.removeSurveyMetadata ? surveyFields : surveyFields.concat(nonSurveyFields);
                });
            };
            proto._isIncluded = function (field, filter) {
                if (_.isString(filter)) {
                    filter = [filter];
                }
                return filter ? _.includes(filter, field.type) : true;
            };
            proto._getMenuObject = function (field, options) {
                var self = this;
                field.sourceId = this.sourceId;
                if (_.isArray(field.fields)) {
                    // For Pick, Group and Rank type of question, there could be
                    // several levels of submenus.
                    // If the parent field doesn't have meta data, field.type will be
                    // used as parentFullType
                    var parentFullType_1 = (field.meta && field.meta.fullType) || field.type;
                    var parentMeta_1 = field.meta;
                    var submenu = _.compact(_.map(field.fields, function (field) {
                        if (parentMeta_1) {
                            field.meta = field.meta || {};
                            field.meta.sourceId = parentMeta_1.sourceId;
                            field.meta.iconClass = _.endsWith(field.fieldId, '_TEXT') ?
                                'icon-textentry' :
                                parentMeta_1.iconClass;
                        }
                        field.parentFullType = parentFullType_1;
                        return self._getMenuObject(field, options);
                    }));
                    if (submenu.length) {
                        submenu = this._getSubMenu(submenu, field, options);
                        return {
                            replaceCheck: options.replaceCheck,
                            icon: options.includeIcon && prFields.getFieldIcon(field),
                            label: prFields.getFieldLabel(field, true),
                            submenu: {
                                items: submenu
                            }
                        };
                    }
                }
                else {
                    if (!this._isIncluded(field, options.filter)) {
                        return null;
                    }
                    var ret = {
                        replaceCheck: options.replaceCheck,
                        label: prFields.getFieldLabel(field, true),
                        value: options.fieldAsValue ? field : field.fieldId,
                        icon: options.includeIcon && prFields.getFieldIcon(field),
                        selected: _.includes(options.selected, field.fieldId)
                    };
                    this.rawFields.push(ret);
                    return ret;
                }
            };
            proto._getSubMenu = function (submenu, field, options) {
                var numChoices = submenu.length;
                if (_.get(field, 'meta.fullType')) {
                    // Push all text entry fields (if any) to the bottom
                    var textEntrySection = _.remove(submenu, function (obj) {
                        var fieldId = obj.value && (obj.value.fieldId || obj.value);
                        return fieldId && _.endsWith(fieldId, '_TEXT');
                    });
                    numChoices = submenu.length;
                    if (textEntrySection.length) {
                        submenu = _.concat({ header: translate('FIELDS_MENU_CONFIG.CHOICES') }, submenu, { header: translate('FIELDS_MENU_CONFIG.TEXT_ENTRY') }, textEntrySection);
                    }
                }
                if (!options.includeAllChoices || (!field.meta && field.type !== 'FieldGroup')) {
                    return submenu;
                }
                var allChoicesMenu = [this._getAllChoicesItem(field, options), { divider: true }];
                var addAllChoicesMenu = numChoices >= 2 && this._shouldAddAllChoices(submenu, field);
                return addAllChoicesMenu ? allChoicesMenu.concat(submenu) : submenu;
            };
            proto._getAllChoicesItem = function (field, options) {
                var type = (field.type === 'FieldGroup' && field.description === 'Groups') ?
                    'ALL_GROUPS' : 'ALL_CHOICES';
                field.fieldId = type + ':' + _.get(field, 'meta.sourceId', '');
                var childFieldId = _.get(field, 'fields.0.fieldId');
                if (childFieldId && _.includes(['MatrixNumericEntry', 'MatrixRO', 'PGR', 'FieldGroup'], field.parentFullType)) {
                    // This is a mid-level group, which means fieldId cannot be based just on sourceId
                    // Because different mid-level groups can have identical sourceIds
                    field.fieldId = type + ':' + childFieldId.split('_')[0];
                    if (field.parentFullType !== 'PGR') {
                        // Ignore 2nd level PGR field groups since they do not have "All Choices" enabled
                        field.fieldId += ':' + prFields.constructParentFieldId(childFieldId);
                    }
                }
                return {
                    replaceCheck: options.replaceCheck,
                    label: translate('FIELDS_MENU_CONFIG.' + type),
                    value: options.fieldAsValue ? field : field.fieldId,
                    icon: options.includeIcon && prFields.getFieldIcon(field),
                    selected: _.includes(options.selected, field.fieldId)
                };
            };
            proto._shouldAddAllChoices = function (submenu, field) {
                //if PGR or numeric Matrix top level
                if (field.meta && field.meta.fullType &&
                    (field.meta.fullType.indexOf('PGR') >= 0 ||
                        field.meta.fullType.indexOf('MatrixNumericEntry') >= 0 ||
                        field.meta.fullType.indexOf('MatrixRO') >= 0)) {
                    return false;
                }
                //if PGR Leaf
                if (PGR_REGEX.test(field.parentFullType) ||
                    MATRIX_NUMERIC_ENTRY_REGEX.test(field.parentFullType) ||
                    MATRIX_RO_REGEX.test(field.parentFullType)) {
                    if (field.type === 'FieldGroup' && field.fields[0].type !== 'FieldGroup') {
                        return true;
                    }
                    return false;
                }
                //Don't add all choices if choices are umcomparable
                if (!submenu.length || submenu.length < 2 || field.type === 'FreeFormText' ||
                    (field.meta && (!field.meta.sourceId || field.meta.uncomparableChoices))) {
                    return false;
                }
                return true;
            };
            return FieldMenu;
        }
    ]);
}(angular.module('qualtrics.pr')));
