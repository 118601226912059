"use strict";
(function (module) {
    'use strict';
    /*jshint camelcase: false */
    module.factory('PR_TEXT_STYLES_NAMES', [
        '$filter',
        function ($filter) {
            var translate = $filter('translate');
            return {
                H1: translate('TEXT_FORMATS.TITLE'),
                H2: translate('TEXT_FORMATS.SUBTITLE'),
                H3: translate('TEXT_FORMATS.HEADER_COUNT', { count: 1 }),
                H4: translate('TEXT_FORMATS.HEADER_COUNT', { count: 2 }),
                H5: translate('TEXT_FORMATS.HEADER_COUNT', { count: 3 }),
                body: translate('TEXT_FORMATS.BODY'),
                footnote: translate('TEXT_FORMATS.FOOTNOTE')
            };
        }
    ]);
    module.factory('prTextFormatsHelper', [
        '$filter',
        'prTextStylesModal',
        'PR_TEXT_STYLES_NAMES',
        'FONTS_DATA',
        function ($filter, textStylesModal, TEXT_SYLTES_NAMES, FONTS_DATA) {
            var translate = $filter('translate');
            var FORMATS = {
                STYLE: {
                    name: 'header',
                    type: 'menu',
                    actionFn: 'setStyle',
                    items: [
                        {
                            name: TEXT_SYLTES_NAMES.H1,
                            value: 'H1'
                        },
                        {
                            name: TEXT_SYLTES_NAMES.H2,
                            value: 'H2'
                        },
                        {
                            name: TEXT_SYLTES_NAMES.H3,
                            value: 'H3'
                        },
                        {
                            name: TEXT_SYLTES_NAMES.H4,
                            value: 'H4'
                        },
                        {
                            name: TEXT_SYLTES_NAMES.H5,
                            value: 'H5'
                        },
                        {
                            name: TEXT_SYLTES_NAMES.body,
                            value: 'body'
                        },
                        {
                            name: TEXT_SYLTES_NAMES.footnote,
                            value: 'footnote'
                        },
                        {
                            divider: true,
                        },
                        {
                            name: translate('CONFIGURE_STYLES') + '...',
                            notSelectable: true,
                            action: function () {
                                textStylesModal.open();
                            }
                        }
                    ],
                    tooltipDisabled: true
                },
                FONT: {
                    name: 'font',
                    type: 'menu',
                    actionFn: 'setFont',
                    inputEnabled: false,
                    options: {
                        searchable: true,
                        maxHeight: '500'
                    },
                    items: [],
                    tooltipDisabled: false
                },
                SIZE: {
                    name: 'size',
                    type: 'menu',
                    actionFn: 'setSize',
                    inputEnabled: false,
                    items: [
                        {
                            name: 8,
                            value: 8
                        },
                        {
                            name: 9,
                            value: 9
                        },
                        {
                            name: 10,
                            value: 10
                        },
                        {
                            name: 11,
                            value: 11
                        },
                        {
                            name: 12,
                            value: 12
                        },
                        {
                            name: 13,
                            value: 13
                        },
                        {
                            name: 14,
                            value: 14
                        },
                        {
                            name: 15,
                            value: 15
                        },
                        {
                            name: 16,
                            value: 16
                        },
                        {
                            name: 17,
                            value: 17
                        },
                        {
                            name: 18,
                            value: 18
                        },
                        {
                            name: 19,
                            value: 19
                        },
                        {
                            name: 20,
                            value: 20
                        },
                        {
                            name: 21,
                            value: 21
                        },
                        {
                            name: 22,
                            value: 22
                        },
                        {
                            name: 23,
                            value: 23
                        },
                        {
                            name: 24,
                            value: 24
                        },
                        {
                            name: 25,
                            value: 25
                        },
                        {
                            name: 26,
                            value: 26
                        },
                        {
                            name: 27,
                            value: 27
                        },
                        {
                            name: 28,
                            value: 28
                        },
                        {
                            name: 29,
                            value: 29
                        },
                        {
                            name: 30,
                            value: 30
                        },
                        {
                            name: 31,
                            value: 31
                        },
                        {
                            name: 32,
                            value: 32
                        },
                        {
                            name: 36,
                            value: 36
                        },
                        {
                            name: 48,
                            value: 48
                        },
                        {
                            name: 60,
                            value: 60
                        },
                        {
                            name: 72,
                            value: 72
                        },
                        {
                            name: 96,
                            value: 96
                        }
                    ],
                    tooltipDisabled: true
                },
                BOLD: {
                    name: 'bold',
                    tooltip: translate('FORMAT_RULES.BOLD'),
                    type: 'button',
                    icon: 'icon-bold',
                    value: 'bold',
                    actionFn: 'toggleBold'
                },
                ITALIC: {
                    name: 'italic',
                    tooltip: translate('FORMAT_RULES.ITALIC'),
                    type: 'button',
                    icon: 'icon-italic',
                    value: 'italic',
                    actionFn: 'toggleItalic'
                },
                UNDERLINE: {
                    name: 'underline',
                    tooltip: translate('FORMAT_RULES.UNDERLINE'),
                    type: 'button',
                    icon: 'icon-underline',
                    value: 'underline',
                    actionFn: 'toggleUnderline'
                },
                //STRIKE: {
                //name: 'strike',
                //type: 'button',
                //icon: 'icon-strikethrough',
                //tooltip: 'Strikethrough',
                //value: 'strike',
                //actionFn: 'toggleStrikethrough'
                //},
                COLOR: {
                    name: 'color',
                    type: 'color',
                    icon: 'icon-textcolor',
                    actionFn: 'setForegroundColor',
                },
                NUMBER_LIST: {
                    name: 'list',
                    type: 'button',
                    icon: 'icon-insertnumberedlist',
                    tooltip: translate('NUMBERED_LIST'),
                    value: 'ordered',
                    actionFn: 'toggleList',
                },
                BULLET_LIST: {
                    name: 'list',
                    type: 'button',
                    icon: 'icon-insertbulletedlist',
                    tooltip: translate('BULLET_LIST'),
                    value: 'bullet',
                    actionFn: 'toggleList',
                },
                ALIGN_LEFT: {
                    name: 'align',
                    type: 'button',
                    icon: 'icon icon-alignleft',
                    tooltip: translate('ALIGN_LEFT'),
                    value: 'left',
                    actionFn: 'setAlign'
                },
                ALIGN_CENTER: {
                    name: 'align',
                    type: 'button',
                    icon: 'icon icon-center',
                    tooltip: translate('ALIGN_CENTER'),
                    value: 'center',
                    actionFn: 'setAlign'
                },
                ALIGN_RIGHT: {
                    name: 'align',
                    type: 'button',
                    icon: 'icon icon-alignright',
                    tooltip: translate('ALIGN_RIGHT'),
                    value: 'right',
                    actionFn: 'setAlign'
                },
                CLEAN: {
                    name: 'clean',
                    type: 'button',
                    icon: 'icon icon-cleartextformatting',
                    tooltip: translate('REMOVE_FORMATTING'),
                    actionFn: 'cleanFormat'
                }
            };
            var FONTS = {};
            _.forEach(FONTS_DATA, function (val, key) {
                FONTS[key] = val.fonts;
                FORMATS.FONT.items.push({
                    name: val.label,
                    value: key
                });
            });
            function getAllFormatOptions() {
                return _.cloneDeep(FORMATS);
            }
            function getSubsetFormatOptions(keys) {
                var formats = {};
                _.forEach(keys, function (key) {
                    formats[key] = _.cloneDeep(FORMATS[key]);
                });
                return formats;
            }
            function getStyles(format, asCSS, important) {
                if (important === void 0) { important = ''; }
                var obj = {};
                if (_.has(format, 'FONT')) {
                    obj['font-family'] = FONTS[format.FONT] + important;
                }
                if (_.has(format, 'SIZE')) {
                    obj['font-size'] = format.SIZE + 'pt';
                }
                if (_.has(format, 'COLOR')) {
                    obj.color = format.COLOR;
                }
                if (_.has(format, 'FILL') && _.has(format, 'COLOR')) {
                    obj['fill'] = format.COLOR;
                }
                if (_.has(format, 'BOLD')) {
                    obj['font-weight'] = format.BOLD ? 'bold' : 'normal';
                }
                if (_.has(format, 'UNDERLINE')) {
                    obj['text-decoration'] = format.UNDERLINE ? 'underline' : 'normal';
                }
                if (_.has(format, 'ITALIC')) {
                    obj['font-style'] = format.ITALIC ? 'italic' : 'normal';
                }
                if (_.has(format, 'ALIGNMENT')) {
                    obj['text-align'] = format.ALIGNMENT;
                }
                if (asCSS) {
                    return _.reduce(obj, function (res, val, key) {
                        res += key + ':' + val + ';';
                        return res;
                    }, '');
                }
                return obj;
            }
            return {
                getAllFormatOptions: getAllFormatOptions,
                getSubsetFormatOptions: getSubsetFormatOptions,
                getStyles: getStyles
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
