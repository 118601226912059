"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@services/exports/page-context-manager");
var environment_1 = require("@shared/types/environment");
(function (module) {
    'use strict';
    module.factory('prPPTExportRegionsService', [
        'prFlowingPages',
        'rsEnvironment',
        'pr.pageContextManager',
        'prPageRelativeCoordinates',
        'prPptxEditableWidgetIsEnabled',
        'rsWidgets',
        'prActiveReport',
        function (prFlowingPages, env, pageContextManager, pageRelativeCoordinatesService, editableWidgetsIsEnabledService, rsWidgets, activeReport) {
            function getRegions() {
                var regions = [];
                var pages = document.getElementsByTagName('pr-page');
                var startPageNum = pageContextManager.getStartPageNumber();
                var containerId = env.get(environment_1.ENV_VARS.CONTAINER_ID);
                _.each(pages, function (page, pageIndex) {
                    var headerAndFooter = Array.prototype.slice.call(page.getElementsByTagName('pr-page-header-footer'), 0);
                    var widgets = Array.prototype.slice.call(page.getElementsByTagName('pr-widget'), 0);
                    var elements = headerAndFooter.concat(widgets);
                    _.each(elements, function (element) {
                        var pageRect = page.getBoundingClientRect();
                        var elementRect = element.getBoundingClientRect();
                        var elementHeight = Math.round(elementRect.height);
                        var elementWidth = Math.round(elementRect.width);
                        // only add region who have valid height and width
                        if (elementWidth > 0 && elementHeight > 0) {
                            var widgetId = element.getAttribute('selectionid');
                            var widget = void 0;
                            if (widgetId) {
                                // strip off chunk id off widgetId
                                // example: a text widgetId would be Widget_4f20b534-1efa-4a8d-ad66-06ae219be358.0
                                widgetId = _.split(widgetId, '.')[0];
                                widget = prFlowingPages.getWidget(widgetId);
                                var pageId = activeReport.getPageIdOfWidget(widget);
                                var widgetScope = rsWidgets.getWidgetScope(widgetId, pageId, containerId);
                                if (editableWidgetsIsEnabledService.isEnabled(widgetScope)) {
                                    // This widget is being exported as editable so don't export it here.
                                    return;
                                }
                            }
                            var pageRelativeCoordinates = pageRelativeCoordinatesService.getCoordinates(page, element);
                            regions.push({
                                pageRelativeCoordinates: {
                                    x: pageRelativeCoordinates.x,
                                    y: pageRelativeCoordinates.y,
                                    width: pageRelativeCoordinates.cx,
                                    height: pageRelativeCoordinates.cy
                                },
                                coordinates: {
                                    x: elementRect.left,
                                    y: elementRect.top,
                                    width: elementWidth,
                                    height: elementHeight
                                },
                                meta: {
                                    page: startPageNum + pageIndex,
                                    _id: widgetId || element.className,
                                    _type: widget && widget._type || element.className,
                                    pageX: Math.round(elementRect.left - pageRect.left),
                                    pageY: Math.round(elementRect.top - pageRect.top),
                                    width: elementWidth,
                                    height: elementHeight
                                } // info relative to where widget is relative to page
                            });
                        }
                    });
                });
                return regions;
            }
            return {
                getRegions: getRegions
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
