"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("angular");
require("./report-conflict-modal-controller");
// @ts-ignore
function ReportConflictModalFactory($filter, showModal) {
    var translate = $filter('translate');
    // @ts-ignore
    var modal;
    return {
        show: function () {
            // @ts-ignore
            if (modal) {
                // don't show the modal multiple times
                return;
            }
            modal = showModal({
                show: true,
                controller: 'pr.ReportConflictModalController',
                title: translate('PR.REPORT_CONFLICT.TITLE'),
                content: translate('PR.REPORT_CONFLICT.CONTENT'),
                buttons: [
                    '<button class="btn btn-hover" ng-click="$ctrl.reload()" translate>RELOAD</button>'
                ]
            });
        },
        close: function () {
            // @ts-ignore
            if (modal) {
                // @ts-ignore
                modal.close();
            }
        }
    };
}
(function (module) {
    module.factory('pr.reportConflictModal', [
        '$filter',
        'qstrap.modal',
        ReportConflictModalFactory
    ]);
})(angular_1.module('qualtrics.pr'));
