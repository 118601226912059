"use strict";
(function (module) {
    'use strict';
    module.factory('prPipedTextSourceMenuService', [
        'prSourceDataService',
        'prPipedTextDateService',
        '$filter',
        'pr.folderService',
        'pr.fieldsets',
        function (prSourceDataService, PipedTextDate, $filter, prFolderService, prFieldsets) {
            function PipedTextSourceMenu(shouldFilterNonNumericItems) {
                if (shouldFilterNonNumericItems === void 0) { shouldFilterNonNumericItems = false; }
                this.date = new PipedTextDate();
                this.fieldsets = prFieldsets;
                this.shouldFilterNonNumericItems = shouldFilterNonNumericItems;
                return this;
            }
            PipedTextSourceMenu.translate = $filter('translate');
            PipedTextSourceMenu.VALID_AGGREGATE_TYPES =
                ['ScalarValue', 'EnumerableScalarValue', 'EnumerableValue', 'EnumerableValueSet'];
            var proto = PipedTextSourceMenu.prototype;
            proto.getMenuForSource = function (sourceId) {
                var self = this;
                var menuItems = [{
                        label: PipedTextSourceMenu.translate('PIPED_TEXT.NAME'),
                        isNumeric: false,
                        value: {
                            ptAttrs: {
                                type: 'sourceMeta',
                                attrName: 'sourceName',
                                sourceId: sourceId
                            }
                        }
                    }, {
                        label: PipedTextSourceMenu.translate('PIPED_TEXT.SOURCE_STATUS'),
                        isNumeric: false,
                        value: {
                            ptAttrs: {
                                type: 'sourceMeta',
                                attrName: 'sourceStatus',
                                sourceId: sourceId
                            }
                        }
                    }, {
                        label: PipedTextSourceMenu.translate('PIPED_TEXT.SOURCE_TOTAL_RESPONSES'),
                        isNumeric: true,
                        value: {
                            ptAttrs: {
                                type: 'sourceMeta',
                                attrName: 'sourceTotalResponses',
                                sourceId: sourceId
                            }
                        }
                    }, {
                        label: PipedTextSourceMenu.translate('PIPED_TEXT.LAST_RESPONSE_DATE'),
                        isNumeric: false,
                        submenu: {
                            items: self.date.getDateMenu('lastResponseDate', sourceId)
                        }
                    }, {
                        label: PipedTextSourceMenu.translate('PIPED_TEXT.SOURCE_QUESTIONS'),
                        isNumeric: true,
                        submenu: {
                            items: function () {
                                // Pass in the shouldFilterNonNumericItems to filter out name from the question menu.
                                return self.fieldsets.getPipedTextQuestionMenuBySourceId(sourceId, {
                                    validAggTypes: PipedTextSourceMenu.VALID_AGGREGATE_TYPES,
                                    shouldFilterNonNumericItems: self.shouldFilterNonNumericItems
                                });
                            }
                        }
                    }, {
                        label: PipedTextSourceMenu.translate('PIPED_TEXT.SOURCE_EMBEDEDDDATA'),
                        isNumeric: false,
                        submenu: {
                            items: function () {
                                return self.fieldsets.getPipedTextEdMenuBySourceId(sourceId);
                            }
                        }
                    }];
                return this.shouldFilterNonNumericItems ? _.filter(menuItems, 'isNumeric') : menuItems;
            };
            proto.getSourceMenu = function () {
                var self = this;
                return function () {
                    return prSourceDataService.getUserSources()
                        .then(function (allSources) {
                        allSources = _.map(allSources, 'id');
                        return prFolderService.getFolderMenu(null, allSources, function (id, item) {
                            var topLevelSubmenu = self.getMenuForSource(id);
                            return {
                                label: item.name,
                                submenu: {
                                    items: topLevelSubmenu
                                }
                            };
                        });
                    });
                };
            };
            proto.getSurveyDataMenu = function () {
                var self = this;
                return function () {
                    var sourceId = prFieldsets.getMainSourceId();
                    return self.getMenuForSource(sourceId);
                };
            };
            return PipedTextSourceMenu;
        }
    ]);
}(angular.module('qualtrics.pr')));
