"use strict";
(function (module) {
    module.directive('prSenderPolicyFrameworkRecordValidator', [
        '$q',
        'pr.distributionService',
        function ($q, distributionService) {
            return {
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    ctrl.$asyncValidators.spfRecord = function (modelValue, viewValue) {
                        if (ctrl.$isEmpty(modelValue)) {
                            // Consider empty model valid because the required directive
                            // handles this case.
                            return $q.resolve();
                        }
                        var def = $q.defer();
                        var email = viewValue;
                        distributionService.hasSpfRecord(email).then(function (hasQualtricsSpf) {
                            if (hasQualtricsSpf) {
                                def.resolve();
                            }
                            else {
                                def.reject();
                            }
                        });
                        return def.promise;
                    };
                }
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
