"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUniqueString = void 0;
var angular_1 = require("angular");
function getUniqueString() {
    var currTime = Date.now().toString();
    var randomNumber = Math.random();
    return currTime + "-" + randomNumber;
}
exports.getUniqueString = getUniqueString;
(function (angularModule) {
    angularModule.factory('pr.uniqueString', function () { return getUniqueString; });
})(angular_1.module('qualtrics.pr'));
