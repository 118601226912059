"use strict";
/*
 * Register / deregister scroll listeners for when the app scrolls
 */
(function (module) {
    'use strict';
    var BODY = 'body', SCROLL_CONTAINER = 'body,html', TOOLBAR_HEIGHT = 75, // 1px less to avoid a jumping toolbar when on the first page
    PAGE_MARGIN = 32;
    module.factory('prScrollService', [
        '$window',
        'prViewOptions',
        function ($window, viewOptions) {
            var scrollCallbacks = [], prev = 0;
            function executeCallbacks($event) {
                var winTop = document.documentElement.scrollTop || document.body.scrollTop, scrollHeight = $(BODY).height(), goingDown = (winTop > prev);
                _.each(scrollCallbacks, function (obj) {
                    // we have a specific position we are trying to get to, so use the directional callback
                    if (obj.pos) {
                        positionCallback($event, obj, goingDown, winTop, scrollHeight);
                    }
                    else {
                        obj.callback($event, winTop, scrollHeight);
                    }
                });
                prev = winTop;
            }
            $window.addEventListener('scroll', executeCallbacks, { passive: true });
            /*
             * Calls the callback only once when the scroll passes the position indicated
             */
            function positionCallback($event, obj, goingDown, winTop, scrollHeight) {
                if (goingDown && !obj.downCalled && obj.pos <= winTop ||
                    !goingDown && !obj.upCalled && obj.pos >= winTop) {
                    obj.callback($event, goingDown, winTop, scrollHeight);
                    // update for the next call
                    obj.downCalled = goingDown;
                    obj.upCalled = !goingDown;
                }
            }
            function registerScrollListener(callback, optPos) {
                deregisterScrollListener(callback);
                scrollCallbacks.push({
                    pos: optPos || 0,
                    callback: callback
                });
            }
            function deregisterScrollListener(callback) {
                _.remove(scrollCallbacks, function (obj) {
                    return obj.callback === callback;
                });
            }
            function scrollTo(selector) {
                var margin = PAGE_MARGIN * viewOptions.getZoomFactor();
                $(SCROLL_CONTAINER).stop().animate({
                    scrollTop: $(selector).offset().top - TOOLBAR_HEIGHT - margin
                }, 100);
            }
            return {
                onScroll: registerScrollListener,
                offScroll: deregisterScrollListener,
                scrollTo: scrollTo
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
