"use strict";
(function (module) {
    'use strict';
    module.factory('pr.emailService', [
        function () {
            function extractEmailsFromString(emailString) {
                return _.filter(emailString.split(/[\s,]+/), function (email) {
                    // Remove any empty strings that the RegEx incorrectly split.
                    return email !== '';
                });
            }
            function isValidEmail(email) {
                var EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return EMAIL_REGEX.test(email);
            }
            return {
                extractEmailsFromString: extractEmailsFromString,
                isValidEmail: isValidEmail
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
