"use strict";
(function (module) {
    'use strict';
    /*
     * Chunks a text widget
     */
    module.factory('prTextChunker', [
        'prWidgetChunker',
        'pr.PR_WIDGETS',
        'pr.WIDGET_READY_STATUSES',
        'pr.widgetManager',
        '$timeout',
        function (WidgetChunker, PR_WIDGETS, WIDGET_READY_STATUSES, widgetManager, $timeout) {
            var ROW_ROUND_THRESHOLD = 0.3;
            function TextChunker(widget) {
                var _this = this;
                WidgetChunker.call(this, widget);
                widgetManager.onWidgetStatusUpdate(this.widget._id, 'COMPLETED', function () {
                    // This is needed to ensure we chunk text widgets that set themselves
                    // to COMPLETED after they have finished all DOM updates on the hidden div
                    // See https://qualtrics.atlassian.net/browse/REP-6622.
                    $timeout(function () {
                        // Add timeout just in case the hidden div is updated shortly after
                        // the widget enters the COMPLETED status. This is just an
                        // optimization to avoid chunking unnecessarily.
                        if (_this.widget && !_this.widget.finishedChunking) {
                            _this._updateAndReflow();
                        }
                    }, 1000);
                });
            }
            TextChunker.prototype = _.create(WidgetChunker.prototype, {
                /*
                 * @override
                 */
                _hasContent: function (status) {
                    return WIDGET_READY_STATUSES.includes(status);
                },
                /*
                 * @override
                 */
                _chunk: function () {
                    var self = this;
                    var type = self.widget.type;
                    var textContainer = self._getTextContainer();
                    var hasTextContent = (textContainer || {}).textContent;
                    // Non-pr.text widgets need to be chunked with the regular chunking logic
                    if (type === PR_WIDGETS.TEXT && !hasTextContent) {
                        self._chunkDefault();
                        return;
                    }
                    var chunk = self._initChunk(0);
                    var widgetBody = self._getWidgetBody();
                    // All chunking happens here
                    var chunkIndex = self._chunkHelper(0, widgetBody, [chunk]);
                    self._cleanExtraChunks(chunkIndex + 1);
                },
                _chunkDefault: function () {
                    // uses the default chunks and clones in the dom.
                    // For text widgets, do not use _getWidgetBody()
                    // because doing so ends up with duplicate div Ids
                    // which can cause issues elsewhere
                    var self = this;
                    self._appendDefaultChunks(self._create('div'));
                },
                _chunkHelper: function (chunkIndex, node, path) {
                    // it is a text node
                    if (node.nodeType === Node.TEXT_NODE) {
                        chunkIndex = this._addWordsToChunk(chunkIndex, node, path);
                    }
                    else {
                        chunkIndex = this._addNodeToChunk(chunkIndex, node, path);
                    }
                    return chunkIndex;
                },
                _addWordsToChunk: function (chunkIndex, node, path) {
                    var self = this, 
                    // we replace the space with a delimiter so we can include the spaces when we are rebuilding
                    curChunk = self._getChunk(chunkIndex), maxHeight = self._getChunkMaxHeight(chunkIndex), curTextNode = self._createTextNode();
                    curTextNode.nodeValue = node.nodeValue;
                    path[path.length - 1].appendChild(curTextNode);
                    // it all fits, so move on
                    if (curChunk.clientHeight <= maxHeight) {
                        return chunkIndex;
                    }
                    // clear it out, we now have to add it word-by-word to the chunk
                    curTextNode.nodeValue = '';
                    var DELIMITER = '~q~', words = node.nodeValue.replace(/\s/g, '$&' + DELIMITER).split(DELIMITER), previousTryFailed = false;
                    for (var i = 0; i < words.length; i++) {
                        var prevValue = curTextNode.nodeValue;
                        curTextNode.nodeValue += words[i];
                        // the word doesn't fit on this chunk, so we move to the next chunk
                        if (curChunk.clientHeight > maxHeight) {
                            // If we failed on the previous try, there is nothing we can do...give up
                            if (previousTryFailed) {
                                return;
                            }
                            else {
                                previousTryFailed = true;
                            }
                            curTextNode.nodeValue = prevValue;
                            chunkIndex++;
                            i--;
                            maxHeight = self._getChunkMaxHeight(chunkIndex);
                            curChunk = self._initChunk(chunkIndex);
                            path = self._updateChunkWithPath(curChunk, path);
                            curTextNode = self._createTextNode();
                            path[path.length - 1].appendChild(curTextNode);
                        }
                        else {
                            previousTryFailed = false;
                        }
                    }
                    return chunkIndex;
                },
                _addNodeToChunk: function (chunkIndex, node, path) {
                    var self = this, cloned = node.cloneNode();
                    path[path.length - 1].appendChild(cloned);
                    if (cloned.nodeType !== Node.COMMENT_NODE) {
                        cloned.removeAttribute('id');
                        cloned.removeAttribute('contenteditable');
                    }
                    if (node.hasChildNodes && node.hasChildNodes()) {
                        path.push(cloned);
                        var children = node.childNodes;
                        for (var i = 0; i < children.length; i++) {
                            // recursion
                            chunkIndex = self._chunkHelper(chunkIndex, children[i], path);
                        }
                        path.pop();
                    }
                    else {
                        var curChunk = self._getChunk(chunkIndex), maxHeight = self._getChunkMaxHeight(chunkIndex);
                        if (curChunk.clientHeight > maxHeight) {
                            chunkIndex++;
                            self._remove(cloned);
                            curChunk = self._initChunk(chunkIndex);
                            self._updateChunkWithPath(curChunk, path);
                            path[path.length - 1].appendChild(cloned);
                        }
                    }
                    return chunkIndex;
                },
                /*
                 * This is key to the text chunking. See text nodes can live within multiple different nested dom-nodes,
                 * so if we split text across multiple chunks, we need to EXACTLY clone the dom tree, which is `path`
                 */
                _updateChunkWithPath: function (curChunk, path) {
                    // update the parent stack at the same time as we are appending to the domChunk
                    var prev = curChunk, clone;
                    path[0] = prev;
                    for (var j = 1; j < path.length; j++) {
                        clone = path[j].cloneNode();
                        path[j] = clone;
                        prev.appendChild(clone);
                        prev = clone;
                    }
                    return path;
                },
                _getTextContainer: function () {
                    return this.$element[0].querySelector('[contenteditable]');
                },
                /*
                 * If the number of Rows is close to the low end round down instead of up
                 * For text it is safe to round down becuase of line height
                 */
                _roundRows: function (row) {
                    return row % 1 < ROW_ROUND_THRESHOLD ? Math.floor(row) : Math.ceil(row);
                }
            });
            return TextChunker;
        }
    ]);
}(angular.module('qualtrics.pr')));
