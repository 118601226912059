"use strict";
(function (module) {
    'use strict';
    module.factory('prWidgetMenuService', [
        '$filter',
        'prCustomWidgetService',
        'prCustomWidgetMenuService',
        function ($filter, customWidgetService, customWidgetMenuService) {
            var translate = $filter('translate');
            var WIDGET_ITEMS = {
                BASIC: [
                    {
                        label: translate('TEXT'),
                        icon: 'icon-text',
                        submenu: {
                            items: [
                                {
                                    label: translate('WIDGET_NAME.TEXT_AREA'),
                                    replaceCheck: true,
                                    value: 'pr.text',
                                    icon: 'icon-text-box'
                                }, {
                                    label: translate('WIDGET_NAME.FILTER_SUMMARY'),
                                    replaceCheck: true,
                                    value: 'pr.filtersummary',
                                    icon: 'icon-filter'
                                }
                            ]
                        }
                    }, {
                        label: translate('WIDGET_NAME.IMAGE'),
                        value: 'pr.image',
                        icon: 'icon-image-2'
                    }, {
                        label: translate('WIDGET_NAME.BLANK_SPACE'),
                        value: 'pr.spacer',
                        icon: 'icon-square-wire'
                    }, {
                        label: translate('WIDGET_NAME.DIVIDER'),
                        value: 'pr.divider',
                        icon: 'icon-line-wire'
                    },
                ],
                CHART: [
                    {
                        label: translate('WIDGET_NAME.BAR'),
                        value: 'eng.bar',
                        icon: 'icon-bar-chart'
                    }, {
                        label: translate('WIDGET_NAME.LINE'),
                        value: 'eng.line',
                        icon: 'icon-line-chart'
                    }, {
                        label: translate('WIDGET_NAME.PIE'),
                        value: 'eng.pie',
                        icon: 'icon-donut-chart'
                    }, {
                        label: translate('WIDGET_NAME.BREAKDOWNBAR'),
                        value: 'eng.breakdownbar',
                        icon: 'icon-breakdown-bar'
                    }, {
                        label: translate('WIDGET_NAME.GAUGE'),
                        value: 'eng.gauge',
                        icon: 'icon-gauge-chart'
                    }
                ],
                TABLE: [
                    {
                        label: translate('WIDGET_NAME.DATA_TABLE'),
                        value: 'eng.table',
                        icon: 'icon-simple-table'
                    }, {
                        label: translate('WIDGET_NAME.STATS_TABLE'),
                        value: 'eng.stats',
                        icon: 'icon-stats-table'
                    }, {
                        label: translate('WIDGET_NAME.GRID'),
                        value: 'eng.grid',
                        icon: 'icon-list-table'
                    }
                ],
                OTHER: [
                    {
                        label: translate('WIDGET_NAME.HEATMAP'),
                        value: 'eng.heatmap',
                        icon: 'icon-heat-map'
                    }, {
                        label: translate('WIDGET_NAME.WORDCLOUD'),
                        value: 'eng.wordcloud',
                        icon: 'icon-word-cloud'
                    }
                ]
            };
            addCustomItems(WIDGET_ITEMS);
            addReplaceCheck(WIDGET_ITEMS);
            function getItems(categoryItems) {
                return _.cloneDeep(categoryItems);
            }
            function bind(categoryItems) {
                return _.bind(getItems, null, categoryItems);
            }
            function addReplaceCheck(widgetItems) {
                _.forEach(widgetItems, function (categoryItems) {
                    _.forEach(categoryItems, function (item) {
                        item.replaceCheck = true;
                    });
                });
            }
            function addCustomItems(widgetItems) {
                widgetItems = widgetItems || WIDGET_ITEMS;
                var customWidgets = customWidgetService.getCustomWidgets();
                _.forEach(customWidgets, function (widgetInfo, widgetName) {
                    try {
                        var menuData = customWidgetMenuService.getMenuData(widgetName);
                        var menuItemArray = _.get(widgetItems, menuData.folder) || widgetItems.OTHER;
                        if (!widgetInfo.hideFromMenu) {
                            menuItemArray.push(menuData.menuItem);
                        }
                    }
                    catch (error) {
                        console.error('Failed to get custom widget menu data', error);
                    }
                });
            }
            return {
                getBasicItems: bind(WIDGET_ITEMS.BASIC),
                getChartItems: bind(WIDGET_ITEMS.CHART),
                getTableItems: bind(WIDGET_ITEMS.TABLE),
                getOtherItems: bind(WIDGET_ITEMS.OTHER),
                _addCustomItems: addCustomItems // for testing purposes
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
