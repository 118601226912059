"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENV_VARS = void 0;
/**
 * variables available at window.Qualtrics.Environment in the browser
 *
 * @todo replace hardcoded env vars throughout the code with reference to this object
 *
 * @todo next feature flippers under the 'features' property
 * instead putting them on the root of the environment
 */
var ENV_VARS;
(function (ENV_VARS) {
    ENV_VARS["ACTIVE_PROJECT_ID"] = "activeProjectId";
    ENV_VARS["ACTIVE_SURVEY_ID"] = "activeSurveyId";
    ENV_VARS["BRAND_LOGO"] = "brandLogo";
    ENV_VARS["COMPONENT_BUNDLE"] = "componentBundle";
    ENV_VARS["CONTAINER"] = "container";
    ENV_VARS["CONTAINER_ID"] = "containerId";
    ENV_VARS["CUSTOM_WIDGETS"] = "customWidgets";
    ENV_VARS["DASHBOARD"] = "dashboard";
    ENV_VARS["DISABLE_WINDOW_ALERTS"] = "disableWindowAlerts";
    ENV_VARS["DYNAMIC_TRANSLATIONS"] = "dynamicTranslations";
    ENV_VARS["EDITING"] = "editing";
    ENV_VARS["ERROR"] = "error";
    ENV_VARS["FEATURES"] = "features";
    ENV_VARS["FIELDSETS"] = "fieldsets";
    ENV_VARS["FILTERS"] = "filters";
    ENV_VARS["IS_BATCH_GENERATION_IN_PROGRESS"] = "isBatchReportGenerationInProgress";
    ENV_VARS["IS_FIELDSET_PUBLISHING_V2_ENABLED"] = "fieldsetPublishingV2";
    ENV_VARS["IS_PRIVATE_EXPORT"] = "isPrivateExport";
    ENV_VARS["JWT_OPTIONS"] = "jwtOptions";
    ENV_VARS["MESSAGES"] = "messages";
    ENV_VARS["ORG_HIERARCHY_ID"] = "orgHierarchyId";
    ENV_VARS["PAGE_ID"] = "pageId";
    ENV_VARS["PERMISSIONS"] = "permissions";
    ENV_VARS["PERSON_METADATA"] = "personMetadataFields";
    ENV_VARS["PRIVATE_EXPORT_META"] = "privateExportContextMeta";
    ENV_VARS["PROJECT_TYPE"] = "projectType";
    ENV_VARS["PUBLIC_INFO"] = "publicInfo";
    ENV_VARS["RELATIONSHIPS_BETWEEN_PERSONS"] = "relationshipsBetweenPersons";
    ENV_VARS["RELATIONSHIPS_CONFIG"] = "relationshipsConfig";
    ENV_VARS["REPORT_LANGUAGE"] = "reportLanguage";
    ENV_VARS["REPORT_TYPE"] = "reportType";
    ENV_VARS["RESOURCE_DETAILS"] = "resourceDetails";
    ENV_VARS["RIPSAW_BASE_URL"] = "ripsawBaseUrl";
    ENV_VARS["RS_ACTIVE_SURVEY"] = "rsActiveSurvey";
    ENV_VARS["RS_SAVE_QUEUE_TIME_SEC"] = "rsSaveQueueTimeSec";
    ENV_VARS["SOURCES"] = "sources";
    ENV_VARS["USER"] = "user";
    ENV_VARS["USER_AGENT"] = "userAgent";
    ENV_VARS["USER_LANGUAGE"] = "userLanguage";
})(ENV_VARS = exports.ENV_VARS || (exports.ENV_VARS = {}));
