"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getZoom = void 0;
function getZoom(thisElement) {
    var el = thisElement;
    if (!el.length) {
        return 1;
    }
    var matrix = el.css('transform') ||
        el.css('-ms-transform') ||
        el.css('-moz-transform') ||
        el.css('-webkit-transform');
    if (!matrix) {
        return 1;
    }
    return parseFloat(matrix.split(', ')[3] || 1);
}
exports.getZoom = getZoom;
(function ($) {
    $.fn.getZoom = function () {
        var el = this;
        return getZoom(el);
    };
})(jQuery);
