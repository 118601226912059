"use strict";
(function (module) {
    'use strict';
    /**
     * Add tests for this service
     * https://qualtrics.atlassian.net/browse/REP-6183
     */
    module.factory('prFilterService', [
        'fc.wrapperService',
        'pr.API_ROUTES',
        '$http',
        'prFieldsets',
        '$q',
        'prResponseCount',
        '$location',
        'pr.bulkFieldService',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        'prFilterUtils',
        function (fcWrapperService, API_ROUTES, $http, prFieldsets, $q, prResponseCount, $location, bulkFieldService, triggerOverrides, OVERRIDE_EVENTS, filterUtils) {
            function Filters(sourceId, sourceFilter, filterChangedCb) {
                this.sourceId = sourceId;
                this.fcWrapperService = fcWrapperService;
                this.sourceDef = {};
                this.API_ROUTES = API_ROUTES;
                this.$q = $q;
                this.$http = $http;
                this.fieldsets = prFieldsets;
                this.sourceFilter = sourceFilter;
                this.filterChangedCb = filterChangedCb;
                this.responseCount = prResponseCount;
                this.$location = $location;
            }
            Filters.MAX_FILTER_NAME_LENGTH = 23;
            var proto = Filters.prototype;
            proto.removeFilter = function () {
                return this.fcWrapperService.removeAppliedFilter();
            };
            proto.getResponseCount = function () {
                var self = this;
                return function (filter) {
                    var filterCopy = _.cloneDeep(filter);
                    var timezone = _.get(filterCopy, 'timezone') ||
                        _.get(self, 'sourceFilter.timezone');
                    filterUtils.fixFilters(filterCopy, timezone);
                    return self.responseCount.getResponseCount(self.sourceId, filterCopy);
                };
            };
            proto.getFiltersMenu = function () {
                var self = this;
                return this.getSourceDef(this.sourceId).then(function (sourceDef) {
                    return self.fcWrapperService.getFilterMenu(sourceDef);
                });
            };
            proto.selectFilterMenuItem = function (selectedMenuItem) {
                return this.fcWrapperService.selectFilterMenuItem(selectedMenuItem);
            };
            proto.openManageFiltersModal = function () {
                return this.fcWrapperService.openManageFiltersModal();
            };
            proto.getSourcePermissions = function () {
                var self = this;
                // @todo move this permissions logic to a common service
                // https://qualtrics.atlassian.net/browse/REP-6183
                return self.$http.get(self.API_ROUTES.SURVEYS + self.sourceId + '/permissions', {
                    cache: true
                }).then(function (response) {
                    var permission = response.data || {};
                    self.permissions = {
                        readOnly: !permission.useSubgroupAnalysis,
                        viewPersonalData: permission.viewPersonalData
                    };
                }).catch(function (err) {
                    console.error('Filter permission check error: ', err);
                    self.permissions = {
                        readOnly: true,
                        viewPersonalData: false
                    };
                }).finally(function () {
                    self.permissions = triggerOverrides({
                        event: OVERRIDE_EVENTS.FILTER_PERMISSIONS,
                        data: self.permissions,
                        sync: true
                    });
                });
            };
            proto.canFilter = function () {
                return !((this.permissions || {}).readOnly);
            };
            proto.getSourceDef = function () {
                var self = this;
                if (!_.isEmpty(self.sourceDef)) {
                    return this.$q.when(self.sourceDef);
                }
                return self.fieldsets.getFieldsetBySourceId(self.sourceId).then(function (fs) {
                    self.sourceDef = fs.definition;
                    self.sourceDef.fieldSetLabels = fs.labels;
                }).then(function () {
                    return triggerOverrides({
                        event: OVERRIDE_EVENTS.FILTERS_CLIENT_FIELDSET_DEF,
                        data: self.sourceDef
                    });
                }).then(function (sourceDef) {
                    self.sourceDef = sourceDef;
                    return self.sourceDef;
                });
            };
            proto.hasValidFilterApplied = function () {
                return this.fcWrapperService.hasValidFilterApplied();
            };
            proto.getTruncatedFilterName = function () {
                var filterMeta = this.fcWrapperService.getFilterMeta() || {};
                return _.truncate(filterMeta.name, { length: Filters.MAX_FILTER_NAME_LENGTH });
            };
            proto.getFilterMeta = function () {
                return fcWrapperService.getFilterMeta() || {};
            };
            proto.getFilterName = function () {
                var filterMeta = this.fcWrapperService.getFilterMeta() || {};
                return filterMeta.name || '';
            };
            proto.isFilterNameTruncated = function () {
                var filterMeta = this.fcWrapperService.getFilterMeta() || {};
                return (filterMeta.name || '').length > Filters.MAX_FILTER_NAME_LENGTH;
            };
            proto.hasSavedFilters = function () {
                return this.getFiltersMenu()
                    .then(function (menu) {
                    return menu.length > 1;
                });
            };
            proto.isValidFilter = function (filter) {
                return this.fcWrapperService.isValidFilter(filter);
            };
            proto.init = function () {
                var self = this;
                return self.getSourcePermissions()
                    .then(function () {
                    return self.getSourceDef();
                })
                    .then(function () {
                    return self.fcWrapperService
                        .init(_.cloneDeep(self.sourceFilter), self.sourceDef, self.filterChangedCb, self.permissions, self.getResponseCount());
                });
            };
            return Filters;
        }
    ]);
}(angular.module('qualtrics.pr')));
