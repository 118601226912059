"use strict";
(function (module) {
    module.factory('pr.widgetStylesService', [
        'prStyleNode',
        'prTextFormatsHelper',
        'pr.globalOptionsService',
        'prAutoHeightHelper',
        function (prStyleNode, formatHelper, goService, autoHeight) {
            function applyResponseCountNumberStyles(prefix, formatObj, nodeName) {
                var css = '#' + prefix + ' .response-count-value, '
                    + '#HIDDEN_' + prefix + ' .response-count-value * {'
                    + formatHelper.getStyles({
                        FONT: formatObj.FONT,
                        SIZE: formatObj.SIZE,
                        COLOR: formatObj.COLOR,
                        BOLD: formatObj.BOLD
                    }, true)
                    + '}'
                    + '#' + prefix + ' response-count-value, '
                    + '#HIDDEN_' + prefix + ' response-count-value {'
                    + 'fill: ' + formatObj.COLOR + ';'
                    + '}';
                prStyleNode.updateCSSNode(nodeName, css);
            }
            function applyResponseCountLabelStyles(prefix, formatObj, nodeName) {
                var css = '#' + prefix + ' .widget-response-count, '
                    + '#HIDDEN_' + prefix + ' .widget-response-count * {'
                    + formatHelper.getStyles({
                        FONT: formatObj.FONT,
                        SIZE: formatObj.SIZE,
                        COLOR: formatObj.COLOR,
                        BOLD: formatObj.BOLD
                    }, true)
                    + '}'
                    + '#' + prefix + ' widget-response-count, '
                    + '#HIDDEN_' + prefix + ' widget-response-count {'
                    + 'fill: ' + formatObj.COLOR + ';'
                    + '}';
                prStyleNode.updateCSSNode(nodeName, css);
            }
            function applyTableAltRows(prefix, nodeName) {
                var css = ''
                    + '#' + prefix + ' table tbody tr:nth-child(even) {'
                    + 'background-color: #F9F9F9;' // could use this to set as any color
                    + '}';
                prStyleNode.updateCSSNode(nodeName, css);
            }
            function applyHideHeaderRow(prefix, nodeName, val) {
                var css = '';
                if (val) {
                    css = '#' + prefix + ' .simple-table thead tr {'
                        + 'visibility: collapse;'
                        + '}';
                }
                else {
                    css = '#' + prefix + ' .simple-table thead tr {'
                        + 'visibility: visible;'
                        + '}';
                }
                prStyleNode.updateCSSNode(nodeName, css);
            }
            function applyFirstColumnWidth(prefix, nodeName, val) {
                if (typeof val === 'number') {
                    val = val + '%';
                }
                var css = '#' + prefix + ' .simple-table tr td:first-child {'
                    // Needs to be marked as important since for some reason column widths are set using In-Line (HTML) Style:
                    + 'width: ' + val + ' !important;'
                    + '}';
                var css_hidden = '#HIDDEN_' + prefix + ' .simple-table tr td:first-child {'
                    + 'width: ' + val + ' !important;'
                    + '}';
                prStyleNode.updateCSSNode(nodeName, css);
                prStyleNode.updateCSSNode(nodeName, css_hidden);
            }
            function applyTableBodyDensity(prefix, nodeName, val) {
                var css = ''
                    + '#' + prefix + ' .simple-table td, '
                    + '#' + prefix + ' .simple-table td *, '
                    + '#HIDDEN_' + prefix + ' .simple-table td, '
                    + '#HIDDEN_' + prefix + ' .simple-table td * {'
                    + 'padding: ' + val + ';'
                    + '}';
                prStyleNode.updateCSSNode(nodeName, css);
            }
            function applyChartTextStyles(prefix, formatObj, nodeName) {
                var css = '#' + prefix + ' .dq *, '
                    + '#HIDDEN_' + prefix + ' .dq * {'
                    + formatHelper.getStyles({
                        FONT: formatObj.FONT,
                        SIZE: formatObj.SIZE
                    }, true)
                    + '}'
                    + '#' + prefix + ' .dq text, '
                    + '#HIDDEN_' + prefix + ' .dq text {'
                    + 'fill: ' + formatObj.COLOR + ' !important;'
                    + '}';
                prStyleNode.updateCSSNode(nodeName, css);
            }
            function applyTableHeaderTextStyles(prefix, formatObj, nodeName) {
                var css = ''
                    + '#' + prefix + ' .simple-table th, '
                    + '#' + prefix + ' .simple-table th *, '
                    + '#HIDDEN_' + prefix + ' .simple-table th, '
                    + '#HIDDEN_' + prefix + ' .simple-table th * {'
                    + formatHelper.getStyles({
                        FONT: formatObj.FONT,
                        BOLD: formatObj.BOLD
                    }, true)
                    + '}'
                    + '#' + prefix + ' .simple-table th, '
                    + '#HIDDEN_' + prefix + ' .simple-table th {'
                    + formatHelper.getStyles({
                        SIZE: formatObj.SIZE,
                        COLOR: formatObj.COLOR
                    }, true)
                    + '}';
                prStyleNode.updateCSSNode(nodeName, css);
            }
            function applyTableBodyTextStyles(prefix, formatObj, nodeName) {
                var css = ''
                    + '#' + prefix + ' .simple-table td, '
                    + '#' + prefix + ' .simple-table td *, '
                    + '#HIDDEN_' + prefix + ' .simple-table td, '
                    + '#HIDDEN_' + prefix + ' .simple-table td * {'
                    + formatHelper.getStyles({
                        FONT: formatObj.FONT
                    }, true)
                    + '}'
                    + '#' + prefix + ' .simple-table td, '
                    + '#HIDDEN_' + prefix + ' .simple-table td {'
                    + formatHelper.getStyles({
                        SIZE: formatObj.SIZE,
                        COLOR: formatObj.COLOR
                    }, true)
                    + '}';
                prStyleNode.updateCSSNode(nodeName, css);
            }
            function applyAdvChartTextStyles(prefix, formatObj, nodeName) {
                var css = '';
                if (formatObj.X_AXIS_TEXT) {
                    css = css
                        + '#' + prefix + ' .dq .nv-x text *, '
                        + '#HIDDEN_' + prefix + ' .dq .nv-x text * {'
                        + formatHelper.getStyles({
                            FONT: formatObj.X_AXIS_TEXT.FONT,
                            SIZE: formatObj.X_AXIS_TEXT.SIZE,
                        }, true)
                        + 'fill: ' + formatObj.X_AXIS_TEXT.COLOR + ';'
                        + '}';
                }
                if (formatObj.Y_AXIS_TEXT) {
                    css = css
                        + '#' + prefix + ' .dq .nv-y1 text, '
                        + '#HIDDEN_' + prefix + ' .dq .nv-y1 text {'
                        + formatHelper.getStyles({
                            FONT: formatObj.Y_AXIS_TEXT.FONT,
                            SIZE: formatObj.Y_AXIS_TEXT.SIZE,
                        }, true)
                        + 'fill: ' + formatObj.Y_AXIS_TEXT.COLOR + ';'
                        + '}';
                }
                if (formatObj.LEGEND_TEXT) {
                    css = css
                        + '#' + prefix + ' .dq .dq-legend-text, '
                        + '#HIDDEN_' + prefix + ' .dq .dq-legend-text {'
                        + formatHelper.getStyles({
                            FONT: formatObj.LEGEND_TEXT.FONT,
                            SIZE: formatObj.LEGEND_TEXT.SIZE,
                        }, true)
                        + 'fill: ' + formatObj.LEGEND_TEXT.COLOR + ';'
                        + '}';
                }
                if (formatObj.LABEL_TEXT) {
                    css = css
                        + '#' + prefix + ' .dq .value-labels *, '
                        + '#' + prefix + ' .dq .smart-labels, '
                        + '#HIDDEN_' + prefix + ' .dq .value-labels *, '
                        + '#HIDDEN_' + prefix + ' .dq .smart-labels {'
                        + formatHelper.getStyles({
                            FONT: formatObj.LABEL_TEXT.FONT,
                            SIZE: formatObj.LABEL_TEXT.SIZE,
                        }, true)
                        + 'fill: ' + formatObj.LABEL_TEXT.COLOR + ';'
                        + '}';
                }
                if (formatObj.METRIC_TEXT) {
                    css = css
                        + '#' + prefix + ' .dq .value, '
                        + '#HIDDEN_' + prefix + ' .dq .value {'
                        + formatHelper.getStyles({
                            FONT: formatObj.METRIC_TEXT.FONT
                        }, true)
                        + 'fill: ' + formatObj.METRIC_TEXT.COLOR + ' !important;'
                        + '}';
                }
                if (formatObj.SCALE_TEXT) {
                    css = css
                        + '#' + prefix + ' .dq .midValue *, '
                        + '#' + prefix + ' .dq .minValue, '
                        + '#' + prefix + ' .dq .maxValue, '
                        + '#HIDDEN_' + prefix + ' .dq .midValue *, '
                        + '#HIDDEN_' + prefix + ' .dq .minValue, '
                        + '#HIDDEN_' + prefix + ' .dq .maxValue {'
                        + formatHelper.getStyles({
                            FONT: formatObj.SCALE_TEXT.FONT
                        }, true)
                        + 'fill: ' + formatObj.SCALE_TEXT.COLOR + ' !important;'
                        + '}';
                }
                prStyleNode.updateCSSNode(nodeName, css);
            }
            function applyFilterSummaryStyles(prefix, formatObj, nodeName) {
                var css = '';
                if (formatObj.FILTER_NAME || formatObj.ALIGNMENT) {
                    var filterNameStyles = {};
                    if (formatObj.FILTER_NAME) {
                        filterNameStyles['FONT'] = formatObj.FILTER_NAME.FONT;
                        filterNameStyles['COLOR'] = formatObj.FILTER_NAME.COLOR;
                        filterNameStyles['SIZE'] = formatObj.FILTER_NAME.SIZE;
                        filterNameStyles['BOLD'] = formatObj.FILTER_NAME.BOLD;
                    }
                    if (formatObj.ALIGNMENT) {
                        filterNameStyles['ALIGNMENT'] = formatObj.ALIGNMENT.ALIGNMENT;
                    }
                    css = css
                        + '#' + prefix + ' .filter-summary-name, '
                        + '#' + prefix + ' .filter-summary-name *, '
                        + '#HIDDEN_' + prefix + ' .filter-summary-name, '
                        + '#HIDDEN_' + prefix + ' .filter-summary-name * {'
                        + formatHelper.getStyles(filterNameStyles, true)
                        + '}'
                        + '#' + prefix + ' .filter-summary-name, '
                        + '#HIDDEN_' + prefix + ' .filter-summary-name {'
                        + formatHelper.getStyles(filterNameStyles, true)
                        + '}';
                }
                if (formatObj.FILTER_VALUE || formatObj.ALIGNMENT) {
                    var filterValueStyles = {};
                    if (formatObj.FILTER_VALUE) {
                        filterValueStyles['FONT'] = formatObj.FILTER_VALUE.FONT;
                        filterValueStyles['COLOR'] = formatObj.FILTER_VALUE.COLOR;
                        filterValueStyles['SIZE'] = formatObj.FILTER_VALUE.SIZE;
                        filterValueStyles['BOLD'] = formatObj.FILTER_VALUE.BOLD;
                    }
                    if (formatObj.ALIGNMENT) {
                        filterValueStyles['ALIGNMENT'] = formatObj.ALIGNMENT.ALIGNMENT;
                    }
                    css = css
                        + '#' + prefix + ' .filter-summary-value, '
                        + '#' + prefix + ' .filter-summary-value *, '
                        + '#HIDDEN_' + prefix + ' .filter-summary-value, '
                        + '#HIDDEN_' + prefix + ' .filter-summary-value * {'
                        + formatHelper.getStyles(filterValueStyles, true)
                        + '}'
                        + '#' + prefix + ' .filter-summary-value, '
                        + '#HIDDEN_' + prefix + ' .filter-summary-value {'
                        + formatHelper.getStyles(filterValueStyles, true)
                        + '}';
                }
                prStyleNode.updateCSSNode(nodeName, css);
            }
            function applyAllWidgetStyles(widget) {
                if (!widget._id) {
                    return;
                }
                var style = _.get(widget, 'layout.style') || {}, nodeName;
                if (style.responseCountNumberFormats) {
                    nodeName = 'RESPONSE_COUNT_NUMBER_' + widget._id;
                    applyResponseCountNumberStyles(widget._id, style.responseCountNumberFormats, nodeName);
                }
                if (style.responseCountLabelFormats) {
                    nodeName = 'RESPONSE_COUNT_LABEL_' + widget._id;
                    applyResponseCountLabelStyles(widget._id, style.responseCountLabelFormats, nodeName);
                }
                if (style.tableHeaderTextFormats) {
                    nodeName = 'HEADER_STYLE_' + widget._id;
                    applyTableHeaderTextStyles(widget._id, style.tableHeaderTextFormats, nodeName);
                }
                if (style.tableBodyTextFormats) {
                    nodeName = 'BODY_STYLE_' + widget._id;
                    applyTableBodyTextStyles(widget._id, style.tableBodyTextFormats, nodeName);
                }
                if (style.chartTextFormats && style.chartTextFormats.FONT) {
                    if (widget._type === 'eng.breakdownbar') {
                        style.chartTextFormats = _.omit(style.chartTextFormats, ['SIZE']);
                    }
                    nodeName = 'CHART_STYLE_' + widget._id;
                    applyChartTextStyles(widget._id, style.chartTextFormats, nodeName);
                }
                else if (style.chartTextFormats) {
                    nodeName = 'CHART_STYLE_' + widget._id;
                    applyAdvChartTextStyles(widget._id, style.chartTextFormats, nodeName);
                }
                if (style.enableAltRows || (_.isUndefined(style.enableAltRows) && widget._type === 'eng.grid')) {
                    nodeName = 'ALT_ROWS_' + widget._id;
                    applyTableAltRows(widget._id, nodeName);
                }
                if (style.hideTableHeader) {
                    nodeName = 'HIDE_HEADER_ROW_' + widget._id;
                    applyHideHeaderRow(widget._id, nodeName, style.hideTableHeader);
                }
                if (style.firstColumnWidth && style.enableFirstColumnWidth) {
                    nodeName = 'SET_FIRST_COLUMN_WIDTH_' + widget._id;
                    applyFirstColumnWidth(widget._id, nodeName, style.firstColumnWidth);
                }
                if (style.filterSummaryTextFormats) {
                    nodeName = 'FILTER_SUMMARY_STYLE_' + widget._id;
                    applyFilterSummaryStyles(widget._id, style.filterSummaryTextFormats, nodeName);
                }
                if (autoHeight.isTable(widget._type)) {
                    nodeName = 'TABLE_DENSITY_STYLE_' + widget._id;
                    applyTableBodyDensity(widget._id, nodeName, style.tableBodyDensity || goService.getTableBodyDensity());
                }
            }
            return {
                applyTableAltRows: applyTableAltRows,
                applyHideHeaderRow: applyHideHeaderRow,
                applyFirstColumnWidth: applyFirstColumnWidth,
                applyChartTextStyles: applyChartTextStyles,
                applyTableBodyTextStyles: applyTableBodyTextStyles,
                applyTableHeaderTextStyles: applyTableHeaderTextStyles,
                applyAdvChartTextStyles: applyAdvChartTextStyles,
                applyAllWidgetStyles: applyAllWidgetStyles,
                applyTableBodyDensity: applyTableBodyDensity,
                applyFilterSummaryStyles: applyFilterSummaryStyles,
                applyResponseCountNumberStyles: applyResponseCountNumberStyles,
                applyResponseCountLabelStyles: applyResponseCountLabelStyles
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
