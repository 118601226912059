"use strict";
(function (app) {
    'use strict';
    var CACHE_MAX_SIZE = 500;
    var HttpPostCache = function ($cacheFactory, $http, $q) {
        // key: unique key identifying the request.
        // value: angularjs promise that resolves to the post response.
        var responseCache = $cacheFactory('pr-data-retrieval-cache', {
            capacity: CACHE_MAX_SIZE
        });
        var clearCache = function () {
            responseCache.removeAll();
        };
        /**
         * @param {string} path
         * @param {object} options
         * @param {object} config - an optional configuration object descrbing the request to be made and how it should be processed.
         * 							Currently only used to set the `Content-Language` request header to pass the report language to the server
         * 							See https://docs.angularjs.org/api/ng/service/$http#$http-arguments for more information
         */
        var post = function (path, options, config) {
            var key = createCacheKey(path, options, config);
            var cachedResponsePromise = responseCache.get(key);
            if (cachedResponsePromise) {
                return cachedResponsePromise.then(function () {
                    // The value in cache could have changed since the prior
                    // responseCache.get. This works-around a race condition when
                    // 2 requests are requested nearly at the same time and the
                    // first caller modifies the response data.
                    return responseCache.get(key).then(_.cloneDeep);
                });
            }
            return makeRemoteRequest(path, options, config, key);
        };
        var makeRemoteRequest = function (path, options, config, key) {
            var angularPostPromise = $http.post(path, options, config).then(function (response) {
                var result = _.cloneDeep({
                    data: response.data
                });
                // The value returned below can be updated by the caller. Therefore,
                // we need to clone the data and put it back in cache.
                responseCache.put(key, $q.when(_.cloneDeep(result)));
                return result;
            }).catch(function (error) {
                responseCache.remove(key);
                console.error('http-post-cache-service failed with error: ', error);
                throw new Error(error);
            });
            responseCache.put(key, angularPostPromise);
            return angularPostPromise;
        };
        var createCacheKey = function (path, options, config) {
            var cacheOptions = _.cloneDeep(options) || {};
            delete cacheOptions.decimalPlaces;
            // Needed for metric component
            // delete each metric decimalPlaces and overrideLabel since they don't change response data
            if (cacheOptions.metrics) {
                _.forEach(cacheOptions.metrics, function (metric) {
                    if (metric.format) {
                        delete metric.format.decimalPlaces;
                    }
                    if (metric.overrideLabel) {
                        delete metric.overrideLabel;
                    }
                });
            }
            _.unset(cacheOptions, 'dataRequestParams.widgetId');
            // This contains widget id and a bunch of duplicated data that doesn't affect
            // the data requested.
            _.unset(cacheOptions, 'dataRequestParams.displayLogicRequestParams');
            // @todo for efficiency, remove shape.widgetType from key
            // after preventing it from being read by the backend.
            _.unset(cacheOptions, 'shape.widgetId');
            return JSON.stringify({ path: path, options: cacheOptions, config: config });
        };
        return {
            clearCache: clearCache,
            post: post
        };
    };
    HttpPostCache.$inject = [
        '$cacheFactory',
        '$http',
        '$q'
    ];
    app.factory('pr.httpPostCache', HttpPostCache);
})(angular.module('qualtrics.pr'));
