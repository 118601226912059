"use strict";
(function (module) {
    'use strict';
    module.factory('prErrorModal', [
        'qstrap.modal',
        function (modal) {
            function show(content, title) {
                if (content === void 0) { content = 'AN_ERROR_OCCURRED'; }
                if (title === void 0) { title = 'ERROR'; }
                return modal({
                    title: "<i class=\"icon icon-caution-lg\"></i> <span translate>" + title + "</span>",
                    content: "<span translate>" + content + "</span>",
                    buttons: [
                        '<button class="btn btn-hover" ng-click="close()" translate>CLOSE</button>'
                    ],
                    show: true
                });
            }
            return {
                show: show
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
