"use strict";
(function (module) {
    'use strict';
    module.factory('prPipedTextTooltipService', [
        '$filter',
        'prPipedTextMetricsService',
        function ($filter, PipedTextMetrics) {
            function Tooltip() {
                this.metrics = new PipedTextMetrics();
                return this;
            }
            Tooltip.translate = $filter('translate');
            Tooltip.ATTR_KEY_MAP = {
                'currentDate': 'PIPED_TEXT.CURRENT_DATE_TOOLTIP',
                'lastResponseDate': 'PIPED_TEXT.LAST_RESPONSE_DATE_TOOLTIP',
                'activeReportName': 'PIPED_TEXT.ACTIVE_REPORT_NAME_TOOLTIP',
                'sourceDescription': 'PIPED_TEXT.SOURCE_NAME_TOOLTIP',
                'sourceTotalResponses': 'PIPED_TEXT.SOURCE_TOTAL_RESPONSES_TOOLTIP',
                'sourceStatus': 'PIPED_TEXT.SOURCE_STATUS_TOOLTIP',
                'sourceName': 'PIPED_TEXT.SOURCE_NAME_TOOLTIP',
                'edName': 'PIPED_TEXT.ED_NAME',
                'questionName': 'PIPED_TEXT.QUESTION_NAME',
                'sourceAggregateData': 'PIPED_TEXT.AGGREGATE_DATA',
                'fullName': 'PIPED_TEXT.FULL_NAME',
                'email': 'PIPED_TEXT.EMAIl',
                'firstName': 'PIPED_TEXT.FIRST_NAME',
                'lastName': 'PIPED_TEXT.LAST_NAME',
                'subjectsEvaluators': 'PIPED_TEXT.INVITED_EVALUATOR_NAMES',
                'subjectMetadata': 'PIPED_TEXT.SUBJECT_METADATA_TOOLTIP',
                'subjectSmartText': 'PIPED_TEXT.SUBJECT_SMART_TEXT_TOOLTIP',
                'orgHierarchyName': 'ORG_HIERARCHY_NAME',
                'orgHierarchyUnit': 'ORG_HIERARCHY_UNIT',
                'orgHierarchyNameUnit': 'ORG_HIERARCHY_NAME_UNIT'
            };
            Tooltip.SMART_TEXT_KEY_MAP = {
                'firstPersonPossessive': Tooltip.translate('PIPED_TEXT.FIRST_PERSON_POSSESSIVE'),
                'firstPersonReflexive': Tooltip.translate('PIPED_TEXT.FIRST_PERSON_REFLEXIVE'),
                'secondPersonPossessive': Tooltip.translate('PIPED_TEXT.SECOND_PERSON_POSSESSIVE'),
                'secondPersonReflexive': Tooltip.translate('PIPED_TEXT.SECOND_PERSON_REFLEXIVE'),
            };
            var proto = Tooltip.prototype;
            proto.getToopltipContent = function (ptAttrs) {
                var params = {};
                if (ptAttrs.type === 'date') {
                    if (ptAttrs.attrName === 'currentDate') {
                        params = {
                            format: ptAttrs.format || ''
                        };
                    }
                    else {
                        params = {
                            source: (ptAttrs.sourceName || '') + ' (' + ptAttrs.sourceId + ')',
                            format: ptAttrs.format
                        };
                    }
                }
                else {
                    params = {
                        source: (ptAttrs.sourceName || '') + ' (' + ptAttrs.sourceId + ')' || ''
                    };
                    if (ptAttrs.attrName === 'edName') {
                        params.edField = ptAttrs.edField || ptAttrs.fieldId || '';
                    }
                    else if (ptAttrs.attrName === 'questionName') {
                        params.qid = ptAttrs.qid || ptAttrs.fieldId || '';
                    }
                    else if (ptAttrs.type === 'sourceAggregateData') {
                        params.metric = this.metrics.getSupportedMetrics()[ptAttrs.attrName] || '';
                        params.field = ptAttrs.qid || ptAttrs.fieldId || '';
                    }
                    else if (ptAttrs.type === 'subjectMetadata') {
                        params.metadataName = ptAttrs.attrName;
                    }
                    else if (ptAttrs.type === 'subjectSmartText') {
                        params.smartTextType = Tooltip.SMART_TEXT_KEY_MAP[ptAttrs.attrName];
                    }
                }
                var translateKey = Tooltip.ATTR_KEY_MAP[ptAttrs.attrName] || Tooltip.ATTR_KEY_MAP[ptAttrs.type];
                return Tooltip.translate(translateKey, params);
            };
            return Tooltip;
        }
    ]);
}(angular.module('qualtrics.pr')));
