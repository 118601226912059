"use strict";
(function (module) {
    module.factory('pr.createReport', [
        '$http',
        'rsEnvironment',
        'prPageLayoutService',
        'pr.API_ROUTES',
        'prSanitize',
        'pr.globalOptionsService',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        function ($http, env, pageLayoutService, API_ROUTES, sanitize, globalOptionsService, triggerOverrides, OVERRIDE_EVENTS) {
            function createReport(name, surveyId, pageLayout, globalReportOptions, meta) {
                // if user doesn't have global report options, use Qualtrics default
                var newReportGlobalOptions = _.merge({}, globalOptionsService.getDefaults(), globalReportOptions.globalOptions);
                var containerDef = {
                    containerType: 'dashboard',
                    description: sanitize.stripHTML(name),
                    translationId: 'definition',
                    translatableAttributes: ['description'],
                    product: 'printed-reports',
                    pages: [],
                    fieldSetId: 'multi',
                    contextMeta: {
                        surveyId: [surveyId],
                        pageLayout: pageLayout || globalReportOptions.pageLayout,
                        globalOptions: newReportGlobalOptions,
                        headerFooter: {
                            header: {
                                pageNumber: {
                                    alignment: 'right'
                                }
                            }
                        }
                    }
                };
                if (_.isObject(meta)) {
                    _.merge(containerDef.contextMeta, meta);
                }
                if (globalReportOptions.globalCustomPalettes) {
                    containerDef.contextMeta.customColorPalettes = globalReportOptions.globalCustomPalettes;
                }
                return triggerOverrides({
                    event: OVERRIDE_EVENTS.NEW_CONTAINER_DEFINITION,
                    data: containerDef
                }).then(function (newContainerDef) {
                    return $http.post(API_ROUTES.CONTAINERS, newContainerDef);
                }).then(function (response) {
                    return response.data;
                });
            }
            function createReportForContainerDefinition(containerDefinition) {
                return $http.post(API_ROUTES.CONTAINERS, containerDefinition).then(function (response) {
                    return response.data;
                });
            }
            function createFromViewResults(name, vrId, pageLayout, globalReportOptions, selectedPages) {
                // if user doesn't have global report options, use Qualtrics default
                var newReportGlobalOptions = _.merge({}, globalOptionsService.getDefaults(), globalReportOptions.globalOptions);
                var postData = {
                    name: sanitize.stripHTML(name),
                    pageLayout: pageLayout || globalReportOptions.pageLayout,
                    selectedPages: selectedPages,
                    globalOptions: newReportGlobalOptions
                };
                if (globalReportOptions.globalCustomPalettes) {
                    postData.customColorPalettes = globalReportOptions.globalCustomPalettes;
                }
                _.merge(postData, getWidgetSizeConfig(pageLayout));
                return $http.post(API_ROUTES.CONVERSION + '/' + vrId, postData)
                    .then(function (data) {
                    return data.data.container;
                });
            }
            var HEIGHT = 'height', WIDTH = 'width', PX_TO_IN = pageLayoutService.PX_TO_IN, CELL_SIZE = pageLayoutService.CELL_SIZE;
            function getWidgetSizeConfig(layout) {
                var pageSize = pageLayoutService.getViewDimensions(layout), pageWidth = pageSize[WIDTH], pageHeight = pageSize[HEIGHT], widgetMaxWidth = pageWidth - layout.margins.left - layout.margins.right, widgetMaxHeight = pageHeight - layout.margins.top - layout.margins.bottom;
                return {
                    widgetMaxWidth: Math.floor(widgetMaxWidth * PX_TO_IN / CELL_SIZE),
                    widgetMaxHeight: Math.floor(widgetMaxHeight * PX_TO_IN / CELL_SIZE)
                };
            }
            return {
                createReport: createReport,
                createReportForContainerDefinition: createReportForContainerDefinition,
                createFromViewResults: createFromViewResults
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
