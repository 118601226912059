"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMPATIBLE_WIDGETS = exports.THREE_SIXTY_WIDGETS = exports.ENG_WIDGETS = exports.PR_WIDGETS = void 0;
exports.PR_WIDGETS = {
    BLANK_SPACE: 'pr.spacer',
    DIVIDER: 'pr.divider',
    FILTER_SUMMARY: 'pr.filtersummary',
    IMAGE: 'pr.image',
    PAGE_BREAK: 'pr.pagebreak',
    TEXT: 'pr.text'
};
exports.ENG_WIDGETS = {
    BAR: 'eng.bar',
    LINE: 'eng.line',
    TABLE: 'eng.table',
    PIE: 'eng.pie',
    BREAKDOWN_BAR: 'eng.breakdownbar',
    GAUGE: 'eng.gauge',
    STATS: 'eng.stats',
    GRID: 'eng.grid',
    WORDCLOUD: 'eng.wordcloud',
    HEATMAP: 'eng.heatmap'
};
exports.THREE_SIXTY_WIDGETS = {
    HIGH_LOW_TABLE: 'eng.highlowtable',
    GAP_TABLE: 'eng.gaptable',
    SCORING_OVERVIEW_TABLE: 'eng.scoringoverviewtable',
    REPORT_SUMMARY_TABLE: 'eng.reportsummarytable',
    GAP_CHART: 'eng.gapchart',
    AGREEMENT_CHART: 'eng.agreementchart',
    CONDITIONAL_TEXT: 'eng.conditionaltext',
    RSDK_GAP_TABLE: 'eng.rsdkprgaptable'
};
exports.COMPATIBLE_WIDGETS = (_a = {},
    _a["Id" /* Id */] = [
        exports.ENG_WIDGETS.GRID
    ],
    _a["GenericValue" /* GenericValue */] = [
        exports.ENG_WIDGETS.GRID
    ],
    _a["DateTime" /* DateTime */] = [
        exports.ENG_WIDGETS.BAR,
        exports.ENG_WIDGETS.LINE,
        exports.ENG_WIDGETS.PIE,
        exports.ENG_WIDGETS.BREAKDOWN_BAR,
        exports.ENG_WIDGETS.TABLE,
        exports.ENG_WIDGETS.GRID
    ],
    _a["FreeFormText" /* FreeFormText */] = [
        exports.ENG_WIDGETS.GRID,
        exports.ENG_WIDGETS.WORDCLOUD
    ],
    _a["FieldGroup" /* FieldGroup */] = [
        exports.ENG_WIDGETS.GRID
    ],
    _a["ScalarValue" /* ScalarValue */] = [
        exports.ENG_WIDGETS.BAR,
        exports.ENG_WIDGETS.LINE,
        exports.ENG_WIDGETS.GAUGE,
        exports.ENG_WIDGETS.TABLE,
        exports.ENG_WIDGETS.STATS,
        exports.ENG_WIDGETS.GRID
    ],
    _a["OnlyScalarValue" /* OnlyScalarValue */] = [
        exports.ENG_WIDGETS.STATS,
        exports.ENG_WIDGETS.GRID
    ],
    _a["EnumerableValue" /* EnumerableValue */] = [
        exports.ENG_WIDGETS.BAR,
        exports.ENG_WIDGETS.LINE,
        exports.ENG_WIDGETS.PIE,
        exports.ENG_WIDGETS.BREAKDOWN_BAR,
        exports.ENG_WIDGETS.TABLE,
        exports.ENG_WIDGETS.GRID
    ],
    _a["EnumerableScalarValue" /* EnumerableScalarValue */] = [
        exports.ENG_WIDGETS.BAR,
        exports.ENG_WIDGETS.LINE,
        exports.ENG_WIDGETS.PIE,
        exports.ENG_WIDGETS.BREAKDOWN_BAR,
        exports.ENG_WIDGETS.GAUGE,
        exports.ENG_WIDGETS.TABLE,
        exports.ENG_WIDGETS.STATS,
        exports.ENG_WIDGETS.GRID
    ],
    _a["EnumerableValueSet" /* EnumerableValueSet */] = [
        exports.ENG_WIDGETS.BAR,
        exports.ENG_WIDGETS.LINE,
        exports.ENG_WIDGETS.PIE,
        exports.ENG_WIDGETS.BREAKDOWN_BAR,
        exports.ENG_WIDGETS.TABLE,
        exports.ENG_WIDGETS.GRID
    ],
    _a["Geometry" /* Geometry */] = [
        exports.ENG_WIDGETS.GRID,
        exports.ENG_WIDGETS.HEATMAP
    ],
    _a["TextHighlight" /* TextHighlight */] = [
        exports.ENG_WIDGETS.BAR,
        exports.ENG_WIDGETS.TABLE,
        exports.ENG_WIDGETS.GRID,
        exports.ENG_WIDGETS.WORDCLOUD
    ],
    _a["Category" /* Category */] = [
        exports.ENG_WIDGETS.BAR,
        exports.ENG_WIDGETS.LINE,
        exports.ENG_WIDGETS.GAUGE,
        exports.ENG_WIDGETS.TABLE
    ],
    _a);
