"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("angular");
(function (module) {
    'use strict';
    var WIDGET_SEL = 'pr-widget';
    var TEXT_EDIT_MODE = 'text-edit-mode';
    var RESIZE_ATTR = 'resize';
    module.directive('prTextBody', [
        '$rootScope',
        '$compile',
        'prFloatingContainer',
        'prFlowingPages',
        'prWidgetChunkerFactory',
        'prEventService',
        'prTextEditorMenu',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        'pr.widgetUtils',
        function ($rootScope, $compile, floatingContainer, flowingPages, chunker, eventService, textEditorMenu, triggerOverrides, OVERRIDE_EVENTS, widgetUtils) {
            return {
                scope: false,
                require: '?^^prBody',
                link: function ($scope, $element, $attr, $bodyCtrl) {
                    var chunk = $scope.widget;
                    var widget = flowingPages.getWidget(chunk._id);
                    $scope.$watch('isSelected()', function (selected) {
                        if (!selected) {
                            hideFloatingEditor();
                        }
                        // we need to toggle on each chunk
                        var func = selected ? 'addClass' : 'removeClass';
                        // we use a different class than 'selected' since that is used in the selector
                        // @ts-ignore - TODO: the selected arg may not be doing anything
                        $element.closest(WIDGET_SEL)[func]('active', selected);
                    });
                    $scope.isFixedHeightWidget = !!widgetUtils.isFixedHeightWidget(widget._id);
                    $element.on('$destroy', function () {
                        var activeChunkIndex = floatingContainer.getActiveChunkIndex();
                        eventService.stream($scope.widget._id).off('widgetSectionUpdated', render);
                        // Give focus to previous chunk so text can continue to be edited
                        if (getChunkIndex() > 0 && activeChunkIndex === getChunkIndex()) {
                            floatingContainer.show(widget, getChunkIndex() - 1);
                            floatingContainer.giveFocusEnd();
                        }
                        else if (activeChunkIndex === getChunkIndex()) {
                            hideFloatingEditor();
                        }
                    });
                    render();
                    eventService.stream($scope.widget._id).on('widgetSectionUpdated', render);
                    if (!$scope.$ctrl.isPublic) {
                        // no clicking interactions in public reports
                        $element.on('dblclick.prQuill', function (event) {
                            var editOptions = triggerOverrides({
                                event: OVERRIDE_EVENTS.DISABLE_RICH_TEXT,
                                data: {
                                    widgetType: $scope.widget._type
                                },
                                sync: true
                            });
                            if (!editOptions.disable) {
                                editText(event);
                            }
                        });
                    }
                    function render() {
                        var widgetContainer = $element.closest('pr-widget');
                        var body;
                        if (isChunkable()) {
                            widgetContainer.attr(RESIZE_ATTR, 'x');
                            body = getChunk();
                        }
                        else {
                            widgetContainer.removeAttr(RESIZE_ATTR);
                            var scope = $rootScope.$new();
                            scope.widget = widget;
                            body = $("<div id=" + widget._id + "><pr-widget-body></pr-widget-body></div>");
                            $compile(body)(scope);
                        }
                        $element.empty();
                        $element.append(body);
                    }
                    function isChunkable() {
                        return !widgetUtils.isFixedHeightWidget(widget._id);
                    }
                    function getChunk() {
                        return chunker.getChunk(widget, getChunkIndex());
                    }
                    function getChunkIndex() {
                        var _a;
                        // @ts-ignore
                        return (_a = chunk.layout.chunkIndex) !== null && _a !== void 0 ? _a : 0;
                    }
                    eventService.on('editText-' + widget._id + '-' + getChunkIndex() + '.prQuill', showFloatingEditor);
                    // @ts-ignore
                    // @ts-ignore
                    eventService.on('pipedText-' + widget._id + '-set-selection.prQuill', function (ev, index) {
                        $element.dblclick();
                        var editor = getEditor();
                        editor.setSelection(index);
                    });
                    eventService.on('pipedText-' + widget._id + '-focus.prQuill', function () {
                        $element.dblclick();
                    });
                    var $body = $('body');
                    // @ts-ignore
                    function showFloatingEditor(event) {
                        // Add body class to control editor chrome styles
                        $body.addClass(TEXT_EDIT_MODE);
                        // if the widget is chunkable, allow floatingContainer to pull the element from the hidden renderer
                        var elementToAttach = isChunkable() ? null : $element.children().first();
                        floatingContainer.show(widget, getChunkIndex(), event, elementToAttach);
                        var locator = getQuillLocator();
                        var editor = getEditor();
                        var showPipedText = true;
                        var showHyperlinks = true;
                        textEditorMenu.showTextEditorMenu(editor, locator, widget._id, showPipedText, showHyperlinks);
                        // certain clicks should NOT disable the editor
                        $body.on('mousedown.editorStopHide', 'pr-floating-container, pr-widget-toolbar-options, .qmenu', function (e) {
                            e.stopPropagation();
                        });
                        // all other clicks should exit the editor
                        $body.on('mousedown.hideEditor', hideFloatingEditor);
                    }
                    function hideFloatingEditor() {
                        var editor = getEditor();
                        textEditorMenu.hideTextEditorMenu(editor);
                        floatingContainer.hide();
                        // remove class and clean up events
                        $body.removeClass(TEXT_EDIT_MODE);
                        $body.off('mousedown.editorStopHide');
                        $body.off('mousedown.hideEditor');
                    }
                    function getQuillLocator() {
                        var locator = '#' + widget._id + ' .ql-container';
                        return isChunkable() ? ".pr-hidden-widget " + locator : locator;
                    }
                    function getEditor() {
                        var locator = getQuillLocator();
                        return $(locator).data('quillEditor');
                    }
                    // @ts-ignore
                    function editText(event) {
                        // show the floating editor
                        // we manually select it so stop event prop
                        event.stopImmediatePropagation();
                        showFloatingEditor(event);
                        $bodyCtrl === null || $bodyCtrl === void 0 ? void 0 : $bodyCtrl.selectWidget($scope.getSelectionId());
                        $bodyCtrl === null || $bodyCtrl === void 0 ? void 0 : $bodyCtrl.$digest();
                    }
                }
            };
        }
    ]);
})(angular_1.module('qualtrics.pr'));
