"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rsdk_custom_font_service_1 = require("../services/rsdk-fonts/rsdk-custom-font-service");
(function (module) {
    var GLOBAL_OPTIONS = 'globalOptions';
    var DEFAULT_FONT = 'helvetica';
    var DEFAULTS = {
        colorPalette: {
            group: 'Categorical',
            name: 'QDefault',
            startIndex: 0,
            isReversed: false
        },
        scaleColorPalette: {
            group: 'Divergent',
            name: 'GryBlu',
            startIndex: 0,
            isReversed: false
        },
        customScaleColorPalette: {
            colors: ['#a4a4a4', '#d7d7d7', '#007bd0']
        },
        metric: 'choiceCount',
        decimalPlaces: 2,
        weighted: false,
        enableTotals: false,
        useRowTotals: true,
        percentageDenominator: 'choiceTotals',
        showPercentages: false,
        bodyTextFormats: {
            FONT: DEFAULT_FONT,
            SIZE: 10,
            COLOR: '#222222'
        },
        responseCountViewMode: 'NONE',
        tableHeaderTextFormats: {
            FONT: DEFAULT_FONT,
            SIZE: 10,
            COLOR: '#737373',
            BOLD: false
        },
        tableBodyTextFormats: {
            FONT: DEFAULT_FONT,
            SIZE: 10,
            COLOR: '#222222'
        },
        chartTextFormats: {
            FONT: DEFAULT_FONT,
            SIZE: 10,
            COLOR: '#222222'
        },
        filterSummaryTextFormats: {
            FONT: DEFAULT_FONT,
            SIZE: 10,
            COLOR: '#222222',
            ALIGNMENT: 'center'
        },
        textFormats: {
            // Title
            H1: { FONT: DEFAULT_FONT, SIZE: 26, COLOR: '#222222' },
            // Subtitle
            H2: { FONT: DEFAULT_FONT, SIZE: 16, COLOR: '#737373' },
            // Header 1
            H3: { FONT: DEFAULT_FONT, SIZE: 16, COLOR: '#222222' },
            // Header 2
            H4: { FONT: DEFAULT_FONT, SIZE: 14, COLOR: '#222222' },
            // Header 3
            H5: { FONT: DEFAULT_FONT, SIZE: 12, COLOR: '#737373' },
            // Footnote
            footnote: { FONT: DEFAULT_FONT, SIZE: 8, COLOR: '#737373' }
        },
        widgetPadding: '16px',
        displayLogic: {
            enabled: false,
            responseThreshold: 5
        },
        unit: 'inch',
        tableBodyDensity: '8px',
        resultsTableCharacterLimit: {
            enabled: false,
            // default number derived from manual testing
            value: 4000
        }
    };
    var SIMPLE_TABLE = '.simple-table';
    module.factory('pr.globalOptionsService', [
        'prActiveReport',
        'pr.API_ROUTES',
        'prStyleNode',
        'prTextFormatsHelper',
        'rsEnvironment',
        'pr.STYLE_FORMATS',
        '$injector',
        function (activeReport, API_ROUTES, prStyleNode, formatHelper, env, STYLE_FORMATS, $injector) {
            // keep a cached version so we don't do the merge every time
            var properties;
            DEFAULTS.timezone = (env.get('user') || {}).timezone || moment.tz.guess() || 'America/Denver';
            function setDefaultMomentTimezone(timezone) {
                timezone = timezone || getProperty('timezone');
                moment.tz.setDefault(timezone);
            }
            /**
             * https://qualtrics.atlassian.net/browse/REP-6093
             * @todo avoid calling getProperties without a 'prop' argument.
             * This leads to deep copying the entire global options object,
             * which results in a lot of copies if this is called on each
             * angular digest cycle.
             */
            function getProperties(prop) {
                var fetchResponse = function () { return prop ? properties[prop] : properties; };
                if (properties) {
                    return _.cloneDeep(fetchResponse());
                }
                var savedOptions = _.mapValues(activeReport.getMetadata(GLOBAL_OPTIONS), function (value) {
                    // null and empty strings should be overwritten using `DEFAULTS`
                    return (value !== null && value !== '') ? value : undefined;
                });
                properties = _.defaultsDeep({}, savedOptions || {}, DEFAULTS);
                return fetchResponse();
            }
            function getDefaults() {
                return _.cloneDeep(DEFAULTS);
            }
            function setProperties(props) {
                if (props.timezone !== getProperty('timezone')) {
                    setDefaultMomentTimezone(props.timezone);
                }
                properties = _.cloneDeep(props);
                return activeReport.setMetadata(GLOBAL_OPTIONS, props);
            }
            function mergeProperties(obj) {
                properties = _.assign(getProperties(), _.cloneDeep(obj));
                return activeReport.setMetadata(GLOBAL_OPTIONS, properties);
            }
            function getProperty(prop) {
                return getProperties(prop);
            }
            function bind(prop) {
                return _.bind(getProperty, null, prop);
            }
            function applyResponseCountNumberStyles() {
                // response count number defaults based on body text format
                var formatObj = getProperty(STYLE_FORMATS.BODY_TEXT_FORMATS);
                var fillStyle = "fill: " + formatObj.COLOR + ";";
                var formatHelperStyles = formatHelper.getStyles({
                    FONT: formatObj.FONT,
                    SIZE: formatObj.SIZE,
                    COLOR: formatObj.COLOR,
                    BOLD: formatObj.BOLD
                }, true);
                var nodeName = 'pr-response-count-number-styles';
                var css = ' .response-count-value {'
                    + formatHelperStyles
                    + '}'
                    + ' .response-count-value {'
                    + fillStyle
                    + '}';
                prStyleNode.updateCSSNode(nodeName, css);
            }
            function applyResponseCountLabelStyles() {
                // response count label defaults based on body text format
                var formatObj = getProperty(STYLE_FORMATS.BODY_TEXT_FORMATS);
                var fillStyle = "fill: " + formatObj.COLOR + ";";
                var formatHelperStyles = formatHelper.getStyles({
                    FONT: formatObj.FONT,
                    SIZE: formatObj.SIZE,
                    COLOR: formatObj.COLOR,
                    BOLD: formatObj.BOLD
                }, true);
                var nodeName = 'pr-response-count-labels-styles';
                var css = ' .widget-response-count {'
                    + formatHelperStyles
                    + '}'
                    + ' .widget-response-count {'
                    + fillStyle
                    + '}';
                prStyleNode.updateCSSNode(nodeName, css);
            }
            /**
             * Applies chart text styles from the global options modal.
             * @param {*} prefix css class selector that applies to all elements w/ parent element {prefix}
             * @param {*} formatObj global options table body format.
             * @param {*} nodeName class selectors that the styles are applied to
             */
            function applyChartTextStyles(prefix, formatObj, nodeName) {
                prefix = prefix || '';
                formatObj = formatObj || getProperty(STYLE_FORMATS.CHART_TEXT);
                nodeName = nodeName || 'pr-chart-styles';
                var DQ = '.dq';
                var formatHelperStyles = formatHelper.getStyles({
                    FONT: formatObj.FONT,
                    SIZE: formatObj.SIZE
                }, true);
                var fillStyle = "fill: " + formatObj.COLOR + " !important;";
                var css = prefix + " " + DQ + " * {"
                    + formatHelperStyles
                    + '}'
                    + (" " + prefix + " " + DQ + " text {")
                    + fillStyle
                    + '}';
                var rsdkStyles = rsdk_custom_font_service_1.getRsdkChartStyles(formatHelper, formatObj);
                prStyleNode.updateCSSNode(nodeName, css + rsdkStyles);
            }
            /**
             * Applies table header styles from the global options modal.
             * @param {*} prefix css class selector that applies to all elements w/ parent element {prefix}
             * @param {*} formatObj global options table body format.
             * @param {*} nodeName class selectors that the styles are applied to
             */
            function applyTableHeaderTextStyles(prefix, formatObj, nodeName) {
                prefix = prefix || '';
                formatObj = formatObj || getProperty(STYLE_FORMATS.TABLE_HEADER_TEXT);
                nodeName = nodeName || 'pr-table-header-styles';
                var fontHelperStyles = formatHelper.getStyles({
                    FONT: formatObj.FONT,
                    BOLD: formatObj.BOLD
                }, true);
                var colorAndSize = formatHelper.getStyles({
                    SIZE: formatObj.SIZE,
                    COLOR: formatObj.COLOR
                }, true);
                var css = prefix + " " + SIMPLE_TABLE + " th, " + prefix + " " + SIMPLE_TABLE + " th * {"
                    + fontHelperStyles
                    + '}'
                    + (" " + prefix + " " + SIMPLE_TABLE + " th {")
                    + colorAndSize
                    + '}';
                var rsdkStyles = rsdk_custom_font_service_1.getRsdkTableHeaderStyles(formatHelper, formatObj);
                prStyleNode.updateCSSNode(nodeName, css + rsdkStyles);
            }
            /**
             * Applies table body styles from the global options modal.
             * @param {*} prefix css class selector that applies to all elements w/ parent element {prefix}
             * @param {*} formatObj global options table body format.
             * @param {*} nodeName class selectors that the styles are applied to
             */
            function applyTableBodyTextStyles(prefix, formatObj, nodeName) {
                prefix = prefix || '';
                formatObj = formatObj || getProperty(STYLE_FORMATS.TABLE_BODY_TEXT);
                nodeName = nodeName || 'pr-table-body-styles';
                var fontStyles = formatHelper.getStyles({
                    FONT: formatObj.FONT
                }, true);
                var colorAndSize = formatHelper.getStyles({
                    SIZE: formatObj.SIZE,
                    COLOR: formatObj.COLOR
                }, true);
                var css = prefix + " " + SIMPLE_TABLE + " td, " + prefix + " " + SIMPLE_TABLE + " td * {"
                    + fontStyles
                    + '}'
                    + (" " + prefix + " " + SIMPLE_TABLE + " td {")
                    + colorAndSize
                    + '}';
                var rsdkStyles = rsdk_custom_font_service_1.getRsdkTableBodyStyles(formatHelper, formatObj);
                prStyleNode.updateCSSNode(nodeName, css + rsdkStyles);
            }
            function applyScaleColorPaletteColors() {
                // THIS IS KIND OF A HACK: Since the dependency is not in the components directory;
                var scaleColorPaletteService = $injector.get('widget.componentBase.scaleColorPaletteService');
                var scaleColorPalette = getProperty('scaleColorPalette');
                var palette = scaleColorPaletteService.getColorPalette(scaleColorPalette);
                var unfavorable = palette.getNext();
                var neutral = palette.getNext();
                var favorable = palette.getNext();
                var colors = {
                    unfavorable: unfavorable,
                    neutral: neutral,
                    favorable: favorable
                };
                var rsdkStyles = rsdk_custom_font_service_1.getRsdkTableScaleColorStyles(colors);
                prStyleNode.updateCSSNode('', rsdkStyles);
            }
            function applyBodyTextStyles() {
                // response count/number label styles default based on body text styles
                applyResponseCountNumberStyles();
                applyResponseCountLabelStyles();
                var bodyTextStyles = getProperty('bodyTextFormats');
                var textStyles = getProperty('textFormats');
                var formattedBodyStyles = formatHelper.getStyles(bodyTextStyles, true);
                var fontStyles = formatHelper.getStyles({
                    FONT: bodyTextStyles.FONT
                }, true);
                var css = 'pr-main .ql-editor p, pr-main .ql-editor li, pr-main .pr-body-style {'
                    + formattedBodyStyles
                    + '}'
                    + 'pr-main .ql-editor p *, pr-main .ql-editor li *, pr-main .pr-body-style * {'
                    + fontStyles
                    + '}';
                _.forEach(['H1', 'H2', 'H3', 'H4', 'H5', 'footnote'], function (on) {
                    var selector = on === 'footnote' ? '.ql-class-footnote' : on.toLowerCase();
                    var prMainSelector = "pr-main .ql-editor " + selector;
                    var hiddenWidgetSelector = ".pr-hidden-widget .ql-editor " + selector;
                    var format = textStyles[on];
                    css += getCssForSelector(prMainSelector, format, fontStyles);
                    css += getCssForSelector(hiddenWidgetSelector, format, fontStyles);
                });
                prStyleNode.updateCSSNode('pr-text-styles', css);
            }
            function getCssForSelector(selector, format, fontStyles) {
                var formatCss = selector + "{"
                    + formatHelper.getStyles(format, true)
                    + '}';
                var fontCss = selector + " * {"
                    + fontStyles
                    + '}';
                return (formatCss + fontCss);
            }
            function getWidgetPaddingObj(padding) {
                padding = padding || getProperty('widgetPadding');
                var pTop, pRight, pBottom, pLeft;
                pTop = pRight = pBottom = pLeft = 0;
                padding = padding.split(' ');
                if (padding.length === 1) {
                    var singlePadding = parsePaddingString(padding[0]);
                    pTop = singlePadding;
                    pRight = singlePadding;
                    pBottom = singlePadding;
                    pLeft = singlePadding;
                }
                else {
                    pTop = parsePaddingString(padding[0]);
                    pRight = parsePaddingString(padding[1]);
                    pBottom = parsePaddingString(padding[2]);
                    pLeft = parsePaddingString(padding[3]);
                }
                return {
                    top: pTop,
                    right: pRight,
                    bottom: pBottom,
                    left: pLeft
                };
            }
            function parsePaddingString(paddingStr) {
                return parseInt(paddingStr, 10) || 0;
            }
            return {
                getColorPalette: bind('colorPalette'),
                getScaleColorPalette: bind('scaleColorPalette'),
                getCustomScaleColorPalette: bind('customScaleColorPalette'),
                getBodyTextFormats: bind('bodyTextFormats'),
                getTableHeaderTextFormats: bind('tableHeaderTextFormats'),
                getTableBodyTextFormats: bind('tableBodyTextFormats'),
                getResultsTableCharacterLimit: bind('resultsTableCharacterLimit'),
                getChartTextFormats: bind('chartTextFormats'),
                getTextFormats: bind('textFormats'),
                getWidgetPadding: bind('widgetPadding'),
                getWidgetPaddingObj: getWidgetPaddingObj,
                getDecimals: bind('decimalPlaces'),
                getWeightingEnabled: bind('weighted'),
                getEnableTotals: bind('enableTotals'),
                getUseRowTotals: bind('useRowTotals'),
                getPercentageDenominator: bind('percentageDenominator'),
                getShowPercentages: bind('showPercentages'),
                getMetric: bind('metric'),
                getUnit: bind('unit'),
                getTimezone: bind('timezone'),
                getDisplayLogic: bind('displayLogic'),
                getDefaults: getDefaults,
                getOptions: getProperties,
                setOptions: setProperties,
                mergeOptions: mergeProperties,
                applyBodyTextStyles: applyBodyTextStyles,
                applyTableHeaderTextStyles: applyTableHeaderTextStyles,
                applyTableBodyTextStyles: applyTableBodyTextStyles,
                applyChartTextStyles: applyChartTextStyles,
                applyScaleColorPaletteColors: applyScaleColorPaletteColors,
                getTableBodyDensity: bind('tableBodyDensity'),
                setDefaultMomentTimezone: setDefaultMomentTimezone,
                __TESTING__: {
                    getRsdkChartStyles: rsdk_custom_font_service_1.getRsdkChartStyles,
                    getRsdkTableHeaderStyles: rsdk_custom_font_service_1.getRsdkTableHeaderStyles,
                    getRsdkTableBodyStyles: rsdk_custom_font_service_1.getRsdkTableBodyStyles,
                    getRsdkTableScaleColorStyles: rsdk_custom_font_service_1.getRsdkTableScaleColorStyles
                }
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
