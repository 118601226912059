"use strict";
(function (module) {
    'use strict';
    module.controller('prTextStylesModalController', [
        '$scope',
        '$filter',
        'pr.globalOptionsService',
        'pr.userGlobalOptionsService',
        'prTextFormatsHelper',
        'pr.widgetManager',
        'prEventService',
        'PR_TEXT_STYLES_NAMES',
        function ($scope, $filter, globalOptionsService, userGlobalOptionsService, textFormatsHelper, widgetManager, eventService, TEXT_STYLES_NAMES) {
            var FORMAT_KEYS = _.keys(TEXT_STYLES_NAMES);
            var translate = $filter('translate');
            var $ctrl = this;
            $scope.$ctrl = $ctrl;
            // INIT
            this.$onInit = function () {
                this.initStyles = getStyles(globalOptionsService.getBodyTextFormats(), globalOptionsService.getTextFormats());
                this.styles = _.cloneDeep(this.initStyles);
                this.menu = {};
                updateMenu();
            };
            this.globalOptionsOperationItems = [
                {
                    label: translate('GLOBAL_SETTINGS_APPLY'),
                    action: function () {
                        $ctrl.useDefaultReportOptions();
                    }
                }, {
                    label: translate('GLOBAL_SETTINGS_SAVE'),
                    action: function () {
                        $ctrl.saveAsDefaultReportOptions();
                    }
                }, {
                    divider: true
                }, {
                    label: translate('GLOBAL_SETTINGS_USE_QDEFAULT'),
                    action: function () {
                        $ctrl.useQualtricsDefaultReportOptions();
                    }
                }
            ];
            this.onChange = function (key, name, value) {
                this.styles[key][name.toUpperCase()] = value;
            };
            this.useDefaultReportOptions = function () {
                userGlobalOptionsService.getUserDefaultOptions()
                    .then(function (res) {
                    $ctrl.styles = getStyles(res.globalOptions.bodyTextFormats, res.globalOptions.textFormats);
                    updateMenu();
                });
            };
            this.saveAsDefaultReportOptions = function () {
                var newFormats = {
                    bodyTextFormats: this.styles.body,
                    textFormats: _.omit(this.styles, 'body')
                };
                userGlobalOptionsService.setUserDefaultOptions(newFormats);
            };
            this.useQualtricsDefaultReportOptions = function () {
                var defaultReportOptions = globalOptionsService.getDefaults();
                this.styles = getStyles(defaultReportOptions.bodyTextFormats, defaultReportOptions.textFormats);
                updateMenu();
                $scope.$digest();
            };
            this.save = function () {
                if (_.isEqual(this.initStyles, this.styles)) {
                    return;
                }
                var bodyTextFormats = {};
                var textFormats = {};
                // Split out body
                bodyTextFormats = this.styles.body;
                delete this.styles.body;
                textFormats = this.styles;
                globalOptionsService.mergeOptions({
                    bodyTextFormats: bodyTextFormats,
                    textFormats: textFormats
                });
                globalOptionsService.applyBodyTextStyles();
                // trigger rechunk of text widgets
                eventService.trigger('global-text-style-change');
            };
            function updateMenu() {
                _.forEach($ctrl.styles, function (on, key) {
                    $ctrl.menu[key] = {
                        label: TEXT_STYLES_NAMES[key],
                        menu: getOptions(key)
                    };
                });
            }
            function getOptions(key) {
                var options = ['FONT', 'SIZE', 'COLOR', 'BOLD', 'ITALIC', 'UNDERLINE'];
                if (_.contains(['body', 'footnote'], key)) {
                    // remove BOLD, ITALIC, & UNDERLINE
                    options.length = 3;
                }
                return _.map(textFormatsHelper.getSubsetFormatOptions(options), function (option, type) {
                    option.selected = $ctrl.styles[key][type];
                    return option;
                });
            }
            function getStyles(bodyTextFormats, otherTextFormats) {
                var body = _.cloneDeep(bodyTextFormats);
                var other = _.cloneDeep(otherTextFormats);
                // merge body and other together but put body before footnote
                return _.reduce(FORMAT_KEYS, function (ret, on) {
                    ret[on] = on === 'body' ? body : other[on];
                    return ret;
                }, {});
            }
            this.getStyle = function (format) {
                return textFormatsHelper.getStyles(format);
            };
            return this;
        }
    ]);
}(angular.module('qualtrics.pr')));
