"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var object_utils_1 = require("@shared/utils/object-utils");
var filter_utils_1 = require("@shared/utils/filter-utils");
(function (module) {
    'use strict';
    var ReportFilterSetupController = function (widgetManager, activeReport, eventService, prSourceDataService, $http, API_ROUTES, $q, prFieldsets, Filters, SavedFilters, prFilterUtils, $filter, $scope, triggerOverrides, OVERRIDE_EVENTS, metricsService, filterLevels) {
        _.merge(this, _.pick($scope, ['widgetLevel', 'widgetConfig', 'setWidgetFilters']));
        this.widgetManager = widgetManager;
        this.activeReport = activeReport;
        this.eventService = eventService;
        this.sourceDataService = prSourceDataService;
        this.$http = $http;
        this.API_ROUTES = API_ROUTES;
        this.$q = $q;
        this.fieldsets = prFieldsets;
        this.Filters = Filters;
        this.SavedFilters = SavedFilters;
        this.filterUtils = prFilterUtils;
        this.translate = $filter('translate');
        this.triggerOverrides = triggerOverrides;
        this.OVERRIDE_EVENTS = OVERRIDE_EVENTS;
        this.metricsService = metricsService;
        this.filterLevels = filterLevels;
        this.getFilterLabel = function () {
            var sourceIds = this.getSourceIds();
            var filters = this._getFiltersReference() || {};
            var hasValidFilter = _.some(sourceIds, function (sourceId) {
                return !_.isEmpty(filters[sourceId]);
            });
            return this.translate(hasValidFilter ? 'MODIFY_FILTERS' : 'ADD_FILTER');
        };
        this.$onInit = function () {
            this.init();
        };
    };
    ReportFilterSetupController.$inject = ['pr.widgetManager',
        'prActiveReport',
        'prEventService',
        'prSourceDataService',
        '$http',
        'pr.API_ROUTES',
        '$q',
        'prFieldsets',
        'prFilterService',
        'prSavedFiltersService',
        'prFilterUtils',
        '$filter',
        '$scope',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        'prMetricsService',
        'pr.FILTER_LEVELS'
    ];
    ReportFilterSetupController.prototype.init = function () {
        // Iterate all saved filters applied on the report or multiple metrics widgets and
        // grab the latest definition. This might have changed due to saved filters getting updated
        // in other prodcuts.
        var self = this;
        this.displaySpinner = false;
        var appliedFilters = self.getFilters();
        var patchNeeded = false;
        var filtersP = _.map(appliedFilters, function (filter, sourceId) {
            if (filter.filterId) {
                return new self.SavedFilters(sourceId)
                    .getUpdatedDefinition(filter)
                    .then(function (updatedFilters) {
                    if (!_.isEqual(appliedFilters[sourceId], updatedFilters)) {
                        patchNeeded = true;
                        appliedFilters[sourceId] = updatedFilters;
                    }
                });
            }
            else {
                return self.$q.when();
            }
        });
        self.eventService.on('filtersChanged', function (event, updatedFilter) {
            if (!self.widgetLevel && updatedFilter) {
                var surveyId = _.keys(updatedFilter)[0];
                if (!surveyId) {
                    return;
                }
                var filterId = updatedFilter[surveyId].filterId;
                var appliedFilters_1 = self.getFilters() || {};
                if (appliedFilters_1[surveyId] && appliedFilters_1[surveyId].filterId === filterId) {
                    appliedFilters_1[surveyId] = updatedFilter[surveyId];
                    self.setReportLevelFilters(appliedFilters_1);
                }
            }
        });
        return self.$q.all(filtersP)
            .then(function () {
            if (patchNeeded) {
                if (!self.widgetLevel) {
                    return self.activeReport.setMetadata('filters', appliedFilters);
                }
                return self.setWidgetFilters({ filters: appliedFilters });
            }
        });
    };
    ReportFilterSetupController.prototype.setFilters = function (sourceId) {
        var self = this;
        return function (updatedFilters) {
            var appliedFilters = self.getFilters() || {};
            if (object_utils_1.areDefinedValuesEqual(appliedFilters[sourceId], updatedFilters.filters)) {
                return;
            }
            var type = self.widgetLevel ? self.filterLevels.WIDGET : self.filterLevels.REPORT;
            self.metricsService.setFilter(appliedFilters[sourceId], updatedFilters.filters, type);
            if (updatedFilters.filters) {
                appliedFilters[sourceId] = _.cloneDeep(updatedFilters.filters);
            }
            else {
                delete appliedFilters[sourceId];
            }
            if (!self.widgetLevel) {
                // Disables report-level show modal button because quickly
                // opening & closing the button in quick succession causes the
                // open modal save to collide with the close modal save.
                self.displaySpinner = true;
                return self.setReportLevelFilters(appliedFilters).finally(function () {
                    self.displaySpinner = false;
                });
            }
            else {
                self.widgetManager.notifyAllWidgets('filtersChanged', 'widgetLevelFilters', appliedFilters);
                self.eventService.trigger('filtersChanged', appliedFilters);
                self.setWidgetFilters({ filters: appliedFilters });
            }
        };
    };
    ReportFilterSetupController.prototype.setReportLevelFilters = function (appliedFilters) {
        var self = this;
        return self.activeReport.setMetadata('filters', appliedFilters)
            .then(function () {
            self.widgetManager.notifyAllWidgets('filtersChanged', 'reportLevelFilters', appliedFilters);
            self.eventService.trigger('filtersChanged');
            // updated filters will be saved from filters-client to filter service.
            self.eventService.trigger('responseCountChanged', { skipFetchingFilters: true });
        });
    };
    ReportFilterSetupController.prototype.noOp = function () {
        return _.noop;
    };
    ReportFilterSetupController.prototype._getFiltersReference = function () {
        return this.widgetLevel ? this.widgetConfig.filters : this.activeReport.getMetadata('filters');
    };
    ReportFilterSetupController.prototype.getFilters = function () {
        return _.cloneDeep(this._getFiltersReference());
    };
    ReportFilterSetupController.prototype.getSourceIds = function () {
        return this.widgetLevel ? _.uniq(_.map(this.widgetConfig.metrics, 'sourceId')) : this.activeReport.getMetadata('surveyId');
    };
    ReportFilterSetupController.prototype.getSourceFilterMenu = function (sourceId) {
        // have to init and then get the menu
        var self = this;
        return self.fieldsets.getFieldsetBySourceId(sourceId)
            .then(function (fs) {
            var sourceDef = fs.definition;
            sourceDef.fieldSetLabels = fs.labels;
            var sourceFilter = (self.getFilters() || {})[sourceId];
            self.filterUtils.fixDateFilters(sourceFilter);
            filter_utils_1.fixOldPRFilters(sourceFilter);
            return new self.Filters(sourceId, sourceFilter, self.noOp)
                .init()
                .then(function (filterMenu) {
                // Update selected props
                // if there is saved filter and
                // that filter has been applied to the current report or widget
                var filterForSource = (self.getFilters() || {})[sourceId];
                if (filterForSource && filterForSource.filterId) {
                    var itemToUpdate = _.find(filterMenu, function (item) {
                        return _.get(item, 'value.id') === filterForSource.filterId;
                    });
                    if (itemToUpdate) {
                        itemToUpdate.selected = true;
                        itemToUpdate.value.isAppliedFilter = true;
                    }
                }
                return filterMenu;
            });
        });
    };
    ReportFilterSetupController.prototype.openFiltersMenu = function () {
        var self = this;
        if (!self.widgetLevel) {
            // Deselect any widgets selected. To keep savedFilters state clean.
            self.eventService.trigger('deselectWidgets');
        }
        // Grab all of the users survey and map them to the active report's surveys
        // or widget sources when used for multiple metrics widgets filters
        return self.sourceDataService.getUserSources()
            .then(function (sources) {
            var sourceIds = self.getSourceIds();
            // Map all of the reports survey to a filter menu
            // or widget sources when used for multiple metrics widgets filters
            var pageFilterMenu = [{
                    label: self.translate('DATA_SOURCES'),
                    header: true
                }];
            var permissions = {};
            var permissionsP = _.map(sourceIds, function (sourceId) {
                // @todo move this permissions logic to a common service
                // https://qualtrics.atlassian.net/browse/REP-6183
                return self.$http.get(self.API_ROUTES.SURVEYS + sourceId + '/permissions', {
                    cache: true
                }).then(function (response) {
                    var permission = response.data || {};
                    permissions[sourceId] = {
                        readOnly: !permission.useSubgroupAnalysis,
                        viewPersonalData: permission.viewPersonalData
                    };
                    permissions[sourceId] = self.triggerOverrides({
                        event: self.OVERRIDE_EVENTS.FILTER_PERMISSIONS,
                        data: permissions[sourceId],
                        sync: true
                    });
                }).catch(function (err) {
                    console.error('Filter permission check error: ', err);
                    permissions[sourceId] = {
                        readOnly: true,
                        viewPersonalData: false
                    };
                });
            });
            return self.$q.all(permissionsP)
                .then(function () {
                _.forEach(sourceIds, function (sourceId) {
                    var source = _.find(sources, { id: sourceId });
                    if (source) {
                        var menuItem = {
                            label: source && source.name,
                            disabled: permissions[sourceId].readOnly,
                            submenu: {
                                items: function () {
                                    return self.getSourceFilterMenu(sourceId);
                                },
                                action: function ($value) {
                                    // Initialize saved filters with this sourceId
                                    // and apply the selected filter.
                                    if ($value.isAppliedFilter) {
                                        return self.setFilters(sourceId)({});
                                    }
                                    else {
                                        var sourceFilter = (self.getFilters() || {})[sourceId];
                                        self.filterUtils.fixDateFilters(sourceFilter);
                                        filter_utils_1.fixOldPRFilters(sourceFilter);
                                        // setFilters is passed in as a callback so filters-client can set the
                                        // filters on the container.
                                        self.filterService = new self.Filters(sourceId, sourceFilter, self.setFilters(sourceId));
                                        return self.filterService
                                            .init()
                                            .then(function () {
                                            if ($value.menuItemType === 'filter') {
                                                return self.filterService.selectFilterMenuItem($value);
                                            }
                                            else {
                                                return self.filterService.openManageFiltersModal();
                                            }
                                        });
                                    }
                                }
                            }
                        };
                        menuItem.selected = !_.isEmpty((self.getFilters() || {})[sourceId]);
                        pageFilterMenu.push(menuItem);
                    }
                });
                return self.triggerOverrides({
                    event: self.OVERRIDE_EVENTS.PAGE_FILTER_MENU,
                    data: pageFilterMenu
                });
            });
        });
    };
    var template = "\n\t\t<button class=\"filter-button btn btn-sm btn-hover\" ng-class=\"{'disabled': $ctrl.displaySpinner}\" qstrap.menu items=\"$ctrl.openFiltersMenu()\">\n\t\t\t<span qstrap.spin spin-if=\"$ctrl.displaySpinner\" spin-size=\"md\"></span>\n\t\t\t<span class=\"icon icon-filter\"></span>\n\t\t\t<span>{{ $ctrl.getFilterLabel() }}</span>\n\t\t\t<span class=\"icon icon-angle-down-sm\"></span>\n\t\t</button>";
    module.component('prReportFilterSetup', {
        template: template,
        controller: ReportFilterSetupController,
        bindings: {
            widgetConfig: '<',
            widgetLevel: '<',
            setWidgetFilters: '&'
        }
    });
})(angular.module('qualtrics.pr'));
