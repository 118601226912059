"use strict";
/* eslint no-redeclare: "off" */
/* global jQuery, document */
(function ($) {
    var BODY_SELECTOR = 'body', CURSOR_CLASS = 'cursor-grabbing', MOVE_EVENT = 'move', MOVE_START_EVENT = 'moveStart', MOVE_FINISHED_EVENT = 'moveFinished', REPORT_SELECTOR = 'pr-report', EDITING_DISABLED_CLASS = 'editing-disabled';
    $.widget('layout.mover', $.ui.mouse, {
        options: {
            disabled: false,
            moveCapture: $.noop,
            move: $.noop,
            moveFinished: $.noop,
            zoomParent: null,
            keyCapture: false
        },
        _create: function () {
            this._mouseInit();
            this._keyInit();
            // NOTE: we can't use ui.mouseUp since it does fancy things like invoking _mouseStop
            this.element.on('mouseup', this._boundMouseup = $.proxy(this._mouseup, this));
        },
        _keyInit: function () {
            // we allow for some awesome movement if keys are pressed
            if (this.options.keyCapture) {
                $(document).on('keydown', (this._boundKeyPress = $.proxy(this._keyPress, this)));
            }
        },
        _destroy: function () {
            this._mouseDestroy();
            this._keyDestroy();
            this.element.off('mouseup', this._boundMouseup);
        },
        _keyDestroy: function () {
            if (this._boundKeyPress) {
                $(document).off('keydown', this._boundKeyPress);
            }
        },
        _mouseCapture: function (event) {
            // we have to check the capture event first
            var capture = !this.options.disabled && (this.options.moveCapture === $.noop || this.options.moveCapture(event));
            var titleContainer = $(event.target).closest('.pr-inline-quill-header');
            var reportContainer = $(REPORT_SELECTOR);
            if ((titleContainer.length && titleContainer.hasClass('editing')) || reportContainer.hasClass(EDITING_DISABLED_CLASS)) {
                return false;
            }
            if (capture) {
                // add cursor css to the body so it shows the right cursor
                $(BODY_SELECTOR).addClass(CURSOR_CLASS);
            }
            return capture;
        },
        _mouseStart: function (event) {
            this._moveInfo = {
                startX: event.pageX,
                startY: event.pageY,
                zoom: $(this.options.zoomParent).getZoom(),
                event: event
            };
            this._trigger(MOVE_START_EVENT, event, this._ui(event));
        },
        _mouseDrag: function (event) {
            this._trigger(MOVE_EVENT, event, this._ui(event));
        },
        _mouseStop: function (event) {
            this._trigger(MOVE_FINISHED_EVENT, event, this._ui(event));
            delete this._moveInfo;
        },
        _mouseup: function () {
            $(BODY_SELECTOR).removeClass(CURSOR_CLASS);
        },
        _keyPress: function (event) {
            if ($(event.target).isTextArea()) {
                return;
            }
            var keys = {
                37: [-1, 0],
                38: [0, -1],
                39: [1, 0],
                40: [0, 1] // down
            };
            if (keys[event.which]) {
                event.preventDefault();
                event.pageX = event.pageY = 0;
                this._mouseStart(event);
                // update with the right direction
                var delta = 10 * (event.shiftKey ? 5 : 1);
                event.pageX = keys[event.which][0] * delta; // move a signifiant amount
                event.pageY = keys[event.which][1] * delta;
                this._mouseDrag(event);
                this._mouseStop(event);
            }
        },
        _ui: function (event) {
            var info = this._moveInfo, zoom = info.zoom;
            return {
                dx: (event.pageX - info.startX) / zoom,
                dy: (event.pageY - info.startY) / zoom
            };
        },
        updateMoveInfo: function () {
            if (this._moveInfo) {
                this._mouseStart(this._moveInfo.event);
            }
        }
    });
})(jQuery);
