"use strict";
(function (module) {
    'use strict';
    /*
     * Responsible for determining widget chunks, both the data model, and the view.
     * The data model is partially driven by the view
     */
    module.factory('prWidgetChunkerFactory', [
        'prAutoHeightHelper',
        'prTextChunker',
        'prTableChunker',
        function (autoHeight, TextChunker, TableChunker) {
            // an obect with an ID to the chunker classes
            // TODO: figure out when we can clear these
            var chunkerRegistry = {};
            function getChunker(widget) {
                if (!chunkerRegistry[widget._id]) {
                    initChunker(widget);
                }
                return chunkerRegistry[widget._id];
            }
            function initChunker(widget) {
                var id = widget._id;
                if (!id) {
                    return null;
                }
                if (!chunkerRegistry[id]) {
                    var Chunker = autoHeight.isTable(widget._type) ? TableChunker : TextChunker;
                    chunkerRegistry[id] = new Chunker(widget);
                }
                return chunkerRegistry[id];
            }
            function getChunk(widget, chunkIndex) {
                return getChunker(widget).getChunk(chunkIndex);
            }
            function getChunkTop(widget, chunkIndex) {
                return getChunker(widget).getChunkTop(chunkIndex);
            }
            // only update if the chunker exists
            function updateChunks(widget) {
                if (!chunkerRegistry[widget._id]) {
                    return;
                }
                getChunker(widget).updateChunks();
            }
            function removeChunker(widget) {
                if (chunkerRegistry[widget._id]) {
                    getChunker(widget).destruct();
                    delete chunkerRegistry[widget._id];
                }
            }
            return {
                getChunk: getChunk,
                getChunkTop: getChunkTop,
                updateChunks: updateChunks,
                removeChunker: removeChunker
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
