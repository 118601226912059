"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("@shared/types/environment");
var smart_text_1 = require("@shared/types/smart-text");
require("@services/custom-metrics/custom-metrics-service");
var default_decimal_places_1 = require("@clientConstants/default-decimal-places");
(function (module) {
    'use strict';
    module.factory('prPipedTextDynamicValueService', [
        '$filter',
        '$q',
        'rsEnvironment',
        'prPipedTextDateService',
        'prPipedTextTooltipService',
        'prActiveReport',
        'prFieldsets',
        'prFields',
        'prSourceDataService',
        'pr.dataRequestService',
        'pr.bulkFieldService',
        'pr.personService',
        'prSurveyPipedTextMap',
        'pr.filters',
        'pr.orgHierarchyService',
        'pr.widgetManager',
        'prDynamicTranslationsHelperUtils',
        'prThreesixtyRelationshipsService',
        'pr.globalOptionsService',
        'pr.customMetricsService',
        'pr.navigationService',
        function ($filter, $q, env, PipedTextDate, Tooltip, prActiveReport, prFieldsets, prFields, prSourceDataService, dataRequestService, bulkFieldService, personService, surveyPipedTextMap, prFilters, orgHierarchyService, widgetManager, dynamicTranslationHelperUtils, prThreesixtyRelationshipsService, globalOptionsService, customMetricsService, navigationService) {
            function PipedTextDynamicValue(widgetId) {
                this.widgetId = widgetId;
                this.tooltip = new Tooltip();
                this.fieldsets = prFieldsets;
                this.date = new PipedTextDate();
                return this;
            }
            PipedTextDynamicValue.translate = $filter('translate');
            PipedTextDynamicValue.isDynamicTranslationEnabled = dynamicTranslationHelperUtils.isDynamicTranslationsSupported();
            PipedTextDynamicValue.PIPED_TEXT_ID_PREFIX = 'piped-text-';
            PipedTextDynamicValue.LAST_RESPONSE_DATE_OPTIONS = {
                'fields': [
                    '_recordedDate'
                ],
                'sort': {
                    'field': '_recordedDate',
                    'order': 'desc'
                },
                'offset': 0,
                'limit': 1
            };
            PipedTextDynamicValue.TOTAL_RESPONSES_OPTIONS = {
                metrics: [{
                        type: 'count',
                        fieldId: '_recordId'
                    }],
                filters: []
            };
            var proto = PipedTextDynamicValue.prototype;
            var translate = $filter('translate');
            /**
             * @param {insert: {
             * 			piped-text: {
             * 				ptAttrs: {
             * 					attrName: string, - The plaintext piped text attribute name
             * 					innerText: string, - The text displayed by the piped text
             * 					ptId: string, - Piped text id
             * 					tooltipContent: string, - content of hoverable tooltip
             * 					type: string, - the type of piped text
             * 				}
             * 			 }
             * 		    }
             * 		    resolved: boolean - set to false when an error occurs resolving piped text values
             * 		   } op
             *
             * Custom metric piped text can also be inserted from the custom-metrics-modal-controller.
             * These piped texts are not inserted via the rich text editor and are formatted differently
             * with only the ptAttrs property and the boolean isCustomMetricPipedText on the object.
             */
            var getPipedTextAttributes = function (op) {
                if (_.get(op, 'isCustomMetricPipedText', false)) {
                    return op.ptAttrs;
                }
                return _.get(op, 'insert.piped-text.ptAttrs', {});
            };
            var handleError = function (e, op) {
                console.error(e);
                op.resolved = false;
                return op;
            };
            proto.getRandomId = function () {
                return PipedTextDynamicValue.PIPED_TEXT_ID_PREFIX + Math.random().toString(36).substr(2, 9);
            };
            proto.getReportLanguage = function () {
                if (PipedTextDynamicValue.isDynamicTranslationEnabled) {
                    return prActiveReport.getLanguageCode();
                }
            };
            proto.getData = function (sourceId, reqType, dataOptions, resProp) {
                var _this = this;
                if (dataOptions === void 0) { dataOptions = {}; }
                var dataConfig;
                return widgetManager.getWidgetDataConfig(this.widgetId).catch(function (error) {
                    // suppress the error so that the piped text doesn't completely break
                    console.error("Failed to fetch dataConfig for " + _this.widgetId, error);
                }).then(function (response) {
                    dataConfig = response;
                    var widgetFilters = _.get(dataConfig, 'filters');
                    // Get widget-level and report-level filters (if any)
                    return prFilters.prepRequestFiltersAsync(widgetFilters, sourceId);
                }).then(function (filters) {
                    dataOptions.filters = filters;
                    if (dataConfig) {
                        // Also, filter on the fields used in the widget (if any)
                        var isPresentFilter = prFilters.getIsPresentFilter(sourceId, dataConfig);
                        dataOptions.filters.children.push(isPresentFilter);
                    }
                    return dataRequestService.post(dataOptions, reqType, {
                        sourceId: sourceId,
                        widgetId: _this.widgetId,
                        useCache: true
                    });
                }).then(function (res) {
                    return _.get(res, 'data.data.0.' + resProp, '');
                });
            };
            proto.fetchDate = function (op, source) {
                var _this = this;
                var ptAttrs = getPipedTextAttributes(op);
                if (ptAttrs.attrName === 'currentDate') {
                    ptAttrs.innerText = this.date.getCurrentDate(ptAttrs);
                    ptAttrs.tooltipContent = this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                    return $q.when(op);
                }
                else {
                    return this.getData(ptAttrs.sourceId, 'records', PipedTextDynamicValue.LAST_RESPONSE_DATE_OPTIONS, '_recordedDate')
                        .then(function (lastResponseDate) {
                        ptAttrs.innerText = lastResponseDate;
                        ptAttrs.innerText = _this.date.updateDateFormat(ptAttrs);
                        ptAttrs.sourceName = source && source.name ||
                            PipedTextDynamicValue.translate('PIPED_TEXT.UNKNOWN');
                        ptAttrs.tooltipContent = _this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                        return op;
                    }).catch(function (e) {
                        return handleError(e, op);
                    });
                }
            };
            proto.fetchActiveReportMeta = function (op) {
                var ptAttrs = getPipedTextAttributes(op);
                ptAttrs.innerText = prActiveReport.getDescription();
                ptAttrs.tooltipContent = this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                return $q.when(op);
            };
            proto.fetchSourceEmbeddedData = function (op, source) {
                var _this = this;
                var ptAttrs = getPipedTextAttributes(op);
                return this.fieldsets.getFieldsetBySourceId(ptAttrs.sourceId, this.getReportLanguage())
                    .then(function (fs) {
                    var fsView = fs.definition.fieldSetView;
                    var edField = _.find(fsView, function (field) {
                        return field.fieldId === ptAttrs.fieldId;
                    });
                    ptAttrs.innerText = edField && edField.description ||
                        PipedTextDynamicValue.translate('PIPED_TEXT.UNKNOWN');
                    ptAttrs.sourceName = source && source.name ||
                        PipedTextDynamicValue.translate('PIPED_TEXT.UNKNOWN');
                    ptAttrs.edField = edField.name || edField.description ||
                        PipedTextDynamicValue.translate('PIPED_TEXT.UNKNOWN');
                    ptAttrs.tooltipContent = _this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                    return op;
                })
                    .catch(function (e) {
                    return handleError(e, op);
                });
            };
            proto.fetchSourceMeta = function (op, source) {
                var _this = this;
                var ptAttrs = getPipedTextAttributes(op);
                ptAttrs.sourceName = source && source.name ||
                    PipedTextDynamicValue.translate('PIPED_TEXT.UNKNOWN');
                if (ptAttrs.attrName === 'sourceName') {
                    var language = this.getReportLanguage();
                    ptAttrs.innerText = _.get(source, ['localizedNames', language]) || ptAttrs.sourceName;
                    ptAttrs.tooltipContent = this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                    return $q.when(op);
                }
                else if (ptAttrs.attrName === 'sourceStatus') {
                    ptAttrs.innerText = (source && source.status) ?
                        PipedTextDynamicValue.translate('PIPED_TEXT.SOURCE_STATUS_' + _.toUpper(source.status)) :
                        PipedTextDynamicValue.translate('PIPED_TEXT.UNKNOWN');
                    ptAttrs.tooltipContent = this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                    return $q.when(op);
                }
                else if (ptAttrs.attrName === 'sourceTotalResponses') {
                    return this.getData(ptAttrs.sourceId, 'aggregate', PipedTextDynamicValue.TOTAL_RESPONSES_OPTIONS, 'value')
                        .then(function (metricValue) {
                        ptAttrs.innerText = metricValue || 0;
                        ptAttrs.tooltipContent = _this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                        return op;
                    }).catch(function (e) {
                        return handleError(e, op);
                    });
                }
                else {
                    op.resolved = false;
                    return $q.when(op);
                }
            };
            proto.fetchSourceQuestionMeta = function (op, source) {
                var _this = this;
                var ptAttrs = getPipedTextAttributes(op);
                return this.fieldsets.getFieldsetBySourceId(ptAttrs.sourceId, this.getReportLanguage())
                    .then(function (fs) {
                    var fields = fs.definition.fieldSetView;
                    var qField = _.find(fields, function (field) {
                        return prFields.getFieldIdForPipedText(field) === ptAttrs.fieldId;
                    });
                    if (!qField) {
                        // Text field names are present only in the fields property.
                        qField = _.find(fs.definition.fields, function (field, key) {
                            return key === ptAttrs.fieldId;
                        });
                    }
                    ptAttrs.innerText = (qField.meta || {}).description || qField.description ||
                        PipedTextDynamicValue.translate('PIPED_TEXT.UNKNOWN');
                    ptAttrs.sourceName = source && source.name ||
                        PipedTextDynamicValue.translate('PIPED_TEXT.UNKNOWN');
                    ptAttrs.qid = qField.name || PipedTextDynamicValue.translate('PIPED_TEXT.UNKNOWN');
                    ptAttrs.tooltipContent = _this.tooltip.getToopltipContent(ptAttrs) ||
                        ptAttrs.innerText;
                    return op;
                }).catch(function (e) {
                    return handleError(e, op);
                });
            };
            proto.fetchSourceAggregateData = function (op, source) {
                var _this = this;
                var ptAttrs = getPipedTextAttributes(op);
                var options = {
                    metrics: [{
                            type: ptAttrs.attrName,
                            fieldId: ptAttrs.fieldId
                        }],
                    filters: []
                };
                return this.getData(ptAttrs.sourceId, 'aggregate', options, 'value')
                    .then(function (metricValue) {
                    metricValue = metricValue || 0;
                    var isFloat = metricValue % 1;
                    if (isFloat && !ptAttrs.doNotConvertFloat) {
                        var globalDecimalPlaces = globalOptionsService.getOptions().decimalPlaces;
                        var decimalPlaces = _.isNumber(globalDecimalPlaces) ? globalDecimalPlaces : default_decimal_places_1.DEFAULT_DECIMAL_PLACES;
                        metricValue = $filter('number')(metricValue, decimalPlaces);
                    }
                    return _this.fieldsets.getFieldsetBySourceId(ptAttrs.sourceId, _this.getReportLanguage())
                        .then(function (fs) {
                        var fields = fs.definition.fields;
                        var qField = _.find(fields, function (field, key) {
                            return key === ptAttrs.fieldId;
                        });
                        ptAttrs.innerText = metricValue || 0;
                        ptAttrs.sourceName = source && source.name
                            || PipedTextDynamicValue.translate('PIPED_TEXT.UNKNOWN');
                        ptAttrs.qid = qField.name || PipedTextDynamicValue.translate('PIPED_TEXT.UNKNOWN');
                        ptAttrs.tooltipContent = _this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                        return op;
                    });
                }).catch(function (e) {
                    return handleError(e, op);
                });
            };
            proto.getFallbackText = function (ptAttrs) {
                if (navigationService.isReadOnly) {
                    return '';
                }
                if (ptAttrs.type === 'subjectMetadata') {
                    return "[Field-Subject" + ptAttrs.attrName + "]";
                }
                var pipedTextMap = surveyPipedTextMap.get();
                var target = _.pick(ptAttrs, ['attrName', 'type']);
                var typeOnly = _.pick(ptAttrs, 'type');
                var tag = _.findKey(pipedTextMap, target) || _.findKey(pipedTextMap, function (o) { return _.isEqual(o, typeOnly); });
                if (tag) {
                    return tag;
                }
                return '[' + PipedTextDynamicValue.translate('NOT_APPLICABLE') + ']';
            };
            proto.fetchSubjectInfo = function (op) {
                var _this = this;
                var ptAttrs = getPipedTextAttributes(op);
                var personId = bulkFieldService.getConfig('value');
                return personService.getPersonInfo(personId).then(function (personInfo) {
                    if (personInfo === void 0) { personInfo = {}; }
                    var attrValue = personInfo[ptAttrs.attrName];
                    if (ptAttrs.attrName === 'fullName') {
                        var firstName = personInfo.firstName;
                        var lastName = personInfo.lastName;
                        if (!_.isUndefined(firstName) && !_.isUndefined(lastName)) {
                            attrValue = firstName + " " + lastName;
                        }
                    }
                    ptAttrs.innerText = attrValue || _this.getFallbackText(ptAttrs);
                    ptAttrs.tooltipContent = _this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                    return op;
                }).catch(function (e) {
                    return handleError(e, op);
                });
            };
            proto.fetchSubjectMetadata = function (op) {
                var _this = this;
                var ptAttrs = getPipedTextAttributes(op);
                var personId = bulkFieldService.getConfig('value');
                return personService.getPersonMetadata(personId).then(function (info) {
                    var attrValue = info[ptAttrs.attrName];
                    ptAttrs.innerText = attrValue || _this.getFallbackText(ptAttrs);
                    ptAttrs.tooltipContent = _this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                    return op;
                }).catch(function (e) {
                    return handleError(e, op);
                });
            };
            proto.fetchSubjectsEvaluators = function (op) {
                var _this = this;
                var ptAttrs = getPipedTextAttributes(op);
                var subjectsEvaluatorFallbackText = this.getFallbackText(ptAttrs);
                var relationshipId = ptAttrs.attrName;
                var personId = bulkFieldService.getConfig('value');
                return prThreesixtyRelationshipsService.getSubjectsInvitedEvaluators(personId, relationshipId).then(function (evaluators) {
                    return evaluators.map(function (evaluator) { return evaluator.evaluatorPersonId; });
                }).then(personService.getPersons).then(function (persons) {
                    return _.sortBy(persons, ['firstName'])
                        .map(function (person) { return person.firstName + " " + person.lastName; })
                        .join(', ');
                }).then(function (sortedPersons) {
                    ptAttrs.innerText = sortedPersons || subjectsEvaluatorFallbackText;
                    ptAttrs.tooltipContent = _this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                    return op;
                }).catch(function (e) {
                    return handleError(e, op);
                });
            };
            proto.fetchSubjectSmartText = function (op) {
                var _this = this;
                var ptAttrs = getPipedTextAttributes(op);
                var personId = bulkFieldService.getConfig('value');
                var relationships = env.get(environment_1.ENV_VARS.RELATIONSHIPS_BETWEEN_PERSONS);
                var relationshipIsSelf = _.some(relationships, { name: 'Self' });
                if (relationshipIsSelf) {
                    switch (ptAttrs.attrName) {
                        case smart_text_1.SMART_TEXT.FIRST_PERSON_POSSESSIVE:
                            ptAttrs.innerText = PipedTextDynamicValue.translate('PIPED_TEXT.MY');
                            break;
                        case smart_text_1.SMART_TEXT.FIRST_PERSON_REFLEXIVE:
                            ptAttrs.innerText = PipedTextDynamicValue.translate('PIPED_TEXT.MYSELF');
                            break;
                        case smart_text_1.SMART_TEXT.SECOND_PERSON_POSSESSIVE:
                            ptAttrs.innerText = PipedTextDynamicValue.translate('PIPED_TEXT.YOUR');
                            break;
                        case smart_text_1.SMART_TEXT.SECOND_PERSON_REFLEXIVE:
                            ptAttrs.innerText = PipedTextDynamicValue.translate('PIPED_TEXT.YOURSELF');
                            break;
                    }
                    ptAttrs.tooltipContent = this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                    return $q.when(op);
                }
                else {
                    return personService.getPersonInfo(personId).then(function (personInfo) {
                        if (personInfo === void 0) { personInfo = {}; }
                        var firstName = personInfo.firstName, lastName = personInfo.lastName;
                        if (!_.isUndefined(firstName) && !_.isUndefined(lastName)) {
                            var fullName = firstName + " " + lastName;
                            if (ptAttrs.attrName === smart_text_1.SMART_TEXT.FIRST_PERSON_POSSESSIVE
                                || ptAttrs.attrName === smart_text_1.SMART_TEXT.SECOND_PERSON_POSSESSIVE) {
                                ptAttrs.innerText = PipedTextDynamicValue.translate('PIPED_TEXT.FULL_NAME_POSSESSIVE', { fullName: fullName });
                            }
                            else {
                                ptAttrs.innerText = fullName;
                            }
                        }
                        else {
                            ptAttrs.innerText = _this.getFallbackText(ptAttrs);
                        }
                        ptAttrs.tooltipContent = _this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                        return op;
                    }).catch(function (e) {
                        return handleError(e, op);
                    });
                }
            };
            proto.fetchOrgHierarchyData = function (op) {
                var _this = this;
                /**
                 * @param  {{orgHierarchy: { name: string }}[]} orgUnits
                 * @return {string}
                 */
                var getOrgHierarchyName = function (orgUnits) {
                    var placeholder = "{" + translate('ORG_HIERARCHY_NAME') + "}";
                    return _.get(orgUnits, '0.orgHierarchy.name', placeholder);
                };
                /**
                 * @param  {{ name: string }[]} orgUnits
                 * @return {string}
                 */
                var getOrgUnitNames = function (orgUnits) {
                    var placeholder = "{" + translate('ORG_HIERARCHY_UNIT') + "}";
                    return _.map(orgUnits, 'name').join(', ') || placeholder;
                };
                return $q.resolve().then(function () {
                    var orgUnitIds = orgHierarchyService.getOrgUnitIds();
                    if (orgUnitIds && orgUnitIds.length) {
                        return orgHierarchyService.getOrgUnits(orgUnitIds);
                    }
                }).then(function (orgUnits) {
                    var orgHierarchyName = getOrgHierarchyName(orgUnits);
                    var orgUnitNames = getOrgUnitNames(orgUnits);
                    var ptAttrs = getPipedTextAttributes(op);
                    switch (ptAttrs.attrName) {
                        case 'orgHierarchyName':
                            ptAttrs.innerText = orgHierarchyName;
                            break;
                        case 'orgHierarchyUnit':
                            ptAttrs.innerText = orgUnitNames;
                            break;
                        default:
                            ptAttrs.innerText = orgHierarchyName + ": " + orgUnitNames;
                    }
                    ptAttrs.tooltipContent = _this.tooltip.getToopltipContent(ptAttrs) || ptAttrs.innerText;
                    return op;
                }).catch(function (e) {
                    return handleError(e, op);
                });
            };
            /**
             * Fetch a custom metric value and call this service to
             * resolve individual piped text data requests.
             * @param {object} op custom metric piped text operation
             * @returns resolved custom metric piped text element
             */
            proto.fetchCustomMetric = function (op) {
                var _this = this;
                var ptAttrs = getPipedTextAttributes(op);
                var customMetricId = ptAttrs.attrName;
                var customMetric = customMetricsService.getCustomMetric(customMetricId);
                var equation = customMetric.equation;
                var ops = customMetricsService.getPipedTextAttributes(customMetric);
                ops.forEach(function (op) {
                    // Custom Metrics run computations on the values so they cannot
                    // be localized numbers. The library the errors for comma decimals.
                    if (op.ptAttrs) {
                        op.ptAttrs.doNotConvertFloat = true;
                    }
                });
                return $q.resolve().then(function () {
                    return _this.fetchPipedTextValue(ops);
                }).then(function (result) {
                    var resolvedValue = customMetricsService.resolveEquation(customMetric, result);
                    ptAttrs.innerText = resolvedValue;
                    ptAttrs.tooltipContent = equation;
                    return op;
                }).catch(function (error) {
                    return handleError(error, op);
                });
            };
            proto.fnMapping = {
                'date': proto.fetchDate,
                'activeReportMeta': proto.fetchActiveReportMeta,
                'customMetric': proto.fetchCustomMetric,
                'sourceEmbeddedData': proto.fetchSourceEmbeddedData,
                'sourceMeta': proto.fetchSourceMeta,
                'sourceQuestionMeta': proto.fetchSourceQuestionMeta,
                'sourceAggregateData': proto.fetchSourceAggregateData,
                'subjectInfo': proto.fetchSubjectInfo,
                'subjectMetadata': proto.fetchSubjectMetadata,
                'subjectsEvaluators': proto.fetchSubjectsEvaluators,
                'subjectSmartText': proto.fetchSubjectSmartText,
                'orgHierarchy': proto.fetchOrgHierarchyData
            };
            proto.fetchPipedTextValue = function (ops) {
                var _this = this;
                var sourceIds = [];
                _.forEach(ops, function (op) {
                    var ptAttrs = getPipedTextAttributes(op);
                    if (ptAttrs.sourceId) {
                        sourceIds.push(ptAttrs.sourceId);
                    }
                });
                var getSourcesPromise = sourceIds.length > 0 ? prSourceDataService.getUserSources(sourceIds) : $q.when([]);
                return getSourcesPromise.then(function (sources) {
                    return $q.all(_.map(ops, function (op) {
                        var ptAttrs = getPipedTextAttributes(op);
                        if (_.isEmpty(ptAttrs)) {
                            return $q.when(op);
                        }
                        var source = _.find(sources, { 'id': ptAttrs.sourceId });
                        op.resolved = true;
                        ptAttrs.ptId = ptAttrs.ptId || _this.getRandomId();
                        if (proto.fnMapping[ptAttrs.type]) {
                            return proto.fnMapping[ptAttrs.type].call(_this, op, source);
                        }
                        else {
                            op.resolved = false;
                            return $q.when(op);
                        }
                    }));
                });
            };
            return PipedTextDynamicValue;
        }
    ]);
}(angular.module('qualtrics.pr')));
