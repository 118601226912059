"use strict";
(function (module) {
    'use strict';
    var FOLDER_INDENTATION = 12;
    var DATASET_FOLDER = 'Datasets';
    var INDENTATION_SPACE = 1;
    module.factory('pr.folderService', [
        'surveyFolderService',
        'prSourceDataService',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        function (surveyFolderService, sourceData, triggerOverrides, OVERRIDE_EVENTS) {
            // @ts-ignore
            var cache;
            function getFolderData() {
                // @ts-ignore
                if (!cache) {
                    // @ts-ignore
                    cache = surveyFolderService.getSurveySelectorFolderDefinition().then(function (surveyData) {
                        // @ts-ignore
                        return getDatasetSelectorFolderDefinition().then(function (datasetData) {
                            surveyData.push(datasetData);
                            return surveyData;
                        });
                    });
                }
                // @ts-ignore
                return cache;
            }
            function getDatasetSelectorFolderDefinition() {
                // @ts-ignore
                return sourceData.getUserSources().then(function (sources) {
                    var datasetFolder = {
                        id: 'DATASET_FOLDER_ID',
                        name: DATASET_FOLDER,
                        itemType: 'folder',
                        children: []
                    };
                    _.forEach(sources, function (source, id) {
                        var _a;
                        var isCanonicalFieldset = !!((_a = source.contextMeta) === null || _a === void 0 ? void 0 : _a.sourceSurveyId);
                        if (sourceData.getSourceType(id) === sourceData.sourceTypes.FIELDSET && !isCanonicalFieldset) {
                            // @ts-ignore
                            datasetFolder.children.push(source);
                        }
                    });
                    return datasetFolder;
                });
            }
            /* getFolderMenu
             *
             * @param {string} optSelectedId the curently selected survey
             * @param {array} optSourceIds a list of sourceIds to include in the menu, all others will be ignored
             * @returns {array} qstrap menu representation for folders
             */
            // @ts-ignore
            // @ts-ignore
            // @ts-ignore
            function getFolderMenu(optSelectedId, optSourceIds, optCallback) {
                // @ts-ignore
                return getFolderData().then(function (data) {
                    // @ts-ignore
                    var menu = [];
                    _.forEach(data, function evalFolder(folder) {
                        if (!folder.indentationLevel) {
                            folder.indentationLevel = 0;
                        }
                        var currentMenuLevel = menu.length;
                        var submenuItems = _.compact(_.map(folder.children, function (item) {
                            if (item.itemType === 'folder') {
                                item.indentationLevel = folder.indentationLevel + INDENTATION_SPACE;
                                return evalFolder(item);
                            }
                            if (item.itemType === 'project' && _.get(item, 'children[0].contentType') === 'survey') {
                                item = item.children[0];
                            }
                            if (optSourceIds && !_.includes(optSourceIds, item.id)) {
                                return;
                            }
                            if (optCallback) {
                                return optCallback(item.id, item);
                            }
                            return {
                                label: item.name,
                                value: item.id
                            };
                        }));
                        if (submenuItems.length) {
                            // @ts-ignore
                            menu.splice(currentMenuLevel, 0, {
                                label: $(''
                                    + '<span class="icon icon-folder-wire folder-menu-label"></span>'
                                    + '<span>' + _.escape(folder.name) + '</span>')
                                    .css('margin-left', FOLDER_INDENTATION * (folder.indentationLevel)),
                                submenu: {
                                    items: submenuItems
                                }
                            });
                        }
                    });
                    return triggerOverrides({
                        event: OVERRIDE_EVENTS.FOLDER_MENU,
                        // @ts-ignore
                        data: menu
                    });
                });
            }
            return {
                getFolderMenu: getFolderMenu
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
