"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var angular = __importStar(require("angular"));
var ResponseCountConverter = /** @class */ (function () {
    function ResponseCountConverter(editablePptxsUtils, pageRelativeCoordinates) {
        this.editablePptxsUtils = editablePptxsUtils;
        this.pageRelativeCoordinates = pageRelativeCoordinates;
    }
    ResponseCountConverter.prototype.getEditableResponseTotals = function (widgetElement, pageElement, meta) {
        var responseCountElement = widgetElement.querySelector('.widget-response-count .widget-response-count');
        var responseCountText = responseCountElement === null || responseCountElement === void 0 ? void 0 : responseCountElement.innerText;
        if (responseCountText) {
            // @ts-ignore
            var coordinates = this.pageRelativeCoordinates.getCoordinates(pageElement, responseCountElement);
            // @ts-ignore
            var computedStyle = window.getComputedStyle(responseCountElement);
            var lines = [{
                    text: responseCountText || '',
                    options: {
                        font_size: this.editablePptxsUtils.convertFontSize(computedStyle),
                        font_face: this.editablePptxsUtils.convertFontFace(computedStyle),
                        color: this.editablePptxsUtils.convertFontColor(computedStyle),
                        // @ts-ignore
                        bold: this.editablePptxsUtils.isBold(responseCountElement, computedStyle),
                        // @ts-ignore
                        italic: this.editablePptxsUtils.isItalics(responseCountElement, computedStyle),
                        // @ts-ignore
                        underline: this.editablePptxsUtils.isUnderlined(responseCountElement, computedStyle),
                        align: this.editablePptxsUtils.getTextAlign(computedStyle)
                    }
                }];
            return [{
                    type: "text" /* Text */,
                    widgetOptions: {
                        lines: lines,
                        coordinates: coordinates
                    },
                    meta: meta
                }];
        }
        return [];
    };
    ResponseCountConverter.$inject = [
        'prEditablePptxsUtils',
        'prPageRelativeCoordinates'
    ];
    return ResponseCountConverter;
}());
(function (angularModule) {
    angularModule.service('prPptxResponseCountConverter', ResponseCountConverter);
})(angular.module('qualtrics.pr'));
