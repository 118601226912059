"use strict";
(function (module) {
    'use strict';
    module.factory('pr.bulkFieldService', [
        '$q',
        '$http',
        'prEventService',
        'pr.API_ROUTES',
        'prActiveReport',
        'pr.widgetManager',
        'pr.navigationService',
        '$routeParams',
        function ($q, $http, eventService, API_ROUTES, activeReport, widgetManager, navigationService, $routeParams) {
            /**
             * Gets the bulk field options object from the context meta
             * @return {object} bulk field options
             */
            function getConfig(property) {
                var options = _.cloneDeep(activeReport.getMetadata('bulkFieldOptions')) || {};
                if (options.sourceId && options.fieldId &&
                    (navigationService.isExport || navigationService.isPreview) &&
                    $routeParams.bulkFieldValue) {
                    // in case the bulk field value is set in the url parameters
                    options.value = $routeParams.bulkFieldValue;
                }
                return _.isString(property) ? (options || {})[property] : options;
            }
            /**
             * Sets the new bulk field value on the context meta
             * @param {string} value
             * @returns Promise<void>
             */
            function setValue(value) {
                var bulkFieldOptions = getConfig() || {};
                if (_.isUndefined(value) || !bulkFieldOptions.fieldId) {
                    console.error('Failed to set bulk field value', bulkFieldOptions, value);
                    return $q.when();
                }
                bulkFieldOptions.value = value;
                // await so we don't run into container and widget save conflict errors
                return activeReport.setMetadata('bulkFieldOptions', bulkFieldOptions).then(function () {
                    widgetManager.notifyAllWidgets('filtersChanged');
                    eventService.trigger('filtersChanged');
                    eventService.trigger('responseCountChanged');
                    eventService.trigger('ParticipantChange');
                });
            }
            var bulkFieldDomain;
            var labelMap = {};
            /**
             * Gets the list of possible values that it is legal to set
             * contextMeta.bulkFieldOptions.value to
             *
             * @return {Promise<*>} - list of all unique possible responses to the field
             */
            function getDomain() {
                var bulkFieldOptions = getConfig() || {};
                var sourceId = bulkFieldOptions.sourceId;
                var fieldId = bulkFieldOptions.fieldId;
                var labelFieldId = bulkFieldOptions.labelFieldId;
                if (!bulkFieldDomain && sourceId && fieldId) {
                    return $http.get(API_ROUTES.BULK_FIELD + sourceId, {
                        params: {
                            fieldId: fieldId,
                            labelFieldId: labelFieldId
                        }
                    }).then(function (response) {
                        bulkFieldDomain = response.data;
                        _.forEach(bulkFieldDomain, function (entry) {
                            if (entry && entry.value && entry.label) {
                                labelMap[entry.value] = entry.label;
                            }
                        });
                        return response.data;
                    }).catch(function (error) {
                        console.error(error);
                    });
                }
                return $q.when(bulkFieldDomain);
            }
            /**
             * Get the label asociated with the bulk field value
             * @param  {[type]} domainEntry [description]
             * @return {[type]}             [description]
             *
             * TODO: This is DEAD CODE, but the unit tests appear to be coupled to this function for validation. Fix the tests and remove this code.
             */
            function getLabel(bulkFieldValue) {
                if (!bulkFieldValue) {
                    bulkFieldValue = getConfig('value');
                    if (!bulkFieldValue) {
                        return $q.when(null);
                    }
                }
                if (!bulkFieldDomain) {
                    return getDomain().then(function () {
                        return labelMap[bulkFieldValue];
                    });
                }
                return $q.when(labelMap[bulkFieldValue]);
            }
            return {
                getConfig: getConfig,
                setValue: setValue,
                getDomain: getDomain,
                getLabel: getLabel
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
