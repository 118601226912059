"use strict";
(function (module) {
    'use strict';
    DeleteReportController.$inject = [
        '$scope',
        '$filter',
        '$routeParams',
        '$window',
        '$location',
        'pr.rsContainersWrapper',
        'prActiveReport',
        'prErrorModal',
        'pr.API_ROUTES',
        'prQueryString',
        'prMetricsService',
        'pr.reportStatus',
        'pr.scheduleEmailService'
    ];
    function DeleteReportController($scope, $filter, $routeParams, $window, $location, containers, activeReport, errorModal, API_ROUTES, qs, metricsService, reportStatus, scheduleEmailService) {
        var translate = $filter('translate');
        var $ctrl = this;
        $scope.$ctrl = $ctrl;
        var reportId = activeReport.getReportId();
        $ctrl.deleteReport = function () {
            $ctrl.spin = true;
            scheduleEmailService.deleteScheduledEmailsForContainerId(reportId)
                .then(function () {
                return containers.deleteContainer(reportId);
            })
                .then(function () {
                metricsService.deleteReport();
                reportStatus.onReportDeletion();
                $window.location = qs.append(API_ROUTES.VIEW + $routeParams.surveyId, $location.search());
            })
                .catch(function () {
                errorModal.show(translate('ERROR_DELETE_REPORT'));
            });
        };
    }
    module.controller('prDeleteReportController', DeleteReportController);
}(angular.module('qualtrics.pr')));
