"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BODY_SELECTOR = 'body';
var INPUT = 'INPUT';
var TEXTAREA = 'TEXTAREA';
/*
 * Returns true if the element is an input, text area or content editable
 */
function isTextArea(element) {
    var el;
    el = element[0];
    if (el && el.tagName) {
        if (el.tagName === INPUT || el.tagName === TEXTAREA) {
            return true;
        }
        // traverse up the parent node looking for content editable
        var body = $(BODY_SELECTOR)[0];
        while (el && el !== body) {
            var ce = el.getAttribute('contenteditable');
            if (ce && ce !== 'false') {
                return true;
            }
            el = el.parentNode;
        }
    }
    return false;
}
exports.default = isTextArea;
/* global jQuery */
(function ($) {
    $.fn.isTextArea = function () {
        return isTextArea(this);
    };
})(jQuery);
