"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("@shared/types/environment");
(function (module) {
    'use strict';
    module.factory('prPartialDataService', [
        '$http',
        'pr.API_ROUTES',
        'pr.widgetManager',
        'prActiveReport',
        'prEventService',
        'pr.httpPostCache',
        'rsEnvironment',
        function ($http, API_ROUTES, widgetManager, activeReport, eventService, prHttpCache, env) {
            /**
             *	Issue a partial data check every 5 seconds.
             * 	After 5 checks, increase the interval to 20 seconds, etc.
             */
            var requestRetryProtocol = [
                {
                    interval: 5000,
                    numChecks: 5
                }, {
                    interval: 20000,
                    numChecks: 5
                }, {
                    interval: 60000,
                }
            ];
            /**
             *	Wrapper to calculate the delay for the next partial data request based on
             *	the requestRetryProtocol set earlier up.
             */
            var REQUEST_DELAY = (function () {
                var index = 0;
                var numChecks = 0;
                return {
                    reset: function () {
                        index = 0;
                        numChecks = 0;
                    },
                    get: function () {
                        var delay = requestRetryProtocol[index].interval;
                        if (index + 1 < requestRetryProtocol.length) {
                            numChecks++;
                            if (numChecks === requestRetryProtocol[index].numChecks) {
                                numChecks = 0;
                                index++;
                            }
                        }
                        return delay;
                    }
                };
            })();
            // {object} - sources using partial data, key = sourceId, value = sample respone meta from RE
            var partialDataSources;
            // {array} - sources that have not yet been checked for partial data
            var uncheckedSources = [];
            // {object} - keys = sourceId, value = array of connected widgets
            var sourceToWidgetMap = {};
            init();
            /**
             * 	Create a map from sourceId to widgetId and initiate check for partial data
             */
            function init() {
                var widgetToSourceMap = activeReport.getMetadata('connectedSources');
                _.forOwn(widgetToSourceMap, function (sourceIds, widgetId) {
                    sourceIds = _.isArray(sourceIds) ? sourceIds : [sourceIds];
                    _.forEach(sourceIds, function (sourceId) {
                        if (!sourceToWidgetMap[sourceId]) {
                            sourceToWidgetMap[sourceId] = [];
                            uncheckedSources.push(sourceId);
                        }
                        sourceToWidgetMap[sourceId].push(widgetId);
                    });
                });
                checkForPartialData();
            }
            /**
             *	Get the sourceIds that should be checked in the next request to prs.
             *	If this returns an empty array, then partial data checks are completed (unless new sources are added).
             *	return {Array} sourceIds
             */
            function getSourcesToCheck() {
                return _.concat(uncheckedSources, _.keys(partialDataSources));
            }
            /**
             *	Signal server to return information about partial data. If this is
             *	the first request, request information for all sources in container.
             *	Otherwise, request information only for sources that currently
             *	display partial data.
             */
            function checkForPartialData() {
                var sourcesToCheck = getSourcesToCheck();
                var params = {
                    sourceIds: sourcesToCheck
                };
                if (env.get(environment_1.ENV_VARS.IS_FIELDSET_PUBLISHING_V2_ENABLED)) {
                    params.publishVersion = 'unpublished';
                }
                if (sourcesToCheck.length) {
                    $http.get(API_ROUTES.PARTIAL_DATA, {
                        params: params
                    }).then(processResponse);
                }
            }
            /**
             * 	@param {object} response - response.data will contain sources that use partial data
             * 	After response from prs:
             * 		update any widgets that no longer use partial data, and
             * 		set a timeout for another request if necessary.
             */
            function processResponse(response) {
                var sourcesToCheck = getSourcesToCheck();
                var sourcesNotChecked = _.difference(sourcesToCheck, response.config.params.sourceIds);
                if (sourcesNotChecked.length) {
                    // Ignore this response. Additional source(s) were added after this request
                    // was triggered, which means a new request was triggered. See the
                    // eventService.on('sourceLinked') function handler below.
                    return;
                }
                uncheckedSources = [];
                var oldPartialDataSources = _.keys(partialDataSources);
                partialDataSources = _.pick(response.data, sourcesToCheck);
                if (!_.isEmpty(partialDataSources)) {
                    // schedule a later check if some sources still use partial data
                    schedulePartialDataCheck();
                }
                else {
                    // Reset the delay between future network requests (for new sources added)
                    REQUEST_DELAY.reset();
                    if (oldPartialDataSources.length) {
                        onPartialDataResolve();
                    }
                }
            }
            /**
             * 	When all partial data is resolved, we need to
             * 		bust the data cache,
             * 		refresh widgets,
             * 		refresh response count
             */
            function onPartialDataResolve() {
                prHttpCache.clearCache();
                widgetManager.notifyAllWidgets('filtersChanged', 'prPartialDataService');
                eventService.trigger('responseCountChanged');
                /**
                 * 	Trigger filtersChanged in order to refresh piped text
                 * 	after partial data resolves.
                 *
                 * 	TODO: Extract this to a common function.
                 */
                eventService.trigger('filtersChanged');
            }
            /**
             *	Add widget to sourceToWidgetMap[sourceId].
             * 	If a new source(s) is added, schedule a new request to prs immediately
             *  and cancel any scheduled requests. If a request has already been sent,
             *	ignore the response from prs since it won't include the new source(s).
             */
            eventService.on('sourceLinked', function (event, data) {
                if (!data.sourceId || !data.widgetId) {
                    return;
                }
                var shouldCheckForPartialData = false;
                var sourceIds = _.isArray(data.sourceId) ? data.sourceId : [data.sourceId];
                _.forEach(sourceIds, function (sourceId) {
                    if (!sourceToWidgetMap[sourceId]) {
                        shouldCheckForPartialData = true;
                        sourceToWidgetMap[sourceId] = [];
                        uncheckedSources.push(sourceId);
                    }
                    sourceToWidgetMap[sourceId] = _.uniq(sourceToWidgetMap[sourceId]);
                    sourceToWidgetMap[sourceId].push(data.widgetId);
                });
                if (shouldCheckForPartialData) {
                    cancelScheduledCheck();
                    checkForPartialData();
                }
            });
            /**
             *	Remove widget from sourceToWidgetMap[sourceId].
             *	If source is completely removed, remove from partialDataSources and uncheckedSources.
             */
            eventService.on('sourceUnlinked', function (event, data) {
                if (!data.sourceId || !data.widgetId) {
                    return;
                }
                var sourceIds = _.isArray(data.sourceId) ? data.sourceId : [data.sourceId];
                _.forEach(sourceIds, function (sourceId) {
                    var index = sourceToWidgetMap[sourceId].indexOf(data.widgetId);
                    sourceToWidgetMap[sourceId].splice(index, 1);
                    if (!sourceToWidgetMap[sourceId].length) {
                        delete sourceToWidgetMap[sourceId];
                        delete partialDataSources[sourceId];
                        index = uncheckedSources.indexOf(sourceId);
                        if (index >= 0) {
                            delete uncheckedSources[index];
                        }
                    }
                });
            });
            /**
             * 	Helper functions to schedule/cancel partial data checks
             */
            var timeoutId = null;
            function schedulePartialDataCheck() {
                timeoutId = setTimeout(checkForPartialData, REQUEST_DELAY.get());
            }
            function cancelScheduledCheck() {
                clearTimeout(timeoutId);
            }
            return {
                usingPartialData: function () {
                    // explicitly return undefined if var is still undefined
                    return partialDataSources && !_.isEmpty(partialDataSources);
                },
                isPartialDataCheckComplete: function () {
                    return !getSourcesToCheck().length;
                },
                getSourcesToCheck: getSourcesToCheck,
                __TESTING__: {
                    // methods called for unit testing
                    setRequestRetryProtocol: function (protocol) {
                        requestRetryProtocol = protocol;
                    },
                    getRequestDelayObject: function () {
                        return REQUEST_DELAY;
                    }
                }
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
