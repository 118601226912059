"use strict";
(function (app) {
    'use strict';
    app.service('surveyFolderService', [
        '$http',
        'pr.API_ROUTES',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        function ($http, API_ROUTES, triggerOverrides, OVERRIDE_EVENTS) {
            this.getSurveySelectorFolderDefinition = function () {
                var projectParams = { exclude: ['EX'] };
                projectParams = triggerOverrides({
                    event: OVERRIDE_EVENTS.FOLDER_API_OPTIONS,
                    data: projectParams,
                    sync: true,
                });
                var body = {
                    contentTypeFilters: {
                        projects: projectParams,
                        resources: ['survey'],
                        programs: 'none',
                        metafolders: ['uncategorized']
                    },
                    includeIcons: 'none'
                };
                var url = API_ROUTES.FOLDER_SELECTOR;
                return $http.post(url, body).then(function (res) {
                    return res.data.data || {};
                });
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
