"use strict";
(function (module) {
    'use strict';
    module.constant('prQueryString', {
        append: function (path, qs) {
            var queryString = !_.isEmpty(qs) ? '?' + _.map(qs, function (val, key) {
                return key + '=' + val;
            }).join('&') : '';
            return path + queryString;
        }
    });
})(angular.module('qualtrics.pr'));
