"use strict";
(function (module) {
    'use strict';
    module.factory('prPipedTextDateService', [
        '$filter',
        '$q',
        function ($filter) {
            function PipedTextDate() {
                return this;
            }
            PipedTextDate.translate = $filter('translate');
            PipedTextDate.EXAMPLE_DATE = 'Mon Apr 02 2018 16:00:00 GMT-0700 (PDT)';
            PipedTextDate.DEFAULT_FORMATS = [
                'D MMM YYYY',
                'MMMM D, YYYY',
                'dddd, MMMM D',
                'dddd, MMMM D, YYYY',
                'M/D/YYYY',
                'D/M/YYYY',
                'MM/DD/YYYY',
                'DD/MM/YYYY'
            ];
            PipedTextDate.LEADING_ZERO_FORMATS = ['MM/DD/YYYY', 'DD/MM/YYYY'];
            var proto = PipedTextDate.prototype;
            proto.getDateMenu = function (dateString, optSourceId) {
                var menu = _.map(PipedTextDate.DEFAULT_FORMATS, function (format) {
                    var label = _.contains(PipedTextDate.LEADING_ZERO_FORMATS, format) ?
                        moment(PipedTextDate.EXAMPLE_DATE).format(format) +
                            ' (' + PipedTextDate.translate('PIPED_TEXT.LEADING_ZERO_FORMATS') + ')' :
                        moment(PipedTextDate.EXAMPLE_DATE).format(format);
                    var ptAttrs = {
                        format: format,
                        type: 'date',
                        attrName: _.isEmpty(dateString) ? 'currentDate' : dateString
                    };
                    if (dateString === 'lastResponseDate') {
                        ptAttrs.sourceId = optSourceId;
                    }
                    return {
                        label: label,
                        value: {
                            ptAttrs: ptAttrs
                        }
                    };
                });
                menu.unshift({
                    label: PipedTextDate.translate('PIPED_TEXT.SELECT_FORMAT'),
                    header: true
                });
                return menu;
            };
            proto.getCurrentDate = function (ptAttrs) {
                if (ptAttrs.type === 'date') {
                    return moment(moment.now()).format(ptAttrs.format);
                }
                return '';
            };
            proto.updateDateFormat = function (ptAttrs) {
                if (ptAttrs.type === 'date') {
                    return moment(ptAttrs.innerText).format(ptAttrs.format);
                }
                return '';
            };
            return PipedTextDate;
        }
    ]);
}(angular.module('qualtrics.pr')));
