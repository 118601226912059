"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("angular");
var PageHeaderFooterExportLoadingService = /** @class */ (function () {
    function PageHeaderFooterExportLoadingService() {
        this.pageMap = new Set();
    }
    PageHeaderFooterExportLoadingService.prototype.setStartLoading = function (pageNumber) {
        this.pageMap.add(pageNumber);
    };
    PageHeaderFooterExportLoadingService.prototype.setFinishLoading = function (pageNumber) {
        this.pageMap.delete(pageNumber);
    };
    PageHeaderFooterExportLoadingService.prototype.isDoneLoading = function () {
        return this.pageMap.size === 0;
    };
    PageHeaderFooterExportLoadingService.prototype.getPages = function () {
        return this.pageMap;
    };
    return PageHeaderFooterExportLoadingService;
}());
(function (angularModule) {
    angularModule.service('prPageHeaderFooterExportLoadingService', PageHeaderFooterExportLoadingService);
})(angular_1.module('qualtrics.pr'));
