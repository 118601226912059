"use strict";
(function (module) {
    //var UndoRedoStack = function (keylog) {
    var UndoRedoStack = function () {
        this.stack = [];
        this.current = -1;
        // var boundUndo = _.bind(this.undo, this),
        // 	boundRedo = _.bind(this.redo, this);
        // keylog.registerAction([keylog.CTRL, keylog.Z], boundUndo);
        // keylog.registerAction([keylog.CTRL, keylog.SHIFT, keylog.Z] , boundRedo);
    };
    var UndoItem = function (data, callback) {
        this.data = data;
        this.callback = callback;
        this.self = this;
    };
    /**
     * Pushes reference data and a callback to the undo/redo stack
     *
     * @param {any} data - data in which the callback will be called with
     * @param {function} callback - called with metadata and the before/after data
     */
    UndoRedoStack.prototype.push = function (data, callback) {
        this.current++;
        this.stack.splice(this.current);
        this.stack.push(new UndoItem(data, callback));
    };
    /**
     * Invokes the callback with data in the location previous to the current pointer
     */
    UndoRedoStack.prototype.undo = function () {
        var metaData = {
            isUndo: true,
            isRedo: false
        };
        if (this.current >= 0) {
            var item = this.stack[this.current];
            item.callback.call(this.self, metaData, item.data);
            this.current--;
        }
        else {
            console.error('No changes left');
        }
    };
    /**
     * Invokes the callback with data in the location after the current pointer
     */
    UndoRedoStack.prototype.redo = function () {
        var metaData = {
            isUndo: false,
            isRedo: true
        };
        var item = this.stack[this.current + 1];
        if (item) {
            item.callback.call(this.self, metaData, item.data);
            this.current++;
        }
        else {
            console.error('No changes left');
        }
    };
    /**
     * Sets up all operations for monitoring an undor/redo operation
     *
     * @param {any} data - data in which the callback will be called with
     * @param {function} callback - called with metadata and the before/after data
     */
    UndoRedoStack.prototype.watch = function (data, callback) {
        this.push(data, callback);
    };
    //UndoRedoStack.$inject = ['prKeylogService'];
    UndoRedoStack.$inject = [];
    module.service('undoRedoStack', UndoRedoStack);
})(angular.module('qualtrics.pr'));
