"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@services/widget-title-helper");
(function (module) {
    'use strict';
    var CHUNKING_VALIDATION_TIMEOUT = 5000;
    /*
     * Chunks a table widget
     */
    module.factory('prTableChunker', [
        'prPageLayoutService',
        'prWidgetChunker',
        'prEventService',
        'pr.EVENTS',
        'pr.widgetTitleHelper',
        function (pageLayout, WidgetChunker, eventService, EVENTS, widgetTitleHelper) {
            var WIDGET_COMPLETED_STATUS = 'WIDGET_STATUS:COMPLETED';
            function TableChunker(widget) {
                var _this = this;
                WidgetChunker.call(this, widget);
                var eventStream = eventService.stream(this.widget._id);
                // https://qualtrics.atlassian.net/browse/REP-4382
                // If updateChunks() hasn't been called within the normal time window,
                // add this listener to guarantee that it will be called.
                setTimeout(function () {
                    eventStream.on(WIDGET_COMPLETED_STATUS, onWidgetCompletedStatus);
                }, CHUNKING_VALIDATION_TIMEOUT);
                var onWidgetCompletedStatus = function () {
                    if (!_this.widget) {
                        eventStream.off(WIDGET_COMPLETED_STATUS, onWidgetCompletedStatus);
                        return;
                    }
                    if (!_this.widget.finishedChunking) {
                        _this.updateChunks();
                    }
                };
            }
            TableChunker.prototype = _.create(WidgetChunker.prototype, {
                /*
                 * @override
                 */
                _isDoneRendering: function () {
                    return widgetTitleHelper.isReady(this.widget._id) && this._hasContent();
                },
                _hasContent: function () {
                    return (this._getTable() || this._getAlert());
                },
                _getAlert: function () {
                    // In some cases (data error, view error...), an alert with context is shown instead of a table
                    return this.$element[0].querySelector('.widget-alert');
                },
                _getTable: function () {
                    return this.$element[0].querySelector('table');
                },
                _chunk: function () {
                    var table = this._getTable();
                    var layout = this.widget.layout || {};
                    var padding = this._getWidgetPadding();
                    var paddingSpace = padding.top + padding.bottom;
                    var pageRows = pageLayout.getRows();
                    var cellSize = pageLayout.getCellSize();
                    // Style option to force a new chunk/page for each section of a widget, determined
                    // by header rows. Implemented initially for EX heatmap.
                    var newPagePerHeaderSection = _.get(this.widget, 'layout.style.newPagePerHeaderSection');
                    var row = layout.row;
                    var rowSpan = pageRows - (row % pageRows);
                    var chunkHeightLeft = rowSpan * cellSize - paddingSpace;
                    var chunkIndex = 0;
                    var widgetClone = this._initChunk(chunkIndex);
                    if (!table) {
                        this._chunkAlert(chunkHeightLeft);
                        return;
                    }
                    // dom nodes
                    var trs = table.querySelectorAll('tr');
                    var hiddenWidgetClone = this._cloneWidget();
                    var clonedTrs = hiddenWidgetClone.querySelectorAll('tr');
                    // we use _ because clonedTrs is a NodeList and not an Array so it doesn't have forEach
                    var i;
                    for (i = 0; i < clonedTrs.length; i++) {
                        this._remove(clonedTrs[i]);
                    }
                    var newNode = hiddenWidgetClone.cloneNode(true);
                    widgetClone.appendChild(newNode);
                    var productViewHeight = this._getProductViewHeightOnChunk(newNode);
                    var chunkToKeepProductView = getChunkIndexWithProductView(chunkHeightLeft, productViewHeight, trs);
                    chunkHeightLeft = this._keepOrRemoveProductViewOnChunk(chunkIndex, newNode, chunkHeightLeft, productViewHeight, chunkToKeepProductView);
                    for (i = 0; i < trs.length; i++) {
                        var tr = trs[i], rowIndex = i;
                        var rowHeight = getPreciseElementHeight(tr);
                        chunkHeightLeft -= rowHeight;
                        if (chunkHeightLeft < 0 || forceNewChunk(newPagePerHeaderSection, tr, i)) {
                            chunkIndex++;
                            // set each following chunk to be the initial page height
                            row += rowSpan;
                            rowSpan = pageRows;
                            chunkHeightLeft = (rowSpan * cellSize) - rowHeight - paddingSpace;
                            widgetClone = this._initChunk(chunkIndex);
                            newNode = hiddenWidgetClone.cloneNode(true);
                            widgetClone.appendChild(newNode);
                            chunkHeightLeft = this._keepOrRemoveProductViewOnChunk(chunkIndex, newNode, chunkHeightLeft, productViewHeight, chunkToKeepProductView);
                            // add table header (first row) to the top of the chunk to repeat header on each page
                            if (layout.style && layout.style.repeatTableHeaders) {
                                this._appendTRToTableClone(widgetClone, trs[0], clonedTrs[0].cloneNode(true));
                                chunkHeightLeft -= getPreciseElementHeight(trs[0]);
                            }
                        }
                        this._appendTRToTableClone(widgetClone, tr, clonedTrs[rowIndex]);
                    }
                    this._updateProductView(chunkToKeepProductView);
                    this._cleanExtraChunks(chunkIndex + 1);
                    // this is expensive
                    this._updateColumnWidths();
                    this._setChunksToVisible();
                },
                _updateProductView: function (chunkIndex) {
                    var chunkWithProductView = this.chunks[chunkIndex];
                    eventService.stream(this.widget._id).trigger(EVENTS.WIDGET_CHUNKED, chunkWithProductView);
                },
                /*
                 * Appends directly to the table if the TR is not in a tbody, thead, or tfoot
                 */
                _appendTRToTableClone: function (widgetClone, tr, trClone) {
                    var parentName = (tr.parentNode || {}).nodeName || 'TABLE';
                    widgetClone.style.display = 'none';
                    widgetClone = widgetClone.querySelector(parentName);
                    widgetClone.appendChild(trClone);
                },
                _updateColumnWidths: function () {
                    // eslint-disable-next-line
                    var parentTableRow = this.$element[0].querySelector("tr");
                    // we don't have a single row!
                    if (!parentTableRow) {
                        return;
                    }
                    var parentTableData = parentTableRow.querySelectorAll('td,th');
                    var widths = _.map(parentTableData, 'clientWidth');
                    var sum = _.sum(widths);
                    _.forEach(this.chunks, function (chunk) {
                        var firstRow = chunk.querySelector('tr');
                        if (!firstRow) {
                            return;
                        }
                        var firstRowTds = firstRow.querySelectorAll('td,th');
                        _.forEach(firstRowTds, function (td, idx) {
                            td.style.width = ('width', (100 * widths[idx] / sum) + '%');
                        });
                    });
                },
                _setChunksToVisible: function () {
                    var _this = this;
                    _.forEach(this.chunks, function (chunk, chunkIndex) {
                        chunk.style.display = 'block';
                        if (_this._isChunkHidden(chunk)) {
                            /**
                             * The chunk is still on the hidden-renderer.
                             * This can happen if a widget is shuffled a lot while being chunked.
                             * Just tell table-body to render the hidden chunk.
                             * @todo - at this point, no chunks SHOULD be on the hidden-renderer,
                             *       figure out why this is happening and fix it.
                             */
                            var chunkId = _.get(_this.widget, ['chunks', chunkIndex, 'layout', 'id']);
                            eventService.stream(chunkId).trigger(EVENTS.RENDER_HIDDEN_CHUNK, chunk);
                        }
                    });
                },
                _chunkAlert: function (chunkHeightLeft) {
                    this._chunkDefault();
                    var alertBody = this._getAlert();
                    if (alertBody) {
                        var productViewHeight = this._getProductViewHeightOnChunk(this._getWidgetBody());
                        var alertHeight = _.get(alertBody, 'clientHeight', 20);
                        var chunkToKeepProductView = getChunkIndexWithProductView(chunkHeightLeft, productViewHeight + alertHeight);
                        this._updateProductView(chunkToKeepProductView);
                        // This widget is narrow & shouldn't be chunked, remove all chunks except the first
                        this._cleanExtraChunks(1);
                    }
                }
            });
            return TableChunker;
            /**
             * @param  {number} chunkHeightRemaining - number of pixels remaining in the first chunk
             * @param  {number} productViewHeight - number of pixels that the product view consumes
             * @param  {NodeList} tableRows - the rows containing the content for the widget
             * @return {boolean} - whether the product view AND the first row can fit in the first chunk
             */
            function doesContentFitOnFirstChunk(chunkHeightRemaining, productViewHeight, tableRows) {
                // don't use '0.clientHeight' because tableRows['0.clientHeight'] === tableRows[0]
                // in ie11 (the implementation of NodeList is browser dependent)
                var heightOfFirstRow = _.get(tableRows, '[0].clientHeight', 0);
                return chunkHeightRemaining >= productViewHeight + heightOfFirstRow;
            }
            /**
             * The product view should appear on the first chunk that contains table content.
             * Remove the product view from every other chunk
             */
            function getChunkIndexWithProductView(firstChunkHeightLeft, productViewHeight, trs) {
                return doesContentFitOnFirstChunk(firstChunkHeightLeft, productViewHeight, trs) ? 0 : 1;
            }
            /**
             * Determines if a new chunk should be created for a header section
             * @param {boolean} newPagePerHeaderSection layout style flag; true if new chunks should be created
             * @param {Node} tr table row element
             * @param {number} i index of row element in table
             * @returns true if row should begin a new chunk
             */
            function forceNewChunk(newPagePerHeaderSection, tr, i) {
                if (newPagePerHeaderSection) {
                    var className = _.get(tr, 'firstChild.className');
                    var isHeaderElement = _.includes(className, 'header');
                    return i > 0 && isHeaderElement;
                }
                return false;
            }
            /**
             * Use getBoundingClientRect() because alternatives (e.g. clientHeight)
             * will round down which can cause too many rows to be allocated to a chunk.
             * @param  {HTMLElement} element
             * @return {number}
             */
            function getPreciseElementHeight(element) {
                return element.getBoundingClientRect().height;
            }
        }
    ]);
}(angular.module('qualtrics.pr')));
