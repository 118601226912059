"use strict";
(function (module) {
    'use strict';
    module.factory('pr.hotkeyUtil', [
        '$filter',
        function ($filter) {
            var translate = $filter('translate');
            var IS_WINDOWS = navigator.userAgent.indexOf('Win') !== -1;
            function buildHotkeyLabel(label, iconList) {
                label = translate(label);
                if (!iconList || !iconList.length) {
                    return label;
                }
                return $('<span>' + label + '</span>' + buildHotkeyIcons(iconList));
            }
            /*
             * returns HTML for the hotkey icons
             */
            function buildHotkeyIcons(iconList) {
                var iconHtml = iconList.map(function (icon) {
                    // Swap out Command for Ctrl+ on windows
                    if (icon.indexOf('icon-commandkey') === 0 && IS_WINDOWS) {
                        return '<span>Ctrl+</span>';
                    }
                    if (icon.indexOf('icon') === 0) {
                        return '<span class="icon ' + icon + '"></span>';
                    }
                    if (icon === 'DELETE') {
                        icon = translate(IS_WINDOWS ? 'BACKSPACE' : 'DELETE');
                    }
                    return '<span>' + icon + '</span>';
                }).join('');
                return '<span class="hotkeys">' + iconHtml + '</span>';
            }
            return {
                buildHotkeyLabel: buildHotkeyLabel
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
