"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./fonts.less");
angular.module('qualtrics.pr').constant('FONTS_DATA', {
    'amaranth': {
        'fonts': 'Amaranth, sans-serif',
        'label': 'Amaranth',
        'file': 'amaranth'
    },
    'anonymous_pro': {
        'fonts': '\"Anonymous Pro\", monospace',
        'label': 'Anonymous Pro',
        'file': 'anonymousPro'
    },
    'arial': {
        'fonts': 'arial, helvetica, sans-serif',
        'label': 'Arial'
    },
    'arvo': {
        'fonts': 'Arvo, serif',
        'label': 'Arvo',
        'file': 'arvo'
    },
    'averia_serif_libre': {
        'fonts': '\"Averia Serif Libre\", serif',
        'label': 'Averia Serif Libre',
        'file': 'averiaSerifLibre'
    },
    'barlow_condensed': {
        'fonts': '\"Barlow Condensed\", sans-serif',
        'label': 'Barlow Condensed',
        'file': 'barlowCondensed'
    },
    'courier': {
        'fonts': 'courier, monospace',
        'label': 'Courier'
    },
    'courier_new': {
        'fonts': '\"courier new\", courier, monospace',
        'label': 'Courier New'
    },
    'crimson_text': {
        'fonts': '\"Crimson Text\", serif',
        'label': 'Crimson Text',
        'file': 'crimsonText'
    },
    'economica': {
        'fonts': 'Economica, sans-serif',
        'label': 'Economica',
        'file': 'economica'
    },
    'gentium_book_basic': {
        'fonts': '\"Gentium Book Basic\", serif',
        'label': 'Gentium Book Basic',
        'file': 'gentiumBookBasic'
    },
    'georgia': {
        'fonts': 'georgia, times, serif',
        'label': 'Georgia'
    },
    'helvetica': {
        'fonts': 'helvetica, arial, sans-serif',
        'label': 'Helvetica'
    },
    'ibm_plex_mono': {
        'fonts': '\"IBM Plex Mono\", monospace',
        'label': 'IBM Plex Mono',
        'file': 'ibmPlexMono'
    },
    'ibm_plex_serif': {
        'fonts': '\"IBM Plex Serif\", serif',
        'label': 'IBM Plex Serif',
        'file': 'ibmPlexSerif'
    },
    'josefin-sans': {
        'fonts': '\"Josefin Sans\", sans-serif',
        'label': 'Josefin Sans',
        'file': 'josefinSans'
    },
    'josefin-slab': {
        'fonts': '\"Josefin Slab\", serif',
        'label': 'Josefin Slab',
        'file': 'josefinSlab'
    },
    'lora': {
        'fonts': 'Lora, serif',
        'label': 'Lora',
        'file': 'lora'
    },
    'lobster_two': {
        'fonts': '\"Lobster Two\", display',
        'label': 'Lobster Two',
        'file': 'lobsterTwo'
    },
    'merriweather': {
        'fonts': 'Merriweather, serif',
        'label': 'Merriweather',
        'file': 'merriweather'
    },
    'montserrat': {
        'fonts': 'Montserrat, sans-serif',
        'label': 'Montserrat',
        'file': 'montserrat'
    },
    'noto_serif': {
        'fonts': '\"Noto Serif\", serif',
        'label': 'Noto Serif',
        'file': 'notoSerif'
    },
    'open_sans': {
        'fonts': '\"Open Sans\", sans-serif',
        'label': 'Open Sans',
        'file': 'openSans'
    },
    'overlock': {
        'fonts': 'Overlock, display',
        'label': 'Overlock',
        'file': 'overlock'
    },
    'playfair_display': {
        'fonts': '\"Playfair Display\", serif',
        'label': 'Playfair Display',
        'file': 'playfairDisplay'
    },
    'playfair_display_sc': {
        'fonts': '\"Playfair Display SC\", serif',
        'label': 'Playfair Display SC',
        'file': 'playfairDisplaySC'
    },
    'prompt': {
        'fonts': 'Prompt, sans-serif',
        'label': 'Prompt',
        'file': 'prompt'
    },
    'pt_serif': {
        'fonts': '\"PT Serif\", serif',
        'label': 'PT Serif',
        'file': 'ptSerif'
    },
    'raleway': {
        'fonts': 'Raleway, sans-serif',
        'label': 'Raleway',
        'file': 'raleway'
    },
    'roboto': {
        'fonts': 'Roboto, sans-serif',
        'label': 'Roboto',
        'file': 'roboto'
    },
    'sansita': {
        'fonts': 'Sansita, sans-serif',
        'label': 'Sansita',
        'file': 'sansita'
    },
    'space_mono': {
        'fonts': '\"Space Mono\", monospace',
        'label': 'Space Mono',
        'file': 'spaceMono'
    },
    'taviraj': {
        'fonts': 'Taviraj, serif',
        'label': 'Taviraj',
        'file': 'taviraj'
    },
    'times': {
        'fonts': 'times, \"Times Roman\", \"Times New Roman\", TimesNewRoman, serif',
        'label': 'Times'
    },
    'times_new_roman': {
        'fonts': '\"Times New Roman\", TimesNewRoman, times, \"Times Roman\", serif',
        'label': 'Times New Roman'
    },
    'ubuntu_mono': {
        'fonts': '\"Ubuntu Mono\", monospace',
        'label': 'Ubuntu Mono',
        'file': 'ubuntuMono'
    },
    'unna': {
        'fonts': 'Unna, serif',
        'label': 'Unna',
        'file': 'unna'
    },
    'verdana': {
        'fonts': 'verdana, Geneva, arial, hevetica, sans-serif',
        'label': 'Verdana'
    }
});
