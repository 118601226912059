"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@services/dynamic-translation/common");
var angular = __importStar(require("angular"));
var translations_1 = require("@shared/constants/translations");
var ThreeSixtyRelationshipsService = /** @class */ (function () {
    function ThreeSixtyRelationshipsService($q, $http, 
    // @ts-ignore
    $sanitize, 
    // @ts-ignore
    $filter, 
    // @ts-ignore
    API_ROUTES, 
    // @ts-ignore
    env, 
    // @ts-ignore
    $routeParams, 
    // @ts-ignore
    activeReport) {
        this.$q = $q;
        this.$http = $http;
        this.$sanitize = $sanitize;
        this.$filter = $filter;
        this.API_ROUTES = API_ROUTES;
        this.env = env;
        this.$routeParams = $routeParams;
        this.activeReport = activeReport;
        this.DEFAULT_RELATIONSHIP_MAP = {
            'ThreeSixty.DirectReport': 'AWA.REPORT_ONLY.RELATIONSHIPS.DIRECT_REPORT',
            'ThreeSixty.Manager': 'AWA.REPORT_ONLY.RELATIONSHIPS.MANAGER',
            'ThreeSixty.Peer': 'AWA.REPORT_ONLY.RELATIONSHIPS.PEER',
            'ThreeSixty.Self': 'AWA.REPORT_ONLY.RELATIONSHIPS.SELF',
            'ThreeSixty.#anyone#': 'AWA.REPORT_ONLY.RELATIONSHIPS.ANYONE'
        };
    }
    /**
     * Gets a list of all persons relationship in project. Used for assertion after integration tests
     * @param  {ThreeSixtyId}            threeSixtyId (optional)
     * @return {IPromise<RelationshipDef[]>}
     *
     * e.g. if in project TS_123, there are two persons: P_A and P_B, and P_A is P_B's manager,
     * - getAllRelationships(TS_123) returns:
     * [
     * 		{
     * 			sourcePersonId: 'P_A',
     * 			targetPersonId: 'P_B',
     * 			relationshipName: 'Manager'
     * 		},
     * 		{
     * 			sourcePersonId: 'P_B',
     * 			targetPersonId: 'P_A',
     * 			relationshipName: 'DirectReport'
     * 		}
     * ]
     */
    ThreeSixtyRelationshipsService.prototype.getAllRelationships = function (threeSixtyId) {
        var url = this.getRelationshipsUrl(threeSixtyId);
        return this.$http.get(url, { cache: true })
            .then(function (response) {
            return response.data;
        }).catch(function (err) {
            console.error("Could not get all relationships of " + threeSixtyId + ": ", err);
            return [];
        });
    };
    /**
     * Gets all translated relationships
     * @param  none
     * @return {IPromise<RelationshipTranslations>}
     *
     * example returned value
     * {
     *     DirectReport: 'Direct Report',
     *     Manager: 'Manager',
     *     RL_vjgu54t5: 'Custom relationship',
     *     '#anyone#': 'Anyone'
     * }
     */
    ThreeSixtyRelationshipsService.prototype.getAllRelationshipTranslations = function () {
        var _this = this;
        var threeSixtyId = this.$routeParams.projectId || this.env.get('activeProjectId');
        return this.getAllProjectRelationships(threeSixtyId).then(function (relationshipTranslations) {
            var translate = _this.$filter('translate');
            return relationshipTranslations.reduce(function (translatedRelationships, relationshipTranslation) {
                var relationshipId = relationshipTranslation.relationshipId;
                var translation = relationshipTranslation.translation;
                var translationId = relationshipTranslation.translationId;
                // @ts-ignore
                // @ts-ignore
                translatedRelationships[relationshipId] = translation || translate(_this.DEFAULT_RELATIONSHIP_MAP[translationId]);
                return translatedRelationships;
            }, {
                '#anyone#': translate(_this.DEFAULT_RELATIONSHIP_MAP['ThreeSixty.#anyone#'])
            });
        });
    };
    /**
     * Gets all relationships with translation in given language for project
     * @param  {ThreeSixtyId}            threeSixtyId (required)
     * @return {IPromise<RelationshipDef[]>}
     *
     * e.g. if in project TS_123, language code is EN and includeDefaultRelationships is not defined,
     * - getAllProjectRelationships(TS_123, EN) returns:
     * [
     * 		{
     * 			relationshipId: 'DirectReport',
     * 			translation: null,
     * 			translationId: 'ThreeSixty.DirectReport'
     * 		},
     * 		{
     * 			relationshipId: 'RL_v3g54g45',
     * 			translation: 'Custom Relationship,
     * 			translationId: null
     * 		}
     * ]
     */
    ThreeSixtyRelationshipsService.prototype.getAllProjectRelationships = function (threeSixtyId) {
        var url = this.getRelationshipsUrl(threeSixtyId) + "/translations";
        var language = this.$sanitize(this.activeReport.getLanguageCode()) || translations_1.DEFAULT_LANGUAGE_CODE;
        return this.$http.get(url, common_1.getLanguageHeaders(language))
            .then(function (response) {
            return response.data;
        }).catch(function (err) {
            console.error("Could not get all relationships of " + threeSixtyId + ": ", err);
            return [];
        });
    };
    /**
     * Provides list of relationship names for persons relationships between two people in a 360 project
     * @param  {PersonId}            sourcePersonId
     * @param  {PersonId}            targetPersonId
     * @param  {ThreeSixtyId}        threeSixtyId (optional)
     * @return {IPromise<RelationshipRet[]>}
     *
     * e.g. if in project TS_123, P_A is P_B's manager,
     * - getBetweenRelationships(P_A, P_B, TS_123) returns [{ name: 'Manager' }]
     * - getBetweenRelationships(P_B, P_A, TS_123) returns [{ name: 'DirectReport' }]
     */
    ThreeSixtyRelationshipsService.prototype.getBetweenRelationships = function (sourcePersonId, targetPersonId, threeSixtyId) {
        var url = this.getRelationshipsUrl(threeSixtyId) + "/source/" + sourcePersonId + "/target/" + targetPersonId;
        return this.$http.get(url, { cache: true })
            .then(function (response) {
            return response.data;
        }).catch(function (err) {
            console.error('Could not get relationships between' +
                (sourcePersonId + " and " + targetPersonId + " in " + threeSixtyId + ": "), err);
            return [];
        });
    };
    /**
     * Provides list of invited evaluators for a subject
     */
    ThreeSixtyRelationshipsService.prototype.getSubjectsInvitedEvaluators = function (subjectPersonId, relationshipId, threeSixtyId) {
        if (!subjectPersonId) {
            return this.$q.when([]);
        }
        var url = this.getRelationshipsUrl(threeSixtyId) + "/nominations/subject/" + subjectPersonId;
        var requestOptions = {
            cache: true
        };
        if (relationshipId) {
            requestOptions.params = {
                relationshipId: relationshipId
            };
        }
        return this.$http.get(url, requestOptions)
            .then(function (response) {
            return response.data;
        }).catch(function (err) {
            console.error('Could not get invited evaluators of ' +
                (relationshipId + " for subject: " + subjectPersonId + ": "), err);
            return [];
        });
    };
    ThreeSixtyRelationshipsService.prototype.getRelationshipsUrl = function (threeSixtyId) {
        if (!threeSixtyId) {
            threeSixtyId = this.$routeParams.projectId || this.env.get('activeProjectId');
        }
        return this.API_ROUTES.THREE_SIXTY_RELATIONSHIPS + "/threeSixty/" + threeSixtyId;
    };
    ThreeSixtyRelationshipsService.$inject = [
        '$q',
        '$http',
        '$sanitize',
        '$filter',
        'pr.API_ROUTES',
        'rsEnvironment',
        '$routeParams',
        'prActiveReport'
    ];
    return ThreeSixtyRelationshipsService;
}());
(function (angularModule) {
    angularModule.service('prThreesixtyRelationshipsService', ThreeSixtyRelationshipsService);
})(angular.module('qualtrics.pr'));
