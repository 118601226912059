"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Adds a global Promise object (Node or Browser) if native Promise doesn't exist.
require("promise-polyfill/src/polyfill");
require("core-js");
// getAttributesNames used in text widget for piped text
// https://developer.mozilla.org/en-US/docs/Web/API/Element/getAttributeNames
if (Element.prototype.getAttributeNames === undefined) {
    Element.prototype.getAttributeNames = function () {
        var attributes = this.attributes;
        var length = attributes.length;
        var result = new Array(length);
        for (var i = 0; i < length; i++) {
            result[i] = attributes[i].name;
        }
        return result;
    };
}
