"use strict";
(function (module) {
    'use strict';
    var BROWSERS = {
        SAFARI: 'safari'
    };
    module.factory('pr.navigationService', [
        '$injector',
        '$routeParams',
        '$window',
        function ($injector, $routeParams, $window) {
            var bulkFieldService, metricsService;
            document.body.classList.add(getBrowser());
            var isExport = !!$routeParams.showAllPages;
            var isPublic = !!$routeParams.publicHash;
            var isPreview = !!$routeParams.preview;
            var isSingleWidget = !!$routeParams.widgetId;
            var isParticipantPreview = isPreview && !!$routeParams.token;
            // isEditor=true when in the web view, or in an export triggered from the web view (i.e. editor triggered export)
            var isEditor = !isPublic && !isPreview;
            var isReadOnly = !isEditor || isExport;
            return {
                isEditor: isEditor,
                isExport: isExport,
                isPublic: isPublic,
                isPreview: isPreview,
                isParticipantPreview: isParticipantPreview,
                isReadOnly: isReadOnly,
                isSingleWidget: isSingleWidget,
                loadPreview: function loadPreview(source) {
                    if (!bulkFieldService) {
                        // inject like this because bulkFieldService depends on the
                        // 'getViewMode' method of this service
                        bulkFieldService = $injector.get('pr.bulkFieldService');
                    }
                    if (!metricsService) {
                        metricsService = $injector.get('prMetricsService');
                    }
                    var bulkFieldValue = bulkFieldService.getConfig('value');
                    var path = $window.location.href;
                    path += (_.includes(path, '?') ? '&' : '?') + 'preview=true';
                    if (bulkFieldValue) {
                        path += '&bulkFieldValue=' + bulkFieldValue;
                    }
                    metricsService.preview(source);
                    $window.open(path, '_blank');
                }
            };
            function getBrowser() {
                // Currently, we only have special logic for safari
                // add additional browsers if its ever necessary
                return window.safari ? BROWSERS.SAFARI : 'unknownBrowser';
            }
        }
    ]);
}(angular.module('qualtrics.pr')));
