"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var angular = __importStar(require("angular"));
(function (angularModule) {
    'use strict';
    angularModule.factory('prRenameReportModal', [
        'qstrap.modal',
        function (modal) {
            function show() {
                return modal({
                    show: true,
                    controller: 'prRenameReportController',
                    title: '<i class="icon icon-pencil"></i><span translate>RENAME_REPORT</span>',
                    content: '<div qstrap.spin spin-if="$ctrl.spin" class="form-group">' +
                        '<label translate>REPORT_NAME</label>' +
                        '<input class="dashboard-rename-input form-control form-control-full" ' +
                        'type="text" ng-model="$ctrl.reportName"/>' +
                        '</div>',
                    buttons: [
                        '<button class="btn btn-success" ng-disabled="$ctrl.spin" ' +
                            'ng-click="$ctrl.renameReport();close()" translate>SAVE</button>',
                        '<button class="btn btn-hover" ng-click="close()" translate>CANCEL</button>'
                    ]
                });
            }
            return {
                show: show
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
