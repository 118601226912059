"use strict";
(function (module) {
    'use strict';
    function getDefaultOptions() {
        return _.cloneDeep({
            zoom: '100%',
            toc: false,
            grid: false,
            outlines: false,
            margins: true,
            highlights: false
        });
    }
    var FIT = 'Fit', PAGE_MARGIN = 32, FIT_MARGIN = 40, LEFT_PCT = -50, Z_WINDOW_SELECTOR = '.pr-zoom-window', Z_CONTENT_SELECTOR = '.pr-zoom-content', BODY_SELECTOR = 'body', LS_KEY = 'prViewOptions';
    module.factory('prViewOptions', [
        '$window',
        '$timeout',
        '$filter',
        'prLocalStorage',
        'prEventService',
        'prPageLayoutService',
        function ($window, $timeout, $filter, localStorage, eventService, pageLayout) {
            var currentZoomFactor;
            var translate = $filter('translate');
            var options = localStorage.get(LS_KEY) || getDefaultOptions();
            // wait a cycle
            $timeout(init);
            function init() {
                updateZoom();
                toggleClass('grid');
                toggleClass('margins');
                toggleClass('outlines');
                toggleClass('highlights');
                $($window).on('resize', function () { updateZoom(); });
            }
            function getSetProp(prop, value) {
                if (!_.isNil(value)) {
                    options[prop] = value;
                    localStorage.set(LS_KEY, options);
                }
                return options[prop];
            }
            function getSetToggleProp(prop, value) {
                var ret = getSetProp(prop, value);
                if (!_.isNil(value)) {
                    toggleClass(prop);
                }
                return ret;
            }
            function toggleClass(prop) {
                var val = getSetProp(prop), func = val ? 'addClass' : 'removeClass';
                $(BODY_SELECTOR)[func]('show-' + prop);
            }
            function zoom(z) {
                var ret = getSetProp('zoom', z);
                if (!_.isNil(z)) {
                    updateZoom(z);
                }
                return ret;
            }
            function getZoomLabel() {
                return translate(_.toUpper(getSetProp('zoom')));
            }
            function showToc() {
                return false;
            }
            function toggleToc() {
                return showToc(!showToc());
            }
            function showGrid(on) {
                return getSetToggleProp('grid', on);
            }
            function toggleGrid() {
                return showGrid(!showGrid());
            }
            function showMargins(on) {
                return getSetToggleProp('margins', on);
            }
            function toggleMargins() {
                return showMargins(!showMargins());
            }
            function showOutlines(on) {
                return getSetToggleProp('outlines', on);
            }
            function toggleOutlines() {
                return showOutlines(!showOutlines());
            }
            function showHighlights(on) {
                return getSetToggleProp('highlights', on);
            }
            function toggleHighlights() {
                return showHighlights(!showHighlights());
            }
            function getZoomItems() {
                var zm = zoom();
                var ret = _.map(['50%', '75%', '90%', '100%', '125%', '150%', '200%'], function (val) {
                    return {
                        label: val,
                        value: val,
                        selected: zm === val
                    };
                });
                ret.splice(0, 0, {
                    label: translate('FIT'),
                    value: FIT,
                    selected: zm === FIT
                }, {
                    divider: true
                });
                return ret;
            }
            function updateZoom(zm) {
                var zoomWindow = $(Z_WINDOW_SELECTOR)
                    .css({
                    'height': '',
                    'width': ''
                }), zoomContent = $(Z_CONTENT_SELECTOR)
                    .css({
                    'transform': '',
                    'left': ''
                });
                zm = _getZoomFactor(zm);
                currentZoomFactor = zm;
                zoomWindow.css({
                    'height': zm * (zoomContent.height() + PAGE_MARGIN),
                    'width': pageLayout.getWidth()
                });
                zoomContent.css({
                    'transform': 'scale(' + zm + ')',
                    'left': LEFT_PCT * zm + '%'
                });
                eventService.trigger('zoomChange');
            }
            function _getZoomFactor(zm) {
                zm = zm || getSetProp('zoom');
                if (zm === FIT) {
                    return ($(Z_CONTENT_SELECTOR).width() - FIT_MARGIN) / pageLayout.getWidth();
                }
                return parseFloat(zm) / 100;
            }
            /*
             * The internal function _getZoomFactor depends on CSS being unset to return
             * the correct value when zoom is set to Fit. So we cache the value whenever the zoom
             * is adjusted.
             */
            function getZoomFactor() {
                return currentZoomFactor || 1;
            }
            return {
                zoom: zoom,
                getZoomLabel: getZoomLabel,
                getZoomFactor: getZoomFactor,
                updateZoom: updateZoom,
                getZoomItems: getZoomItems,
                showToc: showToc,
                showGrid: showGrid,
                showMargins: showMargins,
                toggleToc: toggleToc,
                toggleGrid: toggleGrid,
                toggleMargins: toggleMargins,
                showOutlines: showOutlines,
                toggleOutlines: toggleOutlines,
                showHighlights: showHighlights,
                toggleHighlights: toggleHighlights
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
