"use strict";
(function (module) {
    'use strict';
    module.factory('pr.viewResultsProjects', [
        '$http',
        'pr.API_ROUTES',
        function ($http, API_ROUTES) {
            var cache = {};
            function getViewResultsProjects(surveyId) {
                if (!cache[surveyId]) {
                    cache[surveyId] = $http.get(API_ROUTES.REPORTS + '/' + surveyId + '?product=results')
                        .then(function (data) {
                        return data.data;
                    })
                        .then(function (data) {
                        _.forEach(data, function (on) {
                            cache[surveyId][on._id] = on;
                        });
                        return data;
                    });
                }
                return cache[surveyId];
            }
            function getViewResultsProject(surveyId, projectId) {
                return getViewResultsProjects(surveyId).then(function () {
                    return cache[surveyId][projectId];
                });
            }
            function getPagesOfReport(projectId) {
                return $http.get(API_ROUTES.CONTAINERS + '/' + projectId + '/compound?inlineStates=true')
                    .then(function (data) {
                    return data.data.definition.pages;
                });
            }
            return {
                getViewResultsProjects: getViewResultsProjects,
                getViewResultsProject: getViewResultsProject,
                getPagesOfReport: getPagesOfReport
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
