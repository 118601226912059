"use strict";
(function (module) {
    'use strict';
    /*
     * NOTE
     *
     * this service is depecated please use
     * prFieldsets,  prFields, and prFieldMenu
     * directly
     */
    module.factory('pr.fieldsets', [
        'prFieldsets',
        'prFields',
        'prFieldMenu',
        function (fieldsets, fields, FieldMenu) {
            return {
                getMainFieldset: fieldsets.getMainFieldset.bind(fieldsets),
                getMainSourceId: fieldsets.getMainSourceId.bind(fieldsets),
                getFieldsetBySurveyId: fieldsets.getFieldsetBySourceId.bind(fieldsets),
                isWeightingEnabled: fieldsets.isWeightingEnabled.bind(fieldsets),
                getFieldLabel: fields.getFieldLabel.bind(fields),
                getFieldLabelById: fields.getFieldLabelById.bind(fields),
                getFieldById: fields.getFieldById.bind(fields),
                getGroupFieldById: fields.getGroupFieldById.bind(fields),
                getFieldViewById: fields.getFieldViewById.bind(fields),
                getFieldIcon: fields.getFieldIcon.bind(fields),
                getFieldIconById: fields.getFieldIconById.bind(fields),
                getFieldsByType: function (surveyId, type) {
                    return new FieldMenu(surveyId).getFieldsByType(type);
                },
                getFieldMenuBySurveyId: function (surveyId, options) {
                    return new FieldMenu(surveyId).get(options);
                },
                getPipedTextEdMenuBySourceId: function (sourceId) {
                    return new FieldMenu(sourceId).getPipedTextEdMenu();
                },
                getPipedTextQuestionMenuBySourceId: function (sourceId, options) {
                    return new FieldMenu(sourceId).getPipedTextQuestionMenu(options);
                }
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
