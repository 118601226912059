"use strict";
(function (module) {
    /* dq is global*/
    'use strict';
    GlobalOptionsPreviewWidgetController.$inject = [
        '$scope',
        '$timeout',
        '$filter',
        'widget.componentBase.colorPaletteService'
    ];
    function GlobalOptionsPreviewWidgetController($scope, $timeout, $filter, colorPaletteService) {
        var translate = $filter('translate');
        var $pwCtrl = this;
        $scope.$pwCtrl = $pwCtrl;
        var LABEL = translate('LABEL');
        var GROUP = translate('GROUP');
        function getPreviewData(metric) {
            var data = [
                {
                    key: LABEL + ' 1',
                    values: [{
                            x: GROUP + ' 1',
                            y: 25
                        },
                        {
                            x: GROUP + ' 2',
                            y: 15
                        }]
                },
                {
                    key: LABEL + ' 2',
                    values: [{
                            x: GROUP + ' 1',
                            y: 45
                        },
                        {
                            x: GROUP + ' 2',
                            y: 25
                        }]
                },
                {
                    key: LABEL + ' 3',
                    values: [{
                            x: GROUP + ' 1',
                            y: 5
                        },
                        {
                            x: GROUP + ' 2',
                            y: 25
                        }]
                }
            ];
            if (metric === 'percentage') {
                // sum data
                var sum_1 = 0;
                _.forEach(data, function (series) {
                    _.forEach(series.values, function (value) {
                        sum_1 += value.y;
                    });
                });
                // calculate percentage
                _.forEach(data, function (series) {
                    _.forEach(series.values, function (value) {
                        value.y = value.y / sum_1;
                    });
                });
            }
            return data;
        }
        angular.extend($pwCtrl, {
            $onInit: function () {
                $pwCtrl.loaded = false;
                $timeout(function () {
                    $pwCtrl.generateChart();
                });
                var props = ['$pwCtrl.decimals', '$pwCtrl.widgetPadding',
                    '$pwCtrl.metric', '$pwCtrl.colorPalette', '$pwCtrl.textFormat'];
                _.forEach(props, function (prop) {
                    $scope.$watch(prop, function (newVal, oldVal) {
                        if (!_.isEqual(newVal, oldVal)) {
                            $pwCtrl.generateChart();
                        }
                    }, true);
                });
            },
            generateChart: function () {
                var palette = colorPaletteService.getColorPalette($pwCtrl.colorPalette);
                var colorFn = function () {
                    return palette.getNext();
                };
                var padding = 96 * $pwCtrl.widgetPadding;
                var height = 180 - padding * 2;
                var width = 300 - padding * 2;
                $('#preview_chart').height(height + 'px');
                $('#preview_chart').width(width + 'px');
                if (!$pwCtrl.previewChart) {
                    $pwCtrl.previewChart = new dq.Chart.BarChart({ containerId: 'preview_chart' });
                }
                $pwCtrl.previewChart.setData(getPreviewData($pwCtrl.metric))
                    .setColorScheme(colorFn)
                    .setTooltip(false)
                    .showLegend(false)
                    .isStacked(false)
                    .setTickFormat(d3.format('.' + $pwCtrl.decimals + ($pwCtrl.metric === 'percentage' ? '%' : 'f')))
                    .render();
                $pwCtrl.loaded = true;
            },
            getWidgetPaddingStyle: function () {
                return {
                    'padding': $pwCtrl.widgetPadding
                };
            }
        });
    }
    module.controller('prGlobalOptionsPreviewWidgetController', GlobalOptionsPreviewWidgetController);
})(angular.module('qualtrics.pr'));
