"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("angular");
function DisplayLogicRequestParamsManagerFactory() {
    var widgetParamsMap = {};
    return {
        setDisplayLogicRequestParams: function (widgetId, displayLogicParams) {
            // @ts-ignore
            widgetParamsMap[widgetId] = displayLogicParams;
        },
        getDisplayLogicRequestParams: function (widgetId) {
            // @ts-ignore
            return widgetParamsMap[widgetId];
        }
    };
}
(function (module) {
    module.factory('pr.displayLogicParamsManager', DisplayLogicRequestParamsManagerFactory);
})(angular_1.module('qualtrics.pr'));
