"use strict";
(function (module) {
    'use strict';
    function PublicExportPasscodeController($scope, $http, $location, $filter, publicExportPasscodeModal, downloadHelper, FILE_TYPES, metricsService) {
        var $ctrl = this;
        $scope.$ctrl = $ctrl;
        var translate = $filter('translate');
        angular.extend($ctrl, {
            passscode: '',
            errorMessage: '',
            pending: false,
            submitPasscode: function () {
                if (!$ctrl.passcode || $ctrl.passcode.length === 0) {
                    return;
                }
                $ctrl.pending = true;
                $ctrl.errorMessage = '';
                $http.post($location.url(), {
                    // include 'passcodeCheck' so server knows that this is just a passcode check;
                    // the server need not return entire view
                    passcode: $ctrl.passcode,
                    passcodeCheck: true
                }).then(function () {
                    // correct passcode
                    $ctrl.pending = false;
                    publicExportPasscodeModal.close();
                    metricsService.exportComplete(FILE_TYPES.PDF, metricsService.CALL_SOURCE.BUTTON);
                    downloadHelper.downloadPDF($ctrl.passcode);
                })
                    .catch(function (response) {
                    // incorrect passcode
                    $ctrl.pending = false;
                    if (response.status === 401) {
                        $ctrl.errorMessage = translate('LOGIN.INVALID_PASSCODE');
                    }
                    else {
                        $ctrl.errorMessage = translate('AN_ERROR_OCCURRED');
                    }
                });
            }
        });
    }
    PublicExportPasscodeController.$inject = ['$scope',
        '$http',
        '$location',
        '$filter',
        'prPublicExportPasscodeModal',
        'prDownloadHelper',
        'pr.EXPORT_FILE_TYPES',
        'prMetricsService'
    ];
    module.controller('prPublicExportPasscodeController', PublicExportPasscodeController);
})(angular.module('qualtrics.pr'));
