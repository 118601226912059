"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var custom_filter_utils_1 = require("@services/filters/custom-filters/custom-filter-utils");
require("@services/filters/custom-filters/unsolicited-eval-filter");
(function (module) {
    'use strict';
    var CUSTOM_FILTERS = 'customFilters';
    var FILTER_OBJ_PROPS = [
        'filterId',
        'filterName',
        'type',
        'filter',
        'children'
    ];
    module.constant('pr.CUSTOM_FILTER_FIELDS', custom_filter_utils_1.CUSTOM_FILTER_FIELDS);
    module.constant('pr.CUSTOM_FILTER_SUBMENU_TYPES', {
        PERSON_METADATA: 'PersonMetadata'
    });
    module.constant('pr.CUSTOM_FILTER_DIRECTIVE_LINKERS', {
        EVAL_SCOPE: 'prEvalScopeFilterDirectiveLinker',
        PERSON_METADATA: 'prPersonMetadataFilterDirectiveLinker',
        RELATIONSHIP: 'prRelationshipFilterDirectiveLinker',
        SOURCE_ID: 'prSourceFilterDirectiveLinker',
        UNSOL_EVAL: 'prUnsolicitedEvalFilterDirectiveLinker'
    });
    module.factory('pr.customFilterService', [
        'fc.wrapperService',
        'prActiveReport',
        '$filter',
        'pr.CUSTOM_FILTER_FIELDS',
        'pr.CUSTOM_FILTER_SUBMENU_TYPES',
        'pr.CUSTOM_FILTER_DIRECTIVE_LINKERS',
        '$injector',
        'pr.customFilterUtils',
        function (fcWrapperService, activeReport, $filter, pr_CUSTOM_FILTER_FIELDS, CUSTOM_FILTER_SUBMENU_TYPES, FILTER_DIRECTIVE_LINKERS, $injector, customFilterUtils) {
            /**
             * Repair/add missing parameters that can be assumed/constructed
             * @param  {Object} filter to augment
             * @return {Object} the augmented filter
             */
            function prepareFilter(filter) {
                if (!_.isObject(filter)) {
                    console.error('No filter passed');
                    return;
                }
                filter = _.pick(filter, FILTER_OBJ_PROPS);
                if (!customFilterUtils.isCustomFilterId(filter.filterId)) {
                    // new filter
                    filter.filterId = customFilterUtils.constructFilterId();
                }
                if (filter.filter) {
                    filter.type = 'filter';
                }
                filter.filterName = filter.filterName || $filter('translate')('NEW_FILTER');
                return filter;
            }
            /**
             * Gets the custom filters stored on the container for the given fieldsetId,
             * either as an object or array
             * @param  fieldsetId
             * @param  {boolean} whether the filters should be returned as an Object or Array
             * @return {Promise.<Array|Object>} the custom filters
             */
            function getFilters(fieldsetId, asMap) {
                if (!fieldsetId || !_.isString(fieldsetId)) {
                    console.error('Cannot get custom filters with no fieldsetId');
                    return;
                }
                var filters = (activeReport.getMetadata(CUSTOM_FILTERS) || {})[fieldsetId] || {};
                return asMap ? filters : _.values(filters);
            }
            /**
             * Updates the custom filters stored on the context meta of the container
             * @param  fieldsetId
             * @param  {Array.<Object>} updatedFilters
             * @return {Promise<*>} result of calling update to container
             */
            function updateFilters(fieldsetId, updatedFilters) {
                if (!fieldsetId || !_.isString(fieldsetId) || !_.isArray(updatedFilters)) {
                    console.error('Either no updated filters or no fieldsetId passed');
                    return;
                }
                else if (_.isEmpty(updatedFilters)) {
                    return;
                }
                var meta = _.cloneDeep(activeReport.getMetadata(CUSTOM_FILTERS)) || {};
                meta[fieldsetId] = meta[fieldsetId] || {};
                _.forEach(updatedFilters, function (filter, index) {
                    if (!_.isObject(filter)) {
                        console.error('Invalid Filter', filter);
                    }
                    else if (_.isEmpty(filter.filter) && _.isEmpty(filter.children)) {
                        // delete filter
                        delete meta[fieldsetId][filter.filterId];
                    }
                    else {
                        // create/update filter
                        filter = prepareFilter(filter);
                        if (customFilterUtils.isValidFilter(filter)) {
                            meta[fieldsetId][filter.filterId] = filter;
                            updatedFilters[index].filterId = filter.filterId;
                            // update filter id for caller
                        }
                        else {
                            console.error('Invalid Filter', filter);
                        }
                    }
                });
                if (_.isEmpty(meta[fieldsetId])) {
                    delete meta[fieldsetId];
                }
                return activeReport.setMetadata(CUSTOM_FILTERS, meta);
            }
            /**
             * Set the scope for the custom filter.
             * This function acts as the controller for the custom filters.
             * @param {object} $scope
             * @param {object} $rootScope
             * @param {object} $fcFilter - the equivalent to $filter within filters-client scope
             */
            function linkDirective($rootScope, $fcFilter, $scope) {
                function getLinker() {
                    var field = $scope.displayedFilter.filter.field;
                    var type = $scope.type;
                    var linker;
                    switch (field) {
                        case pr_CUSTOM_FILTER_FIELDS.EVAL_SCOPE:
                            linker = $injector.get(FILTER_DIRECTIVE_LINKERS.EVAL_SCOPE);
                            break;
                        case pr_CUSTOM_FILTER_FIELDS.RELATIONSHIP:
                            linker = $injector.get(FILTER_DIRECTIVE_LINKERS.RELATIONSHIP);
                            break;
                        case pr_CUSTOM_FILTER_FIELDS.SOURCE_ID:
                            linker = $injector.get(FILTER_DIRECTIVE_LINKERS.SOURCE_ID);
                            break;
                        case pr_CUSTOM_FILTER_FIELDS.UNSOL_EVAL:
                            linker = $injector.get(FILTER_DIRECTIVE_LINKERS.UNSOL_EVAL);
                            break;
                        default:
                            if (isCustomSubmenuItem(type)) {
                                linker = getCustomSubmenuItemLinker(type);
                                break;
                            }
                            throw Error('Unrecognized field type', field);
                    }
                    new linker($rootScope, $fcFilter, $scope);
                }
                $scope.$watch('fieldLabel', function () {
                    getLinker();
                });
            }
            function isCustomSubmenuItem(type) {
                return _.includes(_.values(CUSTOM_FILTER_SUBMENU_TYPES), type);
            }
            function getCustomSubmenuItemLinker(type) {
                switch (type) {
                    case CUSTOM_FILTER_SUBMENU_TYPES.PERSON_METADATA:
                        return $injector.get(FILTER_DIRECTIVE_LINKERS.PERSON_METADATA);
                    default:
                        throw Error('Unrecognized custom submenu type');
                }
            }
            return {
                register: function () {
                    fcWrapperService.registerCustomFilterService({
                        isCustomFilterId: customFilterUtils.isCustomFilterId.bind(customFilterUtils),
                        isCustomFilter: customFilterUtils.isCustomFilter.bind(customFilterUtils),
                        getFilters: getFilters,
                        updateFilters: updateFilters,
                        linkDirective: linkDirective
                    });
                },
                prepareFilter: prepareFilter,
                getFilters: getFilters,
                updateFilters: updateFilters,
                linkDirective: linkDirective
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
