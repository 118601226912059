"use strict";
/* eslint no-redeclare: "off", no-prototype-builtins: "off" */
/* global jQuery */
(function ($) {
    var HANDLE_CLASS = 'resize-handle', HANDLE_DIR_ATTR = 'data-resize-handle-dir', SHOWHANDLE_CLASS = 'show-handles', CURSOR_CLASS = 'cursor-resizing', BODY_SELECTOR = 'body';
    $.widget('layout.resizer', $.ui.mouse, {
        options: {
            handles: 'n,e,s,w,se,sw,ne,nw',
            disabled: false,
            zoomParent: null
        },
        _create: function () {
            this._setupHandles();
            this._mouseInit();
        },
        _destroy: function () {
            this._mouseDestroy();
            this.element.off();
            this._handles.remove();
        },
        _setupHandles: function () {
            var o = this.options, classes = o.handles.split(','), handles = '', className, i;
            for (i = classes.length; i--;) {
                className = HANDLE_CLASS + '-' + classes[i];
                handles += '<div class="' + className + '" ' + HANDLE_DIR_ATTR + '="' + classes[i] + '"></div>';
            }
            this._handles = $(handles);
            this.element.append(this._handles);
        },
        _mouseCapture: function (event) {
            var capture = false;
            this._handles.each(function (i, handle) {
                if (handle === event.target || $.contains(handle, event.target)) {
                    capture = true;
                }
            });
            var shouldCapture = !this.options.disabled && capture;
            if (shouldCapture) {
                event.stopImmediatePropagation();
            }
            return shouldCapture;
        },
        _mouseStart: function (event) {
            var dir = $(event.target).attr(HANDLE_DIR_ATTR), el = this.element, zoom = $(this.options.zoomParent).getZoom();
            this._moveInfo = {
                dir: dir,
                width: parseInt(el.css('width') || el.width()) / zoom,
                height: parseInt(el.css('height') || el.height()) / zoom,
                left: parseInt(el.css('left') || 0),
                top: parseInt(el.css('top') || 0),
                pageX: event.pageX,
                pageY: event.pageY,
                zoom: zoom
            };
            this._afterInfo = {};
            // get the initial values so we can fall back to them
            // add cursor css to the body so it shows the right cursor
            $(BODY_SELECTOR).css({ cursor: dir + '-resize' }).addClass(CURSOR_CLASS);
            this.element.addClass(SHOWHANDLE_CLASS);
            this._propagate('resizeStart', event);
        },
        _mouseDrag: function (event) {
            var info = this._moveInfo, zoom = info.zoom, dx = (event.pageX - info.pageX) / zoom, dy = (event.pageY - info.pageY) / zoom;
            this._afterInfo = changeGetters[info.dir](info, event, dx, dy);
            this._propagate('resize', event);
        },
        _mouseStop: function (event) {
            $(BODY_SELECTOR).css({ cursor: '' }).removeClass(CURSOR_CLASS);
            this.element.removeClass(SHOWHANDLE_CLASS);
            this._propagate('resizeFinished', event);
        },
        _propagate: function (n, event) {
            var ui = this.ui();
            this._trigger(n, event, ui);
        },
        ui: function () {
            var attrs = ['top', 'left', 'width', 'height'], moveInfo = this._moveInfo, after = this._afterInfo, before = {}, change = {};
            $.each(attrs, function (i, attr) {
                before[attr] = moveInfo[attr] || 0;
                change[attr] = after.hasOwnProperty(attr) ? (after[attr] || 0) - before[attr] : 0;
            });
            return {
                before: before,
                after: after,
                change: change
            };
        }
    });
    var changeGetters = {
        e: function (info, event, dx) {
            return {
                width: info.width + dx
            };
        },
        w: function (info, event, dx) {
            return {
                left: info.left + dx,
                width: info.width - dx
            };
        },
        n: function (info, event, dx, dy) {
            return {
                top: info.top + dy,
                height: info.height - dy
            };
        },
        s: function (info, event, dx, dy) {
            return {
                height: info.height + dy
            };
        },
        se: function (info, event, dx, dy) {
            return $.extend(changeGetters.s(info, event, dx, dy), changeGetters.e(info, event, dx, dy));
        },
        sw: function (info, event, dx, dy) {
            return $.extend(changeGetters.s(info, event, dx, dy), changeGetters.w(info, event, dx, dy));
        },
        ne: function (info, event, dx, dy) {
            return $.extend(changeGetters.n(info, event, dx, dy), changeGetters.e(info, event, dx, dy));
        },
        nw: function (info, event, dx, dy) {
            return $.extend(changeGetters.n(info, event, dx, dy), changeGetters.w(info, event, dx, dy));
        }
    };
})(jQuery);
