"use strict";
(function (module) {
    'use strict';
    module.factory('prIsolationService', [
        '$http',
        'pr.API_ROUTES',
        function ($http, API_ROUTES) {
            this.isBrandEncrypted = function () {
                return $http.get(API_ROUTES.ISOLATION_SERVICE, { cache: true })
                    .then(function (response) {
                    return response.data;
                });
            };
            return this;
        }
    ]);
}(angular.module('qualtrics.pr')));
