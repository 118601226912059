"use strict";
(function (module) {
    'use strict';
    /*
     * Draws a shadow box where the widget should be
     */
    module.directive('prWidgetShadow', [
        'prPageLayoutService',
        function (pageLayout) {
            return {
                restrict: 'E',
                scope: false,
                link: function ($scope, $element) {
                    startWatchingLayout();
                    var layoutWatcher;
                    function stopWatchingLayout() {
                        if (layoutWatcher) {
                            layoutWatcher();
                            layoutWatcher = null;
                        }
                    }
                    function startWatchingLayout() {
                        // using $watchCollection is the bomb! We get about 50% speed increase over watching it ourselves or using ng-style
                        layoutWatcher = $scope.$watchCollection('shadow', function () {
                            $element.css(pageLayout.convertLayoutToStyle($scope.shadow));
                        });
                    }
                    $scope.$on('$destroy', function () {
                        if ($scope) {
                            stopWatchingLayout();
                            $scope = null;
                            $element = null;
                        }
                    });
                }
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
