"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var translations_1 = require("@shared/constants/translations");
(function (module) {
    'use strict';
    module.factory('pr.scheduleEmailService', [
        '$q',
        '$http',
        'pr.API_ROUTES',
        'pr.scheduleEmailDateTimeMenuService',
        'rsEnvironment',
        'pr.reportTypeService',
        'pr.distributionService',
        'pr.REPORT_TYPES',
        function ($q, $http, API_ROUTES, dateTimeMenuService, env, reportTypeService, distributionService, REPORT_TYPES) {
            var DEFAULT_EMAIL_ADDRESS = 'noreply@qemailserver.com';
            function ScheduledEmail(id, containerId, recipientsInfo, fromName, fromEmail, replyToEmail, cronDatetime, timezone, subject, message, lang) {
                var scheduledEmail = NowEmail(id, containerId, recipientsInfo, fromName, fromEmail, replyToEmail, subject, message, lang);
                scheduledEmail.cronDatetime = cronDatetime;
                scheduledEmail.scheduledFrequencyInWords = dateTimeMenuService.getFrequencyInWordsFromCron(cronDatetime);
                scheduledEmail.timezone = timezone;
                scheduledEmail.lang = lang || translations_1.DEFAULT_LANGUAGE_CODE;
                return scheduledEmail;
            }
            function NowEmail(id, containerId, recipientsInfo, fromName, fromEmail, replyToEmail, subject, message, lang) {
                return {
                    id: id,
                    containerId: containerId,
                    recipientsInfo: recipientsInfo,
                    fromName: fromName,
                    fromEmail: fromEmail,
                    replyToEmail: replyToEmail,
                    subject: subject,
                    message: message,
                    lang: lang
                };
            }
            function extractInfoFromSchedules(schedule) {
                return ScheduledEmail(schedule.id, schedule.containerId, schedule.recipientsInfo, schedule.fromName, schedule.fromEmail, schedule.replyToEmail, schedule.cronDatetime, schedule.timezone, schedule.subject, schedule.message, schedule.lang);
            }
            /**
             * get email settings and build email menu items for scheduled email
             * - get all emails from admin settings
             * - check the spf record of every email and build menu items
             *
             * @return {object} - fromEmailMenuItems, replyToEmailMenuItems, defaultSettings
             */
            function getEmailSettings() {
                var reportType = reportTypeService.getReportType();
                var productOfReport = reportType === REPORT_TYPES.RC ? 'rc' : 'ex';
                var productsToPick = ['cx', 'ex', 'rc', 'default'];
                return $http.get(API_ROUTES.ADMIN_SERVICE + '/email-settings').then(function (res) {
                    var settings = res.data || {};
                    var fromEmailSet = {};
                    var replyToEmailSet = {};
                    var defaultEmailSettings;
                    _.forEach(productsToPick, function (product) {
                        var emailEntry = settings[product];
                        var fromEmail = emailEntry && emailEntry.from;
                        var replyToEmail = emailEntry && emailEntry.replyTo;
                        addEmailToSet(fromEmail, fromEmailSet);
                        addEmailToSet(replyToEmail, replyToEmailSet);
                        var shouldSetDefaultEmailSettings = product === productOfReport;
                        if (shouldSetDefaultEmailSettings) {
                            defaultEmailSettings = {
                                fromEmail: fromEmail || DEFAULT_EMAIL_ADDRESS,
                                replyToEmail: replyToEmail || DEFAULT_EMAIL_ADDRESS
                            };
                        }
                    });
                    addEmailToSet(DEFAULT_EMAIL_ADDRESS, fromEmailSet);
                    addEmailToSet(DEFAULT_EMAIL_ADDRESS, replyToEmailSet);
                    if (!defaultEmailSettings) {
                        defaultEmailSettings = {
                            fromEmail: DEFAULT_EMAIL_ADDRESS,
                            replyToEmail: DEFAULT_EMAIL_ADDRESS
                        };
                    }
                    var promises = [];
                    var fromEmailMenuItems = [];
                    var replyToEmailMenuItems = [];
                    buildEmailMenuItems(promises, fromEmailSet, fromEmailMenuItems);
                    buildEmailMenuItems(promises, replyToEmailSet, replyToEmailMenuItems);
                    return $q.all(promises).then(function () {
                        return {
                            fromEmailMenuItems: fromEmailMenuItems,
                            replyToEmailMenuItems: replyToEmailMenuItems,
                            defaultEmailSettings: defaultEmailSettings
                        };
                    });
                });
            }
            function addEmailToSet(email, emailSet) {
                if (email) {
                    emailSet[email] = true;
                }
            }
            /**
             * Check if the domain every email in a set has an SPF record
             * if it has an SPF record, add it to menu items
             *
             * @param  {array} promises
             * @param  {object} emailSet
             * @param  {array} menuItems
             * @return
             */
            function buildEmailMenuItems(promises, emailSet, menuItems) {
                _.forEach(emailSet, function (value, key) {
                    var promise = distributionService.hasSpfRecord(key).then(function (hasQualtricsSpf) {
                        if (hasQualtricsSpf) {
                            menuItems.push({
                                value: key,
                                label: key
                            });
                        }
                    });
                    promises.push(promise);
                });
            }
            return {
                ScheduledEmail: ScheduledEmail,
                NowEmail: NowEmail,
                getValidDomains: function () {
                    return $http.get(API_ROUTES.SCHEDULE_EMAIL + '/valid-email-domains', {
                        cache: true
                    });
                },
                getScheduledEmailsForContainerId: function (containerId) {
                    return $http.get(API_ROUTES.SCHEDULE_EMAIL + '/schedule-groups/' + containerId).then(function (res) {
                        var emailSchedules = res.data;
                        return _.map(emailSchedules, extractInfoFromSchedules);
                    });
                },
                createScheduledEmail: function (scheduledEmail) {
                    var surveyId = env.get('rsActiveSurvey');
                    return $http.post(API_ROUTES.SCHEDULE_EMAIL + '/' + surveyId, scheduledEmail).then(function (res) {
                        return res.data;
                    });
                },
                updateScheduledEmail: function (scheduledEmail) {
                    var surveyId = env.get('rsActiveSurvey');
                    var data = JSON.stringify(scheduledEmail);
                    return $http.put(API_ROUTES.SCHEDULE_EMAIL + '/' + surveyId + '/' + scheduledEmail.id, data).then(function (res) {
                        return extractInfoFromSchedules(res.data);
                    });
                },
                deleteScheduledEmail: function (scheduledEmail) {
                    return $http.delete(API_ROUTES.SCHEDULE_EMAIL + '/' + scheduledEmail.id);
                },
                deleteScheduledEmailsForContainerId: function (containerId) {
                    return $http.delete(API_ROUTES.SCHEDULE_EMAIL + '/schedule-groups/' + containerId);
                },
                sendEmailNow: function (scheduledEmail) {
                    var surveyId = env.get('rsActiveSurvey');
                    var data = JSON.stringify(scheduledEmail);
                    return $http.post(API_ROUTES.SCHEDULE_EMAIL + '/schedule-operations/' + surveyId, data);
                },
                getEmailSettings: getEmailSettings,
                DEFAULT_EMAIL_ADDRESS: DEFAULT_EMAIL_ADDRESS
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
