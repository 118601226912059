"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MATCHER = void 0;
var widgets_1 = require("@shared/constants/widgets");
exports.MATCHER = (_a = {},
    _a[widgets_1.ENG_WIDGETS.BAR] = "pr-metric-component" /* METRIC */,
    _a[widgets_1.ENG_WIDGETS.LINE] = "pr-metric-component" /* METRIC */,
    _a[widgets_1.ENG_WIDGETS.TABLE] = "pr-metric-component" /* METRIC */,
    _a[widgets_1.ENG_WIDGETS.PIE] = "pr-metric-component" /* METRIC */,
    _a[widgets_1.ENG_WIDGETS.BREAKDOWN_BAR] = "pr-metric-component" /* METRIC */,
    _a[widgets_1.ENG_WIDGETS.GAUGE] = "pr-metric-component" /* METRIC */,
    _a[widgets_1.ENG_WIDGETS.GRID] = "pr-records-component" /* RECORDS */,
    _a[widgets_1.ENG_WIDGETS.STATS] = "pr-stats-component" /* STATS */,
    _a[widgets_1.ENG_WIDGETS.WORDCLOUD] = "pr-wordcount-component" /* WORDCOUNT */,
    _a[widgets_1.ENG_WIDGETS.HEATMAP] = "pr-geometry-component" /* GEOMETRY */,
    _a[widgets_1.PR_WIDGETS.TEXT] = "pr-text-component" /* TEXT */,
    _a[widgets_1.PR_WIDGETS.IMAGE] = "pr-display-logic-component" /* DISPLAY_LOGIC */,
    _a[widgets_1.PR_WIDGETS.BLANK_SPACE] = "pr-display-logic-component" /* DISPLAY_LOGIC */,
    _a[widgets_1.PR_WIDGETS.DIVIDER] = "pr-display-logic-component" /* DISPLAY_LOGIC */,
    _a);
