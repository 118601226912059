"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.THREE_SIXTY_PERCENTILE_METRIC = exports.ConditionOperator = exports.PersonMetadataConditionPerson = exports.SUBJECT_FIELD_PREFIX = void 0;
exports.SUBJECT_FIELD_PREFIX = 'Subject';
var PersonMetadataConditionPerson;
(function (PersonMetadataConditionPerson) {
    PersonMetadataConditionPerson["SUBJECT"] = "AWA.PERSON_METADATA_SUBJECT";
    PersonMetadataConditionPerson["EVALUATOR"] = "AWA.PERSON_METADATA_EVALUATOR";
})(PersonMetadataConditionPerson = exports.PersonMetadataConditionPerson || (exports.PersonMetadataConditionPerson = {}));
var ConditionOperator;
(function (ConditionOperator) {
    ConditionOperator["IS"] = "AWA.IS";
    ConditionOperator["IS_NOT"] = "AWA.IS_NOT";
    ConditionOperator["IS_SAME_AS_SUBJECT"] = "AWA.IS_SAME_AS_SUBJECT";
})(ConditionOperator = exports.ConditionOperator || (exports.ConditionOperator = {}));
exports.THREE_SIXTY_PERCENTILE_METRIC = '360percentile';
