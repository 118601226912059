"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var angular = __importStar(require("angular"));
var CreateReportThreeSixtyService = /** @class */ (function () {
    function CreateReportThreeSixtyService($http, 
    // @ts-ignore
    API_ROUTES, 
    // @ts-ignore
    sanitize, 
    // @ts-ignore
    prCreateReportService, 
    // @ts-ignore
    qs, 
    // @ts-ignore
    containers) {
        this.$http = $http;
        this.API_ROUTES = API_ROUTES;
        this.sanitize = sanitize;
        this.prCreateReportService = prCreateReportService;
        this.qs = qs;
        this.containers = containers;
    }
    // @ts-ignore
    // @ts-ignore
    CreateReportThreeSixtyService.prototype.getDefaultReportDefinition = function (surveyId, pageLayout) {
        var route = this.API_ROUTES.THREE_SIXTY_SERVICE + "default-container/default/" + surveyId;
        var defaultUrl = this.qs.append(route, this.getQueryParams(pageLayout));
        return this.$http.get(defaultUrl).then(function (response) {
            return response.data;
        });
    };
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    CreateReportThreeSixtyService.prototype.createDefaultReportFromDefinition = function (name, pageLayout, reportDefinition) {
        var _this = this;
        return this.createReport(reportDefinition, name, pageLayout)
            // @ts-ignore
            .then(function (container) {
            return _this.createDefaultReportTranslations(container._id).then(function () {
                return container;
            }).catch(function (error) {
                _this.containers.deleteContainer(container._id);
                throw error;
            });
        });
    };
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    CreateReportThreeSixtyService.prototype.createEmptyDefaultReport = function (name, surveyId, pageLayout) {
        var _this = this;
        var route = this.API_ROUTES.THREE_SIXTY_SERVICE + "default-container/empty-default/" + surveyId;
        var emptyDefaultUrl = this.qs.append(route, this.getQueryParams(pageLayout));
        return this.$http.get(emptyDefaultUrl).then(function (response) {
            return _this.createReport(response.data, name, pageLayout);
        }).then(function (container) {
            return _this.createDefaultReportTranslations(container._id).then(function () {
                return container;
            }).catch(function (error) {
                _this.containers.deleteContainer(container._id);
                throw error;
            });
        });
    };
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    CreateReportThreeSixtyService.prototype.createReport = function (containerDefinition, name, pageLayout) {
        containerDefinition.description = this.sanitize.stripHTML(name);
        containerDefinition.contextMeta.pageLayout = pageLayout;
        return this.prCreateReportService.createReportForContainerDefinition(containerDefinition);
    };
    // @ts-ignore
    CreateReportThreeSixtyService.prototype.getQueryParams = function (pageLayout) {
        return {
            size: pageLayout.size,
            orientation: pageLayout.orientation
        };
    };
    // @ts-ignore
    CreateReportThreeSixtyService.prototype.createDefaultReportTranslations = function (containerId) {
        var url = this.API_ROUTES.THREE_SIXTY_SERVICE + "default-container/" + containerId + "/translations";
        return this.$http.post(url, null);
    };
    CreateReportThreeSixtyService.$inject = [
        '$http',
        'pr.API_ROUTES',
        'prSanitize',
        'pr.createReport',
        'prQueryString',
        'rsContainers'
    ];
    return CreateReportThreeSixtyService;
}());
(function (angularModule) {
    angularModule.service('pr.createReport360', CreateReportThreeSixtyService);
})(angular.module('qualtrics.pr'));
