"use strict";
(function (module) {
    'use strict';
    module.factory('prGraphicLibraryService', [
        '$http',
        '$filter',
        'pr.API_ROUTES',
        'rsEnvironment',
        function ($http, $filter, API_ROUTES, env) {
            var currentUser = env.get('user');
            var translate = $filter('translate');
            /* for processing library label to keep consistant with other Qualtrics products
               real type -> display type:
                 Global -> Global
                 Brand -> Organization
                 User -> Group
                 */
            var LIBRARY_TYPE_LABEL_MAPS = {
                Global: translate('GLOBAL_LIBRARY'),
                Brand: translate('ORGANIZATION_LIBRARY'),
                User: translate('GROUP_LIBRARY')
            };
            function getLibraries() {
                // user always has their own library
                var userGraphicLibraries = [{
                        value: currentUser.userId,
                        label: translate('MY_LIBRARY') + ': ' + currentUser.firstName,
                        uploadPermission: true
                    }];
                return $http.get(API_ROUTES.GRAPHIC_LIBRARIES)
                    .then(function (response) {
                    /* reponse.data format:
                    {
                      "Group_Library_ID": {
                        "Description": "library name",
                        "Type": "Brand",
                        "manageLibraryGraphics": true
                      },
                      ...
                    }
                    */
                    var groupLibraries = _.map(response.data, function (groupLibrary, groupLibraryKey) {
                        return {
                            value: groupLibraryKey,
                            label: processLibraryLabel(groupLibrary.Description, groupLibrary.Type),
                            uploadPermission: groupLibrary.manageLibraryGraphics || false
                        };
                    });
                    return _.concat(userGraphicLibraries, groupLibraries);
                })
                    .catch(function () {
                    return userGraphicLibraries;
                });
            }
            function getLibrary(libraryId) {
                return $http.get(API_ROUTES.GRAPHIC_LIBRARIES + libraryId)
                    .then(function (response) {
                    /* reponse.data format:
                    {
                        "getLibraryGraphics": {
                            "folder 1":{
                                "IM_6KyvTmnAEQS2nCl":{
                                    "Description":"IMG_5975.JPG",
                                    "LastModified":"2016-08-30 18:31:34"
                                },
                                "IM_1zWo99ird9UeQoB":{
                                    "Description":"photo.png",
                                    "LastModified":"2016-08-30 18:29:03"
                                }
                            }
                        }
                    }*/
                    return [{
                            folders: _.map(response.data.getLibraryGraphics, processFolder)
                        }];
                })
                    .catch(function () {
                    return [];
                });
            }
            function processLibraryLabel(description, type) {
                return (LIBRARY_TYPE_LABEL_MAPS[type] || translate('LIBRARY')) + ': ' + description;
            }
            function processGraphic(graphic, graphicKey) {
                return {
                    label: graphic.Description,
                    value: graphicKey,
                    graphicName: graphic.Description
                };
            }
            function processFolder(folder, folderKey) {
                return {
                    label: folderKey,
                    value: folderKey,
                    graphics: _.map(folder, processGraphic)
                };
            }
            function uploadImageToLibrary(libraryId, params) {
                return $.ajax({
                    url: API_ROUTES.GRAPHIC_LIBRARIES + libraryId,
                    data: params,
                    type: 'POST',
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    headers: {
                        'Content-Type': undefined
                    }
                });
            }
            return {
                getLibraries: getLibraries,
                getLibrary: getLibrary,
                uploadImageToLibrary: uploadImageToLibrary
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
