"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("@shared/types/environment");
var smart_text_1 = require("@shared/types/smart-text");
(function (module) {
    'use strict';
    module.factory('prSurveyPipedTextMap', [
        'rsEnvironment',
        function (env) {
            var map = {
                '[Field-SubjectName]': {
                    attrName: 'fullName',
                    type: 'subjectInfo'
                },
                '[Field-SubjectFirstName]': {
                    attrName: 'firstName',
                    type: 'subjectInfo'
                },
                '[Field-SubjectLastName]': {
                    attrName: 'lastName',
                    type: 'subjectInfo'
                },
                '[Field-SubjectEmail]': {
                    attrName: 'email',
                    type: 'subjectInfo'
                },
                '[Field-EvaluatorsSpecialRelationship]': {
                    type: 'subjectsEvaluators'
                },
                '[Field-EvaluatorsDirectReport]': {
                    attrName: 'DirectReport',
                    type: 'subjectsEvaluators'
                },
                '[Field-EvaluatorsPeer]': {
                    attrName: 'Peer',
                    type: 'subjectsEvaluators'
                },
                '[Field-EvaluatorsManager]': {
                    attrName: 'Manager',
                    type: 'subjectsEvaluators'
                },
                '[FirstPersonPossessive]': {
                    attrName: smart_text_1.SMART_TEXT.FIRST_PERSON_POSSESSIVE,
                    type: 'subjectSmartText'
                },
                '[FirstPersonReflexive]': {
                    attrName: smart_text_1.SMART_TEXT.FIRST_PERSON_REFLEXIVE,
                    type: 'subjectSmartText'
                },
                '[SecondPersonPossessive]': {
                    attrName: smart_text_1.SMART_TEXT.SECOND_PERSON_POSSESSIVE,
                    type: 'subjectSmartText'
                },
                '[SecondPersonReflexive]': {
                    attrName: smart_text_1.SMART_TEXT.SECOND_PERSON_REFLEXIVE,
                    type: 'subjectSmartText'
                },
            };
            var personMetadata = env.get(environment_1.ENV_VARS.PERSON_METADATA) || [];
            personMetadata.forEach(function (item) {
                map["[Field-Subject" + encodeURIComponent(item) + "]"] = {
                    attrName: item,
                    type: 'subjectMetadata'
                };
            });
            return {
                get: function get(tag) {
                    if (tag) {
                        return _.cloneDeep(map[tag]);
                    }
                    return _.cloneDeep(map);
                }
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
