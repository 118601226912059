"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint no-redeclare: "off" */
require("./core.less");
/* global jQuery */
/*
 * The transformer is responsible for selecting a set of items and sending callbacks for move and resize on the selection
 */
(function ($) {
    // enable our pointer events polyfill
    if (window && typeof window.pointerEventsPolyfill === 'function') {
        window.pointerEventsPolyfill();
    }
    // Check the system and type of browser
    // Reference:
    // https://stackoverflow.com/questions/10527983/best-way-to-detect-mac-os-x-or-windows-computers-with-javascript-or-jquery
    // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
    var isWindows = window.navigator.platform.indexOf('Win') > -1, isIE = false || !!document.documentMode, isEdge = !isIE && !!window.styleMedia;
    // this is how fast the scrolling animates
    // If using Edge in windows system,
    // increase the time of animate (slow down scrolling)
    // Please see REP-4372
    var SCROLL_SPEED = isWindows && isEdge ? 200 : 50;
    // constants
    var SELECT_EVENT = 'select', TRANSFORM_START_EVENT = 'transformStart', TRANSFORM_EVENT = 'transform', TRANSFORM_FINISHED_EVENT = 'transformFinished', MOVE = 'move', RESIZE = 'resize', SCROLLER, SCROLL_ZONE = 32; // this is how many pixels on the top and bottom of the screen before we start scrolling
    $.widget('layout.transformer', {
        options: {
            disabled: false,
            selectables: '.item',
            scrollParent: 'body,html',
            zoomParent: null,
            snapX: 1,
            snapY: 1,
            maxWidthRight: null,
            maxWidthLeft: null,
            maxHeightTop: null,
            maxWidth: null,
            maxHeight: null,
            minWidth: null,
            minHeight: null,
            // these are additional distance to the true top / bottom screen
            // that we use to adjust the scroll zones when moving a widget
            bottomScrollOffset: 0,
            topScrollOffset: 0
        },
        /************************
         * Initialization
         ************************/
        _create: function () {
            this._initSelector();
            this._initResizer();
            this._initMover();
            this._initScroller();
            this._initMutationObserver();
        },
        // make our element an item selector
        _initSelector: function () {
            // initialize our selected items collection
            this._selectedItems = $([]);
            this._boundSelect = $.proxy(this._select, this);
            this.element.itemSelector({
                selectables: this.options.selectables,
                select: this._boundSelect,
                zoomParent: this.options.zoomParent,
                disabled: this.options.disabled
            });
        },
        _initMover: function () {
            // the item mover is pretty cool it doesn't actually move this item,
            // but gives us callbacks for the change in x and y
            this.element.mover({
                moveStart: (this._boundMoveStart = $.proxy(this._moveStart, this)),
                move: (this._boundMove = $.proxy(this._move, this)),
                moveFinished: (this._boundMove = $.proxy(this._moveFinished, this)),
                moveCapture: (this._boundMoveCapture = $.proxy(this._moveCapture, this)),
                keyCapture: false,
                zoomParent: this.options.zoomParent
            });
        },
        _initResizer: function () {
            this._resizer = $('<div class="item-resizer">')
                .appendTo(this.element)
                .resizer({
                resizeStart: (this._boundResizeStart = $.proxy(this._resizeStart, this)),
                resize: (this._boundResize = $.proxy(this._resize, this)),
                resizeFinished: (this._boundResizeFinished = $.proxy(this._resizeFinished, this)),
                zoomParent: this.options.zoomParent
            })
                .hide();
        },
        _initScroller: function () {
            this._scrollParent = $(this.options.scrollParent || 'body,html');
            this._boundScroll = $.proxy(this._scroll, this);
        },
        /*
         * This guy watches for style changes on our selected elements to update the resizer
         */
        _initMutationObserver: function () {
            this._observer = new MutationObserver(this._boundPositionResizer = $.proxy(this._positionResizer, this));
        },
        /************************
         * Destruction
         ************************/
        _destroy: function () {
        },
        _destroySelector: function () {
        },
        _destroyMover: function () {
        },
        _destroyResizer: function () {
            this._resizer.resizer('destroy');
            delete this._resizer;
            delete this._boundResizeStart;
            delete this._boundResize;
            delete this._boundResizeFinished;
        },
        _destroyScroller: function () {
            delete this._scrollParent;
            delete this._boundScroll;
        },
        /************************
         * Select
         ************************/
        select: function (items) {
            this.element.itemSelector('select', items);
        },
        _select: function (evt, items) {
            this._selectedItems.removeClass('selected');
            this._selectedItems = items;
            this._selectedItems.addClass('selected');
            if (this._selectedItems.length) {
                this._showResizer();
            }
            else {
                this._hideResizer();
            }
            // if we have an event it was coming from us
            if (evt) {
                this._trigger(SELECT_EVENT, evt, this._ui());
            }
        },
        deselectAll: function () {
        },
        /************************
         * Move
         ************************/
        _moveCapture: function (event) {
            // don't initiate the move it's a no-move-capture element
            // e.g. widget-actions-button
            if ($(event.target).closest('[no-move-capture]').length) {
                return false;
            }
            var movingEl = $(event.target).closest(this.options.selectables + '.selected');
            return (movingEl && movingEl.length);
        },
        _moveStart: function (event) {
            // verify the selected items
            this._trigger(TRANSFORM_START_EVENT, event, this._ui(MOVE));
            this._captureStart(true);
            // must happen after _captureStart so we have the bounding clients
            this._setTransformOrigin(event);
            this._selectedItems.addClass('moving');
        },
        _move: function (event, ui, noScroll) {
            var o = this.options, x = Math.round(ui.dx / o.snapX) * o.snapX, y = Math.round(ui.dy / o.snapY) * o.snapY;
            this._getSelectedItems().each(function () {
                var el = $(this), start = el.data('startPosition');
                el.css({
                    top: start.top + y,
                    left: start.left + x
                });
            });
            this._trigger(TRANSFORM_EVENT, event, this._ui(MOVE, x, y));
            if (!noScroll) {
                this._scrollPage(event, ui);
            }
        },
        _moveFinished: function (event) {
            this._selectedItems.removeClass('moving');
            event.stopImmediatePropagation();
            this._trigger(TRANSFORM_FINISHED_EVENT, event, this._ui(MOVE));
            this._stopScroll();
            this._clearTransformOrigin();
        },
        /************************
         * Resize
         ************************/
        _showResizer: function () {
            this._observeSelectedItems();
            this._resizer.show();
            this._positionResizer();
        },
        _hideResizer: function () {
            this._stopObservingSelectedItems();
            this._resizer.hide();
        },
        // expose this method so we can re-select after widgets animate
        positionResizer: function () {
            this._positionResizer();
        },
        _positionResizer: function () {
            var selected = this._getSelectedItems();
            if (!selected.length) {
                this._hideResizer();
                return;
            }
            var rect = {
                top: Infinity,
                left: Infinity,
                bottom: -Infinity,
                right: -Infinity
            }, getElementStyles = this._getElementStyles;
            // this would be much better with getBoundingClientRect, but that is expensive and causes reflow
            var zoom = this.options.zoomParent ? $(this.options.zoomParent).getZoom() : 1;
            var xOnly = false;
            var yOnly = false;
            var noResize = false;
            selected.each(function (idx, el) {
                var offset = $(el).offset(), top = offset.top, left = offset.left, styles = getElementStyles(el), bottom = top + (styles.height * zoom), right = left + (styles.width * zoom);
                xOnly = xOnly || el.getAttribute('resize') === 'x';
                yOnly = yOnly || el.getAttribute('resize') === 'y';
                noResize = noResize || el.getAttribute('resize') === 'none';
                rect.top = Math.min(rect.top, top);
                rect.left = Math.min(rect.left, left);
                rect.bottom = Math.max(rect.bottom, bottom);
                rect.right = Math.max(rect.right, right);
            });
            var offset = this.element.offset();
            this._resizer.css({
                top: rect.top - offset.top,
                left: rect.left - offset.left,
                width: rect.right - rect.left,
                height: rect.bottom - rect.top
            });
            this._resizer.toggleClass('x-only', xOnly);
            this._resizer.toggleClass('y-only', yOnly);
            if (noResize) {
                this._resizer.addClass('x-only');
                this._resizer.addClass('y-only');
            }
        },
        _resizeStart: function (event) {
            function getAttribute(el, attribute) {
                return $(el).attr(attribute);
            }
            /** Get a list of values of the provided HTML attribute in the provided items */
            function getAttributeList(items, attribute) {
                if (items === void 0) { items = []; }
                return _.without(_.map(items, function (el) {
                    return getAttribute(el, attribute);
                }), null, undefined);
            }
            /** Get the minimum value of a specific HTML attribute in the provided items */
            function getMin(items, attr) {
                var minList = getAttributeList(items, attr);
                return minList.length > 0 ? Math.min.apply(Math, minList) : null;
            }
            this._captureStart(true);
            this.options.minWidth = getMin(this._getSelectedItems(), 'minWidth');
            this.options.minHeight = getMin(this._getSelectedItems(), 'minHeight');
            // we have to use the bounding client rect so we have an absolute reference for the changes
            this._resizer.data('rect', this._resizer[0].getBoundingClientRect());
            this._trigger(TRANSFORM_START_EVENT, event, this._ui(RESIZE));
        },
        _resize: function (evt, ui) {
            // we have to use the width and height to determine the scale for top and left
            var o = this.options, props = ['width', 'height', 'top', 'left'], 
            // the top / left are also affected as a function of the height / width
            adtl = { left: 'width', top: 'height' }, snaps = {
                width: o.snapX,
                height: o.snapY,
                left: o.snapX,
                top: o.snapY
            }, sizerRect = this._resizer.data('rect'), sizerAfter = ui.after, sizerBefore = ui.before, sizerChange = ui.change;
            var isResizingToRight = !ui.after.left;
            o.maxWidth = isResizingToRight ? o.maxWidthRight : o.maxWidthLeft;
            var isResizingToTop = ui.after.top;
            o.maxHeight = isResizingToTop ? o.maxHeightTop : o.maxHeight;
            // do some bounds checking
            if (this._exceedsBounds(ui.after, o)) {
                _.forEach(['maxWidth', 'maxHeight'], function (prop) {
                    if (o[prop]) {
                        var widthHeight = prop.toLowerCase().replace('max', '');
                        sizerAfter[widthHeight] = Math.min(o[prop], sizerAfter[widthHeight]);
                        sizerChange[widthHeight] = sizerAfter[widthHeight] - sizerBefore[widthHeight];
                    }
                });
                _.forEach(['minWidth', 'minHeight'], function (prop) {
                    if (o[prop]) {
                        var widthHeight = prop.toLowerCase().replace('min', '');
                        sizerAfter[widthHeight] = Math.max(o[prop], sizerAfter[widthHeight]);
                        sizerChange[widthHeight] = sizerAfter[widthHeight] - sizerBefore[widthHeight];
                    }
                });
            }
            // round the resizer
            $.each(sizerChange, function (key, value) {
                sizerChange[key] = Math.round(value / snaps[key]) * snaps[key];
            });
            // we are changing the top and left so make sure the width and height changed the same
            $.each(adtl, function (key, value) {
                if (sizerChange[key] && sizerChange[key] + sizerChange[value]) {
                    sizerChange[key] = -sizerChange[value];
                }
            });
            // we resize each item proportionally
            this._getSelectedItems().each(function () {
                var el = $(this), elRect = el.data('rect'), elStart = el.data('startPosition'), css = {}, scale, change;
                // for some reason the selected element no longer exists
                if (!el.length || !elRect) {
                    return;
                }
                $.each(props, function (idx, prop) {
                    if (adtl[prop]) {
                        scale = ((elRect[prop] - sizerRect[prop]) / sizerRect[adtl[prop]]);
                        change = scale * sizerChange[adtl[prop]] + sizerChange[prop];
                    }
                    else {
                        scale = elRect[prop] / sizerRect[prop];
                        change = scale * sizerChange[prop];
                    }
                    change = Math.floor(change / snaps[prop]) * snaps[prop];
                    // round each item
                    css[prop] = elStart[prop] + change;
                    // don't allow them to have a 0 width / height
                    if (prop === 'width' || prop === 'height') {
                        css[prop] = Math.max(css[prop], snaps[prop]);
                    }
                });
                // we don't ever modify the height of x resize elements
                if (el.attr('resize') === 'x') {
                    delete css.height;
                }
                if (el.attr('resize') === 'y') {
                    delete css.width;
                }
                el.css(css);
            });
            this._trigger(TRANSFORM_EVENT, evt, this._ui(RESIZE, sizerChange.left, sizerChange.top));
        },
        _resizeFinished: function (event) {
            // pass along the event
            this._trigger(TRANSFORM_FINISHED_EVENT, event, this._ui(RESIZE));
        },
        /*
         * Returns true if the passed in dimensions are greater than our max width/height, or less than our min width/height
         */
        _exceedsBounds: function (dims, options) {
            var maxWidth = options.maxWidth;
            var maxHeight = options.maxHeight;
            var minWidth = options.minWidth;
            var minHeight = options.minHeight;
            return !!maxWidth && dims.width > maxWidth ||
                !!maxHeight && dims.height > maxHeight ||
                !!minWidth && dims.width < minWidth ||
                !!minHeight && dims.height < minHeight;
        },
        /************************
         * Scroller
         ************************/
        _scroll: function (amount, event, ui) {
            var scrollParent = this._scrollParent;
            scrollParent.stop().animate({ 'scrollTop': scrollParent.scrollTop() + amount }, SCROLL_SPEED, 'linear');
            ui.dy += amount;
            this._move(event, ui, true);
        },
        _scrollPage: function (event, ui) {
            // check if the mouse is in the bumper zone
            var y = event.pageY - this._scrollParent.scrollTop(), toScroll = 0, dy = ui.dy - (this._prevDy || 0), o = this.options, topOffset = o.topScrollOffset, bottomOffset = o.bottomScrollOffset, topZone = topOffset + SCROLL_ZONE, bottomZone = $(window).height() - (bottomOffset + SCROLL_ZONE), snapY = o.snapY || 1;
            // the speed is a function of how close to the true top / bottom we are
            // the mouse is moving up and the mouse is in the top range
            if (y < topZone && dy < 0) {
                // scroll up a tad
                toScroll = -Math.min(SCROLL_ZONE, topZone - y);
            }
            else if (y > bottomZone && dy > 0) {
                // the mouse is moving down and the mouse is in the bottom range
                // scroll down a tad
                toScroll = Math.min(SCROLL_ZONE, y - bottomZone);
            }
            // we use a constant to give it a curved speed up
            var SPEED = 8;
            toScroll = SPEED * Math.round(toScroll / snapY) * snapY;
            this._startScroll(toScroll, event, ui);
            this._prevDy = ui.dy;
        },
        _startScroll: function (amount, event, info) {
            this._stopScroll();
            if (amount) {
                this._boundScroll(amount, event, info);
                SCROLLER = setInterval(this._boundScroll, SCROLL_SPEED * 1.4, amount, event, info);
            }
        },
        _stopScroll: function () {
            delete this._prevDy;
            if (SCROLLER) {
                clearInterval(SCROLLER);
                SCROLLER = null;
            }
        },
        /************************
         * Item observer
         ************************/
        _observeSelectedItems: function () {
            this._stopObservingSelectedItems();
            var observer = this._observer;
            _.forEach(this._getSelectedItems(), function (el) {
                observer.observe(el, { attributes: true, attributeFilter: ['style'] });
            });
        },
        _stopObservingSelectedItems: function () {
            this._observer.disconnect();
        },
        /************************
         * Helpers
         ************************/
        _getSelectedItems: function () {
            return this._selectedItems.filter(function (i, el) {
                return !!el.parentNode;
            });
        },
        _ui: function (type) {
            return {
                items: this._getSelectedItems(),
                type: type,
                mouseOffset: this._mouseOffset,
                change: Array.prototype.slice.apply(arguments).slice(1)
            };
        },
        _captureStart: function (isMoving) {
            var getElementStyles = this._getElementStyles;
            this._getSelectedItems().each(function () {
                var el = $(this);
                el.data('startPosition', getElementStyles(el[0]));
                // also get our rect
                if (isMoving) {
                    el.data('rect', el[0].getBoundingClientRect());
                }
            });
        },
        _getElementStyles: function (el) {
            return _.reduce(['top', 'left', 'width', 'height'], function (acc, prop) {
                acc[prop] = parseInt(el.style[prop], 10);
                return acc;
            }, {});
        },
        _setTransformOrigin: function (evt) {
            var mx = evt.clientX, my = evt.clientY, minX = Infinity, minY = Infinity;
            this._selectedItems.each(function (idx, el) {
                var rect = $(el).data('rect') || {}, l = mx - rect.left, t = my - rect.top;
                if (el.style) {
                    el.style.transformOrigin = l + 'px ' + t + 'px';
                }
                minX = Math.min(minX, rect.left);
                minY = Math.min(minY, rect.top);
            });
            if (minX === Infinity || minY === Infinity) {
                this._mouseOffset = [];
            }
            else {
                // this is the mouseOffset relative to the entire selection's top-left position
                this._mouseOffset = [mx - minX, my - minY];
            }
        },
        _clearTransformOrigin: function () {
            this._selectedItems.css('transformOrigin', '');
        }
    });
})(jQuery);
