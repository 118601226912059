"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var filter_utils_1 = require("@shared/utils/filter-utils");
(function (app) {
    'use strict';
    app.constant('pr.FILTER_LEVELS', {
        METRIC_LEVEL: 'Metric',
        WIDGET_LEVEL: 'Visualization',
        REPORT_LEVEL: 'Report'
    });
    var fieldsService;
    var Filters = function ($q, filterUtils, activeReport, env, triggerOverrides, OVERRIDE_EVENTS, _fieldsService, prSavedFiltersService, customFilterService, customFilterUtils, fieldsetsService) {
        this.$q = $q;
        this.filterUtils = filterUtils;
        this.activeReport = activeReport;
        this.env = env;
        this.configFlags = triggerOverrides({
            event: OVERRIDE_EVENTS.CONFIG_FLAGS,
            sync: true,
            data: {}
        });
        fieldsService = _fieldsService;
        this.SavedFiltersService = prSavedFiltersService;
        this.customFilterService = customFilterService;
        this.customFilterUtils = customFilterUtils;
        this.fieldsetsService = fieldsetsService;
    };
    Filters.prototype.getOverrideFilters = function getOverrideFilters() {
        var filters = {};
        if (!this.configFlags.disableReportFilters) {
            filters = this.activeReport.getMetadata('filters') || {};
            filters = _.cloneDeep(filters); // don't modify reference to metadata
        }
        var envFilters = this.env.get('filters');
        _.forOwn(envFilters, function (envFilter, sourceId) {
            if (!filters[sourceId]) {
                filters[sourceId] = envFilter;
            }
            else {
                var reportFilter = filters[sourceId];
                filters[sourceId] = {
                    type: 'and',
                    children: [envFilter, reportFilter]
                };
            }
        });
        return filters;
    };
    Filters.prototype.getFiltersBySource = function getFiltersBySource(sourceIds) {
        if (!sourceIds) {
            console.error('No sourceIds passed');
            return {};
        }
        sourceIds = _.isArray(sourceIds) ? sourceIds : [sourceIds];
        var overrideFilters = this.getOverrideFilters();
        return _.pick(overrideFilters || {}, _.uniq(sourceIds));
    };
    // Merge widget and report filters
    Filters.prototype.mergeFilters = function mergeFilters(widgetFilters, sourceId) {
        var overrideFilters = this.getOverrideFilters();
        overrideFilters = (overrideFilters || {})[sourceId] || {};
        widgetFilters = _.cloneDeep(widgetFilters);
        overrideFilters = _.cloneDeep(overrideFilters);
        this.filterUtils.fixDateFilters(widgetFilters);
        this.filterUtils.fixDateFilters(overrideFilters);
        filter_utils_1.fixOldPRFilters(widgetFilters);
        filter_utils_1.fixOldPRFilters(overrideFilters);
        return {
            type: 'and',
            children: [widgetFilters, overrideFilters]
        };
    };
    Filters.prototype.prepRequestFiltersAsync = function prepRequestFiltersAsync(widgetFilters, sourceId) {
        var _this = this;
        return this.getUpdatedFilterDefinition(widgetFilters, sourceId).then(function (updatedFilters) {
            return _this.mergeFilters(updatedFilters, sourceId);
        });
    };
    Filters.prototype.getUpdatedFilterDefinition = function (filters, sourceId) {
        var validatedFilters = filter_utils_1.getValidDataPlatformFilter(sourceId, filters);
        if (_.isEmpty(validatedFilters)) {
            return this.$q.resolve(validatedFilters);
        }
        if (this.customFilterUtils.isCustomFilter(validatedFilters)) {
            return this.getUpdatedCustomFilter(validatedFilters, sourceId);
        }
        else {
            return new this.SavedFiltersService(sourceId).getUpdatedDefinition(validatedFilters);
        }
    };
    Filters.prototype.getUpdatedCustomFilter = function (sourceFilters, sourceId) {
        var _this = this;
        return this.fieldsetsService.getFieldsetIdBySourceId(sourceId).then(function (fieldsetId) {
            var _a;
            var updatedFilters = _this.customFilterService.getFilters(fieldsetId, true);
            var filterId = sourceFilters.filterId;
            var updatedFilterDef = (_a = updatedFilters === null || updatedFilters === void 0 ? void 0 : updatedFilters[filterId]) === null || _a === void 0 ? void 0 : _a.filter;
            if (!updatedFilterDef) {
                return sourceFilters;
            }
            // clone so that callers do not modify the filter definition
            var clonedFilterDef = _.cloneDeep(updatedFilterDef);
            return __assign(__assign({}, sourceFilters), clonedFilterDef);
        }).catch(function (error) {
            console.error('Could not get updated custom filter', error);
            return sourceFilters;
        });
    };
    /**
     * Creates isPresent filters based on sourceId and dataConfig.
     * The filter should return only responses to the fields defined in the dataConfig.
     * @param  {string} sourceId - survey or fieldset id
     * @param {object} dataConfig - data config for the widget
     * @return {object} filter
     */
    Filters.prototype.getIsPresentFilter = function getIsPresentFilter(sourceId, dataConfig) {
        var fieldMatrix = [];
        if (dataConfig.metrics) {
            _.forEach(dataConfig.metrics, function (metric) {
                // for each metric,
                // record the metric and breakout field(s)
                var metricSourceId = metric.sourceId || dataConfig.sourceId;
                if (metricSourceId !== sourceId) {
                    return;
                }
                var breakouts = _.concat(metric.breakouts || [], dataConfig.breakouts || []);
                var breakoutFields = _.map(breakouts, 'fieldId');
                var fields = [metric.fieldId].concat(breakoutFields);
                fieldMatrix.push(fields);
            });
        }
        else {
            var fieldIds = getFields(dataConfig);
            fieldMatrix = fieldIds.map(function (fieldId) {
                return [fieldId];
            });
        }
        return {
            type: 'or',
            children: fieldMatrix.map(getIsPresentFilterForMultipleFields)
        };
    };
    /**
     * Extracts the field Ids for the dataConfig into an array.
     * This function is only valid for non-metric widgets
     * @param  {dataConfig} dataConfig - for the non-metric widget
     * @return {Array.<string>}
     */
    function getFields(dataConfig) {
        if (dataConfig.fields) {
            return dataConfig.fields;
        }
        if (fieldsService.isGroupMetric(dataConfig.fieldId)) {
            var fields = fieldsService.getAllChoicesById(dataConfig.sourceId, dataConfig.fieldId);
            return _.map(fields, 'fieldId');
        }
        return [dataConfig.fieldId];
    }
    function getIsPresentFilterForMultipleFields(fieldIds) {
        fieldIds = _.compact(fieldIds);
        return {
            type: 'and',
            children: fieldIds.map(getIsPresentFilterForSingleField)
        };
    }
    function getIsPresentFilterForSingleField(fieldId) {
        return {
            type: 'filter',
            filter: {
                field: fieldId,
                operator: 'present',
                operand: true
            }
        };
    }
    Filters.$inject = [
        '$q',
        'prFilterUtils',
        'prActiveReport',
        'rsEnvironment',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        'prFields',
        'prSavedFiltersService',
        'pr.customFilterService',
        'pr.customFilterUtils',
        'prFieldsets'
    ];
    app.service('pr.filters', Filters);
})(angular.module('qualtrics.pr'));
