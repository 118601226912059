"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var max_scheduled_emails_1 = require("@shared/constants/max-scheduled-emails");
var feature_flipper_1 = require("@shared/constants/feature-flipper");
var environment_1 = require("@shared/types/environment");
(function (module) {
    'use strict';
    var ScheduleEmailModalController = function ($scope, $filter, $q, env, globalOptionsService, timezoneUtil, dateTimeMenuService, scheduleEmailService, emailService, modal, dynamicTranslationsUtil, dynamicTranslationHelperUtils, metricsService, activeReport) {
        var $ctrl = this;
        $scope.$ctrl = $ctrl;
        $ctrl.isDynamicTranslationsEnabled = dynamicTranslationHelperUtils.isDynamicTranslationsSupported();
        var translate = $filter('translate');
        var user = env.get('user');
        var DEFAULT_SEND_EMAIL_NOW_STRING_LIST = user.email;
        $scope.$ctrl.sendNowEmailsStringList = DEFAULT_SEND_EMAIL_NOW_STRING_LIST;
        var DEFAULT_FROM_NAME = user.firstName + ' ' + user.lastName;
        var DEFAULT_FROM_EMAIL = scheduleEmailService.DEFAULT_EMAIL_ADDRESS;
        var DEFAULT_REPLY_TO_EMAIL = scheduleEmailService.DEFAULT_EMAIL_ADDRESS;
        var DEFAULT_SUBJECT = translate('NEW_EMAIL');
        var DEFAULT_HOUR = 9;
        var DEFAULT_MINUTE = 0;
        var DEFAULT_TIMEZONE = globalOptionsService.getOptions().timezone || moment.tz.guess();
        var _hashOfScheduledEmailContents;
        var defaultEmailSettings;
        $scope.config = {
            status: {
                displaySpinner: false,
                hasFetchedScheduledEmails: false
            },
            scheduledEmails: [],
            quillEditor: null,
            selectedEmail: {
                id: null,
                fromName: DEFAULT_FROM_NAME,
                fromEmail: DEFAULT_FROM_EMAIL,
                replyToEmail: DEFAULT_REPLY_TO_EMAIL,
                recipients: '',
                when: {
                    scheduledFrequency: '',
                    scheduledFrequencyInWords: '',
                    time: null,
                    timezone: null,
                },
                subject: DEFAULT_SUBJECT,
                lang: activeReport.getLanguageCode()
            },
            scheduledFrequencyMenuItems: [],
            fromEmailMenuItems: [],
            replyToEmailMenuItems: []
        };
        function init() {
            $scope.config.status.displaySpinner = true;
            var containerId = env.get('containerId');
            scheduleEmailService.getScheduledEmailsForContainerId(containerId).then(function (scheduledEmails) {
                $scope.config.scheduledEmails = scheduledEmails;
                $scope.config.scheduledFrequencyMenuItems =
                    dateTimeMenuService.getFrequencyMenu(updateSchedulingFrequencyAndLabel);
                trySelectFirstEmail();
                if ($ctrl.isDynamicTranslationsEnabled) {
                    return dynamicTranslationsUtil.getAvailableLanguageCodes();
                }
                return $q.when();
            }).then(function (availableLanguageCodes) {
                $ctrl.availableLanguageCodes = availableLanguageCodes;
                dynamicTranslationsUtil.setCachedAvailableLanguageCodes(availableLanguageCodes);
                // Enable model for editing.
                $scope.config.status.displaySpinner = false;
                $scope.config.status.hasFetchedScheduledEmails = true;
            });
            scheduleEmailService.getEmailSettings().then(function (emailSettings) {
                defaultEmailSettings = emailSettings.defaultSettings;
                $scope.config.fromEmailMenuItems = emailSettings.fromEmailMenuItems;
                $scope.config.replyToEmailMenuItems = emailSettings.replyToEmailMenuItems;
            });
        }
        this.setFromEmail = function (fromEmail) {
            $scope.config.selectedEmail.fromEmail = fromEmail;
        };
        this.setReplyToEmail = function (replyToEmail) {
            $scope.config.selectedEmail.replyToEmail = replyToEmail;
        };
        this.createNewEmail = function (isOverride) {
            if (isSelectedEmailFormDirty() && !isOverride) {
                var onConfirm = $ctrl.createNewEmail.bind(this, true);
                showUnsavedChangesModal(onConfirm);
            }
            else {
                $scope.config.selectedEmail.id = getTempScheduledEmailId();
                $scope.config.selectedEmail.fromName = DEFAULT_FROM_NAME;
                $scope.config.selectedEmail.fromEmail = (defaultEmailSettings || {}).fromEmail || DEFAULT_FROM_EMAIL;
                $scope.config.selectedEmail.replyToEmail = (defaultEmailSettings || {}).replyToEmail || DEFAULT_REPLY_TO_EMAIL;
                $scope.config.selectedEmail.recipients = '';
                $scope.config.selectedEmail.when.scheduledFrequency = null;
                $scope.config.selectedEmail.when.time = createDateForHourAndMinute(DEFAULT_HOUR, DEFAULT_MINUTE);
                $scope.config.selectedEmail.when.scheduledFrequencyInWords = '';
                $scope.config.selectedEmail.when.timezone = DEFAULT_TIMEZONE;
                $scope.config.selectedEmail.subject = DEFAULT_SUBJECT;
                $scope.config.quillEditor.setHtml('');
                $scope.config.selectedEmail.lang = activeReport.getLanguageCode();
                // Reset the frequency menu.
                dateTimeMenuService.clearFrequencySubMenu($scope.config.scheduledFrequencyMenuItems, dateTimeMenuService.WEEKLY_SCHEDULE);
                dateTimeMenuService.clearFrequencySubMenu($scope.config.scheduledFrequencyMenuItems, dateTimeMenuService.MONTHLY_SCHEDULE);
                var newEmail = getScheduledEmailFromSelectedEmail();
                $scope.config.scheduledEmails.push(newEmail);
                $scope.scheduleEmailForm.$setPristine();
                updateHashedOfSelectedEmailForm();
            }
        };
        var TEMP_ID_PREFIX = 'tempId-';
        var currentNewEmailCount = 0;
        /**
         * Creates a unique temporary id that can be used to uniquely identify
         * the new email. It is replaced by a real id when the email is scheduled.
         */
        var getTempScheduledEmailId = function () {
            var id = TEMP_ID_PREFIX + currentNewEmailCount;
            currentNewEmailCount += 1;
            return id;
        };
        /**
         * New emails have not been scheduled with the email scheduler and
         * therefore, have a temp id.
         */
        this.isNewEmail = function (id) {
            return _.startsWith(id, TEMP_ID_PREFIX);
        };
        var createDateForHourAndMinute = function (hour, minute) {
            return new Date(/*year*/ 0, /*month*/ 0, /*day*/ 0, hour, minute);
        };
        var updateSchedulingFrequencyAndLabel = function ($value, $item) {
            $scope.config.selectedEmail.when.scheduledFrequencyInWords = dateTimeMenuService.getFrequencyInWords($item);
            // Copy the $item array. Even though it is hooked up with ng-model, qstrap doesnt update it for select-menus with submenus.
            $scope.config.selectedEmail.when.scheduledFrequency = _.cloneDeep($item);
            // Uncheck all checked menu items in the other sub menu.
            if (!_.isEmpty($item)) {
                var value = $item.type === dateTimeMenuService.WEEKLY_SCHEDULE ?
                    dateTimeMenuService.MONTHLY_SCHEDULE : dateTimeMenuService.WEEKLY_SCHEDULE;
                dateTimeMenuService.clearFrequencySubMenu($scope.config.scheduledFrequencyMenuItems, value);
            }
            // The html was updating slowly so trigger a digest now.
            $scope.$apply();
        };
        function showUnsavedChangesModal(onConfirm) {
            var modalScope = $scope.$new();
            modalScope.onConfirm = onConfirm;
            var unsavedChangesModal = modal({
                scope: modalScope,
                title: '<span class="icon icon-caution-lg"></span><span translate style="vertical-align: middle;">DISCARD_CHANGES</span>',
                content: '<div translate>PROCEED_CONFIRMATION</div>',
                buttons: [
                    '<div class="btn btn-success" ng-click="onConfirm();close();" translate>YES</div>',
                    '<div class="btn btn-hover" ng-click="close()" translate>NO</div>'
                ],
                className: 'automation-unsaved-changes-modal'
            });
            unsavedChangesModal.show();
        }
        this.selectScheduledEmail = function (email, isOverride) {
            var isEmailAlreadySelected = $scope.config.selectedEmail.id === email.id;
            if (isEmailAlreadySelected) {
                return;
            }
            if (isSelectedEmailFormDirty() && !isOverride) {
                var onConfirm = $ctrl.selectScheduledEmail.bind(this, email, true);
                showUnsavedChangesModal(onConfirm);
            }
            else {
                $scope.config.selectedEmail.id = email.id;
                $scope.config.selectedEmail.fromName = email.fromName;
                $scope.config.selectedEmail.fromEmail = email.fromEmail;
                $scope.config.selectedEmail.replyToEmail = email.replyToEmail;
                $scope.config.selectedEmail.recipients = email.recipientsInfo.recipients;
                $scope.config.selectedEmail.when.scheduledFrequency = dateTimeMenuService.getObjectFromCron(email.cronDatetime);
                $scope.config.selectedEmail.when.scheduledFrequencyInWords = email.scheduledFrequencyInWords;
                $scope.config.selectedEmail.subject = email.subject;
                $scope.config.selectedEmail.lang = email.lang;
                dateTimeMenuService.updateFrequencyMenu($scope.config.scheduledFrequencyMenuItems, email.cronDatetime);
                var hour = dateTimeMenuService.getHourFromCron(email.cronDatetime);
                var minute = dateTimeMenuService.getMinuteFromCron(email.cronDatetime);
                $scope.config.selectedEmail.when.time = createDateForHourAndMinute(hour, minute);
                $scope.config.selectedEmail.when.timezone = email.timezone;
                $scope.config.quillEditor.setHtml(email.message);
                updateHashedOfSelectedEmailForm();
            }
        };
        this.getTimezoneLabel = function () {
            var _a;
            var timezone = (_a = $scope.config.selectedEmail.when.timezone) !== null && _a !== void 0 ? _a : DEFAULT_TIMEZONE;
            return timezoneUtil.getTimezoneOffsetLabel(timezone);
        };
        /**
         * Checks the current value of the scheduled email form against the
         * last updated value. This is the best way to determine if the user
         * has modified the form value in any way.
         */
        function isSelectedEmailFormDirty() {
            return _hashOfScheduledEmailContents !== getHashOfSelectedEmailForm();
        }
        function updateHashedOfSelectedEmailForm() {
            _hashOfScheduledEmailContents = getHashOfSelectedEmailForm();
        }
        /**
         * Returns the stringified version of the current scheduled email form.
         *
         * This does NOT return a true hash. Its return is meant to be used as a
         * hash to determine if the selected email form has changed.
         */
        function getHashOfSelectedEmailForm() {
            var selectedEmail = $scope.config.selectedEmail;
            return JSON.stringify({
                messageContents: $scope.config.quillEditor.getHtml(),
                fromName: selectedEmail.fromName,
                fromEmail: selectedEmail.fromEmail,
                replyToEmail: selectedEmail.replyToEmail,
                recipients: selectedEmail.recipients,
                scheduledFrequencyInWords: selectedEmail.when.scheduledFrequencyInWords,
                time: selectedEmail.when.time,
                subject: selectedEmail.subject,
                lang: selectedEmail.lang
            });
        }
        this.canScheduleEmail = function () {
            if (!$scope.scheduleEmailForm || $scope.config.scheduledEmails.length === 0) {
                return false;
            }
            return (!$scope.config.displaySpinner &&
                !$scope.scheduleEmailForm.$invalid &&
                !$scope.scheduleEmailForm.$pending);
        };
        this.canCreateNewSchedule = function () {
            if (env.get(environment_1.ENV_VARS.FEATURES)[feature_flipper_1.FEATURES.LIMIT_SCHEDULED_EMAILS]) {
                return $scope.config.scheduledEmails.length < max_scheduled_emails_1.MAX_ALLOWED_SCHEDULED_EMAILS;
            }
            return true;
        };
        this.canSendEmailNow = function () {
            // The recipients and when components are not required to send an emails now.
            return (!$scope.config.displaySpinner &&
                $scope.scheduleEmailForm &&
                $scope.scheduleEmailForm.fromName.$valid &&
                $scope.scheduleEmailForm.fromEmail.$valid &&
                $scope.scheduleEmailForm.replyToEmail.$valid &&
                $scope.scheduleEmailForm.subject.$valid &&
                !$scope.scheduleEmailForm.$pending);
        };
        this.showSendEmailNowModal = function () {
            $ctrl.sendNowEmailsStringList = DEFAULT_SEND_EMAIL_NOW_STRING_LIST;
            var sendEmailNowModal = modal({
                scope: $scope,
                title: '<span class="icon icon-envelope-wire-lg"></span><span translate style="vertical-align: middle;" translate>PR.SEND_EMAIL_NOW</span>',
                content: '' +
                    '<div ng-form="sendEmailToForm">' +
                    '<p translate>PR.SEND_EMAIL_TO</p>' +
                    '<div class="send-email-to-form-container">' +
                    '<pr-email-list class="schedule-email-full-width schedule-to-email-input" ' +
                    'name="recipients"' +
                    'email-list="$ctrl.sendNowEmailsStringList">' +
                    '</pr-email-list>' +
                    '<span class="icon icon-caution email-list-error"' +
                    'ng-show="sendEmailToForm.recipients.$error.emailList"' +
                    'qstrap.tooltip ' +
                    'content="{{ \'EMAIL_COMMA_SEPARATED_EMAIL_LIST_ERROR\' | translate }}"' +
                    'position="bottom"></span>' +
                    '<div>' +
                    '<div>',
                buttons: [
                    '<div class="btn btn-success" ng-class="{ disabled: !$ctrl.sendNowEmailsStringList }" ng-click="close();$ctrl.sendEmailNow()" translate>SEND</div>',
                    '<div class="btn btn-hover" ng-click="close()" translate>CLOSE</div>'
                ],
                className: 'send-email-now-modal'
            });
            sendEmailNowModal.show();
        };
        this.setSelectedEmailLanguageCode = function (lang) {
            $scope.config.selectedEmail.lang = lang;
        };
        this.sendEmailNow = function () {
            $scope.config.status.displaySpinner = true;
            var scheduledEmail = getSendEmailNowFromSelectedEmail();
            scheduleEmailService.sendEmailNow(scheduledEmail).then(function () {
                $scope.config.status.displaySpinner = false;
                showSendEmailNowSuccessModal();
            });
        };
        var getSendEmailNowFromSelectedEmail = function () {
            var selectedEmail = $scope.config.selectedEmail;
            return scheduleEmailService.NowEmail(selectedEmail.id, env.get('containerId'), {
                type: 'emails',
                recipients: emailService.extractEmailsFromString($ctrl.sendNowEmailsStringList)
            }, selectedEmail.fromName, selectedEmail.fromEmail, selectedEmail.replyToEmail, selectedEmail.subject, $scope.config.quillEditor.getHtml(), selectedEmail.lang);
        };
        function showSendEmailNowSuccessModal() {
            var sendEmailNowModal = modal({
                scope: $scope,
                title: '<span class="icon icon-envelope-wire-lg"></span><span translate style="vertical-align: middle;" translate>EMAIL_STATUS</span>',
                content: '<div translate>EMAIL_SENT</div>',
                buttons: [
                    '<div class="btn btn-hover" ng-click="close()" translate>CLOSE</div>'
                ],
                className: 'send-email-now-modal'
            });
            sendEmailNowModal.show();
        }
        var getScheduledEmailFromSelectedEmail = function () {
            var selectedEmail = $scope.config.selectedEmail;
            return scheduleEmailService.ScheduledEmail(selectedEmail.id, env.get('containerId'), {
                type: 'emails',
                recipients: emailService.extractEmailsFromString(selectedEmail.recipients)
            }, selectedEmail.fromName, selectedEmail.fromEmail, selectedEmail.replyToEmail, dateTimeMenuService.getCronTime(selectedEmail.when.scheduledFrequency, selectedEmail.when.time.getHours(), selectedEmail.when.time.getMinutes()), selectedEmail.when.timezone, selectedEmail.subject, $scope.config.quillEditor.getHtml(), selectedEmail.lang);
        };
        this.createSelectedEmail = function () {
            $scope.config.status.displaySpinner = true;
            var scheduledEmail = getScheduledEmailFromSelectedEmail();
            var tempNewEmailId = $scope.config.selectedEmail.id;
            scheduleEmailService.createScheduledEmail(scheduledEmail).then(function (response) {
                // Replace the temporary id with the real id from GE.
                var createdScheduledEmailId = response.id;
                scheduledEmail.id = createdScheduledEmailId;
                var isCreatedScheduleSelected = $scope.config.selectedEmail.id === tempNewEmailId;
                if (isCreatedScheduleSelected) {
                    $scope.config.selectedEmail.id = createdScheduledEmailId;
                }
                // Replace old scheduledEmail with the new one.
                var emailInScheduledEmailList = _.find($scope.config.scheduledEmails, { 'id': tempNewEmailId });
                _.extend(emailInScheduledEmailList, scheduledEmail);
                updateHashedOfSelectedEmailForm();
                $scope.config.status.displaySpinner = false;
                metricsService.scheduledEmail(_.get(dateTimeMenuService.getObjectFromCron(scheduledEmail.cronDatetime), 'type'), _.get(scheduledEmail, 'recipientsInfo.recipients.length'), metricsService.EMAIL_TYPE.CREATE);
            });
        };
        this.updateSelectedEmail = function () {
            $scope.config.status.displaySpinner = true;
            var scheduledEmail = getScheduledEmailFromSelectedEmail();
            scheduleEmailService.updateScheduledEmail(scheduledEmail).then(function (updatedScheduledEmail) {
                // Replace old scheduledEmail with the updated one.
                var emailInScheduledEmailList = _.find($scope.config.scheduledEmails, { 'id': updatedScheduledEmail.id });
                _.extend(emailInScheduledEmailList, updatedScheduledEmail);
                updateHashedOfSelectedEmailForm();
                $scope.config.status.displaySpinner = false;
                metricsService.scheduledEmail(_.get(dateTimeMenuService.getObjectFromCron(scheduledEmail.cronDatetime), 'type'), _.get(scheduledEmail, 'recipientsInfo.recipients.length'), metricsService.EMAIL_TYPE.UPDATE);
            });
        };
        this.deleteSelectedEmail = function (isOverride) {
            if (!isOverride) {
                confirmDeleteSelectedEmail();
            }
            else {
                // New emails haven't been persisted and just need deleted in the UI.
                if ($ctrl.isNewEmail($scope.config.selectedEmail.id)) {
                    // Remove old scheduledEmail
                    _.remove($scope.config.scheduledEmails, function (email) {
                        return email.id === $scope.config.selectedEmail.id;
                    });
                    trySelectFirstEmail();
                }
                else {
                    $scope.config.status.displaySpinner = true;
                    var scheduledEmail_1 = getScheduledEmailFromSelectedEmail();
                    scheduleEmailService.deleteScheduledEmail(scheduledEmail_1).then(function () {
                        // Remove old scheduledEmail
                        _.remove($scope.config.scheduledEmails, function (email) {
                            return email.id === scheduledEmail_1.id;
                        });
                        trySelectFirstEmail();
                        $scope.config.status.displaySpinner = false;
                    });
                }
            }
        };
        var trySelectFirstEmail = function () {
            if ($scope.config.scheduledEmails.length > 0) {
                var firstEmail = $scope.config.scheduledEmails[0];
                $ctrl.selectScheduledEmail(firstEmail, true);
            }
            else {
                setSelectedEmailToEmptyValues();
            }
            $scope.scheduleEmailForm.$setPristine();
        };
        this.shouldShowLanguagePicker = function () {
            return $ctrl.isDynamicTranslationsEnabled &&
                $ctrl.availableLanguageCodes && $ctrl.availableLanguageCodes.length > 1;
        };
        var setSelectedEmailToEmptyValues = function () {
            $scope.config.selectedEmail.id = null;
            $scope.config.selectedEmail.fromName = null;
            $scope.config.selectedEmail.fromEmail = null;
            $scope.config.selectedEmail.replyToEmail = null;
            $scope.config.selectedEmail.recipients = '';
            $scope.config.selectedEmail.when.scheduledFrequency = null;
            $scope.config.selectedEmail.when.time = null;
            $scope.config.selectedEmail.when.scheduledFrequencyInWords = '';
            $scope.config.selectedEmail.when.timezone = null;
            $scope.config.selectedEmail.subject = null;
            $scope.config.selectedEmail.lang = '';
            $scope.config.quillEditor.setHtml('');
            updateHashedOfSelectedEmailForm();
        };
        var confirmDeleteSelectedEmail = function () {
            var deleteSelectedEmail = modal({
                scope: $scope,
                title: '<span class="icon icon-caution-lg"></span><span translate style="vertical-align: middle;">CONFIRM_DELETION</span>',
                content: '<div translate>PR.CONFIRM_SCHEDULE_EMAIL_DELETION</div>',
                buttons: [
                    '<div class="btn btn-success" ng-click="close();$ctrl.deleteSelectedEmail(true)" translate>YES</div>',
                    '<div class="btn btn-hover" ng-click="close()" translate>NO</div>'
                ],
                className: 'delete-selected-email-modal'
            });
            deleteSelectedEmail.show();
        };
        this.$onInit = function () {
            init();
        };
    };
    ScheduleEmailModalController.$inject = [
        '$scope',
        '$filter',
        '$q',
        'rsEnvironment',
        'pr.globalOptionsService',
        'pr.timezoneUtil',
        'pr.scheduleEmailDateTimeMenuService',
        'pr.scheduleEmailService',
        'pr.emailService',
        'qstrap.modal',
        'prDynamicTranslationsUtil',
        'prDynamicTranslationsHelperUtils',
        'prMetricsService',
        'prActiveReport'
    ];
    module.controller('ScheduleEmailModalController', ScheduleEmailModalController);
}(angular.module('qualtrics.pr')));
