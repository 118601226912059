"use strict";
(function (module) {
    'use strict';
    /*
     * A wrapper around window.localStorage that conveniently converts values to / from json
     */
    module.factory('prLocalStorage', [
        '$window',
        function ($window) {
            var localStorage = $window.localStorage;
            function get(key) {
                if (!key) {
                    return null;
                }
                return angular.fromJson(localStorage.getItem(key));
            }
            function set(key, value) {
                if (!key) {
                    return;
                }
                if (!value) {
                    localStorage.removeItem(key);
                }
                localStorage.setItem(key, angular.toJson(value));
            }
            return {
                get: get,
                set: set
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
