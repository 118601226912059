"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("angular");
(function (module) {
    module.factory('pr.customMetricsMenuService', [
        'pr.customMetricsService',
        // @ts-ignore
        function (customMetricsService) {
            return {
                getMenu: getMenu
            };
            function getMenu() {
                var customMetrics = customMetricsService.getCustomMetrics();
                // @ts-ignore
                return customMetrics.map(function (customMetric) {
                    var name = customMetric.name, id = customMetric.id;
                    return {
                        label: name,
                        value: {
                            ptAttrs: {
                                type: 'customMetric',
                                attrName: id
                            }
                        }
                    };
                });
            }
        }
    ]);
})(angular_1.module('qualtrics.pr'));
