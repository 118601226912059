"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var container_utils_1 = require("@shared/utils/container-utils");
(function (module) {
    'use strict';
    CopyReportController.$inject = [
        '$scope',
        '$filter',
        '$routeParams',
        '$window',
        '$location',
        'rsContainers',
        'prActiveReport',
        'prErrorModal',
        'prSanitize',
        'pr.API_ROUTES',
        'prQueryString',
        'prMetricsService'
    ];
    function CopyReportController($scope, $filter, $routeParams, $window, $location, containers, activeReport, errorModal, sanitize, API_ROUTES, qs, metricsService) {
        var translate = $filter('translate');
        var $ctrl = this;
        $scope.$ctrl = $ctrl;
        $ctrl.reportCopyName = translate('PROJECT_COPY', { name: activeReport.getDescription() });
        var reportId = activeReport.getReportId();
        $ctrl.copyReport = function () {
            $ctrl.spin = true;
            return containers.copyContainer(reportId, sanitize.stripHTML($ctrl.reportCopyName), 'multi').then(function (copyRes) {
                if (copyRes.contextMeta && copyRes.contextMeta.connectedSources) {
                    return updateContextMeta(copyRes)
                        .then(function () {
                        metricsService.newReport();
                        $window.location = qs.append(API_ROUTES.VIEW + $routeParams.surveyId + '/' + copyRes._id, $location.search());
                    })
                        .catch(function () {
                        // try to cleanup the error
                        containers.deleteContainer(copyRes._id);
                        errorModal.show(translate('ERROR_CLONING_REPORT'));
                    });
                }
                else {
                    metricsService.newReport();
                    $window.location = qs.append(API_ROUTES.VIEW + $routeParams.surveyId + '/' + copyRes._id, $location.search());
                }
            }).catch(function () {
                errorModal.show(translate('ERROR_CLONING_REPORT'));
            });
        };
        function updateContextMeta(def) {
            var widgetMap = getWidgetMap(def.pages);
            var newConnectedSources = getNewConnectedSources(def.contextMeta.connectedSources, widgetMap);
            var newContextMeta = container_utils_1.copyContextMetaForNewReport(def.contextMeta);
            newContextMeta.connectedSources = newConnectedSources;
            return containers.patchContainer(def._id, { contextMeta: newContextMeta });
        }
        // Replace the key of connectedSource with new widgetId
        function getNewConnectedSources(origSources, widgetMap) {
            var res = {};
            _.forEach(origSources, function (value, key) {
                if (widgetMap[key]) {
                    var newKey = widgetMap[key];
                    res[newKey] = value;
                }
            });
            return res;
        }
        // Build a map of which the key is old widgetId and the value is new widgetId
        function getWidgetMap(pages) {
            var origPages = activeReport.getPages();
            var res = {};
            // loop through each widget on each page tracking by index
            for (var i = 0; i < pages.length; i++) {
                if (pages[i].widgets) {
                    for (var j = 0; j < pages[i].widgets.length; j++) {
                        var newWidgetId = pages[i].widgets[j]._id;
                        var origWidgetId = origPages[i].widgets[j]._id;
                        res[origWidgetId] = newWidgetId;
                    }
                }
            }
            return res;
        }
    }
    module.controller('prCopyReportController', CopyReportController);
}(angular.module('qualtrics.pr')));
