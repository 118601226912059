"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("angular");
/**
 * Factory to handle creating and destroying piped text instances.
 */
function PipedTextFactory(PipedText) {
    var registry = {};
    return {
        create: create,
        destroy: destroy,
        exists: exists
    };
    function create(widgetId, editor, editorLocator, onDestroy) {
        var pipedText = new PipedText(editor, editorLocator, widgetId);
        registry[widgetId] = { pipedText: pipedText, onDestroy: onDestroy };
        return pipedText;
    }
    function destroy(widgetId) {
        if (!exists(widgetId)) {
            return;
        }
        var _a = registry[widgetId], pipedText = _a.pipedText, onDestroy = _a.onDestroy;
        pipedText.destroy();
        onDestroy();
        delete registry[widgetId];
    }
    function exists(widgetId) {
        var _a;
        return !!((_a = registry[widgetId]) === null || _a === void 0 ? void 0 : _a.pipedText);
    }
}
(function (module) {
    module.factory('pr.pipedTextFactory', [
        'prPipedTextService',
        PipedTextFactory
    ]);
})(angular_1.module('qualtrics.pr'));
