"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClientInstanceId = void 0;
var unique_string_1 = require("@services/utils/unique-string");
/**
 * Keep a unique id for each instance of loading a report.
 * This is useful information needed for the backend,
 * e.g. to determine whether a client's version of the report
 * is outdated due to changes by a different client.
 */
var PREFIX = 'pr-client-instance-';
var instanceId = "" + PREFIX + unique_string_1.getUniqueString();
function getClientInstanceId() {
    return instanceId;
}
exports.getClientInstanceId = getClientInstanceId;
