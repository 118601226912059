"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var object_utils_1 = require("@shared/utils/object-utils");
(function (module) {
    'use strict';
    /**
     * Add tests for this
     * https://qualtrics.atlassian.net/browse/REP-6183
     */
    var setPRFactory = function (eventStream, widgetManager) {
        var setPRFilters = function (config, stayAddButton) {
            return function (updatedFilter) {
                if (config.waitForInit) {
                    return;
                }
                if (config.isBreakoutByFilters && _.isEmpty(updatedFilter)) {
                    // edge case where user adds multiple
                    // filters by breakout and removes
                    // them using the modal
                    return;
                }
                var sourceId = config.sourceIds[0];
                var filters = config.filters || {};
                if (object_utils_1.areDefinedValuesEqual(filters[sourceId], updatedFilter.filters)) {
                    return;
                }
                // do not keep references to filters-client data
                updatedFilter = _.cloneDeep(updatedFilter);
                if (updatedFilter.filters) {
                    filters[sourceId] = updatedFilter.filters;
                }
                else {
                    delete filters[sourceId];
                }
                if (!stayAddButton) {
                    config.filters = filters;
                    if (!_.isEmpty(config.filters)) {
                        eventStream.trigger('filtersChanged', filters);
                        widgetManager.notifyAllWidgets('filtersChanged', 'setPRFilters', filters);
                    }
                }
                config.setFilters({ filters: filters });
                config.waitForInit = true;
                config.filterService.hasSavedFilters()
                    .then(function (hasSavedFilters) {
                    config.hasSavedFilters = hasSavedFilters;
                    config.hasFilterApplied = config.filterService.hasValidFilterApplied();
                    config.waitForInit = false;
                    if (stayAddButton) {
                        config.init();
                    }
                });
            };
        };
        return {
            setPRFilters: setPRFilters
        };
    };
    module.factory('prSetPRFiltersFactory', [
        'prEventService',
        'pr.widgetManager',
        setPRFactory
    ]);
})(angular.module('qualtrics.pr'));
