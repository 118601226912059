"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("angular");
function WidgetTitleHelperFactory() {
    var widgetToStatusMap = {};
    return {
        getQuillContainerId: getQuillContainerId,
        onLoading: onLoading,
        onReady: onReady,
        isReady: isReady
    };
    function onLoading(widgetId) {
        widgetToStatusMap[widgetId] = false;
    }
    function onReady(widgetId) {
        widgetToStatusMap[widgetId] = true;
    }
    function isReady(widgetId) {
        return widgetToStatusMap[widgetId];
    }
    function getQuillContainerId(widgetId) {
        return widgetId + '_quill-container';
    }
}
(function (module) {
    module.factory('pr.widgetTitleHelper', WidgetTitleHelperFactory);
})(angular_1.module('qualtrics.pr'));
