"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Try to avoid adding imports to this file!
// Webpack uses imports to create a bundle of JS files. Our old JS files do not
// use ES6 imports/exports This file was created as an incremental step as we
// transition to typescript.
require("@components/advanced-display-logic/advanced-display-logic");
require("@components/advanced-display-logic/display-logic-modal/display-logic-modal-controller");
require("@components/advanced-display-logic/nested-display-logic/nested-display-logic");
require("@components/advanced-display-logic/nested-display-logic/nested-display-logic-controller");
require("@components/advanced-display-logic/single-display-logic/single-display-logic");
require("@components/advanced-display-logic/single-display-logic/single-display-logic-controller");
require("@components/authenticate-public-report/authenticate-public-report");
require("@components/batch-in-progress/batch-in-progress-web-banner");
require("@components/body/body");
require("@components/copy-report/copy-report-controller");
require("@components/copy-report/copy-report-modal");
require("@components/create-report/create-report-modal");
require("@components/create-report/create-report-service");
require("@components/create-report/create-report.controller");
require("@components/create-report/view-results-projects");
require("@components/delete-report/delete-report-controller");
require("@components/delete-report/delete-report-modal");
require("@components/dynamic-translations/dynamic-translations-controller");
require("@components/error-message/error-message");
require("@components/filebar/filebar");
require("@components/filebar/filebar-menus/bulk-field-menu");
require("@components/filebar/filebar-menus/edit-menu");
require("@components/filebar/filebar-menus/file-menu");
require("@components/filebar/filebar-menus/insert-menu");
require("@components/filebar/filebar-menus/report-menu");
require("@components/filebar/filebar-menus/share-menu");
require("@components/filebar/filebar-menus/view-menu");
require("@components/filterable-select-menu/filterable-select-menu");
require("@components/filters/filter-helpers/filter-constants");
require("@components/filters/filter-helpers/filter-util");
require("@components/filters/filter-setup/add-filters-button");
require("@components/filters/filter-setup/manage-filters-button");
require("@components/filters/filter-setup/report-filter-setup");
require("@components/filters/filter-setup/setPRFiltersFactory");
require("@components/fonts/pr-fonts");
require("@components/global-options/global-options-controller");
require("@components/global-options/global-options-modal");
require("@components/global-options/global-options-preview-scales/global-options-preview-scales");
require("@components/global-options/global-options-preview-table/global-options-preview-table");
require("@components/global-options/global-options-preview-widget/global-options-preview-widget-controller");
require("@components/global-options/global-options-preview-widget/global-options-preview-widget-directive");
require("@components/global-options/locale-settings/locale-settings");
require("@components/global-options/widget-settings/widget-settings");
require("@components/graphic-library/graphic-library-controller");
require("@components/graphic-library/graphic-library-modal");
require("@components/graphic-library/graphic-library-service");
require("@components/graphic-library/upload-image/upload-image-controller");
require("@components/graphic-library/upload-image/upload-image-modal");
require("@components/header/EE-overrides/header");
require("@components/header/header");
require("@components/main/main");
require("@components/manage-public-report/manage-public-report-controller");
require("@components/manage-public-report/manage-public-report-modal");
require("@components/no-reports/no-reports.component");
require("@components/options-pane/options-pane-controller");
require("@components/options-pane/widget-style-selector/widget-style-selector");
require("@components/page-break/page-break");
require("@components/page-header-footer/page-header-footer");
require("@components/page-header-footer/page-header-footer-export-loading-service");
require("@components/page-header-footer/page-header-footer-init-service");
require("@components/page-header-footer/page-header-footer-modal/page-header-footer-generator");
require("@components/page-header-footer/page-header-footer-modal/page-header-footer-modal");
require("@components/page-header-footer/page-header-footer-modal/page-header-footer-modal-controller");
require("@components/page-header-footer/page-header-footer-options/page-header-footer-options");
require("@components/page-header-footer/page-header-footer-options/page-header-footer-options-controller");
require("@components/page-header-footer/page-header-footer-options/page-header-footer-options-service");
require("@components/page-layout/page-layout-controller");
require("@components/page-layout/page-layout-directive");
require("@components/page-layout/page-layout-service");
require("@components/page/page");
require("@components/page/page-margins/page-margins");
require("@components/partial-data/export/partial-data-export-banner-directive");
require("@components/partial-data/partial-data-service");
require("@components/partial-data/web/partial-data-web-banner");
require("@components/public-export-passcode/public-export-passcode-controller");
require("@components/public-export-passcode/public-export-passcode-modal");
require("@components/quill/quill-decorator");
require("@components/quill/QuillFormatter");
require("@components/quill/text-editor-menu-service");
require("@components/rename-report/rename-report-modal.component");
require("@components/rename-report/rename-report-modal.controller");
require("@components/report/report");
require("@components/report/toggle-watchers");
require("@components/schedule-email/directives/email-domain-brand-validator");
require("@components/schedule-email/directives/email-list-validator");
require("@components/schedule-email/directives/spf-record-validator");
require("@components/schedule-email/email-list/email-list");
require("@components/schedule-email/quill-editor/quill-editor");
require("@components/schedule-email/schedule-email-modal");
require("@components/schedule-email/schedule-email-modal-controller");
require("@components/single-widget/single-widget");
require("@components/test/test-directive");
require("@components/test/test-directive-controller");
require("@components/text-styles/text-styles-modal/text-styles-modal");
require("@components/text-styles/text-styles-modal/text-styles-modal-controller");
require("@components/three-sixty-rater-display-logic/three-sixty-rater-display-logic-modal/three-sixty-rater-display-logic-modal.component");
require("@components/three-sixty-rater-display-logic/three-sixty-rater-display-logic-modal/three-sixty-rater-display-logic-modal.controller");
require("@components/three-sixty-rater-display-logic/three-sixty-rater-single-display-logic/three-sixty-rater-single-display-logic.component");
require("@components/three-sixty-rater-display-logic/three-sixty-rater-single-display-logic/three-sixty-rater-single-display-logic.controller");
require("@components/toc/toc");
require("@components/toolbar/add-widget-buttons/add-widget-buttons");
require("@components/toolbar/export-button/EE-overrides/export-button");
require("@components/toolbar/export-button/EE-overrides/export-modal");
require("@components/toolbar/export-button/EE-overrides/export-modal-controller");
require("@components/toolbar/export-button/export-button");
require("@components/toolbar/global-option-button/global-option-button");
require("@components/toolbar/language-picker/language-picker");
require("@components/toolbar/preview-button/preview-button");
require("@components/toolbar/public-toolbar-directive");
require("@components/toolbar/response-count/response-count");
require("@components/toolbar/share-button/share-button");
require("@components/toolbar/toolbar-directive");
require("@components/toolbar/undo-redo/undo-redo-controller");
require("@components/toolbar/undo-redo/undo-redo-directive");
require("@components/toolbar/widget-toolbar-options/widget-toolbar-button/widget-toolbar-button");
require("@components/toolbar/widget-toolbar-options/widget-toolbar-color-picker/widget-toolbar-color-picker");
require("@components/toolbar/widget-toolbar-options/widget-toolbar-menu/widget-toolbar-menu");
require("@components/toolbar/widget-toolbar-options/widget-toolbar-options");
require("@components/toolbar/widget-toolbar-options/widget-toolbar-piped-text/widget-toolbar-piped-text");
require("@components/toolbar/zoom-button/zoom-button");
require("@components/transformer/item-mover");
require("@components/transformer/item-resizer");
require("@components/transformer/item-selector");
require("@components/transformer/jquery-extensions/collides");
require("@components/transformer/jquery-extensions/is-text-area");
require("@components/transformer/jquery-extensions/zoom");
require("@components/transformer/transformer");
require("@components/widget-count-warning/widget-count-warning-banner");
require("@components/widget-padding/widget-padding-selector");
require("@components/widget-table-density/widget-table-density");
require("@components/widget-transformer/widget-transformer");
require("@components/widget/floating-container/floating-container");
require("@components/widget/hidden-widget-renderer/hidden-renderer");
require("@components/widget/insert-widget-button/insert-widget-button");
require("@components/widget/widget");
require("@components/widget/widget-actions-button/widget-actions-button");
require("@components/widget/widget-body/divider-body");
require("@components/widget/widget-body/table-body");
require("@components/widget/widget-body/text-body");
require("@components/widget/widget-body/widget-body");
require("@components/widget/widget-chunker/table-chunker");
require("@components/widget/widget-chunker/text-chunker");
require("@components/widget/widget-chunker/widget-chunker");
require("@components/widget/widget-chunker/widget-chunker-factory");
require("@components/widget/widget-error/widget-error");
require("@components/widget/widget-shadow");
require("@services/api-routes");
require("@services/auto-height-helper");
require("@services/bulk-field-service");
require("@services/classic-url-service");
require("@services/custom-widget-service");
require("@services/data-requests/data-request-service");
require("@services/data-requests/http-interceptor-service");
require("@services/data-requests/http-post-cache-service");
require("@services/data-requests/snapshot");
require("@services/display-logic/conditional-display-service");
require("@services/display-logic/display-logic-constants");
require("@services/display-logic/display-logic-service");
require("@services/display-logic/ranked-display-logic");
require("@services/download-helper");
require("@services/dynamic-translation/dynamic-translations-helper-utils");
require("@services/dynamic-translation/dynamic-translations-service");
require("@services/dynamic-translation/static-translation-loader");
require("@services/editable-pptx/chart");
require("@services/editable-pptx/editable-widgets");
require("@services/editable-pptx/is-enabled");
require("@services/editable-pptx/page-coordinates");
require("@services/editable-pptx/quill-converter");
require("@services/editable-pptx/response-count-converter");
require("@services/editable-pptx/table-converter");
require("@services/editable-pptx/utils");
require("@services/event-service");
require("@services/feature-flipper-service");
require("@services/fields-service");
require("@services/fieldset-service");
require("@services/fieldsets-service");
require("@services/filters/custom-filter-service");
require("@services/filters/custom-filters/eval-scope-filters");
require("@services/filters/custom-filters/person-metadata-filters");
require("@services/filters/custom-filters/relationship-filters");
require("@services/filters/custom-filters/source-filters");
require("@services/filters/filter-service");
require("@services/filters/filters");
require("@services/filters/saved-filters-service");
require("@services/filters/shared-filter-utils");
require("@services/global-options-service");
require("@services/header-footer-service");
require("@services/hotkey-util");
require("@services/init-service");
require("@services/instance-context/client-instance-id");
require("@services/instance-context/navigation-service");
require("@services/instance-context/report-types");
require("@services/isolation-service");
require("@services/keylog-service");
require("@services/local-storage");
require("@services/logger-manager");
require("@services/menus/custom-widget-menu-service");
require("@services/menus/field-menu-service");
require("@services/menus/insert-menu-service");
require("@services/menus/report-menu-service");
require("@services/menus/source-menu-service");
require("@services/menus/widget-menu-service");
require("@services/metric-imperial-service");
require("@services/metrics-event-service");
require("@services/modals/error-modal");
require("@services/modals/report-conflict-modal");
require("@services/modals/widget-count-limit-modal");
require("@services/multi-metric-breakout-service");
require("@services/org-hierarchy-service");
require("@services/overrides/360-overrides/overrides");
require("@services/overrides/360-overrides/services/three-sixty-person-metadata-menu.service");
require("@services/overrides/360-overrides/services/three-sixty-rater-display-logic.service");
require("@services/overrides/360-overrides/services/three-sixty-relationship-menu.service");
require("@services/overrides/360-overrides/services/three-sixty-relationships.service");
require("@services/overrides/EE-overrides/overrides");
require("@services/overrides/override-service");
require("@services/padding-service");
require("@services/person-service");
require("@services/piped-text-services/date-menu-service");
require("@services/piped-text-services/dynamic-value-service");
require("@services/piped-text-services/ee-menu-service");
require("@services/piped-text-services/embed-events");
require("@services/piped-text-services/metrics-service");
require("@services/piped-text-services/piped-text-factory");
require("@services/piped-text-services/piped-text-menu-service");
require("@services/piped-text-services/piped-text-service");
require("@services/piped-text-services/piped-text-source-menu-service");
require("@services/piped-text-services/survey-piped-text-map");
require("@services/piped-text-services/three-sixty-menu-service");
require("@services/piped-text-services/tooltip-service");
require("@services/porting-service");
require("@services/ppt-export-regions-service");
require("@services/predefined-data-service");
require("@services/query-string-service");
require("@services/report-state/active-report");
require("@services/report-state/edit-ability-manager");
require("@services/report-state/page-sync-manager");
require("@services/report-state/report-status");
require("@services/report-state/rs-containers-wrapper");
require("@services/report-state/section-manager");
require("@services/report-state/section-manager-helper");
require("@services/report-state/source-management");
require("@services/report-state/status-bar-css-manager");
require("@services/report-state/widget-operations-service");
require("@services/response-count-service");
require("@services/sanitize");
require("@services/schedule-email/datetime-menu-service");
require("@services/schedule-email/distribution-service");
require("@services/schedule-email/email-service");
require("@services/schedule-email/schedule-email-service");
require("@services/scroll-service");
require("@services/source-data-service");
require("@services/source-folder-service");
require("@services/style-node-service");
require("@services/survey-folder-service");
require("@services/text-formats-helper");
require("@services/third-party/org-hierarchy-filter-helper");
require("@services/third-party/third-party-widget-service");
require("@services/three-sixty-service");
require("@services/timezone-util");
require("@services/undo-redo-service");
require("@services/user-global-options-service");
require("@services/utils/sequential-task-queue");
require("@services/utils/ttl-cache-factory");
require("@services/utils/unique-string");
require("@services/view-options");
require("@services/widget-constants");
require("@services/widget-count-warning-banner-service");
require("@services/widget-init");
require("@services/widget-limit");
require("@services/widget-manager");
require("@services/widget-styles-service");
