"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./quill.less");
/* global Quill */
(function (module) {
    module.run(function () {
        // Will skip for tests
        if (!window.Quill) {
            return;
        }
        // Various Quill adjustments
        // allow any fonts, and use classes for fonts
        var Font = Quill.import('formats/font');
        var FontClass = Quill.import('attributors/class/font');
        Font.whitelist = null;
        Quill.register(FontClass, true);
        // allow any font size
        var SizeStyle = Quill.import('attributors/style/size');
        SizeStyle.whitelist = null;
        Quill.register(SizeStyle, true);
        // Define our own class format: used for footnote
        var parchment = Quill.import('parchment');
        Quill.register('formats/class', new parchment.Attributor.Class('class', 'ql-class', {
            whitelist: null,
            scope: parchment.Scope.BLOCK
        }));
        // NOTE: headers are stored as the index (1 INDEXED) of this array so DO NOT change order
        var HEADER_TYPES = ['H1', 'H2', 'H3', 'H4', 'H5'];
        var Header = Quill.import('formats/header');
        Header.tagName = HEADER_TYPES;
        // Register a custom blot for use in piped texts
        var Embed = Quill.import('blots/embed');
        var PipedText = function (node, value) {
            // Call Embed Constructor
            Embed.call(this, node, value);
        };
        PipedText.prototype = Object.create(Embed.prototype);
        $.extend(PipedText, Object.create(Embed));
        PipedText.prototype.constructor = PipedText;
        PipedText.create = function (value) {
            var node = document.createElement(this.tagName);
            node.className = 'piped-text-highlight';
            node.setAttribute('attach-event-listener', 'true');
            node.setAttribute('update-content', 'true');
            if (value.ptAttrs) {
                node.setAttribute('id', value.ptAttrs.ptId);
                value.ptAttrs.tooltipContent = value.ptAttrs.tooltipContent || value.ptAttrs.innerText;
                node.innerText = value.ptAttrs.innerText;
                node.setAttribute('ptAttrs', JSON.stringify(value.ptAttrs));
            }
            return node;
        };
        PipedText.value = function (node) {
            var ptAttrs = JSON.parse(node.getAttribute('ptAttrs'));
            return {
                ptAttrs: ptAttrs
            };
        };
        PipedText.index = function () {
            return 1;
        };
        PipedText.blotName = 'piped-text';
        PipedText.className = 'piped-text';
        PipedText.tagName = 'SPAN';
        Quill.register({
            'formats/piped-text': PipedText
        });
        // Register a custom blot for use in hyperlinks
        var Hyperlink = function (node, value) {
            // Call Embed Constructor
            Embed.call(this, node, value);
        };
        Hyperlink.prototype = Object.create(Embed.prototype);
        $.extend(Hyperlink, Object.create(Embed));
        Hyperlink.prototype.constructor = Hyperlink;
        Hyperlink.create = function (value) {
            var node = document.createElement(this.tagName);
            node.className = 'hyperlink-highlight';
            if (value.hlAttrs) {
                var linkEl = document.createElement('a');
                linkEl.innerText = value.hlAttrs.innerText;
                linkEl.setAttribute('href', value.hlAttrs.url);
                node.appendChild(linkEl);
                node.setAttribute('hlAttrs', JSON.stringify(value.hlAttrs));
                node.setAttribute('id', value.hlAttrs.id);
            }
            return node;
        };
        Hyperlink.value = function (node) {
            var hlAttrs = JSON.parse(node.getAttribute('hlAttrs'));
            return {
                hlAttrs: hlAttrs
            };
        };
        Hyperlink.index = function () {
            return 2;
        };
        Hyperlink.blotName = 'hyperlink';
        Hyperlink.className = 'hyperlink';
        Hyperlink.tagName = 'SPAN';
        Quill.register({
            'formats/hyperlink': Hyperlink
        });
    });
})(angular.module('qualtrics.pr'));
