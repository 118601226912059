"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var multi_metric_breakout_utils_1 = require("@shared/utils/multi-metric-breakout-utils");
var angular_1 = require("angular");
(function (angularModule) {
    angularModule.factory('pr.multiMetricBreakoutService', function () {
        return {
            convertBreakoutFiltersToMetrics: multi_metric_breakout_utils_1.convertBreakoutFiltersToMetrics
        };
    });
})(angular_1.module('qualtrics.pr'));
