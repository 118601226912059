"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collisions = exports.collidesWith = void 0;
/*
 * Returns true if the element collides with the other
 */
function collidesWith(thisElement, withElement) {
    if (!thisElement.length || !withElement.length) {
        return false;
    }
    var a = thisElement[0].getBoundingClientRect();
    var b = withElement[0].getBoundingClientRect();
    var ret = !(a.bottom <= b.top || a.top >= b.bottom || a.right <= b.left || a.left >= b.right);
    return ret;
}
exports.collidesWith = collidesWith;
/*
 * returns a collection of elements that the current element collides with
 */
function collisions(thisElement, withElement) {
    return withElement.filter(function () {
        var sel = $(this);
        return thisElement.collidesWith(sel);
    });
}
exports.collisions = collisions;
/* global jQuery */
(function ($) {
    $.fn.collidesWith = function (withEl) {
        var el = this;
        withEl = $(withEl);
        return collidesWith(el, withEl);
    };
    $.fn.collisions = function (selection) {
        var thisElement = this;
        var withElement = $(selection);
        return collisions(thisElement, withElement);
    };
})(jQuery);
