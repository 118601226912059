"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("angular");
var REMOVE_BODY_EVENT = 'REMOVE_BODY_EVENT';
function WidgetUtilsFactory(pageLayout, 
// @ts-ignore
hiddenRenderer, 
// @ts-ignore
eventService) {
    return {
        moveBodyToFloatingContainer: moveBodyToFloatingContainer,
        removeBodyFromFloatingContainer: removeBodyFromFloatingContainer
    };
    function removeBodyFromFloatingContainer() {
        eventService.trigger(REMOVE_BODY_EVENT);
    }
    /**
     * Moves the body for the widget content to the floating container
     * @param {Widget} widget      - the widget being modified
     * @param {JQLite} $floater    - the floating container
     * @param {JQLite} $customBody - An optional custom body to move. If not provided,
     *                             use the body stored by the hidden renderer
     */
    function moveBodyToFloatingContainer(widget, $floater, $customBody) {
        if (!widget) {
            return;
        }
        removeBodyFromFloatingContainer();
        var $customBodyParent = $customBody === null || $customBody === void 0 ? void 0 : $customBody.parent();
        eventService.on(REMOVE_BODY_EVENT, function () {
            eventService.off(REMOVE_BODY_EVENT);
            if ($customBody) {
                $customBodyParent === null || $customBodyParent === void 0 ? void 0 : $customBodyParent.append($customBody);
            }
            else {
                hiddenRenderer.hideWidget(widget);
            }
        });
        if ($customBody) {
            var width = pageLayout.getWidgetWidth(widget);
            $customBody.css('width', width);
            $floater.append($customBody);
        }
        else {
            hiddenRenderer.showWidget(widget, $floater);
        }
    }
}
(function (angularModule) {
    angularModule.factory('pr.floatingContainerHelper', [
        'prPageLayoutService',
        'prHiddenRenderer',
        'prEventService',
        WidgetUtilsFactory
    ]);
})(angular_1.module('qualtrics.pr'));
