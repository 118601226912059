"use strict";
(function (module) {
    'use strict';
    module.factory('prCustomWidgetMenuService', [
        '$filter',
        'prCustomWidgetService',
        function ($filter, customWidgetService) {
            var translate = $filter('translate');
            var MENU_TYPES = ['BASIC', 'CHART', 'TABLE', 'TEXT'];
            function getFolder(tags) {
                var folder = _.find(tags, function (tag) {
                    return _.includes(MENU_TYPES, tag.toUpperCase());
                });
                return (folder === 'text' && 'BASIC[0].submenu.items') || (folder && folder.toUpperCase()) || 'OTHER';
            }
            return {
                getMenuData: function (widgetName) {
                    var customWidgets = customWidgetService.getCustomWidgets();
                    var widgetMeta = customWidgets[widgetName].registryMeta || {};
                    return {
                        folder: getFolder(widgetMeta.tags),
                        menuItem: {
                            label: translate(widgetMeta.title || widgetName),
                            value: widgetName,
                            icon: widgetMeta.icon || '',
                            replaceCheck: true
                        }
                    };
                }
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
