"use strict";
(function (module) {
    'use strict';
    module.factory('prReportMenuService', [
        '$http',
        '$filter',
        '$routeParams',
        '$window',
        '$location',
        'prActiveReport',
        'pr.API_ROUTES',
        'prClassicURLService',
        'rsEnvironment',
        'prQueryString',
        function ($http, $filter, $routeParams, $window, $location, report, API_ROUTES, classicURL, env, qs) {
            var RECENT_REPORTS_LIMIT = 4;
            var translate = $filter('translate');
            function getMenuItems() {
                return getUserReports().then(function (reports) {
                    var recentReports = reports.slice(0, RECENT_REPORTS_LIMIT), recentMenu = getFormattedReports(recentReports);
                    if (reports.length <= RECENT_REPORTS_LIMIT) {
                        appendLegacyReports(recentMenu);
                        return recentMenu;
                    }
                    recentReports = _.orderBy(reports, 'lastModifiedDate', 'desc').slice(0, RECENT_REPORTS_LIMIT);
                    recentMenu = getFormattedReports(recentReports);
                    var otherReports = _.differenceWith(reports, recentReports, _.isEqual), otherMenu = getFormattedReports(otherReports);
                    var items = [{ header: translate('RECENTLY_USED') }].concat(recentMenu, { divider: true }, {
                        label: translate('OTHER_REPORTS'),
                        submenu: {
                            items: otherMenu,
                            searchable: true,
                            localizedText: {
                                searchText: translate('SEARCH_ELLIPSIS')
                            }
                        }
                    });
                    appendLegacyReports(items);
                    return items;
                });
            }
            function appendLegacyReports(items) {
                if (env.get('permissions').canAccessLegacyReports) {
                    items.push({ divider: true }, {
                        label: translate('VIEW_CLASSIC_REPORTS'),
                        action: function () {
                            $window.open(classicURL.getClassicURL());
                        }
                    });
                }
                return items;
            }
            function getUserReports(sourceId) {
                if (!sourceId) {
                    sourceId = $routeParams.surveyId;
                }
                return $http.get(API_ROUTES.REPORTS + '/' + sourceId + '?sortBy=description&sortOrder=asc')
                    .then(function (data) {
                    return data.data;
                });
            }
            function getFormattedReports(reports) {
                var activeReport = report.getReportId() || $routeParams.containerId;
                return _.map(reports, function (rep) {
                    return {
                        label: rep.description,
                        value: rep._id,
                        selected: rep._id === activeReport,
                        action: navigateToReport
                    };
                });
            }
            function navigateToReport(reportId) {
                var activeSurvey = $routeParams.surveyId;
                $window.location.href = qs.append(API_ROUTES.VIEW + activeSurvey + '/' + reportId, $location.search());
            }
            return {
                getMenuItems: getMenuItems,
                getUserReports: getUserReports
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
