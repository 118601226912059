"use strict";
(function (module) {
    'use strict';
    GlobalOptionsController.$inject = [
        '$scope',
        '$filter',
        '$timeout',
        '$templateCache',
        'pr.globalOptionsService',
        'pr.widgetManager',
        'prActiveReport',
        'prEventService',
        'prTextFormatsHelper',
        'prPageLayoutService',
        'prFlowingPages',
        'prViewOptions',
        'prHeaderFooterService',
        'pr.userGlobalOptionsService',
        'prMetricsService',
        'prDynamicTranslationsHelperUtils'
    ];
    function GlobalOptionsController($scope, $filter, $timeout, $templateCache, goService, widgetManager, activeReport, eventService, formatHelper, pageLayout, flowingPages, viewOptions, headerFooterService, userGlobalOptionsService, metricsService, dynamicTranslationHelperUtils) {
        var TEXT_FORMAT_OPTIONS = ['FONT', 'SIZE', 'COLOR', 'BOLD'], WIDGET_TEMPLATE = 'WIDGET_TEMPLATE', PAGE_TEMPLATE = 'PAGE_TEMPLATE', LOCALE_TEMPLATE = 'LOCALE_TEMPLATE', translate = $filter('translate'), $ctrl = this;
        $scope.$ctrl = $ctrl;
        var oldGlobalOptions;
        var textFormatOptions = formatHelper.getSubsetFormatOptions(TEXT_FORMAT_OPTIONS);
        var widgetDirective = '' +
            '<div class="global-option">' +
            '<pr-widget-settings ' +
            'global-options="$ctrl.globalOptions" ' +
            'body-text-formats-options="$ctrl.bodyTextFormatsOptions" ' +
            'table-header-text-formats-options="$ctrl.tableHeaderTextFormatsOptions" ' +
            'table-body-text-formats-options="$ctrl.tableBodyTextFormatsOptions" ' +
            'chart-text-formats-options="$ctrl.chartTextFormatsOptions" ' +
            'select-widget-tab="$ctrl.selectWidgetTab">' +
            '</pr-widget-settings>' +
            '</div>';
        $templateCache.put(WIDGET_TEMPLATE, widgetDirective);
        var pageDirective = '' +
            '<div class="global-option">' +
            '<pr-page-layout ' +
            'page-layout="$ctrl.pageLayout" ' +
            'unit="$ctrl.globalOptions.unit">' +
            '</pr-page-layout>' +
            '</div>';
        $templateCache.put(PAGE_TEMPLATE, pageDirective);
        var localeDirective = '' +
            '<div class="global-option">' +
            '<pr-locale-settings global-options="$ctrl.globalOptions">' +
            '</pr-locale-settings>' +
            '</div>';
        $templateCache.put(LOCALE_TEMPLATE, localeDirective);
        angular.extend($ctrl, {
            tabs: [
                {
                    title: translate('VISUALIZATION'),
                    template: WIDGET_TEMPLATE
                },
                {
                    title: translate('PAGE'),
                    template: PAGE_TEMPLATE
                },
                {
                    title: dynamicTranslationHelperUtils.isDynamicTranslationsSupported()
                        ? translate('TRANSLATIONS')
                        : translate('LOCALE'),
                    template: LOCALE_TEMPLATE
                }
            ],
            metricOptions: {
                choiceCount: translate('CHOICE_COUNT'),
                percentage: translate('PERCENTAGE')
            },
            globalOptionsOperationItems: [
                {
                    label: translate('GLOBAL_SETTINGS_APPLY'),
                    action: function () {
                        $ctrl.useDefaultReportOptions();
                    }
                }, {
                    label: translate('GLOBAL_SETTINGS_SAVE'),
                    action: function () {
                        $ctrl.saveAsDefaultReportOptions();
                    }
                }, {
                    divider: true
                }, {
                    label: translate('GLOBAL_SETTINGS_USE_QDEFAULT'),
                    action: function () {
                        $ctrl.useQualtricsDefaultReportOptions();
                    }
                }
            ],
            globalOptions: _.cloneDeep(goService.getOptions()),
            bodyTextFormatsOptions: _.cloneDeep(textFormatOptions),
            tableHeaderTextFormatsOptions: _.cloneDeep(textFormatOptions),
            tableBodyTextFormatsOptions: _.cloneDeep(textFormatOptions),
            chartTextFormatsOptions: _.cloneDeep(textFormatOptions),
            selectedIndex: $scope.selectedIndex,
            selectWidgetTab: $scope.selectedIndex === 0,
            pageLayout: pageLayout.getProperties(),
            $onInit: function () {
                updateTextFormats();
                getCurrentTextFormats();
                oldGlobalOptions = _.cloneDeep($ctrl.globalOptions);
            },
            save: function () {
                saveGlobalOptions();
                if (!_.isEqual(oldGlobalOptions, $ctrl.globalOptions) ||
                    !_.isEqual($ctrl.pageLayout, pageLayout.getProperties())) {
                    metricsService.changeSettings();
                }
                savePageLayout();
            },
            cleanUp: function () {
                $scope.$destroy();
            },
            switchTab: function (template) {
                if (template === WIDGET_TEMPLATE) {
                    $ctrl.selectWidgetTab = true;
                }
            },
            useDefaultReportOptions: function () {
                userGlobalOptionsService.getUserDefaultOptions()
                    .then(function (data) {
                    _.merge($ctrl.globalOptions, data.globalOptions);
                    _.merge($ctrl.pageLayout, data.pageLayout);
                    var customColorPalettes = _.cloneDeep(activeReport.getMetadata('customColorPalettes')) || {};
                    _.merge(customColorPalettes, data.globalCustomPalettes);
                    activeReport.setMetadata('customColorPalettes', customColorPalettes);
                    updateTextFormats();
                });
                metricsService.defaultSettings(metricsService.DEFAULT_TYPES.USE);
            },
            saveAsDefaultReportOptions: function () {
                getCurrentTextFormats();
                var customColorPalettes = activeReport.getMetadata('customColorPalettes') || {};
                var globalOptions = _.merge({}, $ctrl.globalOptions, {
                    pageLayout: $ctrl.pageLayout,
                    globalCustomPalettes: customColorPalettes
                });
                userGlobalOptionsService.setUserDefaultOptions(globalOptions);
                metricsService.defaultSettings(metricsService.DEFAULT_TYPES.SAVE);
            },
            useQualtricsDefaultReportOptions: function () {
                var defaultReportOptions = goService.getDefaults();
                var defaultPageLayout = pageLayout.getDefaultProperties();
                _.merge($ctrl.globalOptions, defaultReportOptions);
                _.merge($ctrl.pageLayout, defaultPageLayout);
                updateTextFormats();
                $scope.$digest();
                metricsService.defaultSettings(metricsService.DEFAULT_TYPES.QUALTRICS);
            }
        });
        function updateTextFormats() {
            var go = $ctrl.globalOptions;
            addDefaultFormats($ctrl.bodyTextFormatsOptions, go.bodyTextFormats);
            addDefaultFormats($ctrl.tableHeaderTextFormatsOptions, go.tableHeaderTextFormats);
            addDefaultFormats($ctrl.tableBodyTextFormatsOptions, go.tableBodyTextFormats);
            addDefaultFormats($ctrl.chartTextFormatsOptions, go.chartTextFormats);
            goService.applyChartTextStyles('.preview-widget-body', go.chartTextFormats, 'pr-chart-styles-preview');
            goService.applyTableHeaderTextStyles('.preview-table-body', go.tableHeaderTextFormats, 'pr-table-header-preview');
            goService.applyTableBodyTextStyles('.preview-table-body', go.tableBodyTextFormats, 'pr-table-body-preview');
        }
        function saveGlobalOptions() {
            getCurrentTextFormats();
            goService.setOptions($ctrl.globalOptions);
            // we need to let widgets know they should update
            updateWidgets();
        }
        function getCurrentTextFormats() {
            $ctrl.globalOptions.bodyTextFormats = _.mapValues($ctrl.bodyTextFormatsOptions, function (o) {
                return o.selectedColor || o.selectedFormat;
            });
            $ctrl.globalOptions.chartTextFormats = _.mapValues($ctrl.chartTextFormatsOptions, function (o) {
                return o.selectedColor || o.selectedFormat;
            });
            $ctrl.globalOptions.tableHeaderTextFormats = _.mapValues($ctrl.tableHeaderTextFormatsOptions, function (o) {
                return o.selectedColor || o.selectedFormat;
            });
            $ctrl.globalOptions.tableBodyTextFormats = _.mapValues($ctrl.tableBodyTextFormatsOptions, function (o) {
                return o.selectedColor || o.selectedFormat;
            });
        }
        function savePageLayout() {
            var oldPageLayout = pageLayout.getProperties();
            if (_.isEqual($ctrl.pageLayout, oldPageLayout)) {
                return;
            }
            pageLayout.setProperties($ctrl.pageLayout);
            flowingPages.updateWidgetScale();
            updateMargins();
            $timeout(function () {
                viewOptions.updateZoom();
            });
            widgetManager.notifyAllWidgets('resized');
            activeReport.saveAllPages();
        }
        function updateWidgets() {
            // If metric, decimalPlaces, weighting, or timezone change we use filtersChanged to cause widgets to refetch data.
            if ($ctrl.globalOptions.metric !== oldGlobalOptions.metric
                || $ctrl.globalOptions.decimalPlaces !== oldGlobalOptions.decimalPlaces
                || $ctrl.globalOptions.weighted !== oldGlobalOptions.weighted
                || $ctrl.globalOptions.enableTotals !== oldGlobalOptions.enableTotals
                || $ctrl.globalOptions.useRowTotals !== oldGlobalOptions.useRowTotals
                || $ctrl.globalOptions.percentageDenominator !== oldGlobalOptions.percentageDenominator
                || $ctrl.globalOptions.showPercentages !== oldGlobalOptions.showPercentages
                || !_.isEqual($ctrl.globalOptions.resultsTableCharacterLimit, oldGlobalOptions.resultsTableCharacterLimit)
                || $ctrl.globalOptions.timezone !== oldGlobalOptions.timezone
                || !_.isEqual(oldGlobalOptions.responseCountViewMode, $ctrl.globalOptions.responseCountViewMode)) {
                // these options are related to dataConfig and/or data requests, so force listeners to re-read dataConfig
                widgetManager.notifyAllWidgets('filtersChanged');
            }
            // if global display logic get updated
            if (!_.isEqual($ctrl.globalOptions.displayLogic, oldGlobalOptions.displayLogic)) {
                widgetManager.notifyAllWidgets('globalDisplayLogicChanged');
            }
            // if widget padding gets updated
            if (!_.isEqual($ctrl.globalOptions.widgetPadding, oldGlobalOptions.widgetPadding)) {
                widgetManager.notifyAllWidgets('widgetPaddingChanged');
            }
            if (!_.isEqual($ctrl.globalOptions.tableBodyDensity, oldGlobalOptions.tableBodyDensity)) {
                widgetManager.notifyAllWidgets('widgetTableBodyDensityChanged');
            }
            // if chart text style gets updated
            if (!_.isEqual(oldGlobalOptions.chartTextFormats, $ctrl.globalOptions.chartTextFormats)) {
                goService.applyChartTextStyles();
                // call all widgets to resize
                widgetManager.notifyAllWidgets('resized');
            }
            // global color palette changed
            if (!_.isEqual(oldGlobalOptions.colorPalette, $ctrl.globalOptions.colorPalette)) {
                widgetManager.notifyAllWidgets('globalOptionsChanged');
            }
            // global scale color palette changed
            if (!_.isEqual(oldGlobalOptions.scaleColorPalette, $ctrl.globalOptions.scaleColorPalette) ||
                !_.isEqual(oldGlobalOptions.customScaleColorPalette, $ctrl.globalOptions.customScaleColorPalette)) {
                goService.applyScaleColorPaletteColors();
                widgetManager.notifyAllWidgets('globalOptionsChanged');
            }
            if (!_.isEqual(oldGlobalOptions.bodyTextFormats, $ctrl.globalOptions.bodyTextFormats)) {
                goService.applyBodyTextStyles();
                if (oldGlobalOptions.bodyTextFormats.SIZE !== $ctrl.globalOptions.bodyTextFormats.SIZE) {
                    headerFooterService.updateHeaderAndFooter();
                }
                // trigger rechunk of text widgets, as styles have changed
                eventService.trigger('global-text-style-change');
            }
            if (!_.isEqual(oldGlobalOptions.tableHeaderTextFormats, $ctrl.globalOptions.tableHeaderTextFormats)) {
                goService.applyTableHeaderTextStyles();
                // trigger rechunk of table widgets, as styles have changed
                eventService.trigger('global-text-style-change');
            }
            if (!_.isEqual(oldGlobalOptions.tableBodyTextFormats, $ctrl.globalOptions.tableBodyTextFormats)) {
                goService.applyTableBodyTextStyles();
                // trigger rechunk of table widgets, as styles have changed
                eventService.trigger('global-text-style-change');
            }
        }
        function addDefaultFormats(formatOptions, defaultTextFormats) {
            formatOptions.FONT.selectedFormat = defaultTextFormats.FONT;
            formatOptions.SIZE.selectedFormat = defaultTextFormats.SIZE;
            formatOptions.COLOR.selectedColor = defaultTextFormats.COLOR;
            formatOptions.BOLD.selectedFormat = defaultTextFormats.BOLD;
        }
        // Notify each page that the margins changed
        function updateMargins() {
            eventService.trigger('updatePageMargins');
        }
    }
    module.controller('prGlobalOptionsController', GlobalOptionsController);
})(angular.module('qualtrics.pr'));
