"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
(function (module) {
    'use strict';
    module.factory('prWidgetInit', [
        'prFields',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        function (fieldService, triggerOverrides, OVERRIDE_EVENTS) {
            var widgetInitTitles = {}; // map of widgetIds to widgetTitles
            var newWidgetSet = {}; // set of ids of new widgets (i.e. widgets being intialized for first time)
            /**
             * Stores the title for the corresponding widgetId
             * @param id {String} id for the widget
             * @param title {String} title for the widget
             */
            function setWidgetInitTitle(id, title) {
                widgetInitTitles[id] = title;
            }
            /**
             *  Returns the title for the corresponding widgetId
             */
            function getWidgetInitTitle(id) {
                return widgetInitTitles[id];
            }
            function addNewWidget(id) {
                newWidgetSet[id] = true;
            }
            function isNewWidget(id) {
                return !!newWidgetSet[id];
            }
            // widgetInitConfig is used for initialization of data-component
            var widgetInitConfigs = [];
            function setWidgetInitConfig(config) {
                widgetInitConfigs.push(config);
            }
            function getWidgetInitConfig() {
                return widgetInitConfigs.shift();
            }
            // utility function for mapping a field to an initialization config
            function getWidgetConfigFromField(field) {
                var config = {};
                config.sourceId = field.sourceId || field.surveyId;
                config.surveyId = config.sourceId;
                config.fieldId = field.fieldId;
                var shouldUseStates = function (leafFields) {
                    return !_.some(leafFields, function (field) {
                        return _.includes(['EnumerableValue', 'EnumerableValueSet'], field.type);
                    });
                };
                var leafFields;
                switch (field.type) {
                    case 'FieldGroup':
                        leafFields = fieldService.getAllLeafFields(field);
                        if (fieldService.isGroupMetric(field.fieldId)) {
                            // Don't include choice label text entry in "ALL CHOICES"
                            _.remove(leafFields, function (field) {
                                return _.endsWith(field.fieldId, '_TEXT');
                            });
                        }
                        if (_.some(leafFields, { type: 'FreeFormText' })) {
                            config.type = 'eng.grid';
                            config.fields = _.map(leafFields, 'fieldId');
                        }
                        else {
                            // Normally, numeric matrix questions are initialized to stats tables
                            // But non-scalar (i.e. multiple answer) fields cannot use the stats widget
                            config.type = shouldUseStates(leafFields) ? 'eng.stats' : 'eng.bar';
                        }
                        break;
                    case "Category" /* Category */:
                        config.type = 'eng.bar';
                        config.metric = 'min';
                        break;
                    case 'ScalarValue':
                    case 'EnumerableScalarValue':
                        // NPS Value: gauge with NPS
                        if (field.parentFullType === 'MCNPS' && !field.labels) {
                            config.type = 'eng.gauge';
                            config.metric = 'nps';
                        }
                        else if (field.labels) { // labels implies it can be represented catagorically
                            config.type = 'eng.bar';
                            config.metric = 'default';
                            // Everything else: Stats table
                        }
                        else {
                            config.type = 'eng.stats';
                        }
                        break;
                    case 'EnumerableValue':
                    case 'EnumerableValueSet':
                        config.type = 'eng.bar';
                        config.metric = 'default';
                        break;
                    case 'Geometry':
                        config.type = 'eng.heatmap';
                        break;
                    default:
                        config.type = 'eng.grid';
                        break;
                }
                var override = triggerOverrides({
                    event: OVERRIDE_EVENTS.WIDGET_CONFIG_INIT,
                    data: {
                        config: config,
                        field: field
                    },
                    sync: true
                });
                return override.config;
            }
            return {
                addNewWidget: addNewWidget,
                getWidgetConfigFromField: getWidgetConfigFromField,
                getWidgetInitConfig: getWidgetInitConfig,
                getWidgetInitTitle: getWidgetInitTitle,
                isNewWidget: isNewWidget,
                setWidgetInitConfig: setWidgetInitConfig,
                setWidgetInitTitle: setWidgetInitTitle
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
