"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var data_component_1 = require("@shared/constants/data-component");
var widgets_1 = require("@shared/constants/widgets");
var angular_1 = require("angular");
(function (angularModule) {
    angularModule.factory('pr.widgetConstants', [
        'prCustomWidgetService',
        'rsEnvironment',
        // @ts-ignore
        // @ts-ignore
        function (customWidgetService, env) {
            // This is populated on application init after checking feature flippers
            var RSDK_WIDGETS = [];
            function getCompatibleWidgets() {
                return widgets_1.COMPATIBLE_WIDGETS;
            }
            function getRsdkWidgets() {
                return RSDK_WIDGETS;
            }
            function getWidgets() {
                return widgets_1.ENG_WIDGETS;
            }
            function addRsdkWidgets() {
                _addCustomRsdkWidgets();
            }
            /**
             * Currently, the only other custom RSDK widgets are EX widgets, so all RSDK widgets are added under this FF.
             * We need to decouple this further if RSDK adds widgets that should be available in other types of reports.
             */
            function _addCustomRsdkWidgets() {
                RSDK_WIDGETS = RSDK_WIDGETS.concat(customWidgetService.getCustomRsdkWidgets());
            }
            return {
                addRsdkWidgets: addRsdkWidgets,
                getCompatibleWidgets: getCompatibleWidgets,
                getRsdkWidgets: getRsdkWidgets,
                getWidgets: getWidgets
            };
        }
    ]);
    angularModule.constant('pr.PR_WIDGETS', widgets_1.PR_WIDGETS);
    angularModule.constant('pr.DATA_COMPONENT_MATCHER', data_component_1.MATCHER);
})(angular_1.module('qualtrics.pr'));
