"use strict";
/* eslint no-redeclare: "off" */
/*globals MutationObserver:false*/
(function (module) {
    'use strict';
    var PRODUCT_VIEW_CLASS = 'product-view-component';
    var GLOBAL_TEXT_STYLE_CHANGE_EVENT = 'global-text-style-change';
    /*
     * Responsible for determining widget chunks, both the data model, and the view.
     * The data model is partially driven by the view
     */
    module.factory('prWidgetChunker', [
        '$document',
        '$injector',
        '$rootScope',
        'prHiddenRenderer',
        'prPageLayoutService',
        'pr.globalOptionsService',
        'pr.widgetManager',
        'prEventService',
        function ($document, $injector, $rootScope, hiddenRenderer, pageLayout, globalOptionsService, widgetManager, eventService) {
            var document = $document[0], flowingPages; // we are going to use the injector... cause we have a circular dep...
            function Chunker(widget) {
                this.construct(widget);
            }
            Chunker.prototype = {
                chunks: [],
                widget: null,
                // a .pr-hidden-widget div on the hidden-renderer
                $element: null,
                $scope: null,
                domObserver: null,
                layoutObserver: null,
                construct: function (widget) {
                    var self = this;
                    self.widget = widget;
                    self.chunks = [];
                    var info = hiddenRenderer.drawWidget(widget);
                    self.$element = info.$element;
                    self.$scope = info.$scope;
                    var update = _.debounce(_.bind(self._updateAndReflow, self), 100);
                    self.domObserver = new MutationObserver(update);
                    eventService.on(GLOBAL_TEXT_STYLE_CHANGE_EVENT, update);
                    self._stopListening = function () {
                        eventService.off(GLOBAL_TEXT_STYLE_CHANGE_EVENT, update);
                        update.cancel();
                    };
                    self._updateChunks();
                    self._observe();
                },
                destruct: function () {
                    var self = this;
                    self._stopObserving();
                    hiddenRenderer.hideWidget(self.widget);
                    self.$element.remove();
                    self._stopListening();
                    // explicitly destroy everything!!
                    _.forEach(self, function (val, prop) {
                        delete self[prop];
                        self[prop] = null;
                    });
                },
                /***********************
                 * Public API
                 ***********************/
                getChunk: function (chunkIndex) {
                    var self = this;
                    self.updateChunks();
                    return self._getChunk(chunkIndex);
                },
                updateChunks: function () {
                    var self = this, widget = self.widget;
                    // don't update unless we have to
                    if (widget && !self._layoutEquals(self.cachedLayout, widget.layout)) {
                        self._updateChunks();
                    }
                },
                /*
                 * Returns the REAL offset of the top of the chunk
                 */
                getChunkTop: function (chunkIndex) {
                    var top = 0;
                    for (var i = 0; i < chunkIndex; i++) {
                        top += this.chunks[i].clientHeight;
                    }
                    return top;
                },
                /***********************
                 * Core Logic
                 ***********************/
                _updateChunks: function () {
                    var self = this;
                    self._stopObserving();
                    hiddenRenderer.showWidget(self.widget);
                    self.cachedLayout = _.cloneDeep(self.widget.layout);
                    // update the widget height only when widget status is completed or loaded
                    // (for race-condition where the widget renders and triggers the DOM Observer before it set's it's status)
                    var status = widgetManager.getWidgetStatus(self.widget._id);
                    if (self._isDoneRendering(status)) {
                        self._chunkRenderedWidget();
                    }
                    else {
                        self._chunkRenderingWidget();
                    }
                    self._observe();
                },
                /**
                 * Checks if the widget has finished rendering on the hidden-renderer.
                 * overwritten by table-chunker
                 */
                _isDoneRendering: function (status) {
                    return this._hasContent(status);
                },
                _chunkRenderingWidget: function () {
                    this._chunkDefault();
                    widgetManager.onChunkingInProgress(this.widget);
                    /**
                     * rowSpan determines the height of the widget during rendering.
                     * Pick a height that estimates the final height after rendering completes
                     * to minimize unnecessary chunking and widget shuffling.
                     * numRenderedRows will be a very low number so don't
                     * use it unless current rowSpan is even lower.
                     */
                    var numRenderedRows = this._getNumRenderedRows();
                    if ((this.widget.layout.rowSpan || 0) < numRenderedRows) {
                        this.widget.layout.rowSpan = numRenderedRows;
                    }
                },
                _chunkRenderedWidget: function () {
                    this._chunk();
                    var originalRowSpan = this.widget.layout.rowSpan;
                    this.widget.layout.rowSpan = this._getNumRenderedRows();
                    widgetManager.onChunkingFinished(this.widget, {
                        wasRowSpanUpdated: this.widget.layout.rowSpan !== originalRowSpan
                    });
                },
                _chunk: function () {
                    // stub to be overwritten
                },
                _hasContent: function () {
                    // stub to be overwritten
                },
                _chunkDefault: function (body) {
                    // uses the default chunks and clones in the dom
                    // overwritten by text-chunker
                    var self = this, widgetBody = body || self._getWidgetBody();
                    self._appendDefaultChunks(widgetBody);
                },
                _appendDefaultChunks: function (widgetBody) {
                    var self = this, chunks = self.widget.chunks;
                    _.forEach(chunks, function (c, idx) {
                        var chunk = self._initChunk(idx);
                        chunk.appendChild(widgetBody.cloneNode(true));
                    });
                },
                _cleanExtraChunks: function (fromIndex) {
                    var self = this;
                    // remove and destroy!!!!
                    _.forEach(this.chunks.splice(fromIndex), function (chunk) {
                        // only remove the dom if it is connected to us, if it is somewhere else, we will wait for that to be destroyed
                        if (chunk.parentNode === self.$element[0]) {
                            self._remove(chunk);
                        }
                    });
                },
                _observe: function () {
                    var widgetBody = this.$element.find('pr-widget-body')[0];
                    this.domObserver.observe(widgetBody, {
                        attributes: true,
                        childList: true,
                        subtree: true,
                        characterData: true
                    });
                },
                _stopObserving: function () {
                    var _a;
                    var self = this;
                    (_a = self.domObserver) === null || _a === void 0 ? void 0 : _a.disconnect();
                },
                _updateAndReflow: function () {
                    this._updateChunks();
                    if (!flowingPages) {
                        flowingPages = $injector.get('prFlowingPages');
                    }
                    /**
                     * The widget's layout may have changed during chunking.
                     * So reflow all of the widgets so that they can update
                     * based on possible new height of the widget.
                     */
                    flowingPages.reflow();
                    // painful...
                    if (!$rootScope.$$phase) {
                        /**
                         * Because widget layouts may have been updated
                         * outside of an angular context,
                         * we must trigger a digest so that angular
                         * updates the view and triggers any listeners.
                         */
                        $rootScope.$digest();
                    }
                },
                /***********************
                 * Helper methods
                 ***********************/
                /**
                * @todo - This function does a.style === b.style,
                * but this is incorrect because style can be an object.
                * Fix this by doing a deep compare (if it doesn't break anything).
                */
                _layoutEquals: function (a, b) {
                    return _.every(['row', 'col', 'rowSpan', 'colSpan', 'padding', 'style'], function (prop) {
                        return a[prop] === b[prop];
                    });
                },
                /*
                 * returns and clears the chunk
                 */
                _initChunk: function (chunkIndex) {
                    var chunk = this._empty(this._getChunk(chunkIndex));
                    return chunk;
                },
                /*
                 * Returns the chunk at the specified dom index, and creates it if it doesn't exist
                 */
                _getChunk: function (index) {
                    var self = this;
                    while (index >= self.chunks.length) {
                        self.chunks.push(self._buildChunk());
                    }
                    return self.chunks[index];
                },
                _buildChunk: function () {
                    var self = this, chunk = self._create('div');
                    chunk.className = 'pr-widget-chunk';
                    self.$element[0].appendChild(chunk);
                    return chunk;
                },
                _getChunkRow: function (chunkIndex) {
                    var self = this, row = self.widget.layout.row;
                    // add the first height
                    if (chunkIndex) {
                        row += self._getChunkMaxRows(0);
                    }
                    // add any more max height
                    if (chunkIndex > 1) {
                        row += (chunkIndex - 1) * self._getChunkMaxRows(1);
                    }
                    return row;
                },
                /*
                 * Returns the height of the last chunk
                 */
                _getLastChunkHeight: function () {
                    var chunk = this.chunks[this.chunks.length - 1], padding = this._getWidgetPadding(), additionalHeight = padding.top + padding.bottom, chunkHeight = chunk.clientHeight;
                    // Chunk has 0 height, meight be because it is not attached to DOM
                    if (chunkHeight === 0 && !document.body.contains(chunk)) {
                        // Quickly attach it to hidden element to get valid height
                        this.$element.append(chunk);
                        chunkHeight = chunk.clientHeight;
                        this.$element.find(chunk).remove();
                    }
                    return chunkHeight + additionalHeight;
                },
                /*
                 * Returns the total number of rows consumed by the chunks
                 */
                _getNumRenderedRows: function () {
                    var pageRows = pageLayout.getRows(), cellSize = pageLayout.getCellSize(), chunks = this.chunks, len = chunks.length, lastHeight = this._roundRows(Math.max(len ? this._getLastChunkHeight() / cellSize : 1, 1));
                    // only return how tall the first chunk is
                    if (len === 1) {
                        return lastHeight;
                    }
                    var firstHeight = pageRows - (this.widget.layout.row % pageRows), // widget row to the end of the page
                    midHeight = Math.max(len - 2, 0) * pageRows; // Middle chunks should take up the whole page
                    return firstHeight + midHeight + lastHeight;
                },
                _roundRows: function (rows) {
                    return Math.ceil(rows);
                },
                _getWidgetPadding: function () {
                    return globalOptionsService.getWidgetPaddingObj(this.widget.layout.padding);
                },
                _getChunkMaxHeight: function (index) {
                    return this._getChunkMaxRows(index) * pageLayout.getCellSize();
                },
                _getChunkMaxRows: function (index) {
                    var rows = pageLayout.getRows();
                    var cellSize = pageLayout.getCellSize();
                    var padding = this._getWidgetPadding();
                    if (!index) {
                        rows -= (this.widget.layout.row % rows);
                    }
                    rows -= padding.top / cellSize + padding.bottom / cellSize;
                    return rows;
                },
                _cloneWidget: function () {
                    return this._getWidgetBody().cloneNode(true);
                },
                _getWidgetBody: function () {
                    return this.widgetBody || (this.widgetBody = this.$element[0].querySelector('pr-widget-body'));
                },
                _isChunkHidden: function (chunkNode) {
                    return this.$element[0].contains(chunkNode);
                },
                /***********************
                 * Helpers for handling product view
                 ***********************/
                /**
                 * Every chunk will initially have a product view div.
                 * The product view should be removed for all chunks except for one.
                 * @return {number} the chunk height remaining after keeping/removing the product view
                 */
                _keepOrRemoveProductViewOnChunk: function (chunkIndex, chunkNode, chunkHeightRemaining, productViewHeight, chunkToKeepProductView) {
                    if (chunkIndex === chunkToKeepProductView) {
                        return chunkHeightRemaining - productViewHeight;
                    }
                    this._removeProductViewFromChunk(chunkNode);
                    /**
                     * If the product view cannot fit on the first chunk,
                     * then make sure there is no content placed on the first chunk.
                     * So return 0 to indicate that no space is remaining for the chunk.
                     */
                    return chunkIndex === 0 ? 0 : chunkHeightRemaining;
                },
                _removeProductViewFromChunk: function (chunkNode) {
                    var productView = this._getProductViewOnChunk(chunkNode);
                    if (productView) {
                        this._remove(productView);
                    }
                },
                _getProductViewOnChunk: function (chunkNode) {
                    return chunkNode && chunkNode.getElementsByClassName(PRODUCT_VIEW_CLASS)[0];
                },
                _getHeight: function (node) {
                    return _.get(node, 'clientHeight', 0);
                },
                _getProductViewHeightOnChunk: function (chunkNode) {
                    var productView = this._getProductViewOnChunk(chunkNode);
                    return this._getHeight(productView);
                },
                /***********************
                 * DOM Helpers cause they are faster than jQuery
                 ***********************/
                _empty: function (node) {
                    while (node.firstChild) {
                        node.removeChild(node.firstChild);
                    }
                    return node;
                },
                _remove: function (node) {
                    if (node.parentNode) {
                        node.parentNode.removeChild(node);
                    }
                    return node;
                },
                _create: function (type) {
                    return document.createElement(type);
                },
                _createTextNode: function () {
                    return document.createTextNode('');
                }
            };
            return Chunker;
        }
    ]);
}(angular.module('qualtrics.pr')));
