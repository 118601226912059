"use strict";
/* eslint no-redeclare: "off" */
/* global jQuery */
(function ($) {
    var SELECT_EVENT = 'select', BODY_SELECTOR = 'body', CURSOR_CLASS = 'cursor-default';
    /*
     * Some convenience methods around an array
     */
    function ItemCollection(containToParent) {
        this.containToParent = !!containToParent;
    }
    ItemCollection.prototype = {
        items: [],
        // removes all of the selected items
        contains: function (item) {
            return this.items.indexOf(item) >= 0;
        },
        add: function (item) {
            if (!this.contains(item) &&
                // if we are containing to the parent, then we shouldn't add the item if the parent node doesn't match
                (!this.containToParent || !this.items.length || this.items[0].parentNode === item.parentNode)) {
                this.items.push(item);
            }
            return item;
        },
        remove: function (item) {
            var idx = this.items.indexOf(item);
            if (idx >= 0) {
                this.items.splice(idx, 1);
            }
            return item;
        },
        removeAll: function () {
            return this.items.splice(0, this.items.length);
        },
        toggle: function (item) {
            if (this.contains(item)) {
                this.remove(item);
            }
            else {
                this.add(item);
            }
        }
    };
    $.widget('layout.itemSelector', $.ui.mouse, {
        options: {
            disabled: false,
            selectables: '.item',
            // if you can only select items within the same parent
            containToParent: true,
            select: $.noop,
            zoomParent: null
        },
        _create: function () {
            if (this.options.disabled) {
                return;
            }
            this._mouseInit();
            this._eventInit();
            this._items = new ItemCollection(this.options.containToParent);
        },
        _destroy: function () {
            this._mouseDestroy();
            this._eventDestroy();
            delete this._items;
            delete this._selectorInfo;
        },
        _eventInit: function () {
            this.element.on('mousedown', this.options.selectable, (this._boundSelect = $.proxy(this._select, this)));
            // should also be a mousedown so we don't accidentally click after our selection
            // NOTE: we put these AFTER our selectable event listeners rather than using $.ui.mouse events so we can cancel
            this.element.on('mousedown', (this._boundMousedown = $.proxy(this._mousedown, this)));
            this.element.on('mouseup', (this._boundMouseup = $.proxy(this._mouseup, this)));
            // TODO: add some hover class or style when hovering or the drag selector is over the elements
            // this._boundHover = $.proxy(this._hover, this);
            // this.element.on('mouseenter', this.options.selectables, this._boundHover);
        },
        _eventDestroy: function () {
            this.element.off('mousedown', this.options.selectable, this._boundSelect);
            this.element.off('mousedown', this._boundMousedown);
            this.element.off('mouseup', this._boundMouseup);
        },
        _mouseCapture: function (event) {
            var target = $(event.target);
            // don't capture for the drag select if we are starting on an element, or it is a text area, or if it is disabled
            return !this.options.disabled && !target.isTextArea()
                && !target.closest(this.options.selectables).length && !target.closest('[no-select-capture]').length;
        },
        _mouseStart: function (event) {
            $(BODY_SELECTOR).addClass(CURSOR_CLASS);
            this._selectorInfo = {
                pageX: event.pageX,
                pageY: event.pageY,
                zoom: $(this.options.zoomParent).getZoom(),
                event: event
            };
            this._showSelector();
            this._updateSelector(event);
        },
        _mouseDrag: function (event) {
            this._updateSelector(event);
        },
        _mouseStop: function (event) {
            $(BODY_SELECTOR).removeClass(CURSOR_CLASS);
            // select the freeform Items
            this._selectMultiple(event);
            this._hideSelector();
            delete this._selectorInfo;
        },
        _mousedown: function (event) {
            this._pagexy = [event.pageX, event.pageY];
        },
        _mouseup: function (event) {
            this._deselect(event);
        },
        _showSelector: function () {
            if (!this._selector) {
                this._selector = $('<div class="item-selector">');
            }
            $(this.element).append(this._selector);
        },
        _hideSelector: function () {
            if (this._selector) {
                this._selector.remove();
            }
        },
        _updateSelector: function (event) {
            var pos = this._getSelectorPosition(event);
            this._selector.css({
                top: pos.top,
                left: pos.left,
                height: pos.height,
                width: pos.width
            });
        },
        _getSelectorPosition: function (event) {
            var info = this._selectorInfo, top = Math.min(info.pageY, event.pageY), right = Math.max(info.pageX, event.pageX), bottom = Math.max(info.pageY, event.pageY), left = Math.min(info.pageX, event.pageX), height = bottom - top, width = right - left, scrollParent = this._getScrollParent();
            return {
                top: top - scrollParent.scrollTop(),
                left: left - scrollParent.scrollLeft(),
                bottom: bottom,
                right: right,
                height: height,
                width: width
            };
        },
        _getScrollParent: function () {
            if (!this._scrollParent) {
                this._scrollParent = this.element.scrollParent();
            }
            return this._scrollParent;
        },
        // called when a single item is selected
        _select: function (event) {
            var items = $(event.target).closest(this.options.selectables);
            // it is already selected and we aren't trying to remove it, so a re-click does nothing
            if (!event.shiftKey && (!items.length || this._items.contains(items[0]))) {
                return;
            }
            // prevent default from highlighting the page
            event.preventDefault();
            this.select(items, event.shiftKey, event);
        },
        // Called when multiple items are selected
        _selectMultiple: function (event) {
            var items = this._selector.collisions(this.options.selectables);
            this.select(items, event.shiftKey, event);
        },
        /*
         * Adds the selected items to the collection and triggers the selected event
         */
        select: function (items, shiftKey, event) {
            if (!shiftKey) {
                this._items.removeAll();
            }
            var collection = this._items;
            $.each(items, function (idx, item) {
                collection.toggle(item);
            });
            // when selecting an item, we can't let any text input have focus anymore so we can nudge it and such
            if (document.activeElement) {
                document.activeElement.blur();
            }
            this._trigger(SELECT_EVENT, event, $(collection.items));
        },
        _pointInRadius: function (pt0, pt1) {
            var RADIUS = 10;
            return pt0 && pt1 && Math.sqrt(Math.pow(pt1[0] - pt0[0], 2) + Math.pow(pt1[1] - pt0[1], 2)) < RADIUS;
        },
        _deselect: function (event) {
            var target = $(event.target);
            // don't deselect if we are clicking on an element
            // or if we are far away from where we started
            if (target.isTextArea() || target.closest(this.options.selectables).length ||
                !this._pointInRadius(this._pagexy, [event.pageX, event.pageY])) {
                return;
            }
            this._items.removeAll();
            this._trigger(SELECT_EVENT, event, $([]));
        }
    });
})(jQuery);
