"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var feature_flipper_1 = require("@shared/constants/feature-flipper");
var widgets_1 = require("@shared/constants/widgets");
var environment_1 = require("@shared/types/environment");
(function (module) {
    module.factory('prAutoHeightHelper', [
        'prCustomWidgetService',
        'rsEnvironment',
        function (customWidgetService, env) {
            var customTableWidgets = [];
            var customTextWidgets = [];
            try {
                customTableWidgets = customWidgetService.getTableWidgets();
                customTextWidgets = customWidgetService.getTextWidgets();
            }
            catch (error) {
                console.error('Error getting custom widgets', error);
            }
            /*
             * A single place to keep track of which widgets auto expand, and how the do it.
             */
            var TABLE_WIDGETS = [
                widgets_1.ENG_WIDGETS.TABLE,
                widgets_1.ENG_WIDGETS.GRID,
                widgets_1.ENG_WIDGETS.STATS
            ].concat(customTableWidgets);
            var TEXT_WIDGETS = [
                widgets_1.PR_WIDGETS.TEXT,
                widgets_1.PR_WIDGETS.FILTER_SUMMARY
            ].concat(customTextWidgets);
            var AUTO_HEIGHT_WIDGETS = TABLE_WIDGETS.concat(TEXT_WIDGETS);
            var TABLE = 'TABLE';
            var TEXT = 'TEXT';
            function contains(widgets, type) {
                return _.includes(widgets, type);
            }
            function isAutoHeightSupported(type) {
                return contains(AUTO_HEIGHT_WIDGETS, type);
            }
            function isAutoHeightOptional(type) {
                return type === widgets_1.PR_WIDGETS.TEXT &&
                    env.get(environment_1.ENV_VARS.FEATURES, feature_flipper_1.FEATURES.ENABLE_FIXED_HEIGHT_TEXT_WIDGET);
            }
            function isTable(type) {
                return contains(TABLE_WIDGETS, type);
            }
            function isText(type) {
                return contains(TEXT_WIDGETS, type);
            }
            // will be false or truthy with either 'TABLE' or 'TEXT'
            function autoHeightType(type) {
                return isAutoHeightSupported(type) && (isTable(type) ? TABLE : TEXT);
            }
            return {
                autoHeightType: autoHeightType,
                isAutoHeightOptional: isAutoHeightOptional,
                isAutoHeightSupported: isAutoHeightSupported,
                isTable: isTable,
                isText: isText,
                TABLE: TABLE,
                TEXT: TEXT,
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
