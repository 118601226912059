"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("angular");
(function (module) {
    var BANNER_TIMEOUT_MS = 10000;
    module.factory('pr.widgetCountWarningBannerService', [
        '$timeout',
        function (
        // @ts-ignore
        $timeout) {
            var shouldShowBanner = false;
            return {
                show: function () {
                    shouldShowBanner = true;
                    $timeout(function () {
                        shouldShowBanner = false;
                    }, BANNER_TIMEOUT_MS);
                },
                shouldShow: function () {
                    return shouldShowBanner;
                }
            };
        }
    ]);
})(angular_1.module('qualtrics.pr'));
