"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_HEADER_FOOTER = exports.DEFAULT_CONFIG = exports.DEFAULT_QUILL_DELTA = exports.HEADER_FOOTER = void 0;
exports.HEADER_FOOTER = 'headerFooter';
exports.DEFAULT_QUILL_DELTA = {
    ops: [
        {
            insert: '\n'
        }
    ]
};
exports.DEFAULT_CONFIG = {
    calculatedHeight: 96,
    logo: {
        height: 0.5,
        alignment: 'none',
        type: 'BRAND'
    },
    pageNumber: {
        alignment: 'none'
    },
    timezone: {
        alignment: 'none'
    },
    customTitle: {
        alignment: 'left',
        content: '',
        formats: {
            FONT: 'times',
            SIZE: 10,
            COLOR: '#222222'
        }
    },
    quillObject: exports.DEFAULT_QUILL_DELTA,
    html: ''
};
exports.DEFAULT_HEADER_FOOTER = {
    header: __assign({}, exports.DEFAULT_CONFIG),
    footer: __assign({}, exports.DEFAULT_CONFIG)
};
