"use strict";
(function (module) {
    'use strict';
    var TimezoneUtil = function ($filter) {
        this.translate = $filter('translate');
    };
    /**
     * Caches various operations for improved performance
     */
    var cache = {
        nowDate: moment().format('YYYY-MM-DD'),
        timezoneDefs: undefined,
        timezoneOffsets: {},
        timezoneMenu: undefined
    };
    TimezoneUtil.prototype._cache = cache; // Expose for testing;
    TimezoneUtil.$inject = ['$filter'];
    TimezoneUtil.prototype.getTimezoneOffset = function (timezone) {
        if (!cache.timezoneOffsets[timezone]) {
            cache.timezoneOffsets[timezone] = moment.tz(cache.nowDate, timezone).format('Z');
        }
        return cache.timezoneOffsets[timezone];
    };
    TimezoneUtil.prototype.getTimezoneName = function (timezone) {
        return this.translate('TIMEZONE_VALUES' + '.' + timezone.toUpperCase().replace('/', '_'));
    };
    TimezoneUtil.prototype.getGuessTimezone = function () {
        return moment.tz.guess();
    };
    /**
     * Gets a list of ordered timezones provided by the moment() library
     *
     * @returns {array} - timezone definitions in ascending offset order
     */
    TimezoneUtil.prototype.getTimezones = function () {
        if (cache.timezoneDefs) {
            return cache.timezoneDefs;
        }
        var self = this;
        // 1. Get all timezone names and offset
        cache.timezoneDefs = {};
        _.forEach(moment.tz.names(), function (name) {
            // Get the non-DST timezone diff
            var offset = self.getTimezoneOffset(name);
            cache.timezoneDefs[name] = {
                name: self.getTimezoneName(name),
                timezone: name,
                offset: offset
            };
        });
        return cache.timezoneDefs;
    };
    /**
     * Gets a specific timezone definition based on a passed in timezone ID
     *
     * @param {string} timezone - a valid timezone key
     *
     * @returns {object} - the relevant timezone definition or an empty object if the timezone is not found
     */
    TimezoneUtil.prototype.getTimezoneDef = function (timezone) {
        return this.getTimezones()[timezone] || {};
    };
    /**
     *
     */
    TimezoneUtil.prototype.getTimezoneLabel = function (timezone) {
        var name = this.getTimezoneName(timezone);
        var offsetLabel = this.getTimezoneOffsetLabel(timezone);
        return "(" + offsetLabel + ") " + name;
    };
    TimezoneUtil.prototype.getTimezoneOffsetLabel = function (timezone) {
        var name = this.getTimezoneName(timezone);
        var offset = this.getTimezoneOffset(timezone);
        return name + " " + offset;
    };
    /**
     * Gets a list of ordered timezones provided by the moment() library in order to use for a menu
     *
     * @param {selectedTimezone} - the timezone we have already selected
     *
     * @returns {array} - timezone menu items with label and timezone key
     */
    TimezoneUtil.prototype.getTimezoneMenu = function (selectedTimezone) {
        var self = this;
        var timezones;
        if (!cache.timezoneMenu) {
            timezones = _.values(this.getTimezones());
            timezones.sort(function (a, b) {
                return parseInt(a.offset.replace(':', ''), 10) - parseInt(b.offset.replace(':', ''), 10);
            });
            cache.timezoneMenu = timezones;
        }
        timezones = cache.timezoneMenu;
        return _.map(timezones, function (timezoneDef) {
            return {
                value: timezoneDef.timezone,
                selected: timezoneDef.timezone === selectedTimezone,
                label: self.getTimezoneLabel(timezoneDef.timezone)
            };
        });
    };
    /**
     * Some of the timezones returned by moment.tz.names() aren't the standard representation.
     * We need the standard representation when requesting data from Analytics Engine
     *
     * The formats used by AE can be found here:
     * http://joda-time.sourceforge.net/timezones.html
     *
     * @param  {string} timezone as returned from moment.tz.names()
     * @return {string} timezone in standardized form
     */
    TimezoneUtil.prototype.standardizeTimezone = function standardizeTimezone(timezone) {
        switch (timezone) {
            case 'America/Argentina':
                return 'America/Argentina/Catamarca';
            case 'Canada/East':
                return 'Canada/Eastern';
            default:
                return timezone;
        }
    };
    module.service('pr.timezoneUtil', TimezoneUtil);
})(angular.module('qualtrics.pr'));
