"use strict";
(function (module) {
    module.constant('pr.EVENTS', {
        RELOAD_WIDGET_OPTIONS_PANE: 'RELOAD_WIDGET_OPTIONS_PANE',
        RENDER_HIDDEN_CHUNK: 'RENDER_HIDDEN_CHUNK',
        REPORT_LANGUAGE_CHANGED: 'REPORT_LANGUAGE_CHANGED',
        WIDGET_CHUNKED: 'WIDGET_CHUNKED'
    });
    // Uses jquery event API pasing them through single objects
    // gives you top level access to a `global` event stream
    // getEventStream will let you trigger events just to a specific ID
    //
    // Ex:
    // eventService.on('someEvent', console.log('glboal');
    // eventService.stream('myEvents').on('someEvent', console.log('isolated'));
    //
    // eventService.trigger('someEvent'); // global
    // eventService.stream('myEvents').trigger('someEvent'); // isolated
    //
    // see
    // trigger: https://api.jquery.com/trigger/
    // on: https://api.jquery.com/on/
    // tiny pub/sub model: https://gist.github.com/cowboy/661855
    module.factory('prEventService', [
        function () {
            var streams = {};
            var global = createEventStream({});
            function createEventStream(o) {
                o = $(o);
                return {
                    on: function () {
                        o.on.apply(o, arguments);
                        return this;
                    },
                    off: function () {
                        o.off.apply(o, arguments);
                        return this;
                    },
                    one: function () {
                        o.one.apply(o, arguments);
                        return this;
                    },
                    trigger: function () {
                        o.trigger.apply(o, arguments);
                        return this;
                    }
                };
            }
            global.stream = function (id) {
                if (!streams[id]) {
                    streams[id] = createEventStream({});
                }
                return streams[id];
            };
            return global;
        }
    ]);
}(angular.module('qualtrics.pr')));
