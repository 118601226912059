"use strict";
(function (module) {
    'use strict';
    module.factory('prSourceMenuService', [
        '$filter',
        'prFieldMenu',
        'rsEnvironment',
        'pr.sourceManagement',
        'pr.folderService',
        'prSourceDataService',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        'pr.widgetConstants',
        function ($filter, FieldMenu, env, sourceManagement, folderService, sourceData, triggerOverrides, OVERRIDE_EVENTS, widgetConstants) {
            var translate = $filter('translate');
            var sourceIdMap = {}, activeSurvey;
            function init() {
                activeSurvey = env.get('rsActiveSurvey');
                return sourceData.getUserSources().then(function (sources) {
                    sourceIdMap = sources;
                });
            }
            function getSourceDataMenu() {
                var ret = [];
                return init().catch(console.error).then(function () {
                    return getMenuForSource(activeSurvey);
                }).then(function (menu) {
                    ret = ret.concat(menu);
                    ret.push({ divider: true });
                    ret.push({ header: translate('OTHER_PROJECTS') });
                    var recentlyUsedProjectsMenu = getRecentlyUsedProjectsMenu();
                    ret = recentlyUsedProjectsMenu ? ret.concat(recentlyUsedProjectsMenu) : ret;
                    ret = ret.concat(getAllProjectsMenu());
                    return ret;
                }).then(function (menu) {
                    return triggerOverrides({
                        event: OVERRIDE_EVENTS.SOURCE_DATA_MENU,
                        data: menu
                    });
                });
            }
            function getMenuForSource(sourceId) {
                var supportedFieldTypes = _.keys(widgetConstants.getCompatibleWidgets());
                return new FieldMenu(sourceId).get({
                    filter: supportedFieldTypes,
                    questionsAsSubmenu: true,
                    disableDivider: true,
                    fieldAsValue: true,
                    includeIcon: true,
                    includeAllChoices: true,
                    replaceCheck: true
                }).then(function (menu) {
                    // swap order so questions come first & make filterable
                    menu[0].submenu.filterable = true;
                    return menu;
                });
            }
            function getRecentlyUsedProjectsMenu() {
                var recentlyUsedSources = getRecentlyUsedSources();
                if (recentlyUsedSources.length) {
                    return {
                        label: translate('RECENTLY_USED'),
                        submenu: {
                            items: function () {
                                return recentlyUsedSources;
                            }
                        }
                    };
                }
            }
            function getRecentlyUsedSources() {
                var sourcesInReport = sourceManagement.getSources();
                var items = [];
                _.forEach(sourcesInReport, function (sourceId) {
                    if (sourceId !== activeSurvey && sourceIdMap[sourceId]) {
                        items.push({
                            label: sourceIdMap[sourceId].name,
                            submenu: {
                                items: function () {
                                    return getMenuForSource(sourceId);
                                }
                            }
                        });
                    }
                });
                return items;
            }
            function getAllProjectsMenu() {
                return {
                    label: translate('ALL_PROJECTS'),
                    submenu: {
                        items: function () {
                            return getAllSources();
                        }
                    }
                };
            }
            function getAllSources() {
                return folderService.getFolderMenu(activeSurvey, _.keys(sourceIdMap), function (id, item) {
                    return {
                        label: item.name,
                        submenu: {
                            items: function () {
                                return getMenuForSource(id);
                            }
                        }
                    };
                });
            }
            return {
                init: init,
                getSourceDataMenu: getSourceDataMenu,
                getRecentlyUsedProjectsMenu: getRecentlyUsedProjectsMenu,
                getRecentlyUsedSources: getRecentlyUsedSources,
                getAllProjectsMenu: getAllProjectsMenu,
                getAllSources: getAllSources,
                getMenuForSource: getMenuForSource
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
