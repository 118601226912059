"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("angular");
var STATUS_BAR_SELECTOR = '.pr-report-update .text';
// @ts-ignore
var _element = null;
function getElement() {
    // @ts-ignore
    if (!_element) {
        _element = $(STATUS_BAR_SELECTOR);
    }
    // @ts-ignore
    return _element;
}
(function (module) {
    module.factory('pr.statusBarCssManager', function () {
        return {
            highlight: function (transition, background) {
                getElement().css({
                    transition: '',
                    background: background
                });
                setTimeout(function () {
                    getElement().css({
                        transition: transition,
                        background: ''
                    });
                });
            },
            reset: function () {
                getElement().css({
                    transition: '',
                    background: ''
                });
            }
        };
    });
})(angular_1.module('qualtrics.pr'));
