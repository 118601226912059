"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditablePptxsUtils = void 0;
var angular = __importStar(require("angular"));
var PX_TO_PT_MULTIPLIER = .75;
var EditablePptxsUtils = /** @class */ (function () {
    function EditablePptxsUtils() {
        /* ========================================================================== */
        /* Font Size */
        var _this = this;
        this.convertRGBValueToHexColor = function (value) {
            var hex = parseInt(value, 10).toString(16);
            return hex.length === 1 ? "0" + hex : hex;
        };
        this.convertColorProperty = function (computedStyle, defaultColor, property) {
            var rgbColor = computedStyle.getPropertyValue(property);
            var rgbRegex = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
            var rgbColorMatch = rgbColor.match(rgbRegex);
            if (!rgbColorMatch || rgbColorMatch.length !== 4) {
                return defaultColor;
            }
            var r = _this.convertRGBValueToHexColor(rgbColorMatch[1]);
            var g = _this.convertRGBValueToHexColor(rgbColorMatch[2]);
            var b = _this.convertRGBValueToHexColor(rgbColorMatch[3]);
            return "" + r + g + b;
        };
    }
    EditablePptxsUtils.prototype.convertFontSize = function (computedStyle) {
        var fontSizePx = computedStyle.getPropertyValue('font-size'); // '21.3333px'
        return this.convertPxStringToPtNumber(fontSizePx);
    };
    /* ========================================================================== */
    /* Font Face */
    EditablePptxsUtils.prototype.convertFontFace = function (computedStyle) {
        var fontFamilyString = computedStyle.getPropertyValue('font-family');
        var fontFamilies = fontFamilyString.split(', ');
        if (fontFamilies.length >= 1) {
            // The first font in font-family has the highest precedence.
            var fontFace = fontFamilies[0];
            // Remove double quoted font strings: '"Times New Roman"'
            fontFace = fontFace.replace(/"/g, '');
            // Powerpoint fonts all have the first letter capitalized. Do the
            // best job to convert to a font face recognized by powerpoint.
            var pptxFontFace = fontFace.charAt(0).toUpperCase() + fontFace.slice(1);
            return pptxFontFace;
        }
    };
    /* ========================================================================== */
    /* Font Color */
    EditablePptxsUtils.prototype.convertFontColor = function (computedStyle, defaultColor) {
        if (defaultColor === void 0) { defaultColor = '000000'; }
        return this.convertColorProperty(computedStyle, defaultColor, 'color');
    };
    /* ========================================================================== */
    /* Background Color */
    EditablePptxsUtils.prototype.convertBackgroundColor = function (computedStyle, defaultColor) {
        if (defaultColor === void 0) { defaultColor = 'ffffff'; }
        return this.convertColorProperty(computedStyle, defaultColor, 'background-color');
    };
    /* ========================================================================== */
    /* Underline */
    EditablePptxsUtils.prototype.isUnderlined = function (element, computedStyle) {
        return (element.tagName === 'U' ||
            computedStyle.getPropertyValue('text-decoration').includes('underline'));
    };
    /* ========================================================================== */
    /* Bold */
    EditablePptxsUtils.prototype.isBold = function (element, computedStyle) {
        return (element.tagName === 'STRONG' ||
            computedStyle.getPropertyValue('font-weight') === '700');
    };
    /* ========================================================================== */
    /* Italics */
    EditablePptxsUtils.prototype.isItalics = function (element, computedStyle) {
        return (element.tagName === 'EM' ||
            computedStyle.getPropertyValue('font-style') === 'italic');
    };
    /* ========================================================================== */
    /* Align */
    EditablePptxsUtils.prototype.getTextAlign = function (computedStyle) {
        var textAlign = computedStyle.getPropertyValue('text-align');
        if (textAlign === 'center') {
            return "center" /* Center */;
        }
        else if (textAlign === 'right') {
            return "right" /* Right */;
        }
        else if (textAlign === 'justify') {
            // Our editor does not support justify but I added this for API completeness
            return "justify" /* Justify */;
        }
        else {
            return "left" /* Left */;
        }
    };
    EditablePptxsUtils.prototype.getTableAlign = function (computedStyle) {
        var textAlign = computedStyle.getPropertyValue('text-align');
        if (textAlign === 'right') {
            return "r" /* Right */;
        }
        else if (textAlign === 'left') {
            return "l" /* Left */;
        }
        // Undefined causes text to be centered.
        // This is the default for pptx
        return undefined;
    };
    /* ========================================================================== */
    /* Width */
    EditablePptxsUtils.prototype.getWidth = function (computedStyle) {
        var width = computedStyle.getPropertyValue('width');
        return this.convertPxStringToPtNumber(width) + "pt";
    };
    /* ========================================================================== */
    /* Height */
    EditablePptxsUtils.prototype.getHeight = function (computedStyle) {
        var height = computedStyle.getPropertyValue('height');
        return this.convertPxStringToPtNumber(height);
    };
    /* ========================================================================== */
    /* Utils */
    EditablePptxsUtils.prototype.convertPxStringToPtNumber = function (pxString, fallbackPxString) {
        var _a;
        if (pxString === void 0) { pxString = ''; }
        if (fallbackPxString === void 0) { fallbackPxString = '12'; }
        var pxNumber = parseFloat(((_a = pxString.match(/[\d\.]+/)) === null || _a === void 0 ? void 0 : _a[0]) || fallbackPxString);
        var ptNumber = Math.round(pxNumber * PX_TO_PT_MULTIPLIER);
        return ptNumber;
    };
    return EditablePptxsUtils;
}());
exports.EditablePptxsUtils = EditablePptxsUtils;
(function (angularModule) {
    angularModule.service('prEditablePptxsUtils', EditablePptxsUtils);
})(angular.module('qualtrics.pr'));
