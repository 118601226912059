"use strict";
(function (module) {
    'use strict';
    module.factory('prClassicURLService', [
        'rsEnvironment',
        function (env) {
            function getClassicURL() {
                var activeSurveyId = env.get('activeSurveyId') || '';
                var url = '/Q/ReportingSection/MyReports/';
                // Error page does not have activeSurveyId
                if (activeSurveyId) {
                    url += '?ContextSurveyID=' + activeSurveyId;
                }
                return url;
            }
            return {
                getClassicURL: getClassicURL
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
