"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var smart_text_1 = require("@shared/types/smart-text");
(function (module) {
    'use strict';
    module.factory('prPipedTextThreeSixtyMenuService', [
        '$filter',
        '$q',
        'prRelationshipMenuService',
        'pr.personService',
        function ($filter, $q, prRelationshipMenuService, personService) {
            function PipedTextThreeSixty() {
                return this;
            }
            PipedTextThreeSixty.translate = $filter('translate');
            var proto = PipedTextThreeSixty.prototype;
            proto.getMenu = function getMenu() {
                var menu = [{
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.SUBJECT_INFO'),
                        submenu: {
                            items: getSubjectInfoMenu()
                        }
                    }, {
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.SUBJECT_SMART_TEXT'),
                        submenu: {
                            items: getSubjectSmartTextMenu()
                        }
                    }];
                var metadataPromise = getSubjectMetadataMenu();
                var evaluatorsPromise = getSubjectsEvaluatorsMenu();
                return $q.all([metadataPromise, evaluatorsPromise]).then(function (result) {
                    var metadataMenu = result[0], evaluatorsMenu = result[1];
                    menu.push({
                        label: PipedTextThreeSixty.translate('PR.SUBJECT_METADATA'),
                        submenu: {
                            items: metadataMenu
                        }
                    });
                    menu.push({
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.SUBJECTS_EVALUATORS'),
                        submenu: {
                            items: evaluatorsMenu
                        }
                    });
                    return _.filter(menu, function (item) {
                        return _.get(item, 'submenu.items.length');
                    });
                });
            };
            function getSubjectInfoMenu() {
                var type = 'subjectInfo';
                return [{
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.SUBJECT_FULL_NAME'),
                        value: {
                            ptAttrs: {
                                attrName: 'fullName',
                                type: type
                            }
                        }
                    }, {
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.SUBJECT_FIRST_NAME'),
                        value: {
                            ptAttrs: {
                                attrName: 'firstName',
                                type: type
                            }
                        }
                    }, {
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.SUBJECT_LAST_NAME'),
                        value: {
                            ptAttrs: {
                                attrName: 'lastName',
                                type: type
                            }
                        }
                    }, {
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.SUBJECT_EMAIL'),
                        value: {
                            ptAttrs: {
                                attrName: 'email',
                                type: type
                            }
                        }
                    }, {
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.SUBJECT_UNIQUE_IDENTIFIER'),
                        value: {
                            ptAttrs: {
                                attrName: 'uniqueID',
                                type: type
                            }
                        }
                    }];
            }
            function getSubjectMetadataMenu() {
                var type = 'subjectMetadata';
                return personService.getMetadata().then(function (metadata) {
                    return metadata.map(function (item) {
                        return {
                            label: item,
                            value: {
                                ptAttrs: {
                                    attrName: item,
                                    type: type
                                }
                            }
                        };
                    });
                }).catch(function (error) {
                    console.error('Failed to get subject metadata.', error);
                    return [];
                });
            }
            function getSubjectSmartTextMenu() {
                var type = 'subjectSmartText';
                return [{
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.FIRST_PERSON_POSSESSIVE'),
                        value: {
                            ptAttrs: {
                                attrName: smart_text_1.SMART_TEXT.FIRST_PERSON_POSSESSIVE,
                                type: type
                            }
                        }
                    }, {
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.FIRST_PERSON_REFLEXIVE'),
                        value: {
                            ptAttrs: {
                                attrName: smart_text_1.SMART_TEXT.FIRST_PERSON_REFLEXIVE,
                                type: type
                            }
                        }
                    }, {
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.SECOND_PERSON_POSSESSIVE'),
                        value: {
                            ptAttrs: {
                                attrName: smart_text_1.SMART_TEXT.SECOND_PERSON_POSSESSIVE,
                                type: type
                            }
                        }
                    }, {
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.SECOND_PERSON_REFLEXIVE'),
                        value: {
                            ptAttrs: {
                                attrName: smart_text_1.SMART_TEXT.SECOND_PERSON_REFLEXIVE,
                                type: type
                            }
                        }
                    }];
            }
            function getSubjectsEvaluatorsMenu() {
                return prRelationshipMenuService.getFormattedRelationships().then(function (menu) {
                    return menu
                        .filter(function (item) { return item.value !== 'Self'; })
                        .map(function (item) {
                        return {
                            label: item.label,
                            submenu: {
                                items: getSubjectsEvaluatorsSubmenu(item.value)
                            }
                        };
                    });
                }).catch(function (error) {
                    console.error('Failed to get formatted 360 relationships.', error);
                    return [];
                });
            }
            function getSubjectsEvaluatorsSubmenu(relationshipId) {
                var type = 'subjectsEvaluators';
                return [
                    {
                        label: PipedTextThreeSixty.translate('PIPED_TEXT.INVITED_EVALUATOR_NAMES'),
                        value: {
                            ptAttrs: {
                                type: type,
                                attrName: relationshipId
                            }
                        }
                    }
                ];
            }
            return PipedTextThreeSixty;
        }
    ]);
}(angular.module('qualtrics.pr')));
