"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SMART_TEXT = void 0;
var SMART_TEXT;
(function (SMART_TEXT) {
    SMART_TEXT["FIRST_PERSON_POSSESSIVE"] = "firstPersonPossessive";
    SMART_TEXT["FIRST_PERSON_REFLEXIVE"] = "firstPersonReflexive";
    SMART_TEXT["SECOND_PERSON_POSSESSIVE"] = "secondPersonPossessive";
    SMART_TEXT["SECOND_PERSON_REFLEXIVE"] = "secondPersonReflexive";
})(SMART_TEXT = exports.SMART_TEXT || (exports.SMART_TEXT = {}));
