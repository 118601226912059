"use strict";
(function (module) {
    'use strict';
    module.factory('prPipedTextEeMenuService', [
        '$filter',
        function ($filter) {
            function PipedTextEE() {
                return this;
            }
            var translate = $filter('translate');
            var proto = PipedTextEE.prototype;
            var TYPE = 'orgHierarchy';
            var ATTRIBUTES = {
                orgHierarchyName: {
                    value: 'orgHierarchyName',
                    label: 'ORG_HIERARCHY_NAME'
                },
                orgHierarchyUnit: {
                    value: 'orgHierarchyUnit',
                    label: 'ORG_HIERARCHY_UNIT'
                },
                orgHierarchyNameUnit: {
                    value: 'orgHierarchyNameUnit',
                    label: 'ORG_HIERARCHY_NAME_UNIT'
                }
            };
            proto.getMenu = function getMenu(action) {
                return [{
                        label: translate('PIPED_TEXT.SELECT_FORMAT'),
                        header: true
                    }].concat(_.map(ATTRIBUTES, function (attribute) {
                    return getMenuItem(attribute);
                }));
                function getMenuItem(attribute) {
                    var label = translate(attribute.label);
                    var subLabel;
                    var sampleOrgHierarchy = translate('TREAD');
                    var sampleOrgUnit = translate('JOHN_DOE');
                    switch (attribute.value) {
                        case 'orgHierarchyUnit':
                            subLabel = sampleOrgUnit;
                            break;
                        case 'orgHierarchyName':
                            subLabel = sampleOrgHierarchy;
                            break;
                        default:
                            subLabel = sampleOrgHierarchy + ': ' + sampleOrgUnit;
                    }
                    var html = '<style type="text/css">\
							.orgh-piped-text {\
								padding-left:16px;\
							}\
							.orgh-piped-text .label {\
								padding-bottom:0;\
							}\
							.orgh-piped-text .sublabel {\
								padding-top:0;\
								color: #737373;\
								font-size: 11px;\
							}\
							.orgh-piped-text:hover .sublabel {\
								color: #FFFFFF;\
							}\
						</style>\
						<a class="hoverable orgh-piped-text">\
							<div class="label">' + label + '</div> \
							<div class="sublabel">' + subLabel + '</div>\
						</a>';
                    return {
                        domNode: $(html).click(function () {
                            action({
                                ptAttrs: {
                                    attrName: attribute.value,
                                    type: TYPE
                                }
                            });
                        }),
                        replaceCheck: true
                    };
                }
            };
            return PipedTextEE;
        }
    ]);
}(angular.module('qualtrics.pr')));
