"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var angular = __importStar(require("angular"));
var PX_PER_INCH = 96;
var PT_PER_INCH = 72;
var PageRelativeCoordinates = /** @class */ (function () {
    function PageRelativeCoordinates() {
    }
    /**
     * Returns the x, y, cx, & cy positions in "pt" units.
     * "pt" is the only unit shared among all Officegen types.
     */
    PageRelativeCoordinates.prototype.getCoordinates = function (page, widget) {
        var pageRect = page.getBoundingClientRect();
        var widgetRect = widget.getBoundingClientRect();
        var widgetHeightPixels = Math.round(widgetRect.height);
        var widgetWidth = Math.round(widgetRect.width);
        // This should not be possible but this code is mimicing ppt-export-region-service.
        var areWidgetDimensionsValid = widgetWidth > 0 && widgetHeightPixels > 0;
        if (areWidgetDimensionsValid) {
            var pageXPixels = Math.round(widgetRect.left - pageRect.left);
            var pageYPixels = Math.round(widgetRect.top - pageRect.top);
            return {
                x: "" + this.convertPixelToPt(pageXPixels),
                y: "" + this.convertPixelToPt(pageYPixels),
                cx: "" + this.convertPixelToPt(widgetWidth),
                cy: "" + this.convertPixelToPt(widgetHeightPixels)
            };
        }
        throw new Error('Invalid widget width or height');
    };
    PageRelativeCoordinates.prototype.convertPixelToPt = function (pixelValue) {
        var inches = pixelValue / PX_PER_INCH;
        var ptValue = inches * PT_PER_INCH;
        return Math.round(ptValue) + "pt";
    };
    return PageRelativeCoordinates;
}());
(function (angularModule) {
    angularModule.service('prPageRelativeCoordinates', PageRelativeCoordinates);
})(angular.module('qualtrics.pr'));
