"use strict";
(function (module) {
    module.factory('pr.userGlobalOptionsService', [
        'pr.API_ROUTES',
        '$http',
        '$q',
        function (API_ROUTES, $http, $q) {
            // save user global report options cache
            var cache;
            function getUserDefaultOptions() {
                if (cache) {
                    return $q.when(processGlobalReportOptions(cache));
                }
                return $http.get(API_ROUTES.GLOBAL_REPORT_OPTIONS)
                    .then(function (result) {
                    cache = result.data;
                    return processGlobalReportOptions(cache);
                })
                    .catch(function () {
                    cache = {};
                    return cache;
                });
            }
            function setUserDefaultOptions(globalReportOptions) {
                if (cache) {
                    // update cache when saving new global options
                    _.merge(cache, globalReportOptions);
                }
                return $http.post(API_ROUTES.GLOBAL_REPORT_OPTIONS, { globalReportOptions: globalReportOptions });
            }
            function processGlobalReportOptions(options) {
                return _.merge({}, {
                    globalOptions: _.omit(options, ['pageLayout', 'globalCustomPalettes']) || {},
                    pageLayout: options.pageLayout || {},
                    globalCustomPalettes: options.globalCustomPalettes || {}
                });
            }
            return {
                getUserDefaultOptions: getUserDefaultOptions,
                setUserDefaultOptions: setUserDefaultOptions
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
