"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_CELL_SIZE = exports.DEFAULT_DPI = exports.DEFAULT_LAYOUT = exports.SIZES = void 0;
exports.SIZES = (_a = {},
    _a["letter" /* Letter */] = {
        width: 8.5,
        height: 11
    },
    _a["legal" /* Legal */] = {
        width: 8.5,
        height: 14
    },
    _a["a3" /* A3 */] = {
        width: 11.69,
        height: 16.53
    },
    _a["a4" /* A4 */] = {
        width: 8.27,
        height: 11.69
    },
    _a["a5" /* A5 */] = {
        width: 5.83,
        height: 8.27
    },
    _a["b4" /* B4 */] = {
        value: 'b4',
        width: 9.84,
        height: 13.90
    },
    _a["widescreen1" /* Widescreen1 */] = {
        label: 'WIDESCREEN 16:9',
        ratio: {
            x: 16,
            y: 9
        },
        width: 13.33,
        height: 7.5
    },
    _a["widescreen2" /* Widescreen2 */] = {
        label: 'WIDESCREEN 16:10',
        ratio: {
            x: 16,
            y: 10
        },
        width: 10,
        height: 6.25
    },
    _a["fullscreen" /* Fullscreen */] = {
        label: 'FULLSCREEN 4:3',
        ratio: {
            x: 4,
            y: 3
        },
        width: 10,
        height: 7.5
    },
    _a);
exports.DEFAULT_LAYOUT = {
    size: 'letter',
    orientation: "vertical" /* Vertical */,
    margins: {
        // these units are in inches
        top: 0.5,
        bottom: 0.5,
        left: 0.5,
        right: 0.5
    }
};
exports.DEFAULT_DPI = 96;
exports.DEFAULT_CELL_SIZE = 8;
