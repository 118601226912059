"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@services/category-utils");
var fieldset_utils_1 = require("@shared/utils/fieldset-utils");
(function (module) {
    'use strict';
    var metaKeys = ['startDate', 'endDate', 'status', 'ipAddress', 'progress',
        'duration', 'finished', 'recordedDate', 'responseSetName', '_cachedDate'];
    var blacklistedKeys = ['_recordId', 'distributionChannel', 'externalDataReference',
        'recipientLastName', 'recipientFirstName', 'recipientEmail', 'locationLatitude', 'locationLongitude'];
    var GROUP_PREFIXES = ['ALL_GROUPS', 'ALL_CHOICES'];
    module.factory('prFields', [
        'prActiveReport',
        'prFieldsets',
        '$filter',
        '$sanitize',
        'rsEnvironment',
        '$q',
        'pr.categoryUtils',
        function (activeReport, fieldsets, $filter, $sanitize, env, $q, categoryUtils) {
            var translate = $filter('translate');
            var NPS_GROUP_REGEX = /NPS_GROUP/;
            var self = this;
            function hasFalsyArguments(argumentMap, throwError) {
                if (throwError === void 0) { throwError = false; }
                var falsyArguments = false;
                _.forEach(argumentMap, function (value, key) {
                    if (!value) {
                        var message = "prFields: " + key + " must not be falsy";
                        console.error(message);
                        if (throwError) {
                            throw new Error(message);
                        }
                        falsyArguments = true;
                    }
                });
                return falsyArguments;
            }
            this.isEmbeddedDataField = function (field) {
                return fieldset_utils_1.isEmbeddedDataField(field);
            };
            this.isSurveyMetaField = function (field) {
                return field.fieldId && _.contains(metaKeys, field.fieldId);
            };
            this.isBlacklisted = function (field) {
                return field.fieldId && _.contains(blacklistedKeys, field.fieldId);
            };
            this.isSourceQuestionField = function (field) {
                return !this.isBlacklisted(field) &&
                    !this.isEmbeddedDataField(field) &&
                    !this.isSurveyMetaField(field);
            };
            this.getFieldIdForPipedText = function (field) {
                return field.fieldId || _.get(field, ['meta', 'sourceId']);
            };
            this.getFieldLabel = function (field, asHtml) {
                if (field.parentFullType && field.parentFullType === 'MCNPS') {
                    return NPS_GROUP_REGEX.test(field.fieldId)
                        ? translate('FIELDS_MENU_CONFIG.NPS_GROUP')
                        : translate('FIELDS_MENU_CONFIG.NUMERIC_VALUE');
                }
                if (this.isEmbeddedDataField(field) || this.isSurveyMetaField(field)) {
                    return field.description;
                }
                if (field.name && !field.parentFullType) {
                    var descriptionComponent = (field.description !== field.name) ? (field.description || '') : '';
                    if (asHtml) {
                        return descriptionComponent
                            ? $('<span><span class="q-meta">' + $sanitize(field.name) + ' - </span>' + $sanitize(descriptionComponent) + '</span>')
                            : $('<span><span class="q-meta">' + $sanitize(field.name) + '</span></span>');
                    }
                    return descriptionComponent ? field.name + ' - ' + descriptionComponent : field.name;
                }
                return field.description;
            };
            this.getFieldLabelById = function (sourceId, fieldId) {
                if (hasFalsyArguments({ sourceId: sourceId, fieldId: fieldId })) {
                    return;
                }
                var isGroupMetric = this.isGroupMetric(fieldId);
                var isCategoryMetric = this.isCategoryMetric(sourceId, fieldId);
                var field = isGroupMetric ?
                    this.getGroupFieldById(sourceId, fieldId) :
                    isCategoryMetric ?
                        this.getCategoryGroupFieldById(sourceId, fieldId) :
                        this.getFieldById(sourceId, fieldId);
                if (field) {
                    return this.getFieldLabel(field);
                }
                return isGroupMetric ? translate('Group Field') : '';
            };
            var firstLineCache = {};
            this._getFieldset = function (sourceId, options) {
                if (options === void 0) { options = {}; }
                var cacheKey = sourceId + ":" + activeReport.getLanguageCode();
                if (firstLineCache[cacheKey]) {
                    return firstLineCache[cacheKey];
                }
                var cache = env.get('fieldsets') || {};
                firstLineCache = cache; // env.get is slow (because of cloneDeep) so cache the result
                // If no cache hit for the report language, then get the default fieldset
                if (cache[cacheKey] || cache[sourceId]) {
                    // set firstLineCache[cacheKey] so we don't call env.get() next time
                    firstLineCache[cacheKey] = cache[cacheKey] || cache[sourceId];
                    return firstLineCache[cacheKey];
                }
                var fieldsetPromise = fieldsets.getFieldsetBySourceId(sourceId);
                if (options.async) {
                    return fieldsetPromise;
                }
                else {
                    // @todo: This function should really be async 
                    // because it may need to make an async call to get the fieldset. 
                    // For some reason it was initially written synchronously. 
                    // Eventually, we should update this function and all of its callers to be async
                    console.error("prFields._getFieldset() was called synchronously but the fieldset for " + sourceId + " is not cached");
                }
            };
            this.getFieldByIdAsync = function (sourceId, fieldId) {
                hasFalsyArguments({ sourceId: sourceId, fieldId: fieldId }, true);
                return $q.resolve(self._getFieldset(sourceId, {
                    async: true
                })).then(function (fieldset) {
                    return self._getFieldByFieldsetAndFieldId(sourceId, fieldset, fieldId);
                });
            };
            this.getFieldById = function (sourceId, fieldId) {
                if (hasFalsyArguments({ sourceId: sourceId, fieldId: fieldId })) {
                    return;
                }
                var fieldset = this._getFieldset(sourceId);
                return this._getFieldByFieldsetAndFieldId(sourceId, fieldset, fieldId);
            };
            this._getFieldByFieldsetAndFieldId = function (sourceId, fieldset, fieldId) {
                if (fieldset) {
                    if (!this.isCategoryMetric(sourceId, fieldId)) {
                        return fieldset.definition.fields[fieldId];
                    }
                    else {
                        var fieldInfo = categoryUtils.getCategoryFieldInfo(fieldId, fieldset);
                        return _.assign({
                            type: "Category" /* Category */
                        }, fieldInfo);
                    }
                }
                return {};
            };
            /**
             * Constructs the fieldId of the parent of the given Id
             *
             * E.g. if child field ID = QID2_1_3, then parent will be QID2_1
             *
             * @param {string} fieldId - the child field Id
             * @returns {string} - the constructed field Id of the parent
            */
            this.constructParentFieldId = function (fieldId) {
                if (fieldId) {
                    return _.endsWith(fieldId, 'RANK') || _.endsWith(fieldId, 'GROUP') ?
                        fieldId.split('_').slice(0, -2).join('_') :
                        fieldId.split('_').slice(0, -1).join('_');
                }
                return '';
            };
            /**
             * Checks whether `childFieldIds` are all direct children of `parentFieldId`
             *
             * @param {string} parentFieldId - the candidate parent fieldId
             * @param {array} childFieldIds - the candidate child fields
             * @returns {boolean}
            */
            this._areChildFields = function (parentFieldId, childFieldIds) {
                var _this = this;
                if (!_.isArray(childFieldIds) || _.isEmpty(childFieldIds)) {
                    return false;
                }
                return _.every(childFieldIds, function (childFieldId) {
                    return _this.constructParentFieldId(childFieldId) === parentFieldId;
                });
            };
            /**
             * Checks whether each fieldId in `fieldIds` is a direct child of the same group field.
             * If so, returns the group field id. Else, returns undefined.
             */
            this.getParentField = function (sourceId, fieldIds) {
                if (hasFalsyArguments({ sourceId: sourceId, fieldIds: fieldIds }) || !_.isArray(fieldIds)) {
                    return;
                }
                var parentFieldId = this.constructParentFieldId(fieldIds[0]);
                if (parentFieldId && this._areChildFields(parentFieldId, fieldIds)) {
                    var parentField = this.getGroupFieldById(sourceId, parentFieldId);
                    if (!_.isEmpty(parentField)) {
                        return parentField;
                    }
                }
            };
            /**
             * Recursively scan `field` for the groupField identified by `fieldId`
             *
             * @param {object} field - the root field to initiate the search
             * @param {string} fieldId - the Id of the target field to find
             * @returns {object} - the groupField if found, or undefined if not found
            */
            this._scanForGroupField = function (field, fieldId) {
                // Recursive search for matching groupField
                var childFieldIds = _.map(field.fields, 'fieldId');
                if (this._areChildFields(fieldId, childFieldIds)) {
                    return field;
                }
                var groupField;
                _.find(field.fields, function (field) {
                    groupField = self._scanForGroupField(field, fieldId);
                    return groupField;
                });
                return groupField;
            };
            /**
             * Search through all groupFields in the fieldSetView for `sourceId`
             * and return the groupField identified by `fieldId`
             *
             * @param {string} sourceId - the sourceId containing the field
             * @param {string} fieldId - the Id of the target field to find
             * @returns {object} - the groupField if found, or {} if not found
            */
            this.getGroupFieldById = function (sourceId, fieldId) {
                if (hasFalsyArguments({ sourceId: sourceId, fieldId: fieldId })) {
                    return;
                }
                // We only want the substring after the last ':'
                // This is the entire string if there is no ':'
                fieldId = fieldId.split(':').slice(-1)[0];
                var fieldset = this._getFieldset(sourceId);
                if (fieldset) {
                    var groupField_1;
                    _.find(fieldset.definition.fieldSetView, function (field) {
                        groupField_1 = field;
                        var meta = _.get(field, 'meta', {});
                        if (meta.fullType === 'PGR' || meta.sourceId !== fieldId) {
                            // The groupField is not be top-level.
                            // Recursively search until it is found.
                            groupField_1 = self._scanForGroupField(field, fieldId);
                        }
                        return groupField_1;
                    });
                    return groupField_1 || {};
                }
                return {};
            };
            this.getCategoryGroupFieldById = function (sourceId, fieldId) {
                if (hasFalsyArguments({ sourceId: sourceId, fieldId: fieldId })) {
                    return {};
                }
                var fieldset = this._getFieldset(sourceId);
                if (fieldset) {
                    return categoryUtils.getCategoryFieldInfo(fieldId, fieldset);
                }
            };
            this.getCategorySubFields = function (sourceId, fieldId) {
                var category = this.getCategoryGroupFieldById(sourceId, fieldId);
                var self = this;
                return {
                    fields: _.map(category.fieldIds, function (fieldId) {
                        return self.getFieldById(sourceId, fieldId);
                    })
                };
            };
            this.getFieldViewById = function (sourceId, fieldId) {
                if (hasFalsyArguments({ sourceId: sourceId, fieldId: fieldId })) {
                    return;
                }
                var fieldset = this._getFieldset(sourceId);
                if (fieldset) {
                    return _.find(fieldset.definition.fieldSetView, { fieldId: fieldId });
                }
                return {};
            };
            this.getFieldIcon = function (field) {
                return field.meta && field.meta.iconClass && field.meta.iconClass + '-sm' || '';
            };
            this.getFieldIconById = function (sourceId, fieldId) {
                var field = this.getFieldViewById(sourceId, fieldId);
                var fieldIcon;
                if (field) {
                    fieldIcon = this.getFieldIcon(field);
                }
                return fieldIcon || '';
            };
            /**
             * Get the field type of the leaf fields nested within `field`
             *
             * @param {object} field - the root field to initiate the search
             * @returns {string} - the leaf field type, or undefined if type cannot be found
            */
            this.getLeafFieldType = function (field) {
                return _.get(this.getAllLeafFields(field), '0.type');
            };
            /**
             * Get the field type of the leaf fields nested within the field identified by `fieldId`
             *
             * @param {string} sourceId - the source containg the field
             * @param {string} fieldId - the Id of the field to get leaf field type of
             * @returns {string} - the leaf field type, or undefined if type cannot be found
            */
            this.getLeafFieldTypeById = function (sourceId, fieldId) {
                if (hasFalsyArguments({ sourceId: sourceId, fieldId: fieldId })) {
                    return;
                }
                var field;
                if (this.isGroupMetric(fieldId)) {
                    field = this.getGroupFieldById(sourceId, fieldId);
                }
                else if (this.isCategoryMetric(sourceId, fieldId)) {
                    field = this.getCategorySubFields(sourceId, fieldId);
                }
                else {
                    field = this.getFieldById(sourceId, fieldId);
                }
                return field ? this.getLeafFieldType(field) : '';
            };
            /**
             * Get all leaf fields of the given field.
             * A leaf field is a field with no child fields, i.e. no 'fields' property.
             *
             * @param {object} field - the field to get the leaf fields of
             * @returns {array} - all the leaf fields
            */
            this.getAllLeafFields = function (field) {
                if (field.fields) {
                    // Not a leaf field
                    // recursively pull the field of each child field
                    var self_1 = this;
                    return _.reduce(field.fields, function (allFields, innerField) {
                        return _.concat(allFields, self_1.getAllLeafFields(innerField));
                    }, []);
                }
                // This is a leaf field
                return [field];
            };
            /**
             * Checks if the field identified by fieldId is a groupField
             *
             * @param {string} fieldId - the Id of the field to check
             * @returns {bool} - whether the field is a groupField
            */
            this.isGroupMetric = function (fieldId) {
                return _.some(GROUP_PREFIXES, function (prefix) {
                    return _.startsWith(fieldId, prefix);
                });
            };
            this.isCategoryMetric = function (sourceId, fieldId) {
                var fieldset = this._getFieldset(sourceId);
                return categoryUtils.isCategoryField(fieldId, fieldset);
            };
            /**
             * Get all choices nested under `groupField`, as should be included
             * when a user creates a visualizaiton with "ALL Choices" selected.
             * Note that this excludes choice labels
             *
             * @param {object} groupField - parent field to get all choices from
             * @returns {array} - All choices nested under `field`
            */
            this.getAllChoices = function (groupField) {
                if (hasFalsyArguments({ groupField: groupField })) {
                    return;
                }
                return _.filter(this.getAllLeafFields(groupField), function (leafField) {
                    return !_.endsWith(leafField.fieldId, '_TEXT');
                });
            };
            /**
             * Get all choices nested under the group field identified by `fieldId`.
             * Note that this excludes choice labels
             *
             * @param {string} sourceId - the source containg the field
             * @param {string} fieldId - the Id of the field to get all choices from
             * @returns {array} - All choices nested under `field`
            */
            this.getAllChoicesById = function (sourceId, fieldId) {
                if (hasFalsyArguments({ sourceId: sourceId, fieldId: fieldId })) {
                    return;
                }
                var groupField = this.getGroupFieldById(sourceId, fieldId);
                return this.getAllChoices(groupField);
            };
            return this;
        }
    ]);
}(angular.module('qualtrics.pr')));
