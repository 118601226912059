"use strict";
(function (module) {
    var GraphicLibraryController = function ($scope, prGraphicLibraryService, imageUploadModal) {
        var glCtrl = this;
        $scope.glCtrl = glCtrl;
        var prevSelectedLibraryId;
        angular.extend(glCtrl, {
            $onInit: function () {
                glCtrl.librariesListLoading = true;
                prGraphicLibraryService.getLibraries().then(function (libraries) {
                    glCtrl.libraries = libraries;
                    glCtrl.selectedLibrary = libraries[0];
                    glCtrl.selectedLibraryId = libraries[0] && libraries[0].value;
                    prevSelectedLibraryId = glCtrl.selectedLibraryId;
                    glCtrl.librariesListLoading = false;
                    loadLibraryGraphics(glCtrl.selectedLibraryId, '');
                });
            },
            $doCheck: function () {
                if (prevSelectedLibraryId && glCtrl.selectedLibraryId !== prevSelectedLibraryId) {
                    prevSelectedLibraryId = glCtrl.selectedLibraryId;
                    glCtrl.selectedLibrary = _.find(glCtrl.libraries, { 'value': glCtrl.selectedLibraryId });
                    loadLibraryGraphics(glCtrl.selectedLibraryId, glCtrl.searchTerm);
                }
            },
            getSelectedLibraryLabel: function () {
                return glCtrl.selectedLibrary.label;
            },
            hasUploadPermission: function () {
                return glCtrl.selectedLibrary.uploadPermission;
            },
            chooseGraphic: function (graphicId) {
                $scope.setImage(graphicId);
                $scope.close();
            },
            searchLibraies: _.debounce(function () {
                filterItems(glCtrl.selectedLibraryGraphics, glCtrl.searchTerm);
                $scope.$digest();
            }, 100),
            uploadImage: function () {
                var currentLibraryfolders = _.map(glCtrl.selectedLibraryGraphics.folders, function (folder) {
                    return _.pick(folder, ['label', 'value']);
                });
                imageUploadModal.show(glCtrl.selectedLibraryId, currentLibraryfolders, glCtrl.chooseGraphic);
            }
        });
        function loadLibraryGraphics(selectedLibraryId, searchTerm) {
            getLibrary(selectedLibraryId).then(function (data) {
                glCtrl.selectedLibraryGraphics = data[0];
                filterItems(glCtrl.selectedLibraryGraphics, searchTerm);
            });
        }
        function getLibrary(libraryId) {
            return prGraphicLibraryService.getLibrary(libraryId).then(function (data) {
                return data;
            });
        }
        function filterItems(library, searchTerm) {
            if (!searchTerm) {
                glCtrl.currentLibraryGraphics = _.cloneDeep(library);
                return;
            }
            var newFolders = _.map(library.folders, function (folder) {
                var filteredFolder = _.cloneDeep(folder);
                filteredFolder.graphics = _.filter(folder.graphics, function (graphic) {
                    return graphic.label.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
                });
                return filteredFolder;
            });
            glCtrl.currentLibraryGraphics.folders = newFolders;
        }
    };
    module.controller('GraphicLibraryController', [
        '$scope',
        'prGraphicLibraryService',
        'prImageUploadModal',
        GraphicLibraryController
    ]);
})(angular.module('qualtrics.pr'));
