"use strict";
(function (module) {
    'use strict';
    module.factory('pr.threeSixtyService', [
        '$q',
        '$http',
        'pr.API_ROUTES',
        'rsEnvironment',
        'prActiveReport',
        function ($q, $http, API_ROUTES, env, activeReport) {
            var cache = {};
            function getDashboards(sourceId, containerId) {
                if (cache[sourceId]) {
                    return $q.when(containerId ?
                        cache[sourceId][containerId] :
                        cache[sourceId]);
                }
                cache[sourceId] = {};
                return $http.get(API_ROUTES.THREE_SIXTY_SERVICE + 'dashboards/' + sourceId)
                    .then(function (response) {
                    var dashboards = response.data;
                    _.forEach(dashboards, function (dashboard) {
                        cache[sourceId][dashboard.containerId] = dashboard;
                    });
                    return containerId ?
                        cache[sourceId][containerId] :
                        cache[sourceId];
                });
            }
            function getActiveDashboard() {
                return env.get('dashboard') || activeReport.getMetadata('dashboard');
            }
            return {
                getDashboards: getDashboards,
                getActiveDashboard: getActiveDashboard
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
