"use strict";
(function (module) {
    'use strict';
    var UndoRedoController = function (undoRedo) {
        this.undoRedo = undoRedo;
    };
    /**
     * Wrapper to UndoRedoStack.undo
     */
    UndoRedoController.prototype.undo = function () {
        console.log('undoing');
        this.undoRedo.undo();
    };
    /**
     * Wrapper to UndoRedoStack.undo
     */
    UndoRedoController.prototype.redo = function () {
        console.log('redoing');
        this.undoRedo.redo();
    };
    UndoRedoController.$inject = ['undoRedoStack'];
    module.controller('prUndoRedoController', UndoRedoController);
})(angular.module('qualtrics.pr'));
