"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var three_sixty_1 = require("../../../shared/types/three-sixty");
(function (module) {
    'use strict';
    module.factory('prPipedTextMetricsService', [
        '$filter',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        function ($filter, triggerOverrides, OVERRIDE_EVENTS) {
            function PipedTextMetrics() {
                var _a;
                var configFlags = triggerOverrides({
                    event: OVERRIDE_EVENTS.CONFIG_FLAGS,
                    sync: true,
                    data: {}
                });
                if (configFlags.enablePercentileMetric) {
                    _.assign(PipedTextMetrics.SUPPORTED_METRICS, (_a = {},
                        _a[three_sixty_1.THREE_SIXTY_PERCENTILE_METRIC] = PipedTextMetrics.translate('PIPED_TEXT.THREE_SIXTY_PERCENTILE_METRIC'),
                        _a));
                }
                return this;
            }
            PipedTextMetrics.NON_SCALAR_METRIS = ['percent', 'choice_count'];
            PipedTextMetrics.SCALAR_METRIS = ['stddev', 'var'];
            PipedTextMetrics.translate = $filter('translate');
            PipedTextMetrics.SUPPORTED_METRICS = {
                avg: PipedTextMetrics.translate('PIPED_TEXT.MEAN_METRIC'),
                min: PipedTextMetrics.translate('PIPED_TEXT.MIN_METRIC'),
                max: PipedTextMetrics.translate('PIPED_TEXT.MAX_METRIC'),
                nps: PipedTextMetrics.translate('PIPED_TEXT.NPS_METRIC'),
                count: PipedTextMetrics.translate('PIPED_TEXT.RESPONSE_COUNT_METRIC'),
                sum: PipedTextMetrics.translate('PIPED_TEXT.SUM_METRIC'),
                stddev: PipedTextMetrics.translate('PIPED_TEXT.STD_DEV_METRIC'),
                var: PipedTextMetrics.translate('PIPED_TEXT.VAR_METRIC')
            };
            var proto = PipedTextMetrics.prototype;
            proto.getSupportedMetrics = function () {
                return PipedTextMetrics.SUPPORTED_METRICS;
            };
            proto.isScalarValue = function (type) {
                return type === 'ScalarValue';
            };
            proto.isScalarSubset = function (type) {
                return type.indexOf('Enumerable') > -1 && type.indexOf('Scalar') === -1;
            };
            proto.deleteNonScoringCategoryMetrics = function (field, metrics) {
                if (!field.fieldId) {
                    return metrics;
                }
                if (field.fieldId.startsWith('SC_')) {
                    metrics = _.omit(metrics, PipedTextMetrics.SCALAR_METRIS);
                }
                return metrics;
            };
            proto.getSupportedMetricMenu = function (field, sourceId, sourceName, fieldSetId) {
                var metrics = proto.getSupportedMetrics();
                if (this.isScalarSubset(field.type)) {
                    metrics = _.pick(metrics, ['count']);
                }
                else if (this.isScalarValue(field.type)) {
                    metrics = _.omit(metrics, PipedTextMetrics.NON_SCALAR_METRIS);
                }
                else {
                    metrics = _.omit(metrics, PipedTextMetrics.SCALAR_METRIS);
                }
                metrics = this.deleteNonScoringCategoryMetrics(field, metrics);
                return _.map(metrics, function (label, metric) {
                    var ptAttrs = {
                        type: 'sourceAggregateData',
                        attrName: metric,
                        sourceId: sourceId,
                        sourceName: sourceName,
                        fieldSetId: fieldSetId,
                        fieldId: field.fieldId
                    };
                    return {
                        label: label,
                        value: {
                            ptAttrs: ptAttrs
                        }
                    };
                });
            };
            return PipedTextMetrics;
        }
    ]);
}(angular.module('qualtrics.pr')));
