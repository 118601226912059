"use strict";
(function (module) {
    'use strict';
    module.factory('prSectionManagerHelper', [
        '$routeParams',
        function ($routeParams) {
            var PAGE_BREAK_WIDGET_TYPE = 'pr.pagebreak';
            /**
             * Get widgets of the section that a widget belongs to,
             * Note: each section starts with a page break, unless it's the first section
             *
             * @param  {[object]} widget
             * @param  {[array]} allWidgets
             * @return {[array]} widgets in a section
             *
             * Example:
             *
             * widget = { _id: w_4, _type: eng.pie }
             *
             * allWidgets = [
             * 		{ _id: w_1, _type: eng.bar },
             * 		{ _id: w_2, _type: pr.pagebreak },
             * 		{ _id: w_3, _type: eng.line },
             * 		{ _id: w_4, _type: eng.pie },
             * 		{ _id: w_5, _type: pr.pagebreak },
             * 		{ _id: w_6, _type: eng.bar }
             * ]
             *
             * return [
             * 		{ _id: w_2, _type: pr.pagebreak },
             * 		{ _id: w_3, _type: eng.line },
             * 		{ _id: w_4, _type: eng.pie }
             * ]
             */
            function getWidgetsToSave(widget, allWidgets) {
                var widgetIndex = _.findIndex(allWidgets, { _id: widget._id });
                if (widgetIndex === -1) {
                    return [];
                }
                var indexOfPrevPb;
                if (isPageBreak(widget)) {
                    indexOfPrevPb = widgetIndex;
                }
                else {
                    indexOfPrevPb = getIndexOfPrevPb(widgetIndex, allWidgets);
                }
                var indexOfNextPb = getIndexOfNextPb(widgetIndex, allWidgets);
                return allWidgets.slice(indexOfPrevPb, indexOfNextPb);
            }
            /**
             * Get the index of previous page break of current index
             * @param  {int} currIndex
             * @param  {array} allWidgets
             * @return {int}
             */
            function getIndexOfPrevPb(currIndex, allWidgets) {
                for (var i = currIndex - 1; i >= 0; i--) {
                    if (isPageBreak(allWidgets[i])) {
                        return i;
                    }
                }
                return 0;
            }
            /**
             * Get the index of next page break of current index
             * @param  {int} currIndex
             * @param  {array} allWidgets
             * @return {int}
             */
            function getIndexOfNextPb(currIndex, allWidgets) {
                for (var i = currIndex + 1; i < allWidgets.length; i++) {
                    if (isPageBreak(allWidgets[i])) {
                        return i;
                    }
                }
                return allWidgets.length;
            }
            function isPageBreak(widget) {
                return widget && widget._type === PAGE_BREAK_WIDGET_TYPE;
            }
            /**
             * get sections to load in the report
             * @param  {array} allSections
             * @return {array}
             */
            function getSectionsToLoad(allSections) {
                // TODO: get rid of $routeParams.sectionId when GE E2E test is modified
                var startSectionId = $routeParams.startSectionId || $routeParams.sectionId;
                if (startSectionId) {
                    // section export
                    return getSectionsForSectionExport(allSections, startSectionId);
                }
                return allSections;
            }
            /**
             * get sections to load for section export
             * sections returned are decided by two parameters:
             * - startSectionId: the section to get start with
             * - $routeParams.numOfSections: the number of sections to load
             *
             * @param  {array} allSections - all sections in report definition
             * @param  {string} startSectionId - the first section to load
             * @return {array} sections to load
             *
             * E.g.
             * allSections = [
             * 		{ _id: 'sec_a' },
             * 		{ _id: 'sec_b' },
             * 		{ _id: 'sec_c' },
             * 		{ _id: 'sec_d' }
             * ]
             * startSectionId = 'sec_b'
             * $routeParams.numOfSections = 2
             * return: [
             * 		{ _id: 'sec_b' },
             * 		{ _id: 'sec_c' }
             * ]
             */
            function getSectionsForSectionExport(allSections, startSectionId) {
                var numOfSections = parseInt($routeParams.numOfSections || 1);
                var startIndex = _.findIndex(allSections, {
                    _id: startSectionId
                });
                return allSections.slice(startIndex, startIndex + numOfSections);
            }
            return {
                getWidgetsToSave: getWidgetsToSave,
                getIndexOfPrevPb: getIndexOfPrevPb,
                getIndexOfNextPb: getIndexOfNextPb,
                getSectionsToLoad: getSectionsToLoad
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
