"use strict";
(function (module) {
    'use strict';
    module.factory('pr.scheduleEmailDateTimeMenuService', [
        '$filter',
        function ($filter) {
            var translateFilter = $filter('translate');
            var DAY_NUMBERS = {
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10',
                '11': '11',
                '12': '12',
                '13': '13',
                '14': '14',
                '15': '15',
                '16': '16',
                '17': '17',
                '18': '18',
                '19': '19',
                '20': '20',
                '21': '21',
                '22': '22',
                '23': '23',
                '24': '24',
                '25': '25',
                '26': '26',
                '27': '27',
                '28': '28'
            };
            var WEEKLY_SCHEDULE = 'Weekly';
            var MONTHLY_SCHEDULE = 'Monthly';
            function getDaysForMenu() {
                return {
                    '1': translateFilter('EVERY_SUNDAY'),
                    '2': translateFilter('EVERY_MONDAY'),
                    '3': translateFilter('EVERY_TUESDAY'),
                    '4': translateFilter('EVERY_WEDNESDAY'),
                    '5': translateFilter('EVERY_THURSDAY'),
                    '6': translateFilter('EVERY_FRIDAY'),
                    '7': translateFilter('EVERY_SATURDAY')
                };
            }
            function getNonNumericalDates() {
                // The keys for this object is based on the cron expression values.
                // Check http://www.quartz-scheduler.org/documentation/quartz-1.x/tutorials/crontrigger for the format.
                return {
                    '1W': translateFilter('FIRST_WEEKDAY'),
                    'LW': translateFilter('LAST_WEEKDAY'),
                    'L': translateFilter('LAST_DAY')
                };
            }
            function getDaysForLabel() {
                return {
                    '1': translateFilter('EVERY_SUNDAY'),
                    '2': translateFilter('EVERY_MONDAY'),
                    '3': translateFilter('EVERY_TUESDAY'),
                    '4': translateFilter('EVERY_WEDNESDAY'),
                    '5': translateFilter('EVERY_THURSDAY'),
                    '6': translateFilter('EVERY_FRIDAY'),
                    '7': translateFilter('EVERY_SATURDAY')
                };
            }
            // Check http://www.quartz-scheduler.org/documentation/quartz-1.x/tutorials/crontrigger.
            var CRON_EXPRESSION_DATE_INDEX = 3;
            var CRON_EXPRESSION_DAY_INDEX = 5;
            var CRON_EXPRESSION_HOUR_INDEX = 2;
            var CRON_EXPRESSION_MINUTE_INDEX = 1;
            function getDateMenu(type, action) {
                var dateMenu = [];
                _.forOwn(getNonNumericalDates(), function (val, key) {
                    dateMenu.push({
                        label: val,
                        key: key,
                        type: type,
                        action: action
                    });
                });
                // Push a divider to separate numerical and non-numerical dates.
                dateMenu.push({
                    divider: true
                });
                _.forOwn(DAY_NUMBERS, function (val, key) {
                    dateMenu.push({
                        label: val,
                        key: key,
                        type: type,
                        action: action
                    });
                });
                return dateMenu;
            }
            function getDayMenu(type, action) {
                var dayMenu = [];
                _.forOwn(getDaysForMenu(), function (val, key) {
                    dayMenu.push({
                        label: val,
                        key: key,
                        type: type,
                        action: action
                    });
                });
                return dayMenu;
            }
            function getFrequencyMenu(action) {
                var frequencyMenu = [];
                var weeklyMenu = {
                    label: translateFilter('WEEKLY_SCHEDULE'),
                    value: WEEKLY_SCHEDULE,
                    submenu: {
                        items: getDayMenu(WEEKLY_SCHEDULE, action),
                        selectMenu: true,
                        multiSelectMenu: false
                    }
                };
                frequencyMenu.push(weeklyMenu);
                var monthlyMenu = {
                    label: translateFilter('MONTHLY_SCHEDULE'),
                    value: MONTHLY_SCHEDULE,
                    submenu: {
                        items: getDateMenu(MONTHLY_SCHEDULE, action),
                        selectMenu: true,
                        multiSelectMenu: false
                    }
                };
                frequencyMenu.push(monthlyMenu);
                return frequencyMenu;
            }
            function updateFrequencyMenu(menu, cronExpression) {
                var fields = cronExpression.split(' ');
                var setValue, resetValue, keys;
                if (fields[CRON_EXPRESSION_DAY_INDEX] !== '?') {
                    // This is a weekly schedule.
                    setValue = WEEKLY_SCHEDULE;
                    resetValue = MONTHLY_SCHEDULE;
                    keys = fields[CRON_EXPRESSION_DAY_INDEX].split(',');
                }
                else {
                    // This is a monthly schedule.
                    setValue = MONTHLY_SCHEDULE;
                    resetValue = WEEKLY_SCHEDULE;
                    keys = fields[CRON_EXPRESSION_DATE_INDEX].split(',');
                }
                // deselect the other submenu.
                clearFrequencySubMenu(menu, resetValue);
                var setMenu = _.find(menu, function (item) {
                    return item.value === setValue;
                });
                _.each(setMenu.submenu.items, function (item) {
                    item.selected = _.contains(keys, item.key);
                });
            }
            function clearFrequencySubMenu(frequencyMenu, subMenuValue) {
                var subMenu = _.find(frequencyMenu, function (_subMenu) {
                    return _subMenu.value === subMenuValue;
                });
                _.each(subMenu.submenu.items, function (item) {
                    item.selected = false;
                });
            }
            function getFrequencyInWords($item) {
                var label = '';
                if (_.isEmpty($item)) {
                    return label;
                }
                if ($item.type === WEEKLY_SCHEDULE) {
                    var validDays = getDaysForLabel();
                    label = translateFilter('WEEKLY_SCHEDULE') + ', ' + validDays[$item.key];
                }
                else {
                    // Monthly Schedule.
                    var objLabel = void 0;
                    if (isNonNumericalDate($item.key)) {
                        objLabel = getNonNumericalDates()[$item.key];
                    }
                    else {
                        objLabel = $item.key;
                    }
                    label = translateFilter('MONTHLY_SCHEDULE') + ', ' + objLabel;
                }
                return label;
            }
            function getFrequencyInWordsFromCron(cronExpression) {
                var fields = cronExpression.split(' ');
                var label = '';
                var isWeeklySchedule = fields[CRON_EXPRESSION_DAY_INDEX] !== '?';
                var isMonthlySchedule = fields[CRON_EXPRESSION_DATE_INDEX] !== '?';
                if (!isWeeklySchedule && !isMonthlySchedule) {
                    // It is not scheduled so return 
                    return label;
                }
                if (isWeeklySchedule) {
                    var day = fields[CRON_EXPRESSION_DAY_INDEX];
                    label = translateFilter('WEEKLY_SCHEDULE') + ', ' + getDaysForLabel()[day];
                }
                else {
                    label = translateFilter('MONTHLY_SCHEDULE');
                    var objLabel = void 0;
                    var date = fields[CRON_EXPRESSION_DATE_INDEX];
                    if (isNonNumericalDate(date)) {
                        objLabel = getNonNumericalDates()[date];
                    }
                    else {
                        objLabel = date;
                    }
                    label = label + ', ' + objLabel;
                }
                return label;
            }
            function isNonNumericalDate(key) {
                return _.has(getNonNumericalDates(), key);
            }
            function getCronTime(frequency, hour, minute) {
                // Check http://www.quartz-scheduler.org/documentation/quartz-1.x/tutorials/crontrigger for the format.
                // It has 6 mandatory and one optional field. Populate the fields from the selected values
                // We dont support Year field for now.
                // We provide 'schedule every day at a particular hour' as the most frequent option.
                // so secconds and min count to 0.
                // The order of keys in this object matters.
                var cronExpression = {
                    cronSeconds: '0',
                    cronMinutes: minute,
                    cronHours: hour,
                    cronDates: '?',
                    cronMonths: '*',
                    cronDays: '?' // No specific value
                };
                if (_.isEmpty(frequency)) {
                    return _.values(cronExpression).join(' ');
                }
                if (frequency.type === WEEKLY_SCHEDULE) {
                    cronExpression.cronDays = frequency.key;
                }
                else {
                    cronExpression.cronDates = frequency.key;
                }
                return _.values(cronExpression).join(' ');
            }
            function getHourFromCron(cronExpression) {
                var fields = cronExpression.split(' ');
                return fields[CRON_EXPRESSION_HOUR_INDEX];
            }
            function getMinuteFromCron(cronExpression) {
                var fields = cronExpression.split(' ');
                return fields[CRON_EXPRESSION_MINUTE_INDEX];
            }
            function getObjectFromCron(cronExpression) {
                var fields = cronExpression.split(' ');
                var isMissingDayAndDateValues = (fields[CRON_EXPRESSION_DAY_INDEX] === '?' &&
                    fields[CRON_EXPRESSION_DATE_INDEX] === '?');
                if (isMissingDayAndDateValues) {
                    // Nothing has been selected in the menu so return  null
                    return null;
                }
                var key, type;
                var isWeeklySchedule = fields[CRON_EXPRESSION_DAY_INDEX] !== '?';
                if (isWeeklySchedule) {
                    type = WEEKLY_SCHEDULE;
                    key = fields[CRON_EXPRESSION_DAY_INDEX];
                }
                else {
                    type = MONTHLY_SCHEDULE;
                    key = fields[CRON_EXPRESSION_DATE_INDEX];
                }
                return {
                    key: key,
                    type: type
                };
            }
            return {
                WEEKLY_SCHEDULE: WEEKLY_SCHEDULE,
                MONTHLY_SCHEDULE: MONTHLY_SCHEDULE,
                getFrequencyMenu: getFrequencyMenu,
                getFrequencyInWords: getFrequencyInWords,
                getCronTime: getCronTime,
                getHourFromCron: getHourFromCron,
                getMinuteFromCron: getMinuteFromCron,
                getFrequencyInWordsFromCron: getFrequencyInWordsFromCron,
                updateFrequencyMenu: updateFrequencyMenu,
                clearFrequencySubMenu: clearFrequencySubMenu,
                getObjectFromCron: getObjectFromCron
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
