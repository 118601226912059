"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * All query parameters supported by printed reports
 */
exports.default = {
    /**
     * Used for exports, loads all widgets instead of lazy loading
     */
    SHOW_ALL_PAGES: 'showAllPages',
    /**
     * Used for EE and 360 reports
     */
    PROJECT_ID: 'projectId',
    /**
     * For EE reports, indicates the dashboard that the report maps to
     */
    DASHBOARD_ID: 'dashboardId',
    /**
     * If set, will automatically filter all data requests to this value.
     * Currently, only used as personId for 360 reports.
     */
    BULK_FIELD_VALUE: 'bulkFieldValue',
    /**
     * For 360 reports, indicates the perspective that the report should be generated for
     */
    VIEWER_ID: 'viewerId',
    /**
     * Sets the language for exported reports
     */
    LANGUAGE: 'lang',
    /**
     * Display the report as a public report, but require authentication
     */
    PREVIEW: 'preview',
    /**
     * If this is an export, the file type of the requested export, e.g. 'pdf'
     */
    FILE_TYPE: 'fileType',
    /**
     * Indicates whether this an export has editable widgets enabled
     * (currently only for pptx exports)
     */
    EDITABLE_WIDGETS: 'editableWidgets',
    /**
     * Used to validate preview report for 360 participants
     */
    TOKEN: 'token'
};
