"use strict";
(function (module) {
    'use strict';
    var ManagePublicReportController = function (activeReport, $scope, $http, $routeParams, $filter, API_ROUTES, metricsService) {
        var _this = this;
        var $ctrl = this;
        var publicUrl = API_ROUTES.VIEW + $routeParams.surveyId + '/' + $routeParams.containerId + '/public';
        $scope.$ctrl = $ctrl;
        _.merge($ctrl, _.pick(activeReport.getMetadata(), ['isPublic', 'publicHash', 'passcodeProtected']));
        this.$onInit = function () {
            if (!$ctrl.isPublic) {
                // force regeneration of hash when public access is toggled
                delete $ctrl.publicHash;
            }
            $ctrl.hadInitialPasscode = !!$ctrl.passcodeProtected;
            // @TODO - Change this to false if we ever change whether or not you can access a report if you
            // don't have access to all the sources
            this.canPublishPublicLink = true;
            this.noChangeToSave = true;
            $http.get(publicUrl).then(function (response) {
                $ctrl.canPublishPublicLink = response.status === 200;
                if ($ctrl.canPublishPublicLink) {
                    $ctrl.publicUrlPrefix = response.data.publicUrlPrefix;
                }
            });
        };
        this.setUrlBackgroundColor = function () {
            var elem = document.getElementById('public-report-url');
            if (elem) {
                elem.parentElement.style.background = $ctrl.isPublic ? 'inherit' : '#eee';
            }
        };
        $ctrl.updatesPending = 0;
        this.sendUpdates = function () {
            $ctrl.setUrlBackgroundColor();
            if ($ctrl.isPublic && $ctrl.passcodeProtected && !$ctrl.passcode) {
                // Don't update server with empty passcode
                return;
            }
            $ctrl.updatesPending += 1;
            if (!$ctrl.isPublic) {
                $ctrl.passcodeProtected = false;
            }
            if (!$ctrl.passcodeProtected) {
                delete $ctrl.passcode;
            }
            metricsService.managePublicReport($ctrl.isPublic, $ctrl.passcodeProtected, $scope.source);
            $http.post(publicUrl, {
                isPublic: $ctrl.isPublic,
                passcodeProtected: $ctrl.passcodeProtected,
                passcode: $ctrl.passcode
            }).then(function (response) {
                if (response.status === 200) {
                    // clear the public hash from the context meta if public access disabled
                    $ctrl.publicHash = $ctrl.isPublic ? response.data : null;
                    activeReport.updateMetadata({
                        isPublic: $ctrl.isPublic,
                        passcodeProtected: $ctrl.passcodeProtected,
                        publicHash: $ctrl.publicHash
                    });
                }
            }).finally(function () {
                $ctrl.updatesPending -= 1;
            });
        };
        this.togglePublicLink = function () {
            this.noChangeToSave = false;
            if (!this.isPublic) {
                this.passcodeProtected = false;
                delete this.passcode;
                // in case user wants to reset the public link
                delete $ctrl.publicHash;
            }
        };
        this.getPublicLink = function () {
            if ($ctrl.isPublic && !$ctrl.publicHash) {
                return $filter('translate')('PRESS_SAVE_TO_GENERATE_LINK');
            }
            else {
                // The publicUrlPrefix is set on modal init. If the link is
                // enabled when the modal is first shown, then undefined is
                // displayed.
                if ($ctrl.publicUrlPrefix) {
                    return $ctrl.publicUrlPrefix + $ctrl.publicHash;
                }
            }
        };
        this.copyPublicUrl = function () {
            window.getSelection().selectAllChildren(document.getElementById('public-report-url'));
            document.execCommand('copy');
            metricsService.copyPublicLink($ctrl.passcodeProtected, $scope.source);
        };
        var passcodeElement;
        this.toggleShowPasscode = function () {
            if (!passcodeElement) {
                passcodeElement = document.getElementById('passcode-input');
            }
            passcodeElement.type = (passcodeElement.type === 'password') ? 'text' : 'password';
        };
        this.togglePasscodeProtection = function () {
            this.noChangeToSave = false;
            if (!this.passcodeProtected) {
                delete this.passcode;
            }
        };
        this.onPasscodeChange = function () {
            _this.noChangeToSave = false;
        };
        this.save = function () {
            $ctrl.sendUpdates();
        };
        this.disableSaveButton = function () {
            return (_this.isPublic
                && _this.passcodeProtected
                && (!_this.passcode || _this.passcode.length === 0))
                || _this.noChangeToSave;
        };
    };
    ManagePublicReportController.$inject = [
        'prActiveReport',
        '$scope',
        '$http',
        '$routeParams',
        '$filter',
        'pr.API_ROUTES',
        'prMetricsService'
    ];
    module.controller('prManagePublicReportController', ManagePublicReportController);
}(angular.module('qualtrics.pr')));
