"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
(function (module) {
    'use strict';
    module.factory('pr.predefinedDataService', [
        '$q',
        'rsState',
        'prFieldsets',
        'prActiveReport',
        'pr.CONTEXT_META_KEYS',
        'pr.customFilterService',
        'prSavedFiltersService',
        'pr.customFilterUtils',
        function ($q, rsState, fieldsets, activeReport, CONTEXT_META, customFilterService, SavedFiltersService, customFilterUtils) {
            var STATUS = {
                SUCCEEDED: 'succeeded',
                FAILED: 'failed'
            };
            return {
                /**
                 * The 360 team can request to create containers with predefined filters.
                 * We must initialize and setup the report to respect these filters.
                 *
                 * Background: there are two kinds of filters:
                 * 	Saved Filters:
                 * 		These are standard filters that are stored using /filters-service.
                 * 		They are used for filtering on normal fields of the fieldset.
                 * 		/filters-service will store these filters on the fieldset.
                 * 		Saved Filters are accessible to all products that use /filters-service (e.g. pr, results, the D&A tab)
                 * 	Custom Filters:
                 * 		These are filters for fields that do not technically exist on the fieldset.
                 * 		E.g. the "evaluation scope" filter for 360 is custom because there is no "evaluation scope" field.
                 * 		We manually store these filters on the context meta of the container.
                 * 		Custom Filters are accessible only in printed reports.
                 *
                 * E.g. 360 might request the following:
                 *
                 * 	contextMeta: {
                 * 		predefined: {
                 * 			filters: [{
                 * 				filterId: '1',
                 * 				filter: {...}
                 * 			}, {
                 * 				filterId: '2',
                 * 				filter: {...}
                 * 			}]
                 * 		}
                 * 		...
                 * 	}
                 *
                 * We must take these filter definitions and perform the following actions:
                 * 	(1) Create the filters.
                 * 		This involves creating Saved Filters and/or custom filters.
                 * 	(2) Creating the filters may result in modification of the filter contents (e.g. Saved Filters generates a new filter Id).
                 * 		If so, update all references to those filters to use the modified filters.
                 *
                 *	We should do this only the first time that the report initially loads, to avoid creating duplicate filters.
                 *
                 * @return {object} maps from each widget id to the updated widget state
                 */
                setupFilters: function setupFilters() {
                    var predefined = activeReport.getMetadata(CONTEXT_META.PREDEFINED);
                    var filtersArray = _.get(predefined, 'filters');
                    if (_.has(predefined, 'status.filters') || _.isEmpty(filtersArray)) {
                        // if predefined.status.filters is defined, 
                        // then we already tried setting up predefined filters
                        // don't try again (regardless of whether we failed or succeeded)
                        return $q.when();
                    }
                    return fieldsets.getMainFieldset().then(function createFilters(fieldset) {
                        validateFilters(filtersArray);
                        var customFilters = [];
                        var savedFilters = [];
                        _.forEach(filtersArray, function (filterDef) {
                            if (customFilterUtils.isCustomFilter(filterDef)) {
                                customFilters.push(filterDef);
                            }
                            else {
                                savedFilters.push(filterDef);
                            }
                        });
                        var fieldsetId = fieldset.definition.fieldSetId;
                        return $q.all([
                            setupCustomFilters(fieldsetId, customFilters),
                            setupSavedFilters(savedFilters)
                        ]);
                    }).then(function updateFilterIds(filterMaps) {
                        // Update the filter Ids to the new filters
                        var customFilterMap = filterMaps[0];
                        var savedFiltersMap = filterMaps[1];
                        // filterMap will map from the original filter id to the new filter definition after setup
                        var filterMap = _.assign({}, customFilterMap, savedFiltersMap);
                        return updatePredefinedFilterIds(filterMap).then(function () {
                            return updateWidgetFilters(filterMap);
                        });
                    }).catch(function (error) {
                        // Store the error status on the container
                        // so that we do not try setting these filters up again 
                        logGenericError(error, 'predefined filters');
                        _.set(predefined, 'status.filters', error);
                        activeReport.setMetadata(CONTEXT_META.PREDEFINED, predefined);
                    });
                }
            };
            /**
             * Checks if each filter in the array has a unique id.
             * @param  {array} filtersArray
             * @throws {Error} if the array is invalid
             */
            function validateFilters(filtersArray) {
                if (!_.isArray(filtersArray)) {
                    console.error(filtersArray);
                    throw new Error('Cannot setup predefined filters because it is not an array');
                }
                var ids = _.map(filtersArray, 'filterId');
                ids = _.uniq(_.compact(ids));
                if (filtersArray.length !== ids.length) {
                    console.error(filtersArray);
                    throw new Error('Cannot setup predefined filters. All filters must have a unique id');
                }
            }
            function logGenericError(error, data) {
                var msg = 'An error occurred setting up ' + data;
                console.error(msg, error);
            }
            /************************************
             *          CREATE FILTERS          *
             ************************************/
            /**
             * Creates and sets up the predefined Custom Filters.
             * These filters should not be stored by /filters-service.
             * They are stored on the container instead of the fieldset.
             * @return {object} maps from initial filter id to filter that is stored on the container
             */
            function setupCustomFilters(fieldsetId, filters) {
                var originalFilters = _.cloneDeep(filters);
                return customFilterService.updateFilters(fieldsetId, filters).then(function () {
                    var filterMap = {};
                    _.forEach(originalFilters, function (filterDef, index) {
                        // customFilters.updateFilters may update the filter ids
                        filterMap[filterDef.filterId] = filters[index];
                    });
                    return filterMap;
                });
            }
            /**
             * Sets up the predefined Saved Filters.
             * These filters should be stored by /filters-service.
             * @return {object} maps from initial filter id to filter that is stored by /filters-service
             */
            function setupSavedFilters(filters) {
                var filterMap = {};
                var newFilterDefsPromise = filters.map(function (filterDef) {
                    var originalId = filterDef.filterId;
                    // retrieve saved filter
                    return fetchSavedFilter(filterDef).then(function (newFilterDef) {
                        // successfully retrieved saved filter
                        filterMap[originalId] = newFilterDef;
                        return newFilterDef;
                    }).catch(function (error) {
                        // unexpected error; still attempt to setup other saved filters
                        logGenericError(error, 'a predefined saved filter');
                    });
                });
                return $q.all(newFilterDefsPromise).then(function () {
                    return filterMap;
                });
            }
            /**
             * Retrieves a filter def that is stored in /filters-service.
             * If the filter does not exist in /filters-service, will create a new filter.
             * @param  {object} filterDef
             * @return {object} filter definition in /filters-service
             */
            function fetchSavedFilter(filterDef) {
                var sourceId = fieldsets.getMainSourceId();
                var savedFiltersService = new SavedFiltersService(sourceId);
                return savedFiltersService.getExistingFilter(filterDef, {
                    // Treat existing filters with the same name/content as the same filter
                    // No need to create a new filter in that case
                    includeMatchingDefinition: true
                }).then(function (existingFilterDef) {
                    if (existingFilterDef) {
                        // saved filter already exists
                        return existingFilterDef;
                    }
                    // saved filter doesn't exist
                    // create a new one
                    return savedFiltersService.updateFilters({
                        newFilters: [{
                                filterName: filterDef.filterName,
                                filter: filterDef.filter
                            }],
                        filtersToDelete: [],
                        editedFilters: []
                    }).then(function (response) {
                        var filterId = response.newFilterIds[0];
                        return savedFiltersService.getExistingFilter({
                            filterId: filterId
                        });
                    });
                });
            }
            /************************************
             *           UPDATE FILTERS         *
             ************************************/
            /**
             * After setting up the custom filters and saved filters,
             * we must update the filter ids of the predefined filters on the container.
             * @param  {object} filterMap - maps from initial filter id to filter that is stored by /filters-service
             * @return {promise} the result of the request to update the container
             */
            function updatePredefinedFilterIds(filterMap) {
                var predefined = activeReport.getMetadata(CONTEXT_META.PREDEFINED);
                var filtersArray = _.get(predefined, 'filters');
                _.forEach(filtersArray, function (filterDef) {
                    var oldFilterId = filterDef.filterId;
                    if (filterMap[oldFilterId]) {
                        var newFilterDef = filterMap[oldFilterId];
                        filterDef.filterId = newFilterDef.filterId;
                    }
                });
                _.set(predefined, 'status.filters', STATUS.SUCCEEDED);
                return activeReport.setMetadata(CONTEXT_META.PREDEFINED, predefined);
            }
            /**
             * After setting up the custom filters and saved filters,
             * we must update the filters used by each widget.
             * @param  {object} filterMap - maps from initial filter id to filter that is stored on the container
             * @return {object} maps from each widget id to the updated widget state
             */
            function updateWidgetFilters(filterMap) {
                var pages = activeReport.getPages();
                var sourceId = fieldsets.getMainSourceId();
                var widgetStates = {};
                return $q.all(pages.map(function (page) {
                    return $q.all(page.widgets.map(function (widget) {
                        var containerId = activeReport.getReportId();
                        var sharedData = _.get(widget, 'state.sharedData');
                        var filters = _.get(sharedData, ['dataConfig', 'filters'], {});
                        filters = filters[sourceId] || filters;
                        if (_.isEmpty(filters)) {
                            return;
                        }
                        var filterId = filters.filterId;
                        var thisFilter = filterMap[filterId];
                        if (!thisFilter) {
                            console.error('Widget tried using unrecognized filter', widget._id, filterMap, filters);
                            return;
                        }
                        // Replace the old filter and replace with the predefined filter.
                        sharedData.dataConfig.filters = {};
                        sharedData.dataConfig.filters[sourceId] = convertToWidgetFilter(thisFilter);
                        rsState.setCachedState(widget._id, page._id, containerId, widget.state);
                        return rsState.saveConfig(widget._id, page._id, containerId, sharedData).then(function () {
                            widgetStates[widget._id] = widget.state;
                        });
                    }));
                })).then(function () {
                    return widgetStates;
                });
            }
            /**
             * Report filters are wrapped by filters-client in an additional
             * layer that includes metadata about the filter.
             * Widget filters are valid AE/RE filters with a "name" and
             * "filterId" property.
             * @param  {object} thisFilter - a predefined filter
             * @return {object} widgetFilter
             */
            function convertToWidgetFilter(thisFilter) {
                thisFilter = _.cloneDeep(thisFilter);
                return __assign(__assign({}, thisFilter.filter), { filterId: thisFilter.filterId, name: thisFilter.filterName });
            }
        }
    ]);
})(angular.module('qualtrics.pr'));
