"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONTEXT_META_VARS = void 0;
var CONTEXT_META_VARS;
(function (CONTEXT_META_VARS) {
    CONTEXT_META_VARS["BULK_FIELD_OPTIONS"] = "bulkFieldOptions";
    CONTEXT_META_VARS["CONNECTED_SOURCES"] = "connectedSources";
    CONTEXT_META_VARS["CUSTOM_COLOR_PALETTES"] = "customColorPalettes";
    CONTEXT_META_VARS["CUSTOM_FILTERS"] = "customFilters";
    CONTEXT_META_VARS["CUSTOM_METRICS"] = "customMetrics";
    CONTEXT_META_VARS["DASHBOARD"] = "dashboard";
    CONTEXT_META_VARS["DISPLAY_LOGICS"] = "displayLogics";
    CONTEXT_META_VARS["FILTERS"] = "filters";
    CONTEXT_META_VARS["GLOBAL_OPTIONS"] = "globalOptions";
    CONTEXT_META_VARS["HEADER_FOOTER"] = "headerFooter";
    CONTEXT_META_VARS["IS_PREVIEW_SUPPORTED"] = "isPreviewSupported";
    CONTEXT_META_VARS["IS_PUBLIC"] = "isPublic";
    CONTEXT_META_VARS["LAST_UPDATED_CLIENT_INSTANCE_ID"] = "lastUpdatedClientInstanceId";
    CONTEXT_META_VARS["PAGE_LAYOUT"] = "pageLayout";
    CONTEXT_META_VARS["PASSCODE_PROTECTED"] = "passcodeProtected";
    CONTEXT_META_VARS["PASSCODE_HASH"] = "passcodeHash";
    CONTEXT_META_VARS["PREDEFINED"] = "predefined";
    CONTEXT_META_VARS["PROJECT_COPY"] = "ProjectCopy";
    CONTEXT_META_VARS["PUBLIC_HASH"] = "publicHash";
    CONTEXT_META_VARS["RATER_DISPLAY_LOGICS"] = "raterDisplayLogics";
    CONTEXT_META_VARS["REPORT_IMPORT"] = "ReportImport";
    CONTEXT_META_VARS["SAVED_FILTERS"] = "savedFilters";
    CONTEXT_META_VARS["SURVEY_ID"] = "surveyId";
})(CONTEXT_META_VARS = exports.CONTEXT_META_VARS || (exports.CONTEXT_META_VARS = {}));
